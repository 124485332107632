import React from "react";
import PropTypes from "prop-types";

function LoginError({ errorStatusCode }) {
  return (
    <p className='centered'>
      {errorStatusCode === 401
        ? "Username or password is incorrect"
        : "Temporarily unable to login"}
    </p>
  );
}

LoginError.propTypes = {
  // The error status code from the last login attempt
  errorStatusCode: PropTypes.number,
};

export default LoginError;
