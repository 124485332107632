import React, { useEffect, useState } from "react";
import OrganizationList from './OrganizationList';
import NewOrganization from './NewOrganization';
import { useOrgListQuery } from "../../app/api";

export default function Organizations() {

  const [tabView, setTabView] = useState('organizations');

  const { data: orgList, isLoading, error } =  useOrgListQuery(); 
  const [activeOrgs, setActiveOrgs] = useState([]);
  const [archivedOrgs, setArchivedOrgs] = useState([]);

  useEffect(() => {
    if (orgList && !isLoading && !error) {
      setActiveOrgs(orgList.filter(org => org.org_status == 1));
      setArchivedOrgs(orgList.filter(org => org.org_status == 0));
    }
  }, [orgList, isLoading, error]);


  const tabViewScreen = () => {
    if (tabView == 'organizations') {
      return (
        <div className='user-list-display' style={{width: '100%'}}> 
          <OrganizationList organizations={activeOrgs} />
        </div>
      );
    }  
    else if (tabView === 'archived') { 
      return (
        <div className='user-list-display' style={{width: '100%'}}> 
          <OrganizationList organizations={archivedOrgs} />
        </div>
      );
    }
    else { 
      return (
        <div className='user-list-display'> 
          <NewOrganization />
        </div>
      )
    }
  }

  const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'fifth-page centered hover-point';
    }
    else {
      return 'fifth-page centered hover-point disabled';
    }
  }

  return (
    <div>
      <h1>Organizations</h1>
      <div className='full-page tabs'>
        <div className={tabClass('organizations')} onClick = {() => {setTabView('organizations')}}>
          <h3> Active ({activeOrgs && activeOrgs.length})</h3>
        </div>
        <div className={tabClass('archived')} onClick = {() => {setTabView('archived')}}>
          <h3> Archived ({archivedOrgs && archivedOrgs.length})</h3>
        </div>
        <div className={tabClass('new_org')} onClick = {() => {setTabView('new_org')}}>
          <h3> Create New</h3>
        </div>
      </div>
      <div className='break'/>
      {tabViewScreen()}
     </div>
  )
}