import React from "react"; import {useRef, useEffect, useState} from 'react';
import d3 from "d3";
import { useSelector } from "react-redux";
import { hierarchy, treemap } from "d3-hierarchy";
import PropTypes from "prop-types";
import { donutColors } from "../../data/WidgetData";
import { useSelectAudienceTopicsQuery } from "../../app/api";
import { createGAQuery } from "../../data/WidgetData";
import { selectSearchString, selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { topicMappings } from "../../data/HeadlineData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import Loading from "../UIElements/Loading";
import { useDispatch } from "react-redux";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";

export default function TopicTreeMap({type, audience_code}) {

  const filter = useSelector(selectWidgetFilters);
  const currentDateRange = useSelector(selectCurrentHeadlineDateRange);
  const [gaQueryString, setGaQueryString] = useState(createGAQuery(filter, currentDateRange));
  const [hasFetched, setHasFetched] = useState(false);
  const [myTopicData, setMyTopicData] = useState([]);
  const [inTopicString, setInTopicString] = useState('all');
  const [outTopicString, setOutTopicString] = useState('none');
  const savedInTopics = useSelector(selectInTopics);
  const savedOutTopics = useSelector(selectOutTopics);
  const searchString = useSelector(selectSearchString);

  const dispatch = useDispatch();

  const {data: topicData, refetch: refetchTopics, isFetching: topicsFetching} = useSelectAudienceTopicsQuery({type:type, include: inTopicString, exclude: outTopicString, audience_code: audience_code, filter_query: gaQueryString, headline_search: searchString == '' ? 'all': searchString});

  useEffect(() => {
    if (savedInTopics.length > 0 && savedInTopics[0].length > 0 ) {
      let newArray = mergeByTopic(savedInTopics, topicMappings);
      setInTopicString(newArray.join(",")); 
    }
    else {
      setInTopicString('all'); 
    }

  }, [savedInTopics]);

  useEffect(() => {
    if (savedOutTopics.length > 0 && savedOutTopics[0].length > 0 ) {
      let newArray = mergeByTopic(savedOutTopics, topicMappings);
      setOutTopicString(newArray.join(",")); 
    }
    else {
      setOutTopicString('all'); 
    }

  }, [savedOutTopics]);

  useEffect(() => {
    if (!topicsFetching) {
      // if only one topic is selected, so details of that topic.  Otherwise summarize by high level topic category
      if (savedInTopics.length == 1) {
        setMyTopicData(convertData(topicData));
      }
      else {
        setMyTopicData(convertData(topicsToCategories(topicData)));
      }
      if (!hasFetched) {
        setHasFetched(true);
      }
    }
  }, [topicsFetching])


  useEffect(() => {
    if (hasFetched) {
      setGaQueryString(createGAQuery(filter, currentDateRange));
      refetchTopics();
    }
  },[filter, currentDateRange, savedInTopics, savedOutTopics])

  const convertData = (data) => {
    let childs = [];
    let totalTopicCount = 0;

    data?.map((p) => { // Gather Total Topic Count
       totalTopicCount += parseInt(p.all_count);
    });

    data?.map((t) => {
      if (t.topics && t.topics.replace(' ','') != '' && t.topics != 'undefined') {
        childs.push({name: t.topics, value: t.all_count, percentage: ((t.all_count / totalTopicCount) * 100).toFixed(2) + `%`});
      } 
    });

    return {name: "No Results",
            children: childs
            }
  }

  const fontSize = 16;

  const svgRef = useRef();

  // Set default height | width values
  let width = 1000;
  const height = 200;

  let treemap_view = document.getElementsByClassName("treemap")[0];
  if (treemap_view) { // Attempt to set screen width of treemap
    width = treemap_view.clientWidth - 160; // 160px is the padding being accounted for
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);
    console.log(svg); // otherwise mad I don't use svg
  }, []);

  const renderTreemap = () =>  {

 /*   const element = document.getElementById('treemap');
    if (element) {
      element.remove();
    }

    const tree_element = document.createElement('div');
    tree_element.id = 'treemap';
    const svg_element = document.createElement('svg');
    svg_element.ref = svgRef;
    tree_element.appendChild(svg_element);
  
    const parent_element = document.getElementById('treemap-wrapper');
    parent_element.appendChild(tree_element);
*/
    const svg = d3.select(svgRef.current); 
    svg.attr('width', width).attr('height', height);

    const root = 
      hierarchy(myTopicData)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    const treemapRoot = treemap().size([width, height]).padding(1)(root);

    let tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '0px')
      .style('border-radius', '5px')
      .style('padding', '15px');

    const nodes = svg
      .selectAll('g')
      .data(treemapRoot.leaves());
    
    nodes
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

    const colorScale = d3.scale.ordinal().range(donutColors);

    nodes
      .append('rect')
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => colorScale(d.data.name))
      .on('mouseover', function() {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function(d) {
        tooltip
        .attr('font-size', '12px')
        .style('font-size', '12px')
        .style('background', 'rgba(0,0,0,0.7)')
        .style('color', "#ffffff")
        .style('top', d3.event.pageY + 20 + 'px')
        .style('left', d3.event.pageX - 30 + 'px')
        .text(`${d.data.name} - ${d.data.percentage}`);
      })
      .on('mouseout', function() {
        tooltip.style('visibility', 'hidden');
      })
      .on('click', function(d) {
        if (savedInTopics.length != 1) {
          adjustInTopics(d.data.name);
        }
      });

    nodes
    .append('text')
    .text((d) => (d.x1 - d.x0) < 55 ? `${d.data.name.substring(0,1)}` : (d.x1 - d.x0) < 125 ? `${d.data.name.split(' ')[0]} ${d.data.percentage}` : `${d.data.name} ${d.data.percentage}`)
    .attr('data-width', (d) => d.x1 - d.x0)
//    .attr('font-size', (d)  => (d.x1 - d.x0) < 125 ? '11px' : '16px')
    .attr('fill', 'white')
    .attr('x', 10)
    .attr('y', fontSize + 10)
    .call(wrapText);


  } 

  function wrapText(selection) {
    selection.each(function () {
      const node = d3.select(this);
      const rectWidth = +node.attr('data-width');
      let word;
      const words = node.text().replace(/and/g, '&').split(' ').reverse();
      let line = [];
      const x = node.attr('x');
      const y = node.attr('y');
      let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
      let lineNumber = 0;
      if (words.length > 1) {
        while (words.length > 1) {
          word = words.pop();
          line.push(word);
          tspan.text(line.join(' '));
          const tspanLength = tspan.node().getComputedTextLength();
          if (tspanLength > (rectWidth - 10) && line.length !== 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = addTspan(word);
          }
        }
        addTspan(words.pop());
      }
      else {
        node
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', `${(lineNumber * fontSize)}px`)
          .text(words.pop());
      }
      
      //addTspan(words.pop());
  
      function addTspan(text) {
        lineNumber += 1;
        return (
          node
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', `${(lineNumber * fontSize)+(lineNumber * 5)}px`)
            .text(text)
        );
      }
    }); 
  }

  useEffect(() => {
    if (myTopicData)  {
      renderTreemap();
    }
  }, [myTopicData]);

  const adjustInTopics = (topic) => {
    /* remove all tooltips that might be showing and get stuck */
      let tooltipDivs = document.getElementsByClassName('d3-tooltip') ;
      while (tooltipDivs[0]){
        tooltipDivs[0].parentNode.removeChild(tooltipDivs[0]);
      }
    /* end remove tooltips */

    // adjust filter
    let newArray = [...savedInTopics];
    let index = newArray.indexOf(topic);
    if(index !== -1) { // exists
      newArray.splice(index,1);
      dispatch(saveCurrentView({type:'inTopics', value: newArray}));
    }
    else { // add
      newArray = [...savedInTopics, topic];
      dispatch(saveCurrentView({type:'inTopics', value: newArray}));
    }
  }

  return (
    <div className='treemap'>
      <h3>{type == 'affinity' ? "Affinity Topics" : "Headline Content Topics" }</h3>
      <div id='treemap-wrapper'>
        <div id='treemap'>
          {!topicsFetching && <svg ref={svgRef} />}
          {topicsFetching && <Loading />}
        </div>
      </div>
    </div>
  );
}
    

TopicTreeMap.propTypes = {
  type: PropTypes.string, 
  audience_code: PropTypes.string,
}


export const topicsToCategories = (data) => {
  let newArray = [];
  data.map(t => {
    const mappedValue = topicMappings.find(r => r.affinity_topic == t.topics)
    if (mappedValue) {
      const existingCount = newArray.find(l => l.topics == mappedValue.category);
      if (existingCount){  // add to count
        newArray.find(l => l.topics == mappedValue.category).all_count = parseInt(existingCount.all_count) + parseInt(t.all_count);
      }
      else {
        newArray.push({topics: mappedValue.category, all_count: t.all_count})
      }
    }
  })
  return newArray;  
}