import React from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "./authSlice";
import { Navigate, Outlet } from "react-router-dom";

export default function AuthRequired() {
  if (useSelector(selectUserName)) {
    return <Outlet />
  } else {
    return <Navigate to={"login"} />;
  }
}
