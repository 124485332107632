import React from 'react';
import PropTypes from "prop-types";
import { widgetDisplay } from "../../data/WidgetData";
import {downloadCSVTimestamp, downloadCSVFullTimestamp} from "../userSettings"; 

/**
 * This is the module which is specifically for exporting the Comparison Table data
 * 
 * @param {*} param0 
 * @returns 
 */
export default function ComparePageCSVExport({audienceData, widgetList, audiences}) {

  const VISIBILITY_THRESHOLD = 1;

  const getAudienceTitle = (audience) => {
    var audience1title = audiences.filter(obj => {
      return obj.audience === audience
    });
    
    return audience1title[0].title;
  }

  const getAudienceCount = (audience) => {
    const filteredAudiences = audiences.filter(obj => obj.audience === audience);
    const firstAudience = filteredAudiences[0];
    
    if (firstAudience && !isNaN(firstAudience.count)) {
      return parseInt(firstAudience.count, 10).toLocaleString();
    } else {
      return 'N/A'; 
    }
  }
    
  const handleDownloadComparison = () => {

    const allAudiences = new Set(audienceData[0].audiences.map(item => item.audience));
    const audiencesArr = Array.from(allAudiences);
  
    let downloadTimestamp = downloadCSVTimestamp();
    let downloadFullTimestamp = downloadCSVFullTimestamp();
    let result = "Compare Results"+ "\n";
    result += "Date: ," + downloadFullTimestamp + "\n\n";
    result += "Audiences:,," + audiencesArr.map(getAudienceTitle).join(",") + "\n";
    result += "Record Count:,,\"" + audiencesArr.map(getAudienceCount).join("\",\"") + "\"\n\n";
    result += "Demographic,Demographic Attribute" +"\n\n";
 
    widgetList.forEach(widget => {
      const widgetName = widgetDisplay.filter(w => w.widget === widget)[0].displayName
      const audienceDataEntry = audienceData.find(entry => entry.widget === widget);
      if (audienceDataEntry && audienceDataEntry.audiences) {
        let audience1categories = audienceDataEntry.audiences[0].categories;
        let audience2categories = audienceDataEntry.audiences[1].categories;
        result += `${widgetName}, , , \n`;
        Object.entries(audience1categories).forEach((category) => {
          if (category[1].percentage >= VISIBILITY_THRESHOLD && audience2categories[category[0]].percentage >= VISIBILITY_THRESHOLD) {
            result += ",\"" + category[0] + "\"," + category[1].percentage + "%," + audience2categories[category[0]].percentage + "%\n"
          }
        })
        result += "\n";
      }
    })

    result += '\n';

    const url = window.URL.createObjectURL(new Blob([result])) 
    const link = document.createElement('a')
    link.href = url
    // TODO: This filename could be more customized
    const fileName = "Compare - " + audiencesArr.map(getAudienceTitle).join(" - ")  + ' - ' + downloadTimestamp +".csv";
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
      <>
        <button id='comparison-csv-download' onClick={()=>{handleDownloadComparison()}} style={{display: 'none'}}>Download CSV</button>
      </>
  );
};

ComparePageCSVExport.propTypes = {
  audienceData: PropTypes.array,
  widgetList: PropTypes.array,
  audiences: PropTypes.array
}