import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";


export default function ChatGraphic({ graphicalAudienceHeadline }) {

  const navigate = useNavigate();

  const goToAudience = (graphicalAudienceHeadline) => {
    let audiencePath = '';
    if (graphicalAudienceHeadline.cluster == 1) {
      audiencePath = 'cluster-audience'
    }
    else {
      audiencePath = 'audience'
    }
    navigate(`/dashboard/${audiencePath}?uuid=${graphicalAudienceHeadline.uuid}`);
  }

  return (
    <div className="ai_graphical">
      <h2 className="ai_graphical_h2"></h2>
      <button className="ai_graphical_image_button" onClick={() => { goToAudience(graphicalAudienceHeadline) }}>
        <p>{graphicalAudienceHeadline.title}</p>
        <i className="fa fa-users"></i>
        <p>Click for audience details</p>
      </button >
    </div>
  )
}

ChatGraphic.propTypes = {
  image: PropTypes.string,
  graphicalAudienceHeadline: PropTypes.object
}