import React from "react";
import Organization from "./Organization";
import PropTypes from "prop-types";

export default function OrganizationList({organizations}) {

  return (
    <div>
      {!organizations ? 
        <h4>Loading Organizations...</h4>
      : 
        <div>
          {organizations.map((org) => (
            <Organization key={org.id} organization={org} />
          ))}
        </div>
      }
    </div>
  )
}

OrganizationList.propTypes = {
  organizations: PropTypes.array
}
