import React, {useEffect, useState} from "react";
import { useGetHeadlineGroupsQuery } from "../../../app/api";
import { useSelector } from "react-redux";
import { useGetHeadlineAthenaGroupsQuery } from "../../../app/api";
import { HeadlineTile } from "../../UIElements/HeadlineTile";
import { PropTypes } from "prop-types";
import { selectCurrentHeadlineDateRange } from "../../../features/audiences/audienceSlice";
import { selectUserName } from "../../../features/auth/authSlice";
import { useGetUserCategoriesQuery } from "../../../app/api";
import { topicMappings } from "../../../data/HeadlineData";
import { mergeByTopic } from "../Insights";
import { selectSearchString, selectInterestStates } from "../../../features/currentView/currentViewSlice";
import { createGAQuery } from "../../../data/WidgetData";
import Loading from "../../UIElements/Loading";

// Trending topics filters by User's interest Categories
export function TrendingHeadlines({insights}) {

  const username= useSelector(selectUserName);
  const searchString = useSelector(selectSearchString);
  const interestStates = useSelector(selectInterestStates);

  const {data: userCategories, isFetching: fetchingCategories} = useGetUserCategoriesQuery({username: username});

  const {data:headlines}  = useGetHeadlineGroupsQuery();
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  
  const [inTopicString, setInTopicString] = useState('all');
  const [outTopicString] = useState('none');

  const filterQuery = createGAQuery({state: interestStates.split(',')}, currentHeadlineDateRange);

  const {data: athenaHeadlines, isFetching: fetchingHeadlines}  = useGetHeadlineAthenaGroupsQuery({type: 'all-audiences', headline: 'all', headline_search: searchString == '' ? 'all' : searchString, include: inTopicString, exclude:outTopicString, audience_code: 'all', filter_query: filterQuery }, {skip: (!currentHeadlineDateRange || fetchingCategories )});



  const [mergedHeadlines, setMergedHeadlines] = useState([]);

  useEffect( () => {
    if (userCategories.length > 0) {
      let categoryArray = [];
      userCategories.map(t => {
        categoryArray.push(t.title);
      })
      let newArray = mergeByTopic(categoryArray, topicMappings);
      console.log('newArray is', newArray, userCategories);
      setInTopicString(newArray.join(','));
    }

  }, userCategories);

  useEffect( () => {
      if (headlines && athenaHeadlines) {
        const map = new Map();
        headlines?.forEach(item => map.set(item.headline, item));
        athenaHeadlines?.forEach(item => map.set(item.headline, {...map.get(item.headline), ...item}));
        const mergedArr = Array.from(map.values());
        setMergedHeadlines(mergedArr.filter(t => t.all_count)); // only list headlines that are active from athena   
      }
  }, [headlines, athenaHeadlines]);

  const headlineList = () => {
    if (fetchingHeadlines) {
      return (
      <>
        <Loading margin={true}/>
      </>)
    }
    else {
      if (mergedHeadlines.length > 0) {
        let arrayForSort = [...mergedHeadlines];
        return arrayForSort?.sort((a,b) => parseInt(a.all_count) > parseInt(b.all_count) ? -1 : 1)
        .slice(0,20)
        .map((t,i) => (
          <HeadlineTile key={i} headline={t} grouped={true}/>
        ));
      }
      else {
        return (
          <div className='bumper-page grey '>
            No matching Headlines. Please choose more interest categories.
          </div>
        );
      }
    }
  }

  return (
    <>
      {insights && <h1 className='cinq-title'>Insights Into What is Trending Today</h1>}
      <div className={insights ? 'top-bump-40' : ''} >
         {headlineList()}
      </div>
    </>
  )

}

TrendingHeadlines.propTypes = {
  insights: PropTypes.bool,
}