import React, {useRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { selectUserRole, selectOverride, save } from "../../features/auth/roleSlice";
import logo from "../../images/cinq-logo.png";
import { useNavigate } from "react-router-dom";
import { saveDirectory } from "../../features/directory/directorySlice";
import { saveCurrentView, selectInterestStates } from "../../features/currentView/currentViewSlice";

export default function ClientHeader() {

  const userRole = useSelector(selectUserRole);
  const override = useSelector(selectOverride);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchField = useRef();
  const [showApply, setShowApply] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const interestStates = useSelector(selectInterestStates);



  function searchAudience() {
    dispatch(saveCurrentView({type: 'searchString', value: searchField.current.value}));
    setShowApply(false);
    setShowCancel(true);
  }

  function clearSearch() {
    searchField.current.value='';
    dispatch(saveCurrentView({type: 'searchString', value: searchField.current.value}));
    setShowApply(false);
    setShowCancel(false);
  }

  const headerLinks = () => {
    if (userRole.includes('ADMIN') && override == 'NO') {
      return (
        <div className='client-header-option-div'>
          <div className='admin-header-name'> 
             <div className='admin'>  </div>
             <div className='name'>  </div>
          </div>
        </div>
      )
    }
  }

  const turnOffOverride = () => {
    console.log('saving override to NO');
    dispatch(save({type: 'override', value: 'NO'}));
  }

  const adminOption = () => {
    if (userRole.includes('ADMIN')){
      return (
        <div onClick= {() => {turnOffOverride(); navigate('/dashboard'); }} className='admin_dashboard_toggle'>
          <i className='fa fa-solid fa-lock-keyhole' title='Admin Dashboard'></i>
        </div>
      )
    }
  }

  const headerView = () => { 
    if (userRole != "PENDING") { 
      return (
       <div className="client_dashboard_header">
         <div className="client_dashboard_header_wrapper">
            <div className='header_logo_interest_state_wrapper'>
             <img className='cinq-logo' src={logo} onClick={() => {dispatch(saveDirectory({type:'clientPage', value:'client-search'}));navigate('/dashboard');}}/>
              { interestStates.replace(/ /g,'') != 'all'  && 
                <div className='interest-states'>
                  <i className='fa-solid fa-location-dot'/>
                  <p>States: {interestStates}</p>
                </div>
              }
            </div>
          {headerLinks()}
         <div className='dashboard-search'>
           <i className="fa-solid fa-magnifying-glass"></i>
           <input ref={searchField} onChange={()=>{setShowApply(true); setShowCancel(false);}} type='text' className='search-text' placeholder='Search by keyword or phrase' maxLength={40}>
           </input>
           {showApply && <button className='dashboard-search-button' onClick={()=> {searchAudience()}}>Apply</button>}
           {showCancel && <button className='dashboard-search-button' onClick={()=>{clearSearch()}}>Clear</button>}
         </div>
         </div>
        {adminOption()}
       </div>
      )
    }
    else {
      return (
       <div>
         <div className="client_dashboard_header">
          <div className="body">
            <div className='header_logo_wrapper'>
              <img className='cinq-logo' src={logo}/>
            </div>
          </div>
         </div>
       </div>
      )
    }
  }
  
  return (
    <>
      {headerView()}
    </>
  )
}
