import React from "react";
import PropTypes from "prop-types";
import ChatFaqItem from "./ChatFaqItem";

export default function ChatFaq({ faqs, selectFaq, lockUser }) {

  const buildFaqs = faqs?.map((h) => (
    <ChatFaqItem key={'chatFaqItemIterator_' + h.id} selected_faq={h} question={h.question} selectFaq={selectFaq} lockUser={lockUser} />
  ))

  return (
    <div>
      <h2 className="ai_ask_h2"> ASK CINQ</h2>
      <ul className="ai_ask_ul">
        {buildFaqs}
      </ul>
    </div>
  )
}

ChatFaq.propTypes = {
  faqs: PropTypes.array,
  selectFaq: PropTypes.func,
  lockUser: PropTypes.bool
}