import React, {useState} from "react";
import { isWidgetHearted } from "../../userSettings";
import Widget from "../../Widgets/Widget";
import PropTypes from "prop-types";
import { widgetDisplay } from "../../../data/WidgetData";
import HeatMap from "../../Widgets/HeatMap";
import USADensityMap from "../../UIElements/Maps/USADensityMap";
import CompleteWidgetCSVDownloadLink from "../../Widgets/CompleteWidgetCSVDownload";
import Loading from "../../UIElements/Loading";

export default function Demographics({userWidgetData, selectedAudience}) {
  const [runCSVDownloader, setRunCSVDownloader] = useState(false);
  
  let showMap = true;
  const widgetList = 
    widgetDisplay
    .filter((t) => t.category=='demographics')
    .filter((t) => t.hasL2)
    .filter((t) => t.order > 0)
    .map((t,i) => (
      <div key={i} className='mini-half-page leftside widget-bump'>
        {selectedAudience && <Widget widgetType={'bar'} hearted={isWidgetHearted(userWidgetData, t.widget)} passedType={t.widget} selectedAudience={selectedAudience} filterByClick={true} />}
      </div>
    ))

  const csvDownloader = () => {
    if (runCSVDownloader) {
      return (
        <div className='allWidgetCSVDownloader'>
          <Loading/>
          <CompleteWidgetCSVDownloadLink  selectedAudience={selectedAudience} widgetList={widgetDisplay} setRunCSVDownloader={setRunCSVDownloader}></CompleteWidgetCSVDownloadLink>
        </div>
      )
    }
    else {
      return (
        <div className='allWidgetCSVDownloader'>
          <button onClick={() => (setRunCSVDownloader(true))}>
            <i className='fa-light fa-arrow-down-to-bracket'></i>
            <p>Download</p>
          </button>   
        </div>
      )
    }
  }

  return (
    <div className='breakdown-page overview'>
      <div className='breakdown-display'>
        <div className='bumper-page'>
          {!showMap && selectedAudience && <HeatMap size={1} selectedAudience={selectedAudience}/>}
          { showMap && <USADensityMap selectedAudience={selectedAudience}/>}
        </div>
        <div className='other-widgets'>
          {widgetList}
          {csvDownloader()}
        </div>
      </div>
    </div>
  )
}

Demographics.propTypes = {
  userWidgetData: PropTypes.array,
  selectedAudience: PropTypes.object,
}