import React from "react";
import { isWidgetHearted } from "../../userSettings";
import Widget from "../../Widgets/Widget";
import PropTypes from "prop-types";
import HeatMap from "../../Widgets/HeatMap";

export default function Overview({userWidgetData, selectedAudience}) {

  const widgetLength = (userWidgetData || []).length;


  const widgetComponent = (favorite) => {
      return  <>{selectedAudience && <Widget widgetType={'bar'} hearted={isWidgetHearted(userWidgetData, favorite.widget_name)} passedType={favorite.widget_name} selectedAudience={selectedAudience}/>}</>
    }

  const overviewFavorites = (countDown) => {
    let counter = widgetLength - countDown;

    var first;
    var second;
    if (widgetLength % 2 == 0) {
      first = counter - 1;
      second = counter - 2; 
    }
    else {
      first = counter;
      second = counter - 1;
    }
   
    if (counter % 2 == 0) {
      return (
        <>
          <div className='mini-half-page leftside widget-bump' >
            {widgetComponent(userWidgetData[first])}
          </div>
          <div className='mini-half-page leftside'>
            {widgetComponent(userWidgetData[second])}
          </div>
        </>
      )
    }
    if (!(widgetLength % 2 == 0) && counter == 1) {
      return (
        <>
          <div className='mini-half-page leftside widget-bump' >
            {widgetComponent(userWidgetData[0])}
          </div>
        </>
      )
    }
  }

  const overviewDisplay = () => {
    // TODO: compute default by audience name - maybe an audience array attribute
    if (widgetLength === 0){
      return (
        <>
          <div className='mini-half-page leftside widget-bump' >
            {widgetComponent({widget_name: 'age_range'})}
          </div>
          <div className='mini-half-page leftside'>
            {widgetComponent({widget_name: 'ethnicity'})}
          </div>
        </>
      )
    }
    else {
      return (
        userWidgetData.map((w, i) => (
          <div key={i}> {overviewFavorites(i)} </div>
        ))
      )
    }

  }

  return (
    <div className='breakdown-page overview'>
      <div className='mini-half-page leftside widget-bump' >
        <div className='widget'>
          <div className='header'>
            <p>Geography</p>
          </div>
          <div className='widget-div'>
            {selectedAudience && <HeatMap size={1} selectedAudience={selectedAudience}/>}
          </div>
        </div>
      </div>
      <div className='breakdown-display'>
        {overviewDisplay()}
      </div>
    </div>
  )
}

Overview.propTypes = {
  userWidgetData: PropTypes.array,
  selectedAudience: PropTypes.object,
}