import React, {useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Datamap from 'datamaps/dist/datamaps.usa.min.js';
import d3 from 'd3';
import { useSelectWidgetDataQuery } from "../../app/api";
import { selectWidgetData,  getFilter } from "../../features/currentView/currentViewSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createHeatmapObject, createNullHeatmapObject } from '../../data/WidgetData';
import Loading from "../UIElements/Loading";
import PropTypes from "prop-types";

export default function HeatMap({selectedAudience}) {

  const widgetData = useSelector(selectWidgetData);
  const filter = useSelector(selectWidgetFilters);

  const [rawdata, setRawdata] = useState([]);

  const {refetch: refetchData, isLoading: dataLoading} = useSelectWidgetDataQuery(getFilter('state', selectedAudience?.audience, 'audience', filter), {skip: !selectedAudience});

  useEffect(() => {
    if(widgetData['state'].length > 0) {
        if (widgetData['state'][0] == 'none') {
          setRawdata(createNullHeatmapObject());
        }
        else {
          setRawdata(createHeatmapObject(widgetData['state']));
        }
    }
    else {
      if (!dataLoading && widgetData['state'].length == 0) {
        refetchData();
      } 
    }
  }, [widgetData['state']]);

  useEffect(() => {

    if ((widgetData['state']?.length >= 1 && widgetData['state'][0]?.audience == selectedAudience?.audience) || (widgetData['state'] && widgetData['state'][0] == 'none') ) { 
      const element = document.getElementById('usa_map');
      if (element) {
        element.remove();
      }
      const map_element = document.createElement('div');
      map_element.id = 'usa_map';
      const parent_element = document.getElementById('usa_map_holder');
      parent_element.appendChild(map_element);

      let dataset = {};
      let onlyValues = Object.values(rawdata);
      let minValue = Math.min.apply(null, onlyValues),
          maxValue = Math.max.apply(null, onlyValues);

      let paletteScale = d3.scale.linear()
        .domain([minValue, maxValue])
        .range(["#EFEFFF", "#02386F"]); // blue color


      for (let key in rawdata) {
        let iso = key,
            value = rawdata[key];
        dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
      }

      const map = new Datamap({
        scope: 'usa',
        element: document.getElementById('usa_map'),
        height: 400,
        geographyConfig: {
          highlightOnHover: false,
          borderColor: '#444',
          highlightBorderWidth: 1,
          borderWidth: 0.5,
        },
        data: dataset
      });
      map.labels({fontFamily: 'Ubuntu, Poppins, Verdana'});
    }
  }, [rawdata]);

  const loadingMap = () => {
    return (
      <>
        {(!rawdata) && <Loading />}
        <div id='usa_map_holder' className='smaller' />
      </>
    )
  }


  return <>
    {loadingMap()}
  </>
}

HeatMap.propTypes = {
  selectedAudience: PropTypes.object,
}