import React from "react";
import PropTypes from "prop-types";
import default_image from "../../../images/default_image_gray.png";

export default function Headline({img,title,date,blurb,type,categories, onClickFunction}) {

  const determineImage = () => {

    if (img) {
      return (
          <img src={img}/>
      )
    }
    else {
      return (
        <img src={default_image}/>
      )
    }
  }

  const longVersion = 
    <div className='headline'>
      <div className='headline_image_wrapper'>
        {determineImage()}
      </div>
      <div className='headline_info_wrapper'>
          <p className='title' onClick={onClickFunction}> {title} </p>
          <p className='blurb'> {blurb} </p>
          {categories}
      </div>
    </div>

  const condensedVersion = 
    <div className='headline condensed'>
      <div className='title' onClick={onClickFunction}>{title}</div>
      <div className='date hidden'> {date} </div>
    </div> 

  const headlineType = () => {
    if (img != undefined || title != undefined || blurb != undefined || type != undefined || categories != undefined) {
      if (type == 'condensed') {
        return condensedVersion
      }
      else {
        return longVersion
      }
    }
  }

  return (
    <>
      {headlineType()} 
    </>
  )
}

Headline.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  blurb: PropTypes.string,
  type: PropTypes.string,
  categories: PropTypes.array,
  onClickFunction: PropTypes.func,
}