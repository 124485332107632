import React, {useEffect, useState} from "react";
import { useGetUserCategoriesQuery, useGetUserByUsernameQuery, useRemoveUserToCategoryMutation } from "../../app/api";
import { useAllCategoriesQuery , useAddUserToCategoryMutation, useGetAudienceCategoriesQuery} from "../../app/api";
import { useSelector } from "react-redux";
import { selectUserName } from "../../features/auth/authSlice";
import CategoryButton from "./CategoryButton";
import { selectPreferenceChange } from "../../features/currentView/currentViewSlice";
import PropTypes from "prop-types";

// type == 'user' or 'audience' 
export default function Topics({type, audience, recommended}) {

  const username = useSelector(selectUserName);
  const {data: userCategories, refetch: refetchCategories} = useGetUserCategoriesQuery({username: username});

  const queryParameters = new URLSearchParams(window.location.search);
  const customAudience = queryParameters.get("custom");
  const {data: user, refetch: refetchUser} = useGetUserByUsernameQuery({username: username, custom_override: customAudience});

  const {data: categories} = useAllCategoriesQuery();
  const {data: audienceCategories} = useGetAudienceCategoriesQuery({audience: audience}, {skip: !audience});
  const [addUserToCategory] = useAddUserToCategoryMutation();
  const [removeUserToCategory] = useRemoveUserToCategoryMutation();
  const preference = useSelector(selectPreferenceChange);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    refetchCategories();
    refetchUser();
  }, [preference]);

  const listUserCategories = () => {
    return (userCategories || []).map((cat, i) => (
      <div className='inline-list' key={i}>
        <div>
          <CategoryButton label={cat.title} type={'display'}/>
        </div>
      </div>
    ))
  }

  const listUserForEditCategories = () => {
    return (userCategories || []).map((cat, i) => (
      <div className='inline-list' key={i}>
        <div onClick={()=>{removeCategory(cat.id)}} >
          <CategoryButton label={cat.title} type={'remove'}/>
        </div>
      </div>
    ))
  }

  const listAudienceCategories = () => {
    console.log('audience Categories are', audienceCategories, audience);
    return (audienceCategories || []).map((cat, i) => (
      <div className={!recommended && i == 0 ? 'inline-list first-left' : 'inline-list'} key={i}>
        <CategoryButton label={cat.title} type={'display'} className={!recommended && i == 1 ? 'first-left' : ''}/>
      </div>
    ))
  }

  const categoryOptions = () => {
    return (categories || [])
      .filter((cat) => { return userCategories && !userCategories.find((t) => t.title == cat.title) })
      .map((cat, i) => (
        <div key={i} onClick={()=>{addCategory(cat.id)}}> 
          <CategoryButton label={cat.title} type={'add'}/>
        </div>
    ))
  }

  const addCategory = (id) => {
    addUserToCategory({user_id: user.id, category_id: id});
  }

  const removeCategory = (id) => {
    removeUserToCategory({user_id: user.id, category_id: id});
  }

  const topicPopUp = () => {
    if (showPopup) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setShowPopup(false)}}/>
            <div className='centered'>
              <h3> Your Categories </h3>
              {listUserForEditCategories()}
              <div className='break'/>
              <h3>Additional Categories</h3>
              <div className='add-topics'>
                {categoryOptions()}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  // NOTE:  recommended was originally passed because the topics were not to show for bookmarked audience tiles

  const listCategories = () => {
    if (type == 'user' ) {
      return (
        <>
          <div className='user-topics'>
            {topicTitle()}
            {listUserCategories()}
          </div>
        </>
      )
    }
    else {
      if (recommended) {
        return (
          <div className='user-topics '>
            {recommended && <p className=''>Because you are interested in:</p>}
            {listAudienceCategories()}
          </div>
        ) 
      }
      else {
        return (
          <div className='user-topics '>
            {listAudienceCategories()}
          </div>
        ) 
      }
    }
  }

  const topicTitle = () => {
    if (type == 'user') {
      return (
        <div className="topic_title_wrapper">
          <p className='inline'>YOUR INTEREST CATEGORIES</p>
          <a className="fa-solid fa-pen-to-square" href="/dashboard/account-settings/?interest-categories=y"></a>
        </div>
      )
    }
  }

  return (
    <div className={type=='user'?'topic-list': 'topic-list topic_audience_tile'}>
      {listCategories()}
      {topicPopUp()}
     </div>
  )
}

Topics.propTypes = {
  type: PropTypes.string,
  audience: PropTypes.string,
  recommended: PropTypes.bool,
}