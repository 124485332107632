import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectUserName, selectUserRole} from "../features/auth/authSlice";
import LoginForm from "../features/auth/LoginForm";
import Footer from "../routes/Home/Footer";
import {Navigate} from "react-router-dom";
import Header from "../routes/Home/Header";

export default function Login() {

    const navigate = useNavigate();
    const userName = useSelector(selectUserName);
    const userRole = useSelector(selectUserRole)
    // If already logged in, redirect to the client dashboard page
    if (userName && userRole !== "CLIENT") {
        return <Navigate replace to="../dashboard"/>;
    } else {

        // Render the login form
        return (
            <div className='login-page full-page centered'>
                <Header/>
                <img src="https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/Icon-KO.png" alt="" title="Icon-KO"
                     className='login-logo hidden' onClick={() => {
                    navigate(0)
                }}></img>
                <div
                    style={{
                        height: '50px',
                        backgroundColor: '#6366F1',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    className='centered'>
                    <p>
                        {"You're almost there! Our login page has moved – please use this "}
                        <a style={{color: '#FDE68A'}} href="https://mvd.se.cinqdi.com/login" target="_blank"
                           rel="noopener noreferrer">
                            NEW Link
                        </a>
                        {" to access your account."}
                    </p>
                </div>
                <LoginForm/>
                <Footer showAsPopup={false}></Footer>
            </div>
        );
    }
}
