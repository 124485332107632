import React from "react";
import { useSelector } from "react-redux";
import ClientHome from "./ClientHome";
import AudienceExplore from "./AudienceExplore";
import History from "./History";
import Metrics from "./Metrics";
import Settings from "./Settings";
import Methodology from "./Methodology";
import Activated from "./Activated";
import AiBot from "./AI/AiBot";
import Terms from "./Terms";
import HeadlineArticle from "./Headlines/HeadlineArticle";
import AudienceHeadlines from "./Headlines/AudienceHeadlines";
import { selectClientPage } from "../../features/directory/directorySlice";
import { selectUserId } from "../../features/auth/authSlice";
import PropTypes from "prop-types";

function ClientDashboard({requestedPage, thisParamString}) {

  const userId = useSelector(selectUserId);
  const clientPage = useSelector(selectClientPage);

  const renderClientPage = () => {
    switch(requestedPage || clientPage) {
      case 'client-search':
        return userId && <ClientHome/>;
      case 'audience-builder':
        return <AudienceExplore />;
      case 'history':
        return <History />;
      case 'metric':
        return <Metrics />;
      case 'setting':
        return <Settings />;
      case 'terms':
        return <Terms />;
      case 'activate':
        return <Activated />;
      case 'methodology':
        return <Methodology/>;
      case 'headline-article':
        return <HeadlineArticle />;
      case 'audience-headlines':
        return <AudienceHeadlines audience={thisParamString} showHeading={true}/>;
      case 'ai':
        return <AiBot />;
      default:
        return <i className='fas fa-sync centered fa-spin'/>;
    }
  }
  
  return (
    <div>
      { renderClientPage() }
    </div>
  );
}

export default ClientDashboard;

ClientDashboard.propTypes = {
  requestedPage: PropTypes.string,
  thisParamString: PropTypes.string,
}