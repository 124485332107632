import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveDirectory } from "../../../features/directory/directorySlice";
import './AiBot'
import ChatBox from "./ChatBox";
import ChatTerminal from "./ChatTerminal"
import ChatGraphic from "./ChatGraphic";
import ChatFaq from "./ChatFaq";
import { useAllCinqEntriesQuery, useGetSelectAllAudiencesQuery } from "../../../app/api";
import { cinqAIKeywords } from '../../../data/CinqData';
// Remove terminal answer ability
/* import { useAddCinqEntryMutation } from "../../../app/api"; */

const AiBot = () => {
  // Data
  const dispatch = useDispatch();
  const [conversation, setConversation] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [userEntry, setUserEntry] = useState(null);
  const [showImage, setShowImage] = useState(false); 
  const { data: allCinqEntries } = useAllCinqEntriesQuery();
  const { data: audienceData, isSuccess: audienceSuccess } = useGetSelectAllAudiencesQuery();
  // Remove terminal answer ability
  /* const [addCinqEntry] = useAddCinqEntryMutation(); */
  // Verbiage
  const promptInit = "Ask me a question";
  const ai_unknown_response = "Hmmm. I don't know that.";
  const initialAiEntry = "Hello! My name is CINQ.";
  const initateAiEntryBlink = "<span class='blinking-cursor'>I</span>";
  const [prompt, setPrompt] = useState('');
  const [lockUser, setLockUser] = useState(true);
  const inlinePauseMS = 500; // Inline Timeout Period per '.'
  const [audienceListData, setAudienceListData] = useState([]);
  const [graphicalAudienceHeadline, setGraphicalAudienceHeadline] = useState(null);

  useEffect(() => {
    setConversation('');
    setPrompt(promptInit);
    aiEntryAnimation(initialAiEntry, '', '');
    dispatch(saveDirectory({ type: 'clientPage', value: 'ai' }));
  }, []);

  useEffect(() => {
    retrieveChatFaqs();
  }, [allCinqEntries]);

  useEffect(() => {
    if (audienceSuccess) {
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess]);

  /*** Manages the AI UX (thinking) while building the ai response dataset. */
  /** AI Bot Functions          */

  var aiEntryCharacterIndex = 0;
  const aiEntryAnimation = (aiEntry, userEntryObject, associated_audiences) => {
    if (userEntryObject != "" && aiEntryCharacterIndex == 0) {
      setConversation(conversation + userEntryObject);
    }
    if (aiEntryCharacterIndex <= aiEntry.length) { // Builds the aiEntry string via recursion
      if (aiEntryCharacterIndex == 0) {
        if (userEntryObject != "") {
          setConversation(conversation + userEntryObject + getAiEntryObject('', initateAiEntryBlink, associated_audiences));
        }
        else {
          setConversation(conversation + getAiEntryObject('', initateAiEntryBlink, associated_audiences));
        }
        setTimeout(() => { // Set blinking data ux - 2.8 seconds 
          if (conversation == initateAiEntryBlink) {
            setConversation(''); // Remove Blinking after pause expires 
          }
          var aiEntryObject = getAiEntryObject(aiEntry, aiEntry.slice(0, aiEntryCharacterIndex), associated_audiences);
          setConversation(conversation + userEntryObject + aiEntryObject);
          console.log('Index:' + aiEntryCharacterIndex)
          console.log('AiEntryObject:' + aiEntryObject);
          aiEntryCharacterIndex++;
          aiEntryAnimation(aiEntry, userEntryObject, associated_audiences);
        }, 2800);
      }
      else {
        var aiInlinePauseMs = aiInlinePause(aiEntry, aiEntryCharacterIndex); // Calculate pause '.' timing
        setTimeout(() => { // Push rest of aiEntry characters' values 
          var aiEntryObject = getAiEntryObject(aiEntry, aiEntry.slice(0, aiEntryCharacterIndex), associated_audiences);
          setConversation(conversation + userEntryObject + aiEntryObject);
          console.log('Index:' + aiEntryCharacterIndex);
          console.log('AiEntryObject:' + aiEntryObject);
          aiEntryCharacterIndex++;
          aiEntryAnimation(aiEntry, userEntryObject, associated_audiences);
        }, (aiInlinePauseMs) + (Math.random() * 10 + 25)); // Random ms duration to differ keystoke speed slightly      }
      };
      if (aiEntryCharacterIndex == aiEntry.length) { // Show image after ai finishes typing
        setShowImage(true);
        setLockUser(false); // Unlock User
      }
    };
  }

  const aiInlinePause = (aiEntry, aiEntryCharacterIndex) => {
    var aiInlinePauseMs = 0;
    if (aiEntry != '') { // Only catch user terminal entries
      var currentAiEntryCharacter = aiEntry[aiEntryCharacterIndex - 1]
      if (currentAiEntryCharacter == ".") { // If current index == '.', check next char. If a non '.', calculate paused based on previous '.' count 
        var nextAiEntryCharacter = aiEntry[aiEntryCharacterIndex]
        if (nextAiEntryCharacter != '.') {
          var pauseIndex = aiEntryCharacterIndex;
          var lastAiEntryCharacter = aiEntry[pauseIndex - 2]

          while (lastAiEntryCharacter == '.') {
            aiInlinePauseMs += inlinePauseMS; //  Add pause for each '.'
            pauseIndex--;
            lastAiEntryCharacter = aiEntry[pauseIndex];
          }
        }
      }
    }
    return aiInlinePauseMs;
  }

  const buildUserEntry = (userEntry, answeredResponse, associated_audiences) => {
    var aiEntryObject = getUserEntryObject(userEntry);
    if (answeredResponse != '') { // Check if question has an answer?
      aiEntryAnimation(answeredResponse, aiEntryObject, associated_audiences);
    }
    else {
      aiEntryAnimation(ai_unknown_response, aiEntryObject, '');
    }
  };

  async function retrieveEntry(selected_faq, associated_audiences) {
    buildUserEntry(selected_faq.question, selected_faq.answer, associated_audiences);
  };

  // Retrieve FAQ by Question
  const retrieveEntryByQuestion = (faqs, question) => {
    setUserEntry(null);
    var userEntryData = {
      question: question,
      answer: '',
      image: null
    };

    setShowImage(false); // Hide Image state on new terminal input
    let resultEntry = retrieveSelectedFaqs(faqs, question);
    let keywordMatch = retrieveAudienceByKeyword(cinqAIKeywords, question);
    let associated_audiences = null;

    if (resultEntry || (keywordMatch && keywordMatch.length > 0)) {
      if (resultEntry) {
        associated_audiences = retrieveAudienceIfExists(audienceListData, resultEntry.answer);
        if (associated_audiences) {
          /* setGraphicalAudienceHeadline(audienceMatch); MVD261 - remove graphical */
        }
        else {
          setGraphicalAudienceHeadline(null);
        }
      }

      setUserEntry(resultEntry);
      if (resultEntry) {
        buildUserEntry(question, resultEntry.answer, associated_audiences);
      }
      else {
        buildUserEntry(question, 'Based on your entry, I recommend the following audiences:', keywordMatch);
      }
    }
    else {
      setUserEntry(userEntryData);
      buildUserEntry(question, '', '');
    }
  };

  //Retrieve Selected FAQ
  const retrieveSelectedFaqs = (faqs, question) => {
    return faqs.find((e) => formatDataForSearch(e.question) == formatDataForSearch(question));
  }

  //Retrieve Selected Audience 
  const retrieveAudienceIfExists = (audienceData, audience) => {
    let directAudienceLookup = audienceData.find((e) => formatDataForSearch(e.audience) == formatDataForSearch(audience));
    let titleIncludedLookup = audienceData.find((e) => (formatDataForSearch(audience).includes(formatDataForSearch(e.title))));

    if (directAudienceLookup) {
      return directAudienceLookup;
    }
    else {
      return titleIncludedLookup;
    }
  }

  //retrieve Audience by keyword
  const retrieveAudienceByKeyword = (cinqAIKeywords, user_input) => {
    let matchingKeywordRecords = [];
    for(let i = 0; i <= cinqAIKeywords.length - 1; i++ ) {
      let keywords = cinqAIKeywords[i].keywords.split(",");
      
      for(let j = 0; j <= keywords.length - 1; j++ ) {
        if (formatDataForSearch(user_input).includes(formatDataForSearch(keywords[j]))) {
          matchingKeywordRecords.push(cinqAIKeywords[i]);
        }
      }
    }

    let keywordsAudienceRecords = [];
    for(let mk = 0; mk <= matchingKeywordRecords.length - 1; mk++) {
      let aud = retrieveAudienceIfExists(audienceListData, matchingKeywordRecords[mk].audience);
      if (aud) {
        keywordsAudienceRecords.push(aud);
      }
    }

    return keywordsAudienceRecords  
  }

  // Format data search to undercase and filter out special characters
  const formatDataForSearch = (question) => {
    return question.toLowerCase().replace(/[^a-zA-Z ]/g, " ");
  }

  // Remove terminal answer ability
  /* async function submitNewEntry(userEntryData) {
    let result = await addCinqEntry({ question: userEntryData.question, answer: userEntryData.answer, image: '' });
    console.log('new entry submitted. Faqs update: ' + result);
    setFaqs(result.data);
  } */

  const processResponse = (faqs, ai_terminal_input) => {
    // Remove terminal answer ability
    /* var check_for_admin_submission = Array.from(ai_terminal_input)[0];*/

    if (ai_terminal_input != "") { // Clear placeholder if exists
      setShowImage(false); // Hide Image before populating faqs
      setGraphicalAudienceHeadline(null);
      setLockUser(true); // Lock User
      var terminal_input = document.getElementById('ai_terminal_input');
      if (terminal_input) {
        terminal_input.classList.remove('input_error');
      }
      setPrompt(promptInit);
      retrieveEntryByQuestion(faqs, ai_terminal_input);

      // Remove terminal answer ability
      /*if (check_for_admin_submission == "+") {
        if (userEntry.question != '' && userEntry.answer == '') {
          var userEntryData = {
            question: userEntry.question,
            answer: ai_terminal_input.substring(1),
          };
          submitNewEntry(userEntryData);
          buildUserEntry(ai_terminal_input, 'Thanks for that information. I am smarter now.');
          userEntryData = '';
          setUserEntry('');
          retrieveChatFaqs();
        }
      }
      else { // Let the system try to answer it 
        retrieveEntryByQuestion(faqs, ai_terminal_input); 
      } */
    }
    else {
      setPrompt('Please ask me a question');
      terminal_input = document.getElementById('ai_terminal_input');
      if (terminal_input) {
        terminal_input.classList.add('input_error');
      }
    }
  };

  /** Frequently Asked Questions functions */
  const selectFaq = (selected_faq) => {
    setShowImage(false); // Hide Image before populating faqs
    setGraphicalAudienceHeadline(null);
    setLockUser(true); // Lock User
    if (audienceListData) {
      let associated_audiences = retrieveAudienceIfExists(audienceListData, selected_faq.answer);
      if (associated_audiences) {
        /* setGraphicalAudienceHeadline(associated_audiences);   MVD261 - remove graphical */
      }
      else {
        setGraphicalAudienceHeadline(null);
      }
      retrieveEntry(selected_faq, associated_audiences);
    }
  };

  const retrieveChatFaqs = () => {
    console.log(allCinqEntries);
    setFaqs(allCinqEntries);
  }

  /** Graphical Image functions */
  const determineChatboxGraphicTerminalLayout = () => {    
    if (showImage && ((userEntry && userEntry.image != null) || (graphicalAudienceHeadline != null && graphicalAudienceHeadline.title))) {
      return (
        <div className='ai_chatbox_widget'>
          <ChatBox conversation={conversation} showGraphical={true}></ChatBox>
          <ChatGraphic graphicalAudienceHeadline={graphicalAudienceHeadline}> </ChatGraphic>
          <ChatTerminal faqs={faqs} prompt={prompt} processResponse={processResponse} lockUser={lockUser}></ChatTerminal>
        </div>
      )
    }
    else {
      return (
        <div className='ai_chatbox_widget'>
          <ChatBox conversation={conversation} showGraphical={false}></ChatBox>
          <ChatTerminal faqs={faqs} prompt={prompt} processResponse={processResponse} lockUser={lockUser}></ChatTerminal>
        </div>
      )
    }
  }
  /** End Graphical functions */


  /**   List Entry Structures   */
  const getAiEntryObject = (aiEntryFullStr, aiEntry, associated_audiences) => { // aiEntryObject
    if (aiEntryFullStr == aiEntry) {
      if (associated_audiences) {
        console.log(aiEntryFullStr);
        return getAiEntryFrontStructure() + aiEntry + getAiEntryTailWithLinksStructure(associated_audiences);
      }
      else {
       return getAiEntryFrontStructure() + aiEntry + getAiEntryTailStructure();
      }
    }
    else {
      return getAiEntryFrontStructure() + aiEntry + getAiEntryTailStructure();
    }
  }

  const getAiEntryFrontStructure = () => { // aiEntryFront
    return "<li className='ai_chatbox_li '><div className='ai_chatbox_icon_wrapper'><div className='ai_chatbox_icon'></div></div><p className='ai_ask_p'>";
  }

  const getAiEntryTailStructure = () => { // aiEntryTail
    return "</p></li>";
  }

  const getAiEntryTailWithLinksStructure = (associated_audiences) => { // aiEntryTailLinks
    if (associated_audiences.uuid) { //Cinq record found, single record
        return buildAiEntryLink(associated_audiences);
    }
    else { // Keywords found, potential multiple records
      let multipleLinks = '';
      for (let x = 0; x < associated_audiences.length; x++) {
        multipleLinks = multipleLinks + buildAiEntryLink(associated_audiences[x]);
      }
      return multipleLinks + '</p></li>';
    }
  }

  const buildAiEntryLink = (associated_audience) => {
      let audiencePath = '';
      if (associated_audience.cluster == 1) {
        audiencePath = 'cluster-audience'
      }
      else {
        audiencePath = 'audience'
      }
      let strfdsaf = "<a href='/dashboard/" + audiencePath + "?uuid=" +associated_audience.uuid + "' title='" + associated_audience.description +"'>" + associated_audience.title + "</a>";
      return strfdsaf;
  }

  const getUserEntryObject = (userEntry) => { // userEntry 
    return "<li className='ai_chatbox_li ai_ask_li_wrapper'><div className='ai_chatbox_icon_wrapper ai_chatbox_icon_wrapper_user'><div className='ai_chatbox_icon'></div></div><p className='ai_ask_p ai_ask_p_user'>" + userEntry + "</p></li>";
  }
  /** End List Entry Structures */

  return (
    <div className="ai">
      <div className='ai_title'>
        <div className="ai_title_wrapper">
          <div className="ai_title_icon"></div>
          <h1 className="ai_title_h1">CINQ</h1>
        </div>
      </div>
      {determineChatboxGraphicTerminalLayout()}
      <div className='ai_ask'>
        <ChatFaq faqs={faqs} selectFaq={selectFaq} lockUser={lockUser}></ChatFaq>
      </div>
    </div >
  )
};

export default AiBot