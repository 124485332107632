import React from "react";

export default function History() {

  return (
    <div>
      <h1>History</h1>
    </div>
  )
}
