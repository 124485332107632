import React, { useState, useEffect } from "react";
import { 
  useAllCategoriesQuery, 
  useCreateWebUserMutation, 
  useUpdatePendingUserMutation, 
  useAddUserToCategoryMutation, 
  useCheckIfUserExistsQuery,
  useSendEmailMutation, 
  useGetPlatformMutation,
  useGetConfigurableValueQuery } from "../../app/api";
import SignUpCategoryButton from "../../routes/UIElements/SignUpCategoryButton";
import { useNavigate } from "react-router-dom";
import { saveCredentials } from "./authSlice";
import { useLoginMutation } from "../../app/api";

import Header from "../../routes/Home/Header"
import Footer from "../../routes/Home/Footer"

export default function SignUp() {
  
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false);
  const [jobFunction, setJobFunction] = useState('');
  const [jobLevel, setJobLevel] = useState(''); 
  const [demographic, setDemographic] = useState('');
  const [age, setAge] = useState(''); 
  const [submitVerbiage, setSubmitVerbiage] = useState('SKIP, CREATE ACCOUNT'); 

  // Validation
  const [firstNameErrorStatus, setFirstNameErrorStatus] = useState(false);
  const [lastNameErrorStatus, setLastNameErrorStatus] = useState(false);
  const [usernameErrorStatus, setUsernameErrorStatus] = useState(false);
  const [usernameFormatErrorStatus, setUsernameFormatErrorStatus] = useState(false);
  const [usernameUniqueErrorStatus, setUsernameUniqueErrorStatus] = useState(false);
  const [passwordErrorStatus, setPasswordErrorStatus] = useState(false);
  const [passwordConfirmErrorStatus, setPasswordConfirmErrorStatus] = useState(false);
  const [passwordComplexityErrorStatus, setPasswordComplexityErrorStatus] = useState(false);
  const [passwordMismatchErrorStatus, setPasswordMismatchErrorStatus] = useState(false);

  // Logic and Data
  const {data: categories} = useAllCategoriesQuery(); 
  const [addUserToCategory] = useAddUserToCategoryMutation();
  const [login] = useLoginMutation();
  const {data: checkIfUserExists}  = useCheckIfUserExistsQuery({username: username}, {skip: !username});
  const [createUser] = useCreateWebUserMutation();
  const [updateUser] = useUpdatePendingUserMutation();

  // Email
  const [sendEmail] = useSendEmailMutation();
  const [getPlatform] = useGetPlatformMutation();
  const {data: signupNotificationEmailRecipientData} = useGetConfigurableValueQuery({configurable_name: 'email.new_signup.recipient'});
  const [signupNotificationEmailRecipient, setSignupNotificationEmailRecipient] = useState('HGothot@mvdigitalgroup.com, mthayer@mvdigitalgroup.com');

  useEffect(() => {
    if (signupNotificationEmailRecipientData) {
      setSignupNotificationEmailRecipient(signupNotificationEmailRecipientData.config_value);
    }
  }, [signupNotificationEmailRecipientData]);

   useEffect(() => {
    if (!checkIfUserExists && usernameUniqueErrorStatus) {
      setUsernameUniqueErrorStatus(false)
    }
  }, [checkIfUserExists]);

  const manageCategory = (state, category) => {
    if (state == false) {
      setSelectedCategories([...selectedCategories, category])
    }
    else if (state == true) {
      setSelectedCategories(
        selectedCategories.filter(a =>
          a.id !== category.id
        )
      );
    }
  }

  const handleFirstNameChange = (event) => {
    const value = event.target.value;
    setFirstName(value);
    if (firstNameErrorStatus && value.length > 0) {
      setFirstNameErrorStatus(false);
    }
  };
  const handleLastNameChange = (event) => {
    const value = event.target.value;
    setLastName(value);
    if (lastNameErrorStatus && value.length > 0) {
      setLastNameErrorStatus(false);
    }
  };
  const handleUsernameChange = (event) => {
    const value = event.target.value;
    setUsername(value);
    if (usernameErrorStatus && value.length > 0) {
      setUsernameErrorStatus(false);
    }
  };
    const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    if (passwordErrorStatus && value.length > 0) {
      setPasswordErrorStatus(false);
    }
  };
  const handlePasswordConfirmChange = (event) => {
    const value = event.target.value;
    setPasswordConfirm(value);
    if (passwordConfirmErrorStatus && value.length > 0) {
      setPasswordConfirmErrorStatus(false);
    }
  }; 

  const handleJobFunctionChange = (event) => {
    const value = event.target.value;
    setJobFunction(value);
  } 
  const handleJobLevelChange = (event) => {
    const value = event.target.value;
    setJobLevel(value);
  }
  const handleDemographicChange = (event) => {
    const value = event.target.value;
    setDemographic(value);
  }
  const handleAgeChange = (event) => {
    const value = event.target.value;
    setAge(value);
  }

  const isEmail = (userName) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userName);
  const isPasswordComplex = (password) => {
    let passwordStength = 0;

    // validate lowercase 
    if (password.match(/[a-z]/)) {
      passwordStength += 1;
    }
    // validate uppercase 
    if (password.match(/[A-Z]/)) {
      passwordStength += 1;
    }
    // validate number 
    if (password.match(/([0-9])/)) {
      passwordStength += 1;
    }
    // valifate special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      passwordStength += 1;
    }
    // validate 8 character length
    if (password.length > 7) {
      if (passwordStength >= 3) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  const toggleShowCategories = () => {
    setShowCategories(true);
    setSubmitVerbiage('CREATE ACCOUNT');
  }

  const listCategories = (categories) => {
    return (categories || []).map((cat, i) => (
      <div className='category-inline-list' key={i}>
        <SignUpCategoryButton category={cat} manageCategory={manageCategory} />
      </div>
    ))
  }

  const generateSignUp = () => {
    let failed_validation = false;

    if (firstName == '') { // firstName empty
      setFirstNameErrorStatus(true) 
      failed_validation = true
    } 
    if (lastName == '') { // lastName empty
      setLastNameErrorStatus(true) 
      failed_validation = true
    }  
    if (username == '') { // email empty
      setUsernameErrorStatus(true)
      failed_validation = true
    }
    else {     
      if (!isEmail(username)) { // email format check
        setUsernameFormatErrorStatus(true)
        failed_validation = true     
      }
      else {
       setUsernameFormatErrorStatus(false) 
      }
    }
    if (password == '') { // passwordConfirm empty
      setPasswordErrorStatus(true)
      failed_validation = true      
    }
    else {
      if (!isPasswordComplex(password)) { // password complexity
        setPasswordComplexityErrorStatus(true);
        failed_validation = [true]
      }
      else {
        setPasswordComplexityErrorStatus(false);
      }
    }
    if (passwordConfirm == '') { // passwordConfirm empty
      setPasswordConfirmErrorStatus(true);
      failed_validation = true
    }
    else {
      if (password != passwordConfirm) { // verify password match 
        setPasswordMismatchErrorStatus(true);
        failed_validation = true
      }
      else {
        setPasswordMismatchErrorStatus(false);
      }
    }
    if (checkIfUserExists) {
      setUsernameUniqueErrorStatus(true);
      failed_validation = true
    }    
    // verify field validation
    if (!failed_validation) {
      submitSignUp(); // verfied, signup
    }
    else { // Scroll to top
    window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  async function saveCategoryMappings(user_id){
    selectedCategories.map((cat) => {
      saveC(user_id, cat.id);
    })
  }

  async function send_signup_email(username, firstName, lastName) {
    let platformResult = await getPlatform();
    await sendEmail({
      template_name: 'new_signup',
        recipient: signupNotificationEmailRecipient,
        recipient_to: signupNotificationEmailRecipient,
        recipient_cc: '',
        subject: 'New User Request',
        template_variables: {
          recipient_to: signupNotificationEmailRecipient,
          recipient_cc: '',
          first_name: firstName,
          last_name: lastName,
          email: username,
          platform: platformResult.data.platform || 'the platform'
        }
    })
  }

  async function submitSignUp() {
    const {data: user_id} = await createUser({username: username, password: password})
    await updateUser({username: username, firstname: firstName, lastname: lastName, company_name: '', org_type: '', job_function: jobFunction, job_level: jobLevel, demographic: demographic, age: age}); 
    await send_signup_email(username, firstName, lastName);
    saveCategoryMappings(user_id);
    onLogin();
  }

  async function onLogin() {
    try {
      // Submit the login attempt
      const result = await login({ username, password }).unwrap();

      // Reached here when the request is successful. The access token and
      // username have been updated in the local state by authSlice.

      // Save the access token and username to local storage
      saveCredentials(result.access_token, username, result?.user?.userId, result?.user?.userRole, result?.user?.companyName, result?.user?.orgId, result?.user?.permissions);
      navigate('/'); 
    } catch (err) {
      // Set the error status code, if available, for the LoginError component
      console.log('error logging in', err.status);
      navigate('/?access=pending');  
    }
  }

  async function saveC(userId, cId) {
    let result = await addUserToCategory({user_id: userId, category_id: cId});
    console.log('result', result);
  }
 
  return (
    <div className="newSignUp">
      <Header/>
      <div className="main">
        <div className="main_wrapper">
          <img  src="https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/Icon-KO.png" alt="" title="Icon-KO" className='login-logo hidden'  onClick={() => {navigate('/')}}></img>
          <h2>Sign Up</h2>
          <div className="signup_entry_fields">
            <div className='entry_field top_entry_field'>
              <div className='dual_entry'>
                <input maxLength={255} onChange={handleFirstNameChange} value={firstName} className={firstNameErrorStatus ? 'error': ''} placeholder='First name *'></input>
                <label className={firstNameErrorStatus ? 'error show': 'error'}>Enter first name</label>
              </div> 
              <div className='dual_entry'>
                <input maxLength={255} onChange={handleLastNameChange} value={lastName} className={lastNameErrorStatus ? 'error': ''} placeholder='Last name *'></input>
                <label className={lastNameErrorStatus ? 'error show': 'error'}>Enter last name</label>
              </div>
            </div>
            <div className='entry_field single_entry'>
              <input maxLength={255} onChange={handleUsernameChange} value={username} className={usernameErrorStatus ? 'error': ''} placeholder='Email Address *'></input>
              <label className={usernameErrorStatus ? 'error show': 'error'}>Enter email</label>
              <label className={usernameFormatErrorStatus ? 'error show': 'error'}>Enter valid email </label>
              <label className={usernameUniqueErrorStatus ? 'error show': 'error'}>Email already exists </label>          
            </div>
            <div className='entry_field'>
              <div className='dual_entry'>
                <input type='password' maxLength={255} onChange={handlePasswordChange} value={password} className={passwordErrorStatus ? 'error': ''} placeholder='Password *'></input>
                <label className={passwordErrorStatus ? 'error show': 'error'}>Enter password</label>
                <label className={passwordComplexityErrorStatus ? 'error show': 'error'}>Please enter a valid password with a minimum of 8 characters of uppercase lowercase, and special character</label>
                <label className={passwordMismatchErrorStatus ? 'error show': 'error'}>Password mismatch</label>
              </div> 
              <div className='dual_entry'>
                <input type='password' maxLength={255} onChange={handlePasswordConfirmChange} value={passwordConfirm} className={passwordConfirmErrorStatus ? 'error': ''} placeholder='Confirm Password *'></input>
                <label className={passwordConfirmErrorStatus ? 'error show': 'error'}>Enter password </label>
              </div>
            </div>
          </div>
          <div className='entry_field single_entry'>
            <div className='dropdown'>
              <label>
                <select onChange={handleJobFunctionChange} value={jobFunction} className={jobFunction ? 'value_selected' : ''}>
                  <option value="" hidden>Job Function</option>
                  <option value="Account Management">Account Management</option>
                  <option value="Administrative">Administrative</option>
                  <option value="Analytics">Analytics</option>
                  <option value="Audience Development/Circulation">Audience Development/Circulation</option>
                  <option value="Brand Management">Brand Management</option>
                  <option value="Business Development">Business Development</option>
                  <option value="Digital/Online Media Management">Digital/Online Media Management</option>                  
                  <option value="Ecommerce">Ecommerce</option>
                  <option value="Editorial">Editorial</option>
                  <option value="Education">Education</option>
                  <option value="Event Management">Event Management</option>
                  <option value="Finance">Finance</option>
                  <option value="Human Resources">Human Resources</option>
                  <option value="Information Technology">Information Technology</option>                  
                  <option value="Innovation">Innovation</option>
                  <option value="Management">Management</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Media Buyer/Planning">Media Buyer/Planning</option>
                  <option value="Operations">Operations</option>
                  <option value="Product Management">Product Management</option> 
                  <option value="Production">Production</option>
                  <option value="Public Relations">Public Relations</option>
                  <option value="Research">Research</option>
                  <option value="Sales">Sales</option>
                  <option value="Other">Other</option>
                </select>
              </label>  
            </div>
          </div>
          <div className='entry_field single_entry'>
            <div className='dropdown'>
              <label>             
                <select onChange={handleJobLevelChange} value={jobLevel} className={jobLevel ? 'value_selected' : ''}>
                  <option value="" hidden>Senority / Level</option>
                  <option value="C-Suite">C-Suite</option>
                  <option value="Board/Chair">Board/Chair</option>
                  <option value="Managing Director/General Manager">Managing Director/General Manager</option>
                  <option value="Vice President">Vice President</option>
                  <option value="Director">Director</option> 
                  <option value="Manager">Manager</option>
                  <option value="Account Executive">Account Executive</option>
                  <option value="Strategist">Strategist</option>
                  <option value="Analyst">Analyst</option>
                  <option value="Non-Manager (Associate, Coordinator)">Non-Manager (Associate, Coordinator)</option>
                  <option value="Professor/Student">Professor/Student</option>
                  <option value="Other">Other</option>
                </select>
              </label>
            </div>
          </div>
          <div className='entry_field single_entry hidden'>
            <div className='dropdown'>
              <label>             
                <select onChange={handleDemographicChange} value={demographic} className={demographic ? 'value_selected' : ''}>
                  <option value="" hidden>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option> 
                  <option value="Non-binary">Non-binary</option>
                  <option value="I identify in another way">I identify in another way</option>
                </select>
              </label>
            </div>
          </div>

          <div className='entry_field single_entry hidden'>
            <div className='dropdown'>
              <label>             
                <select onChange={handleAgeChange} value={age} className={age ? 'value_selected' : ''}>
                  <option value="" hidden>Age</option>
                  <option value="18 - 24">18 - 24</option>
                  <option value="25 - 34">25 - 34</option> 
                  <option value="35 - 44">35 - 44</option>
                  <option value="45 - 54">45 - 54</option>
                  <option value="55+">55+</option>
                </select>
              </label>
            </div>
          </div>

          <h3> Personalize your experience</h3>
          <div className={showCategories ? 'signup_interests': 'signup_interests hidden'} >
            <h4>Add interests from our suggestions</h4>
            <ul>
              {listCategories(categories)}
            </ul>
          </div>

          <div className="signup_footer">
            <button className='cancel' onClick={() => { navigate('/') }}><p>cancel</p></button>
            <button className={showCategories ? 'personalize hidden' : 'personalize '} onClick={() => { toggleShowCategories() }}><p>PERSONALIZE</p></button>
            <button className={showCategories ? 'create_account toggled' : 'create_account '} onClick={() => { generateSignUp(); }}><p>{submitVerbiage}</p></button>
          </div>
        </div>
      </div>
      <Footer showAsPopup={false} ></Footer>
    </div>
  )
}