import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetAudienceCountQuery } from "../app/api";
import { selectAllAudienceCounts, saveAudience } from "../features/audiences/audienceSlice";

export default function GetSystemData() {
  const dispatch = useDispatch();

  const allAudienceCounts = useSelector(selectAllAudienceCounts);
  const {data:audienceCount}  = useGetAudienceCountQuery({skip: allAudienceCounts});


  useEffect( () => {
    if (audienceCount) {
      dispatch(saveAudience({type: 'all-audience-counts', value: audienceCount}));
    }
  }, [audienceCount])

  return (
    <>
    </>
  )

} 

