import React, { useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ComparisonRow from "../UIElements/ComparisonRow"
import ComparePageCSVExport from './ComparePageCSVExport';
import { selectShowAudienceCrossover, selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import AudienceCrossover from '../UIElements/AudienceCrossover';
export default function ComparisonTable({audiences, widgetList, allAudiences}) {

  const filter = useSelector(selectWidgetFilters);
  const showAudienceCrossover = useSelector(selectShowAudienceCrossover);
  const totalWidgets = widgetList.length;
  const [loadedWidgets, setLoadedWidgets] = useState([]);
  const [completeWidgetsCount, setCompleteWidgets] = useState(0);

  console.log('showAudienceCrossover', showAudienceCrossover);

  /** Reset the loaded data when the filter changes */
  useEffect(() => {
    setLoadedWidgets([]);
    setCompleteWidgets(0);
  }, [filter])
  

  const incrementCompleteWidgets = (loadedWidgetData) => {
    setCompleteWidgets(prevCount => prevCount + 1);
    const index = loadedWidgets.findIndex(item => item.widget === loadedWidgetData.widget);
    if (index !== -1) {
      setLoadedWidgets(loadedWidgets.map(item => (item.widget === loadedWidgetData.widget ? loadedWidgetData : item)));
    } else {
      setLoadedWidgets([ ...loadedWidgets, loadedWidgetData ])
    }
  }
  
  const showComparisonTable = () => {
    document.getElementById('comparison-table').style.display = 'block';
    document.getElementById('download-csv-link').style.display = 'flex';
  }

  const showLoading = () => {

    const comparisontable = document.getElementById("comparison-table");
    if (comparisontable) {
      comparisontable.style.display = 'none';
    } 
    const downloadcsvlink = document.getElementById("download-csv-link");
    if (downloadcsvlink) {
      downloadcsvlink.style.display = 'none';
    }

    return (
      <>
        <h2 style={{fontSize: '24px'}}>{Math.round((completeWidgetsCount / totalWidgets) * 100)}% loaded</h2>
      </>
    )
  }

  const comparisonTable = () => {
    return (
      <>
        <ComparePageCSVExport audienceData={loadedWidgets} widgetList={widgetList} audiences={audiences} />

        <div id="comparison-table"  className='demographics' style={{display: 'none'}}>
          <div className='compare-compare' style={{width: '100%'}}>
            <ul style={{marginTop: '0px'}}>
              <li className='fixed-item'>
                {audiences.map((audience, i) => (
                  <div key={i} className='compare_widget_wrapper'>
                    <h3 className="audience_title">
                      <a href={"/dashboard/audience?uuid=" + audience.uuid}>{audience.title}</a>
                    </h3>
                    <p style={{textAlign: 'center', marginTop: '0px'}}>{Number(audience.count).toLocaleString('en',{useGrouping:true})} Records</p>
                  </div>
                ))}
              </li>
              {widgetList.map((widget, i) => (
                <React.Fragment key={i}>
                  <ComparisonRow audiences={audiences} widget={widget} callback={incrementCompleteWidgets} />
                </React.Fragment>
              ))}
              {showAudienceCrossover && <AudienceCrossover audiences={audiences} widget={'test'} callback={incrementCompleteWidgets} allAudiences={allAudiences} />}
            </ul>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/** Toggle the visibility of the Comparison table, as it needs to be loaded to fetch from the API */}
      {completeWidgetsCount < totalWidgets ? showLoading() : showComparisonTable() } 
      {comparisonTable()}
    </>
  );
};

ComparisonTable.propTypes = {
  audiences: PropTypes.array,
  widgetList: PropTypes.array,
  allAudiences: PropTypes.array,
}
