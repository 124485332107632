import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip} from "chart.js";
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from "prop-types";
import { useSelectGAWidgetDataQuery } from "../../app/api";
import { createLikelyVoterDataObject } from "../../data/WidgetData";
import { useSelector } from "react-redux";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import Loading from "../UIElements/Loading";
import { topicMappings } from "../../data/HeadlineData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { allWidgetFields } from "../../data/WidgetFields";
import { toggleFilterField } from "./BarGraph";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";

// GA data for corresponding widgets
import { createGAQuery, gaWidgetSelect, gaWidgetGroupBy } from "../../data/WidgetData";


export default function LikelyVoterGABarGraph({type, selectedAudience, headlineFilter='none'}) {

  if (type=='placeholder') { //  I just don't want to use this or get rid of it yet
    console.log('type here is a placeholder since this widget is really 3 widgets in one', type);
  }

  const dispatch = useDispatch();
  
  const createDataObject = createLikelyVoterDataObject; // override for likely voter widget
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = allWidgetFields; 
  const currentDateRange = useSelector(selectCurrentHeadlineDateRange);

  const savedTopics = useSelector(selectInTopics);
  const savedOutTopics = useSelector(selectOutTopics);

  //const [myPrimaryWidgetData, setMyPrimaryWidgetData] = useState([]);
  //const [myAllPrimaryWidgetData, setMyAllPrimaryWidgetData] = useState([]);
  const [primaryHasFetched, setPrimaryHasFetched] = useState(false);

  //const [myPresidentialWidgetData, setMyPresidentialWidgetData] = useState([]);
  //const [myAllPresidentialWidgetData, setMyAllPresidentialWidgetData] = useState([]);
  const [presidentialHasFetched, setPresidentialHasFetched] = useState(false);

  //const [myGeneralWidgetData, setMyGeneralWidgetData] = useState([]);
  //const [myAllGeneralWidgetData, setMyAllGeneralWidgetData] = useState([]);
  const [generalHasFetched, setGeneralHasFetched] = useState(false);


  const [gaQueryString, setGaQueryString] = useState(createGAQuery(filter, currentDateRange, headlineFilter));
  const [inTopicString, setInTopicString] = useState(null);
  const [outTopicString, setOutTopicString] = useState(null);

  const {data: primaryWidgetData, refetch: refetchPrimaryWidgets} = useSelectGAWidgetDataQuery(
    {
      filter_query_string: gaQueryString,
      widget_query_string: gaWidgetSelect('likely_primary_voter'),
      group_by_query_string: gaWidgetGroupBy('likely_primary_voter'),
      widget_type: 'likely_primary_voter', 
      audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
      include: inTopicString,
      exclude: outTopicString ,
      source_list: 'none',
    }, {skip: !inTopicString || !outTopicString});

  const [primaryDataObject, setPrimaryDataObject] = useState(primaryWidgetData && createDataObject(primaryWidgetData, [], widgetFields.filter(t => t.widget_type == 'likely_primary_voter'), 'likely_primary_voter'));


  const {data: presidentialWidgetData, refetch: refetchPresidentialWidgets} = useSelectGAWidgetDataQuery(
    {
      filter_query_string: gaQueryString,
      widget_query_string: gaWidgetSelect('likely_presidential_primary_voter'),
      group_by_query_string: gaWidgetGroupBy('likely_presidential_primary_voter'),
      widget_type: 'likely_presidential_primary_voter', 
      audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
      include: inTopicString,
      exclude: outTopicString ,
      source_list: 'none',
    }, {skip: !inTopicString || !outTopicString});

  const [presidentialDataObject, setPresidentialDataObject] = useState(presidentialWidgetData && createDataObject(presidentialWidgetData, [], widgetFields.filter(t => t.widget_type == 'likely_presidential_primary_voter'), 'likely_presidnetial_primary_voter'));


  const {data: generalWidgetData, refetch: refetchGeneralWidgets} = useSelectGAWidgetDataQuery(
    {
      filter_query_string: gaQueryString,
      widget_query_string: gaWidgetSelect('general_election_voter'),
      group_by_query_string: gaWidgetGroupBy('general_election_voter'),
      widget_type: 'general_election_voter', 
      audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
      include: inTopicString,
      exclude: outTopicString ,
      source_list: 'none',
    }, {skip: !inTopicString || !outTopicString});

  const [generalDataObject, setGeneralDataObject] = useState(generalWidgetData && createDataObject(generalWidgetData, [], widgetFields.filter(t => t.widget_type == 'general_election_voter'), 'general_election_voter'));

  const [officialMergedObject, setOfficialMergedObject] = useState(null);


  useEffect(() => {
    if (primaryDataObject && presidentialDataObject && generalDataObject) {
      let mergedObject = {categories: [], colors: [], dataset1: [], dataset2: [], no_of_bars: 2, type: "percentage"};
      primaryDataObject.categories.map(t => {mergedObject.categories.push(t)});
      presidentialDataObject.categories.map(t => {mergedObject.categories.push(t)});
      generalDataObject.categories.map(t => {mergedObject.categories.push(t)});
      primaryDataObject.colors.map(t => {mergedObject.colors.push(t)});
      presidentialDataObject.colors.map(t => {mergedObject.colors.push(t)});
      generalDataObject.colors.map(t => {mergedObject.colors.push(t)});
      primaryDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      presidentialDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      generalDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      primaryDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      presidentialDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      generalDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      setOfficialMergedObject(mergedObject);
    }
  }, [primaryDataObject, presidentialDataObject, generalDataObject]);

  useEffect(() => {
    setPrimaryDataObject(null);
    setPresidentialDataObject(null);
    setGeneralDataObject(null);
    if (savedTopics.length > 0 && savedTopics[0].length > 0 ) {
        let newArray = mergeByTopic(savedTopics, topicMappings);
        setInTopicString(newArray.join(",") || 'all'); 
    }
    else {
      setInTopicString('all'); 
    }
  }, [savedTopics]);

  useEffect(() => {
    setPrimaryDataObject(null);
    setPresidentialDataObject(null);
    setGeneralDataObject(null);
    if (savedOutTopics.length > 0 && savedOutTopics[0].length > 0) {
      let newArray = mergeByTopic(savedOutTopics, topicMappings);
      setOutTopicString(newArray.join(",") || 'none'); 
    }
    else {
      setOutTopicString('none'); 
    }

  }, [savedOutTopics]);

  useEffect(() => {
    if (primaryWidgetData) {
      if (!primaryHasFetched) {
        setPrimaryHasFetched(true);
      }
    }
  }, [primaryWidgetData]);

  useEffect(() => {
    if (presidentialWidgetData) {
      if (!presidentialHasFetched) {
        setPresidentialHasFetched(true);
      }
    }
  }, [presidentialWidgetData]);

  useEffect(() => {
    if (generalWidgetData) {
      if (!generalHasFetched) {
        setGeneralHasFetched(true);
      }
    }
  }, [generalWidgetData]);

  useEffect( () => {
    if (primaryHasFetched) {
      refetchPrimaryWidgets();
    }
    if (generalHasFetched) {
      refetchGeneralWidgets();
    }
    if (presidentialHasFetched) {
      refetchPresidentialWidgets();
    }
  }, [inTopicString, outTopicString]);

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentDateRange, headlineFilter));
    if (primaryHasFetched) {
      refetchPrimaryWidgets();
    }
    if (presidentialHasFetched) {
      refetchPresidentialWidgets();
    }
    if (generalHasFetched) {
      refetchGeneralWidgets();
    }
  },[filter, headlineFilter])

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentDateRange, headlineFilter));
  }, [currentDateRange])

  useEffect(() => {
    if(primaryWidgetData ) {
        const newPrimaryDataObject = createDataObject(primaryWidgetData, [], widgetFields.filter(t => t.widget_type == 'likely_primary_voter'), 'likely_primary_voter');
        setPrimaryDataObject(newPrimaryDataObject);
    }
  }, [primaryWidgetData] );

  useEffect(() => {
    if(presidentialWidgetData ) {
        const newPresidentialDataObject = createDataObject(presidentialWidgetData, [], widgetFields.filter(t => t.widget_type == 'likely_presidential_primary_voter'), 'likely_presidential_primary_voter');
        setPresidentialDataObject(newPresidentialDataObject);
    }
  }, [presidentialWidgetData] );

  useEffect(() => {
    if(generalWidgetData ) {
        const newGeneralDataObject = createDataObject(generalWidgetData, [], widgetFields.filter(t => t.widget_type == 'general_election_voter'), 'general_election_voter');
        setGeneralDataObject(newGeneralDataObject);
    }
  }, [generalWidgetData] );

  useEffect(() => {
    setPrimaryDataObject(null);
    setPresidentialDataObject(null);
    setGeneralDataObject(null);
  }, [filter]);


  // Bar Graph dataObject parameters:
  // title: to be display along the top of the widget
  // maxes: {value: value of max %, desc: descripition between, cat: category of max}
  // no_of_bars: 1 or 2 
  // categories: [name, name] names of categories
  // dataset1: [data, data] data by category
  // dataset2: [data, data] if applicable 
  // type: 'percentage' or 'count'
  // colors: [color1, color2]

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartDataLabels);

  const options = {
    indexAxis: 'y' ,
    layout: {
      padding: {
        right: 16
      }
    },
    scales: {
      x: {
        display: false,
        offset: true,
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#000000',
          font: {
            size: 14,
          }
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    onHover: () => {
        var el = document.getElementById(type);
        el.style.cursor = "pointer" ;
    },
    onClick: (e, activeEls) => {
      let datasetIndex = activeEls[0].datasetIndex;
      let dataIndex = activeEls[0].index;
      let datasetLabel = e.chart.data.datasets[datasetIndex].label;
      let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
      let label = e.chart.data.labels[dataIndex];
      console.log("In click", datasetLabel, label, value);
      let voter_type = (label == 'Primary') ? 'likely_primary_voter' : 
         (label == 'General Election') ? 'general_election_voter'  :
         'likely_presidential_primary_voter';
      toggleFilterField(filter, voter_type, 'Y', dispatch, saveCurrentView);
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#000000',
        anchor: 'end',
        align: 'right',
        offset: 0,
        formatter: function(value) {
          return value + '%';
        },
        font: {
          size: 14,
        },
      },
      tooltip: {
        titleFont: {
          size: 13,
        }, 
        bodyFont: {
          size: 13,
        }
      },
    },
  };

const labels = officialMergedObject?.categories;

const data = {
  labels,
  no_of_bars: 1,
  datasets: [
    {
      label: 'This Audience',
      data: officialMergedObject?.dataset1,
      backgroundColor: '#2F5D62' 
    },
  ],
};

  return (
    <>
      {officialMergedObject && primaryDataObject && presidentialDataObject && generalDataObject ? 
        <Bar id={type} plugins={[ChartDataLabels]} options={options} data={data} />
        :
        <Loading/>
      }
    </>
  );
}

LikelyVoterGABarGraph.propTypes = {
  type: PropTypes.string,
  selectedAudience: PropTypes.object, 
  headlineFilter: PropTypes.string,
}