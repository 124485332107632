import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "../../features/auth/authSlice";
import { useGetUserAudiencePlatformsQuery, useCancelPlatformActivationMutation } from "../../app/api";
import { liveRampPlatforms } from "../../data/ActivateData";
import GetAudienceData from "../GetAudienceData";
import { useNavigate, createSearchParams } from "react-router-dom";
import {formatAudienceTitle} from "../userSettings";

export default function YourAudiencePlatforms() {

  const userId = useSelector(selectUserId);
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const [thisAudience, setThisAudience] = useState({});
  const audienceUUID = queryParameters.get("uuid");
  const origDate = queryParameters.get("orig_date");
  const customAudience = queryParameters.get("custom");

  const [platforms, setPlatforms] = useState([]);
  const { data: platformData, isSuccess: platformDataSuccess, refetch: getPlatformData } = useGetUserAudiencePlatformsQuery({user_id: userId, audience_id: 0});
  const [cancelActivation] = useCancelPlatformActivationMutation();
  const [showDetails, setShowDetails] = useState('');

  useEffect( () => {
    if (platformData) {
      console.log('setting platform data', platformData);
      setPlatforms(platformData);
    }}
    , [platformDataSuccess]);


  useEffect( () => {
    if (thisAudience) {
      getPlatformData();
    }
  }, [thisAudience]);

  function confirmCancelActivation(reference_id, unique_id) {
    if (window.confirm("Are you sure you want to cancel this activation?")) {
      cancelActivation(reference_id, unique_id);
      window.location.reload();
    }
  }

  const cancellationButton = (record) => {
    return record.activation_status != 'CANCELLED' && (
      <tr className='background-cinq-light-yellow-see-through platform-table-details'>
        <td align='right' colSpan={7}>
          <a href='#' onClick={() =>  {confirmCancelActivation({reference_id: record.reference_id, unique_id: record.unique_id})}}>Cancel Platform</a>
        </td>
      </tr>
    )
  }

  const returnShowDetails = (record) => {
    if (record.unique_id == showDetails) {
      return (
        <>
        <tr className='background-cinq-light-yellow-see-through'>
          <td colSpan={2}>
            <div>
              <ul>
                <li>Account ID: <b>{record.account_id}</b></li>
                <li>Status: <b>{record.activation_status}</b></li>
                <li>Billing Rate: <b>{record.billing_rate.toFixed(2)}</b></li>
                <li>Planned Usage: <b>{record.planned_usage}</b></li>
              </ul>
            </div>
          </td>
          <td colSpan={2}>
            <div>
              <ul>
                {/*<li>Platform ID Identify: <b>{record.platform_id_identify == 1 ? 'Yes' : 'No'}</b></li>*/}
                {/*<li>Cookies Identify: <b>{record.cookies_identify == 1 ? 'Yes' : 'No'}</b></li>*/}
                {/*<li>IDFA Identify: <b>{record.idfa_identify == 1 ? 'Yes' : 'No'}</b></li>*/}
                {/*<li>AAID Identify: <b>{record.aaid_identify == 1 ? 'Yes' : 'No'}</b></li>*/}
              </ul>
            </div>
          </td>
          <td colSpan={3}>
            <div>
              <ul>
                <li>Usage Agreement: <b>{new Date(parseInt(record.usage_agree)).toLocaleDateString()}</b></li>
                <li>Contact Email: <b>{record.contact_email}</b></li>
                <li>Note: <b>{record.note_1}</b></li>
                <li>Account Name: <b>{record.account_name_1}</b></li>
              </ul>
            </div>
          </td>
        </tr>
        {cancellationButton(record)}

        </>
      )
    } 
  }

  const audienceDetailRows = () => {
    return platforms
    .filter((t) => t.audience_id == thisAudience?.id)
    .map((row, i) => (
      <React.Fragment key={i}>
        <tr key={i} className={row.unique_id == showDetails ? 'background-cinq-light-yellow-see-through' : ''}>
          <td>
            <div className='platform-image-container'>
              <img src={"../../images/activation/" + liveRampPlatforms.find(t => t.id == row.platform_id).image}/>
            </div>
          </td>
          <td colSpan={2}>{row.start_date} to {row.end_date}</td>
          <td>{row.activation_status}</td>
          <td>{row.segment_id ? row.segment_id : 'TBD'}</td>
          <td>{new Date(row.created_2).toLocaleDateString()}</td>
          <td>
            { row.unique_id != showDetails? <p className='link' onClick={()=>{setShowDetails(row.unique_id)}}>View details</p> : ''}
            { row.unique_id == showDetails? <p className='link' onClick={()=>{setShowDetails('')}}>Close details</p> : ''}
          </td>
        </tr>
        {returnShowDetails(row)}
      </React.Fragment>
    ))
  }

  const showAudienceDetails = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>Platforms </th>
            <th colSpan={2}>Campaign</th>
            <th>Status</th>
            <th>Segment ID</th>
            <th>Transaction Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {audienceDetailRows()}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience} getAudience={true} getCustomAudience={customAudience == 1 ? true : false}/>
      <div className='client_dashboard_view_header standard'>
        <div className='client_dashboard_view_header_wrapper'>
          <h3 className='cinq-title'> {formatAudienceTitle(thisAudience?.title)} </h3> 
        </div>
      </div>
      <div className='client_dashboard_view_content'>
        <div className='your-platform-activations'>
          <div className='your-platform-activations_title_wrapper'>
            <div className='your-platform-activations_title_wrapper_description'>
              <p> Originated on {new Date(origDate).toLocaleDateString()}</p>
              <p> Activity on {platforms?.filter((t) => t.audience_id == thisAudience?.id).length} platforms </p>
            </div>
            <button onClick={() => { navigate({ pathname: '/dashboard/audience/activate', search: createSearchParams({ uuid: thisAudience?.uuid, ref: thisAudience?.reference_id, custom: thisAudience?.custom }).toString() }) }}><p>Add New Platform</p></button> 
          </div>
          <div className='your_platform_activations_table'>
            {showAudienceDetails()}
          </div>
        </div>
      </div>
    </>
  )
}