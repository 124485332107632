import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip} from "chart.js";
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from "prop-types";
import { useSelectGAWidgetDataQuery } from "../../app/api";
import { createDataObject } from "../../data/WidgetData";
import { useSelector } from "react-redux";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import Loading from "../UIElements/Loading";
import { topicMappings } from "../../data/HeadlineData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { allWidgetFields } from "../../data/WidgetFields";
import { sourceRollup } from "../../data/WidgetData";
import { widgetDisplay } from "../../data/WidgetData";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import { toggleFilterField } from "./BarGraph";


// GA data for corresponding widgets
import { createGAQuery, gaWidgetSelect, gaWidgetGroupBy } from "../../data/WidgetData";


export default function GABarGraph({type, selectedAudience, headlineFilter='none', filterByClick=false, refreshCounter=0}) {
  
  //const savedWidgetData = useSelector(selectGAWidgetData);
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = allWidgetFields; 
  const dispatch = useDispatch();
  const currentDateRange = useSelector(selectCurrentHeadlineDateRange);
  const [hasFetched, setHasFetched] = useState(false);
  const savedTopics = useSelector(selectInTopics);
  const savedOutTopics = useSelector(selectOutTopics);


  const [gaQueryString, setGaQueryString] = useState(createGAQuery(filter, currentDateRange, headlineFilter));
  const [inTopicString, setInTopicString] = useState(null);
  const [outTopicString, setOutTopicString] = useState(null);

  const mediaSourceFields = () => { 
    let newArray = [];
    sourceRollup.map(t => {
      newArray.push(t.source);
    });
    return newArray;
  }

  const determineAspectRationBySource = () => {
    if (window.location.pathname == "/dashboard/article") { // Customize Headline Article Page
      return 1.4; // Lower Aspect for 3 wide widgets
    }
    else { // Customize Readership Page
      return 2.0;
    }
  }

  const determineLabelSizeBySource = () => {
     if (window.location.pathname == "/dashboard/article") { // Customize Headline Article Page
      return 13;
    }
    else { // Customize Readership Page
      return 14;
    }
  }

  const {data: widgetData, isFetching: widgetsFetching, refetch: refetchWidgets} = useSelectGAWidgetDataQuery(
    {
      filter_query_string: gaQueryString,
      widget_query_string: gaWidgetSelect(type),
      group_by_query_string: gaWidgetGroupBy(type),
      widget_type: type, 
      audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
      include: inTopicString,
      exclude: outTopicString ,
      source_list: type == 'source' ? mediaSourceFields() : 'none',
    }, {skip: !inTopicString || !outTopicString});

  const [dataObject, setDataObject] = useState(widgetData && createDataObject(widgetData, [], widgetFields.filter(t => t.widget_type == type), type));

  useEffect(() => {
    setDataObject(null);
    if (savedTopics.length > 0 && savedTopics[0].length > 0 ) {
        let newArray = mergeByTopic(savedTopics, topicMappings);
        setInTopicString(newArray.join(",") || 'all'); 
    }
    else {
      setInTopicString('all'); 
    }
  }, [savedTopics]);

  useEffect(() => {
    setDataObject(null);
    if (savedOutTopics.length > 0 && savedOutTopics[0].length > 0) {
      let newArray = mergeByTopic(savedOutTopics, topicMappings);
      setOutTopicString(newArray.join(",") || 'none'); 
    }
    else {
      setOutTopicString('none'); 
    }

  }, [savedOutTopics]);

  useEffect(() => {
    if (widgetData) {
      if (!hasFetched) {
        setHasFetched(true);
      }
    }
  }, [widgetData]);


  useEffect( () => {
    if (hasFetched) {
      refetchWidgets();
    }
  }, [inTopicString, outTopicString]);

  useEffect(() => {
    if (hasFetched) {
      setGaQueryString(createGAQuery(filter, currentDateRange, headlineFilter));
      refetchWidgets();
    }

  },[filter, headlineFilter])

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentDateRange, headlineFilter));
  }, [currentDateRange])

  useEffect(() => {
    setDataObject(null);
  }, [filter]);

  useEffect(() => {
    if(widgetData && !widgetsFetching ) {
        const newDataObject = createDataObject(widgetData, [], widgetFields.filter(t => t.widget_type == type), type);
        setDataObject(newDataObject);
    }
  }, [widgetData, refreshCounter, widgetsFetching ] );


  // Bar Graph dataObject parameters:
  // title: to be display along the top of the widget
  // maxes: {value: value of max %, desc: descripition between, cat: category of max}
  // no_of_bars: 1 or 2 
  // categories: [name, name] names of categories
  // dataset1: [data, data] data by category
  // dataset2: [data, data] if applicable 
  // type: 'percentage' or 'count'
  // colors: [color1, color2]

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartDataLabels);

  const options = {
    indexAxis: 'y' ,
    aspectRatio: determineAspectRationBySource(),
    layout: {
      padding: {
        right: 27
      }
    },
    scales: {
      x: {
        display: false,
        offset: true,
      },
      y: {
        display: true,
        grid: {
          display: false,
        }, 
        ticks: {
          color: '#000000',
          font: {
            size: determineLabelSizeBySource(),
          }
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    onHover: () => {
      let displayInfo = widgetDisplay.find(t => t.widget == type);
      if (displayInfo && displayInfo.hasGA == true && displayInfo.hasL2 == true && displayInfo.skipFilter != true && filterByClick){
        var el = document.getElementById(type);
        el.style.cursor = "pointer" ;
      }
    },
    onClick: (e, activeEls) => {
      let datasetIndex = activeEls[0].datasetIndex;
      let dataIndex = activeEls[0].index;
      let datasetLabel = e.chart.data.datasets[datasetIndex].label;
      let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
      let label = e.chart.data.labels[dataIndex];
      console.log("In click", datasetLabel, label, value);
      let displayInfo = widgetDisplay.find(t => t.widget == type);
      if (displayInfo && displayInfo.hasGA == true && displayInfo.hasL2 == true && displayInfo.skipFilter != true && filterByClick){
        toggleFilterField(filter, type, label, dispatch, saveCurrentView);
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#000000',
        anchor: 'end',
        align: 'right',
        offset: 0,
        formatter: function(value) {
          return value + '%';
        },
        font: {
          size: 14,
        },
      },
      tooltip: {
        titleFont: {
          size: 13,
        }, 
        bodyFont: {
          size: 13,
        }
      },
    },
  };

const labels = dataObject?.categories;

const data = {
  labels,
  no_of_bars: 1,
  datasets: [
    {
      label: 'This Audience',
      data: dataObject?.dataset1,
      backgroundColor: '#2F5D62' 
    },
  ],
};

  return (
    <>
      {(!widgetsFetching && dataObject) ? 
        <Bar id={type} plugins={[ChartDataLabels]} options={options} data={data} />
        :
        <Loading/>
      }
    </>
  );
}

GABarGraph.propTypes = {
  type: PropTypes.string,
  selectedAudience: PropTypes.object, 
  headlineFilter: PropTypes.string,
  filterByClick: PropTypes.bool,
  refreshCounter: PropTypes.number,
}