import React from "react";

export default function Terms() {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <h2> Advance Local Privacy Policy </h2>
      <h3>Categories of Personal Information We Collect About You</h3>
      <h4>Personal information is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly to you. Depending on the Services you use, we may collect the following categories of personal information about you directly from you, automatically through your use of the Services, and from third parties.</h4>
      <h3>How We Use Your Information</h3>
      <h3>How We Disclose the Information We Collect</h3>
      <h3>Our Use of Cookies and Related Technologies</h3>
      <h3>Online Behavioral Advertising (OBA) and How to Opt-Out Of OBA</h3>
      <h3>Your Privacy Choices And Rights; Submitting Requests Under Applicable Local Laws</h3>
      <h3>Opt-Out of Marketing Communications</h3>
      <h3>Security of My Information</h3>
      <h3>Third Party Links</h3>
      <h3>Be Careful Publicly Posting Personal Information</h3>
      <h3>International Transfers</h3>
      <h3>Children</h3>
      <h3>Contact Us</h3>
      <h3>Changes to this Policy</h3>
    </div>
  )
}