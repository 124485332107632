import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Headline from "../ClientDashboard/Headlines/Headline";
import { useAddHeadlineDetailMutation } from "../../app/api";
import { topicMappings } from "../../data/HeadlineData";
import CategoryButton from "./CategoryButton";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";


export function HeadlineTile({headline, fromAudienceArticles}){
  // fromAudienceArticle parameter is passed only from the audienceArticle page.
  // How to navigate to it - Readership -> Select a headline -> Selected an audience under the headline 

  const dispatch = useDispatch();
  const currentPage = window.location.href.includes('audience') ? 'audience' : 'not audience';

  const api_key = '?accept_lang=auto&app_id=719950f1-932c-49f2-81c6-1cde397ccd5d';

  const headline_fix_url = headline.url;

  const headline_base = headline_fix_url.split('.html')[0];

  const headline_url = headline_base.replaceAll(':','%3A').replaceAll('/','%2F') + '.html'

  const headline_straight_url = headline_url.replaceAll('%3A', ':').replaceAll('%2F','/') 


  const url = 'https://opengraph.io/api/1.1/site/' + headline_url + api_key;
  const [saveHeadlineDetails] = useAddHeadlineDetailMutation();

  const navigate = useNavigate();

  const [metadata, setMetadata] = useState({});
  const getMetaData = async() => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setMetadata(data.openGraph);

      console.log('getMetaData Success', data);
    } catch (error) {
      console.error('getMetatData Error', error);
    }
  }

  useEffect(() => {
    if (!headline.publish_date) {
      getMetaData();
    }
  }, [headline]);


  useEffect(() => {
    if (metadata?.articlePublishedTime && headline.headline) {
      saveHeadlineDetails({headline: headline.headline, title: metadata.title,
        img: metadata.image?.url, publish_date: metadata.articlePublishedTime, 
        blurb: metadata.description, article: '', url: headline_straight_url});
    }
  }, [metadata]);
  
  const date = () => {

    const d = Date.parse(headline?.publish_date ? headline.publish_date : metadata?.articlePublishedTime);
    const dT = new Date(d).toLocaleDateString('en-US');
    return dT;
    
  }

  const tileClick = () => {
    if (currentPage == 'audience' &&  fromAudienceArticles != true) {
      dispatch(saveCurrentView({type: 'audienceTab', value: {type:'article', value:headline.headline}}));
       // do not navigate away from audience page, instead load within filter
    }
    else {
      navigate({pathname: '/dashboard/article', search: createSearchParams({headline: headline.headline}).toString()})
    }

  }
  const returnHeadline = () => {
    if (!headline?.publish_date) {
        return <div >
          <Headline img={metadata?.image?.url} title={metadata?.title || headline.headline} categories={mapTopics()}
            date={date()} blurb={metadata?.description} onClickFunction={tileClick}/> </div> 
    }
    else {
        return (
          <div >
              <Headline img={headline?.img} title={headline?.title} date={date()} blurb={headline?.blurb} categories={mapTopics()} onClickFunction={tileClick} /> 
          </div>
        )
    }

  }

  const topicButtons = (arr) => {
    return arr.filter(t => t).map((t,i) => (
      <CategoryButton key={i} label={t} noHover={false}/>
    ));
  }

  const mapTopics = () => {
    let newArray = [];
    headline.topics?.split('|').map((t => {
      let mapper = topicMappings.find(r => r.affinity_topic == t)?.category;
      if (newArray.indexOf(mapper) == -1){
        newArray.push(mapper);
      }
    }))
    return topicButtons(newArray);
  }

  return (
    <div className='headline-tile' onClick={()=>tileClick}>
        {returnHeadline()}
    </div>
  )

}

HeadlineTile.propTypes = {
  headline: PropTypes.object,
  fromAudienceArticles: PropTypes.bool
}