export const topicMappings = [
   {affinity_topic: "Advice and Relationships", category: "Lifestyle & Culture"},
   {affinity_topic: "Aerospace and Aviation", category: "Travel & Transportation"},
   {affinity_topic: "Agriculture", category: "Business & Finance"},
   {affinity_topic: "Alcohol, Drugs, and Gambling", category: "Lifestyle & Culture"},
   {affinity_topic: "Apps and Software", category: "Technology & Innovations"},
   {affinity_topic: "Art", category: "Arts & Entertainment"},
   {affinity_topic: "Attractions", category: "Arts & Entertainment"},
   {affinity_topic: "Automotive", category: "Travel & Transportation"},
   {affinity_topic: "Awards and Distinctions", category: "Lifestyle & Culture"},
   {affinity_topic: "Banking and Finance Industries", category: "Business & Finance"},
   {affinity_topic: "Bars and Clubs", category: "Consumers & Brands"},
   {affinity_topic: "Baseball", category: "Sports"},
   {affinity_topic: "Basketball", category: "Sports"},
   {affinity_topic: "Books and Literature", category: "Arts & Entertainment"},
   {affinity_topic: "Books, Comics and other Literature", category: "Arts & Entertainment"},
   {affinity_topic: "Budgets and Funding", category: "Law & Government"},
   {affinity_topic: "Business and Finance", category: "Business & Finance"},
   {affinity_topic: "Campaigns and Campaign Finance", category: "Politics"},
   {affinity_topic: "Careers", category: "Jobs & Education"},
   {affinity_topic: "Career Development", category: "Jobs & Education"},
   {affinity_topic: "Celebrity", category: "Lifestyle & Culture"},
   {affinity_topic: "Children and Teens", category: "Lifestyle & Culture"},
   {affinity_topic: "Civil Law", category: "Law & Government"},
   {affinity_topic: "College Sports", category: "Sports"},
   {affinity_topic: "Communication", category: "Technology & Innovations"},
   {affinity_topic: "Communities and Subcultures", category: "Lifestyle & Culture"},
   {affinity_topic: "Companies", category: "Business & Finance"},
   {affinity_topic: "Computers", category: "Technology & Innovations"},
   {affinity_topic: "Congress", category: "Politics"},
   {affinity_topic: "Congressional Elections", category: "Politics"},
   {affinity_topic: "Conservative", category: "Politics"},
   {affinity_topic: "Constitutional Law", category: "Law & Government"},
   {affinity_topic: "Consumer goods and services", category: "Consumers & Brands"},
   {affinity_topic: "Crime", category: "Law & Government"},
   {affinity_topic: "Criminal Law", category: "Law & Government"},
   {affinity_topic: "Culture and Tradition", category: "Lifestyle & Culture"},
   {affinity_topic: "Defense and Cybersecurity", category: "Law & Government"},
   {affinity_topic: "Democrats", category: "Politics"},
   {affinity_topic: "Departments and Agencies", category: "Law & Government"},
   {affinity_topic: "Disaster, Accident and Tragedy", category: "News"},
   {affinity_topic: "Disasters", category: "News"},
   {affinity_topic: "Early Development Education", category: "Jobs & Education"},
   {affinity_topic: "Economy", category: "News"},
   {affinity_topic: "Education", category: "Jobs & Education"},
   {affinity_topic: "Education Methodology and Innovation", category: "Jobs & Education"},
   {affinity_topic: "Elderly", category: "Lifestyle & Culture"},
   {affinity_topic: "Entertainment", category: "Arts & Entertainment"},
   {affinity_topic: "Events", category: "Arts & Entertainment"},
   {affinity_topic: "Family", category: "Lifestyle & Culture"},
   {affinity_topic: "Family and Relationships", category: "Lifestyle & Culture"},
   {affinity_topic: "Fashion", category: "Consumers & Brands"},
   {affinity_topic: "Federal Courts and SCOTUS", category: "Politics"},
   {affinity_topic: "Fine Art", category: "Arts & Entertainment"},
   {affinity_topic: "Food & Drink", category: "Consumers & Brands"},
   {affinity_topic: "Food, Drink and Recipes", category: "Consumers & Brands"},
   {affinity_topic: "Football", category: "Sports"},
   {affinity_topic: "Gaming", category: "Technology & Innovations"},
   {affinity_topic: "Genres", category: "Arts & Entertainment"},
   {affinity_topic: "Golf", category: "Sports"},
   {affinity_topic: "Graduate Education", category: "Jobs & Education"},
   {affinity_topic: "Handicapped and Disabled", category: "Lifestyle & Culture"},
   {affinity_topic: "Health Care", category: "Lifestyle & Culture"},
   {affinity_topic: "Healthy Living", category: "Lifestyle & Culture"},
   {affinity_topic: "High School Sports", category: "Sports"},
   {affinity_topic: "Hobbies & Interests", category: "Lifestyle & Culture"},
   {affinity_topic: "Hockey", category: "Sports"},
   {affinity_topic: "Holidays", category: "Lifestyle & Culture"},
   {affinity_topic: "Home and Garden", category: "Lifestyle & Culture"},
   {affinity_topic: "Immigrants", category: "Lifestyle & Culture"},
   {affinity_topic: "Independent", category: "Politics"},
   {affinity_topic: "Infrastructure", category: "Law & Government"},
   {affinity_topic: "Innovations", category: "News"},
   {affinity_topic: "Insurance Industry", category: "Business & Finance"},
   {affinity_topic: "International Relations", category: "Law & Government"},
   {affinity_topic: "Internet", category: "Technology & Innovations"},
   {affinity_topic: "K-12 Education", category: "Jobs & Education"},
   {affinity_topic: "Labor", category: "Jobs & Education"},
   {affinity_topic: "Law", category: "Law & Government"},
   {affinity_topic: "Law Enforcement", category: "Law & Government"},
   {affinity_topic: "Liberal", category: "Politics"},
   {affinity_topic: "Lobbying and Advocacy", category: "Law & Government"},
   {affinity_topic: "Local Governments", category: "Politics"},
   {affinity_topic: "Luxury", category: "Consumers & Brands"},
   {affinity_topic: "Manufacturing", category: "Business & Finance"},
   {affinity_topic: "Maps & Navigation", category: "Travel & Transportation"},
   {affinity_topic: "Markets", category: "Business & Finance"},
   {affinity_topic: "Media and Advertising", category: "Technology & Innovations"},
   {affinity_topic: "Medical", category: "News"},
   {affinity_topic: "Medical Health", category: "News"},
   {affinity_topic: "Military", category: "News"},
   {affinity_topic: "Mobile and Gadgets", category: "Technology & Innovations"},
   {affinity_topic: "Music", category: "Arts & Entertainment"},
   {affinity_topic: "National Security", category: "News"},
   {affinity_topic: "National Sports", category: "Sports"},
   {affinity_topic: "Non Profits", category: "Lifestyle & Culture"},
   {affinity_topic: "Olympics", category: "Sports"},
   {affinity_topic: "Parks and Museums", category: "Lifestyle & Culture"},
   {affinity_topic: "Personal Celebrations & Life Events", category: "Lifestyle & Culture"},
   {affinity_topic: "Personal Finance", category: "Business & Finance"},
   {affinity_topic: "Pets", category: "Lifestyle & Culture"},
   {affinity_topic: "Philosophy, Ethics and Values", category: "Lifestyle & Culture"},
   {affinity_topic: "Physical Science", category: "News"},
   {affinity_topic: "Policy and Regulation", category: "Law & Government"},
   {affinity_topic: "Politics", category: "Politics"},
   {affinity_topic: "Pop Culture", category: "Lifestyle & Culture"},
   {affinity_topic: "Poverty, Welfare and Charity", category: "Lifestyle & Culture"},
   {affinity_topic: "President and Administration", category: "Politics"},
   {affinity_topic: "Presidential Elections", category: "Politics"},
   {affinity_topic: "Productivity", category: "Technology & Innovations"},
   {affinity_topic: "Race and Ethnicity", category: "Lifestyle & Culture"},
   {affinity_topic: "Real Estate", category: "Business & Finance"},
   {affinity_topic: "Real Estate Industry", category: "News"},
   {affinity_topic: "Recreation", category: "Lifestyle & Culture"},
   {affinity_topic: "Religion and Belief", category: "Lifestyle & Culture"},
   {affinity_topic: "Religion & Spirituality", category: "Lifestyle & Culture"},
   {affinity_topic: "Republicans", category: "Politics"},
   {affinity_topic: "Research", category: "News"},
   {affinity_topic: "Restaurants", category: "Consumers & Brands"},
   {affinity_topic: "Rights, Discrimination and Inequality", category: "Lifestyle & Culture"},
   {affinity_topic: "Scandal and Misconduct", category: "News"},
   {affinity_topic: "Science", category: "News"},
   {affinity_topic: "Sensitive Topics", category: "News"},
   {affinity_topic: "Sex and Gender", category: "Lifestyle & Culture"},
   {affinity_topic: "Sexual Orientation", category: "Lifestyle & Culture"},
   {affinity_topic: "Shopping", category: "Consumers & Brands"},
   {affinity_topic: "Soccer", category: "Sports"},
   {affinity_topic: "Social Media", category: "Technology & Innovations"},
   {affinity_topic: "Sports", category: "Sports"},
   {affinity_topic: "State Courts", category: "Law & Government"},
   {affinity_topic: "State Governments", category: "Law & Government"},
   {affinity_topic: "State and Local Elections", category: "Politics"},
   {affinity_topic: "Strategy and Management", category: "Business & Finance"},
   {affinity_topic: "Style & Fashion", category: "Lifestyle & Culture"},
   {affinity_topic: "Taxes", category: "Business & Finance"},
   {affinity_topic: "Technology & Computing", category: "Technology & Innovations"},
   {affinity_topic: "Technology Industry", category: "Technology & Innovations"},
   {affinity_topic: "Tennis", category: "Sports"},
   {affinity_topic: "Theater, Dance and Spoken Word", category: "Arts & Entertainment"},
   {affinity_topic: "Trade and Commerce", category: "Business & Finance"},
   {affinity_topic: "Transportation and Shipping", category: "Business & Finance"},
   {affinity_topic: "Travel", category: "Travel & Transportation"},
   {affinity_topic: "Travel and Tourism", category: "Travel & Transportation"},
   {affinity_topic: "Undergraduate Education", category: "Jobs & Education"},
   {affinity_topic: "Veterans", category: "Lifestyle & Culture"},
   {affinity_topic: "Video Gaming", category: "Arts & Entertainment"},
   {affinity_topic: "Video, TV and Movies", category: "Arts & Entertainment"},
   {affinity_topic: "War and Conflicts", category: "News"},
   {affinity_topic: "Wars, Conflicts and Terrorism", category: "News"},
   {affinity_topic: "Weather", category: "News"},
   {affinity_topic: "Wellness", category: "Consumers & Brands"},
   {affinity_topic: "Advice and Relationships", category: "Lifestyle & Culture"}
]
  