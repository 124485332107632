import React, {useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Compare from './Compare';
//import Explore from './Explore';
//import AudienceBuilder from './AudienceBuilder';
import { selectSearchString } from '../../features/currentView/currentViewSlice';
import { clearFilters} from "../../features/currentView/currentViewSlice";
import { useGetSelectAllAudiencesQuery } from "../../app/api";
import { selectUserId } from "../../features/auth/authSlice";
import GetAudienceData from '../GetAudienceData';
//import ClientAudienceClusterView from './ClientAudienceClusterView';
import AudienceTileView from "../UIElements/AudienceTileView";

export default function AudienceExplore() {

  const dispatch = useDispatch();
  const [tabView, setTabView] = useState('explore');
  const [audienceListData, setAudienceListData] = useState([]);
  const { data: audienceData, isSuccess: audienceSuccess } =  useGetSelectAllAudiencesQuery();
  const searchString = useSelector(selectSearchString);
  const userId = useSelector(selectUserId);
  const [myAudiences, setMyAudiences] = useState([]);

  useEffect( () => {
    if (audienceSuccess) {
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess] );

  useEffect( () => {
    dispatch(clearFilters());
  },[])

  const tabClass = (classList, thisTab) => {
    if (thisTab == tabView) {
      return classList + ' selected';
    }
    else {
      return classList + ' disabled';
    }
  }

  const callbackMyAudiences = (mA) => {
    setMyAudiences(mA);
  }

  const allAudiences = () => {
    return (
      <AudienceTileView audienceListData={audienceListData} searchString={searchString} userId={parseInt(userId)} myAudiences={myAudiences}/>
    )
  } 

  const tabViewScreen = () => {
    switch (tabView) {
      case 'compare':
        return (
          <>  
            <Compare allAudiences={audienceData} />
          </>
        )
      case 'explore':
        return (
          <>
            {audienceSuccess && allAudiences()}           
          </>
        )
      case 'build':
        return (
          <div className='results'>
            <div className='widget-screen light-grey'>
              <p className='top-bump-40 bump-left-40 light-grey'>Build Your Own Audience Coming Soon....</p>
            </div>
          </div>
        )
      default:
        return (
          <div className='results'>
            <div className='widget-screen'>
              <p className='top-bump-40 bump-left-40 light-grey'>Compare Audiences Coming Soon....</p>
            </div>
          </div>
        )
    }
  }

  return (
    <>
    <div className='client_dashboard_view_header standard'>
      <GetAudienceData getAudience={false} getMyAudiences={true} activeAudienceCallback={callbackMyAudiences} />
        <div className='client_dashboard_header_menu'>
          <div className={tabClass('client_dashboard_header_menu_tab tab_no_title', 'explore')} onClick = {() => {setTabView('explore')}}>
            <div className={tabClass('tab-ui', 'explore')}>
              <h3> Explore </h3>
              <div className={tabClass('tab-highlight', 'explore')}></div>
            </div>
          </div>
          <div className={tabClass('client_dashboard_header_menu_tab tab_no_title','compare')} onClick = {() => {setTabView('compare')}}>
            <div className={tabClass('tab-ui', 'compare')}>
              <h3>Compare</h3>
              <div className={tabClass('tab-highlight', 'compare')}></div>
            </div>
          </div>
          <div className={tabClass('client_dashboard_header_menu_tab tab_no_title', 'build')} onClick = {() => {setTabView('build')}}>
            <div className={tabClass('tab-ui', 'build')}>
              <h3> Build </h3>
              <div className={tabClass('tab-highlight', 'build')}></div>
            </div>
          </div>
       </div>
    </div>
          <div className='client_dashboard_view_content no_padding'>
        {tabViewScreen()}
      </div>
    </>
  );
};