
export const societalData = [
  {name: 'trustGovernment', display: 'Trust Government'},
  {name: 'supportSoc', display: 'SS Tax Inc Support'},
  {name: 'trustCEO', display: 'Trust CEOs'},
  {name: 'believeCapitalismFlaw', display: 'Believe Capitalism Flawed'},
  {name: 'believeWealth', display: 'Wealth by Hard Work'},
  {name: 'gunControl', display: 'Gun Control Support'},
  {name: 'worriedViolent', display: 'Worried Violent Crime'},
  {name: 'gayMarriage', display: 'Gay Marriage Support'},
  {name: 'againstBanning', display: 'Against Banning CRT Books'},
]

export const clusterSocietalData = [

  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'trustGovernment', value: 77},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'supportSoc', value: 56},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'trustCEO', value: 35},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'believeCapitalismFlaw', value: 69},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'believeWealth', value: 26},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'gunControl', value: 76},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'worriedViolent', value: 25},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'gayMarriage', value: 81},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'againstBanning', value: 72},

  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'trustGovernment', value: 53},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'supportSoc', value: 60},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'trustCEO', value: 55},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'believeCapitalismFlaw', value: 76},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'believeWealth', value: 37},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'gunControl', value: 65},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'worriedViolent', value: 69},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'gayMarriage', value: 60},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'againstBanning', value: 57},

  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'trustGovernment', value: 79 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'supportSoc', value: 80 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'trustCEO', value: 36 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'believeCapitalismFlaw', value: 21 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'believeWealth', value: 38 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'gunControl', value: 80 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'worriedViolent', value: 36 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'gayMarriage', value: 71 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'againstBanning', value:  67},

  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'trustGovernment', value: 61 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'supportSoc', value: 72 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'trustCEO', value: 50  },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'believeCapitalismFlaw', value: 35  },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'believeWealth', value: 43 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'gunControl', value: 73 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'worriedViolent', value: 75  },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'gayMarriage', value: 50 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'againstBanning', value: 45 },

  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'trustGovernment', value: 65 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'supportSoc', value: 43 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'trustCEO', value: 58 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'believeCapitalismFlaw', value: 45 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'believeWealth', value: 58 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'gunControl', value: 51 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'worriedViolent', value: 39 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'gayMarriage', value: 63 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'againstBanning', value: 54 },

  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'trustGovernment', value: 34 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'supportSoc', value: 40 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'trustCEO', value: 34 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'believeCapitalismFlaw', value: 77 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'believeWealth', value: 51 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'gunControl', value: 41 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'worriedViolent', value: 51 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'gayMarriage', value: 48 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'againstBanning', value: 58 },

  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'trustGovernment', value: 36 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'supportSoc', value: 38},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'trustCEO', value: 54 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'believeCapitalismFlaw', value: 45 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'believeWealth', value: 55 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'gunControl', value: 23 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'worriedViolent', value: 24  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'gayMarriage', value: 46  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'againstBanning', value: 47 },

  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'trustGovernment', value: 25 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'supportSoc', value: 44 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'trustCEO', value: 67 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'believeCapitalismFlaw', value: 29 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'believeWealth', value: 63 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'gunControl', value: 25 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'worriedViolent', value: 54  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'gayMarriage', value: 21 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'againstBanning', value: 33 },

  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'trustGovernment', value: 34 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'supportSoc', value: 24 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'trustCEO', value: 62 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'believeCapitalismFlaw', value: 40 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'believeWealth', value: 78 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'gunControl', value: 27 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'worriedViolent', value: 76 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'gayMarriage', value: 17 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'againstBanning', value: 18 },

  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'trustGovernment', value: 22 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'supportSoc', value: 24 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'trustCEO', value: 67 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'believeCapitalismFlaw', value: 31  },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'believeWealth', value: 77 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'gunControl', value: 17 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'worriedViolent', value: 66  },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'gayMarriage', value: 13 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'againstBanning', value: 17  },

]

export const factorData = [
  {name: 'fundPrograms', display: 'Fund Government Programs'},
  {name: 'rightSystemicWrongs', display: 'Right Systemic Wrongs'},
  {name: 'societalDistrust', display: 'Societal Distrust'},
  {name: 'reformJustice', display: 'Reform Justice System'},
  {name: 'flattenCurve', display: 'Flatten the Curve'},
  {name: 'proChoice', display: 'Pro-Choice'},
  {name: 'prioritizeSecurity', display: 'Prioritize Security'},
  {name: 'traditionalValues', display: 'Traditional Values'},
]

export const clusterDemoList = [
  'Gender',
  'Ethnicity',
  'Party',
  'Education',
  'Avg HH Income'
]

export const clusterDemoData = [
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Average Age', multi: false, value: 37 , percent: false},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Gender', multi: true, sub: 'Male', value: 46 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Gender', multi: true, sub: 'Female', value: 54 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 23 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Party', multi: true, sub: 'Democrat', value: 87 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Party', multi: true, sub: 'Republican', value: 2 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Party', multi: true, sub: 'Independent', value: 10 , percent: true},
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Education', sub:'College Degree', multi: false, value: 23, percent: true },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'Avg HH Income', multi: false, value: '$124,000', percent: false },

  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Average Age', multi: false, value: 38 , percent: false},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Gender', multi: true, sub: 'Male', value: 42 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Gender', multi: true, sub: 'Female', value: 58 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 76 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Party', multi: true, sub: 'Democrat', value: 67 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Party', multi: true, sub: 'Republican', value: 2 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Party', multi: true, sub: 'Independent', value: 28 , percent: true},
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Education', sub:'College Degree', multi: false, value: 11, percent: true },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'Avg HH Income', multi: false, value: '$74,000', percent: false },

  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Average Age', multi: false, value: 68 , percent: false},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Gender', multi: true, sub: 'Male', value: 42 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Gender', multi: true, sub: 'Female', value: 58 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 12 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Party', multi: true, sub: 'Democrat', value: 89 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Party', multi: true, sub: 'Republican', value: 3 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Party', multi: true, sub: 'Independent', value: 7 , percent: true},
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Education', sub:'College Degree', multi: false, value: 46, percent: true },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'Avg HH Income', multi: false, value: '$124,000', percent: false },

  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Average Age', multi: false, value: 68 , percent: false},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Gender', multi: true, sub: 'Male', value: 36 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Gender', multi: true, sub: 'Female', value: 64 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 63 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Party', multi: true, sub: 'Democrat', value: 83 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Party', multi: true, sub: 'Republican', value: 2 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Party', multi: true, sub: 'Independent', value: 14 , percent: true},
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Education', sub:'College Degree', multi: false, value: 25, percent: true },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'Avg HH Income', multi: false, value: '$82,000', percent: false },

  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Average Age', multi: false, value: 44 , percent: false},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Gender', multi: true, sub: 'Male', value: 31 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Gender', multi: true, sub: 'Female', value: 69 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 20 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Party', multi: true, sub: 'Democrat', value: 13 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Party', multi: true, sub: 'Republican', value: 39 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Party', multi: true, sub: 'Independent', value: 45 , percent: true},
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Education', sub:'College Degree', multi: false, value: 44, percent: true },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'Avg HH Income', multi: false, value: '$169,000', percent: false },

  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Average Age', multi: false, value: 42 , percent: false},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Gender', multi: true, sub: 'Male', value: 6 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Gender', multi: true, sub: 'Female', value: 94 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 14 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Party', multi: true, sub: 'Democrat', value: 14 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Party', multi: true, sub: 'Republican', value: 42 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Party', multi: true, sub: 'Independent', value: 41 , percent: true},
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Education', sub:'College Degree', multi: false, value: 17, percent: true },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'Avg HH Income', multi: false, value: '$68,000', percent: false },

  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Average Age', multi: false, value: 38 , percent: false},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Gender', multi: true, sub: 'Male', value: 96 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Gender', multi: true, sub: 'Female', value: 4 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 10 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Party', multi: true, sub: 'Democrat', value: 4 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Party', multi: true, sub: 'Republican', value: 45 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Party', multi: true, sub: 'Independent', value: 47 , percent: true},
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Education', sub:'College Degree', multi: false, value: 22, percent: true },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'Avg HH Income', multi: false, value: '$119,000', percent: false },

  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Average Age', multi: false, value: 63 , percent: false},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Gender', multi: true, sub: 'Male', value: 94 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Gender', multi: true, sub: 'Female', value: 6 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 11 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Party', multi: true, sub: 'Democrat', value: 7 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Party', multi: true, sub: 'Republican', value: 52 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Party', multi: true, sub: 'Independent', value: 38 , percent: true},
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Education', sub:'College Degree', multi: false, value: 33, percent: true },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'Avg HH Income', multi: false, value: '$111,000', percent: false },

  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Average Age', multi: false, value: 71 , percent: false},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Gender', multi: true, sub: 'Male', value: 21 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Gender', multi: true, sub: 'Female', value: 79 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 10 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Party', multi: true, sub: 'Democrat', value: 2 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Party', multi: true, sub: 'Republican', value: 88 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Party', multi: true, sub: 'Independent', value: 10 , percent: true},
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Education', sub:'College Degree', multi: false, value: 38, percent: true },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'Avg HH Income', multi: false, value: '$107,000', percent: false },

  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Average Age', multi: false, value: 66 , percent: false},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Gender', multi: true, sub: 'Male', value: 56 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Gender', multi: true, sub: 'Female', value: 44 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Ethnicity', sub: 'BIPOC', multi: false, value: 8 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Party', multi: true, sub: 'Democrat', value: 2 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Party', multi: true, sub: 'Republican', value: 90 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Party', multi: true, sub: 'Independent', value: 7 , percent: true},
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Education', sub:'College Degree', multi: false, value: 40, percent: true },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'Avg HH Income', multi: false, value: '$113,000', percent: false },

]

export const clusterFactorData = [

  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'fundPrograms', value: 0.3 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'rightSystemicWrongs', value: 1.1 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'societalDistrust', value: 0.4 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'reformJustice', value: 0.7 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'flattenCurve', value: 0.5 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'proChoice', value: 0.0 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'prioritizeSecurity', value: -0.3 },
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'traditionalValues', value: 0.1 },

  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'fundPrograms', value: 0.2 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'rightSystemicWrongs', value: 1.4 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'societalDistrust', value: -0.5 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'reformJustice', value: -0.9 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'flattenCurve', value: 0.1 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'proChoice', value: 0.0 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'prioritizeSecurity', value: -0.1 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'traditionalValues', value: -0.5  },

  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'fundPrograms', value: 1.4  },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'rightSystemicWrongs', value: -0.4 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'societalDistrust', value: 0.5 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'reformJustice', value: 0.6 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'flattenCurve', value: 0.1 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'proChoice', value: 0.2 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'prioritizeSecurity', value: 0.3 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'traditionalValues', value: 0.4  },

  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'fundPrograms', value: 1.1 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'rightSystemicWrongs', value: 0.0  },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'societalDistrust', value: -0.2 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'reformJustice', value: -0.8 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'flattenCurve', value: 0.1 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'proChoice', value: 0.4 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'prioritizeSecurity', value: 0.2 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'traditionalValues', value: -0.1  },

  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'fundPrograms', value: -0.6  },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'rightSystemicWrongs', value: 0.2   },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'societalDistrust', value: -0.4 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'reformJustice', value: 0.5 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'flattenCurve', value: -0.2 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'proChoice', value: -0.1 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'prioritizeSecurity', value: 1.1 },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'traditionalValues', value: 0.7  },

  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'fundPrograms', value: -1.0  },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'rightSystemicWrongs', value: 0.2   },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'societalDistrust', value: 0.8 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'reformJustice', value: 0.0  },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'flattenCurve', value: 0.1 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'proChoice', value: 0.1 },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'prioritizeSecurity', value: 0.5  },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'traditionalValues', value: -0.4  },

  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'fundPrograms', value: -0.9  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'rightSystemicWrongs', value: 0.3  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'societalDistrust', value: -0.3  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'reformJustice', value: 0.8  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'flattenCurve', value: -0.7 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'proChoice', value: 0.1 },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'prioritizeSecurity', value: -0.5  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'traditionalValues', value: -0.2  },

  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'fundPrograms', value: 0.0   },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'rightSystemicWrongs', value: -0.7  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'societalDistrust', value: -0.4  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'reformJustice', value: 0.0  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'flattenCurve', value: -0.7 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'proChoice', value: -0.7 },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'prioritizeSecurity', value: -0.6  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'traditionalValues', value: -0.5  },

  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'fundPrograms', value: -0.5   },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'rightSystemicWrongs', value: -1.1  },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'societalDistrust', value: -0.1  },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'reformJustice', value: -0.7  },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'flattenCurve', value: 0.4 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'proChoice', value: -0.8 },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'prioritizeSecurity', value: 0.1  },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'traditionalValues', value: 0.3 },

  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'fundPrograms', value: -0.7   },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'rightSystemicWrongs', value: -1.1   },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'societalDistrust', value: -0.3  },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'reformJustice', value: -0.3  },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'flattenCurve', value: 0.1 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'proChoice', value: 0.5 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'prioritizeSecurity', value: -0.5  },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'traditionalValues', value: 0.1 },

]

export const dialData = [
  {name: 'avgAge', display: 'Average Age'},
]

export const clusterDialData = [
  {audience: 'Role of Government Personas_1 - Liberal Professionals', name: 'avgAge', value: 37 },
  {audience: 'Role of Government Personas_2 - Working Class Progressives', name: 'avgAge', value: 38 },
  {audience: 'Role of Government Personas_3 - Seniors 4 Safety Net', name: 'avgAge', value: 68 },
  {audience: 'Role of Government Personas_4 - Diverse & Devout', name: 'avgAge', value: 68  },
  {audience: 'Role of Government Personas_5 - Family First Affluent', name: 'avgAge', value: 44   },
  {audience: 'Role of Government Personas_6 - Skeptical Persuadable Women', name: 'avgAge', value: 42  },
  {audience: 'Role of Government Personas_7 - Libertarian Millennial Men', name: 'avgAge', value: 38  },
  {audience: 'Role of Government Personas_8 - Establishment GOP Men', name: 'avgAge', value: 63  },
  {audience: 'Role of Government Personas_9 - Christian Conservative Women', name: 'avgAge', value: 71 },
  {audience: 'Role of Government Personas_10 - MAGA Loyalists', name: 'avgAge', value: 66  },
]