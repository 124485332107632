import React from "react";
import PropTypes from "prop-types";
import AudienceTile from "../UIElements/AudienceTile";
import {Tooltip} from 'react-tooltip';
import { cinqAIKeywords } from "../../data/CinqData";
import { selectOrgId } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";

export default function AudienceTileView({audienceListData, searchString, userId, myAudiences}) {

  const userOrgId = useSelector(selectOrgId);

  const searchStringDescResults = cinqAIKeywords
    .filter(t => t.keywords.toLowerCase().includes(searchString.toLowerCase()))
    .map (t => (
      t.audience
    ));

  const allIssueOrientedAudiences = () => {
    return (
      audienceListData
      .filter(t => t.org_limited == 0 || (t.org_limited == 1 && t.org_id == userOrgId))
      .filter(t => t.active == 1)
      .filter(t => t.title )
      .filter(t => t.title.toLowerCase().includes(searchString.toLowerCase()) || t.description.toLowerCase().includes(searchString.toLowerCase()) || searchStringDescResults.toString().includes(t.audience) ) 
      .filter(t => t.audience_category == 'ISSUE')
      .map((t, i) => (
        <li key={i} >
          <AudienceTile uniqueName={t.audience} displayTitle={t.title} audienceId={t.id} audienceDesc={t.description} audienceCount={parseInt(t.count)} userId={parseInt(userId)} cluster={t.cluster} uuid={t.uuid} myAudiences={myAudiences} isAudiencePage={true}/>
        </li>
      ))
    )
  } 

  const allBehavioralAudiences = () => {
    return (
      audienceListData
      .filter(t => t.org_limited == 0 || (t.org_limited == 1 && t.org_id == userOrgId))
      .filter(t => t.active == 1)
      .filter(t => t.title )
      .filter(t => t.title.toLowerCase().includes(searchString.toLowerCase()) || t.description.toLowerCase().includes(searchString.toLowerCase()) || searchStringDescResults.toString().includes(t.audience) ) 
      .filter(t => t.audience_category == 'BEHAVIORAL')
      .map((t, i) => (
        <li key={i} >
          <AudienceTile uniqueName={t.audience} displayTitle={t.title} audienceId={t.id} audienceDesc={t.description} audienceCount={parseInt(t.count)} userId={parseInt(userId)} cluster={t.cluster} uuid={t.uuid} myAudiences={myAudiences} fromAudienceExplore={true} isAudiencePage={true}/>
        </li>
      ))
    )    
  }

  const allMultiIssueAudiences = () => {
    return (
      audienceListData
      .filter(t => t.org_limited == 0 || (t.org_limited == 1 && t.org_id == userOrgId))
      .filter(t => t.active == 1)
      .filter(t => t.title )
      .filter(t => t.title.toLowerCase().includes(searchString.toLowerCase()) || t.description.toLowerCase().includes(searchString.toLowerCase()) || searchStringDescResults.toString().includes(t.audience) ) 
      .filter(t => t.audience_category == 'MULTI-ISSUE')
      .map((t, i) => (
        <li key={i} >
          <AudienceTile uniqueName={t.audience} displayTitle={t.title} audienceId={t.id} audienceDesc={t.description} audienceCount={parseInt(t.count)} userId={parseInt(userId)} cluster={t.cluster} uuid={t.uuid} myAudiences={myAudiences} fromAudienceExplore={true} isAudiencePage={true}/>
        </li>
      ))
    )    
  }
  
  return (
    <div className="audience_tile_view">
      <div className="audience_tile_view_third_wrapper">
        <h3>Issue-Oriented</h3>
        <ul>{allIssueOrientedAudiences()}</ul>
      </div>
      <div className="audience_tile_view_third_wrapper">
        <div className="audience_tile_view_title_wrapper"> 
          <h3>Behavioral</h3>
        </div> 
        <ul>{allBehavioralAudiences()}</ul>
      </div>
      <div className="audience_tile_view_third_wrapper">
        <div className="audience_tile_view_title_wrapper"> 
          <h3>Multi-Issue Psychographic</h3>
          <div className=''> <div><i className='fa fa-solid fa-info-circle' data-tooltip-id='i-tool'/><Tooltip id='i-tool' className='i-tooltip'>Multi-Issue Psychographic Personas are premium audiences that categorize voters into mutually-exclusive cohorts based on their opinions on various political, economic, and social issues.</Tooltip></div></div>
        </div> 
        <ul>{allMultiIssueAudiences()}</ul>
      </div>
    </div>
  )
}

AudienceTileView.propTypes = {
  audienceListData: PropTypes.array,
  searchString: PropTypes.string,
  userId: PropTypes.number,
  myAudiences: PropTypes.array
}