import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {downloadCSVTimestamp, downloadCSVFullTimestamp, formatAudienceTitle} from "../userSettings"; 
import { useSelectWidgetDataQuery } from "../../app/api";
import { getFilter } from "../../features/currentView/currentViewSlice";
import { noFilter } from "../../features/currentView/currentViewSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createDataObject } from "../../data/WidgetData";
import { selectWidgetFields } from "../../features/currentView/currentViewSlice";

/*   
Audience Insights

Age - age_range_standard 
Generation - age_range
Education (Full Breakout) - education_1
Education (Condensed) - education_2
Ethnicity - ethnicity
Hispanic Origin - ethnicity_hispanic
Hispanic + Language - ethnicity_language_hispanic
Gender - gender
Household Income - income_range
Language - language
Party - party
Home Ownership - home_owner_or_renter
Marital Status - marital_status
Presence of Children - presence_of_children
Voter Frequency - vote_propensity
Likely Voter - likely_primary_voter 
Likely Voter - likely_presidential_primary_voter 
Likely Voter - general_election_voter 
*/


export default function CompleteWidgetCSVDownloadLink({selectedAudience, setRunCSVDownloader}){
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = useSelector(selectWidgetFields);

  // Data queries

  //Age - age_range_standard 
  const{data: ageWidgetData, isSuccess: ageSuccess} = useSelectWidgetDataQuery(getFilter('age_range_standard', selectedAudience?.audience, 'audience', filter));
  const{data: ageAllWidgetData, isSuccess: ageAllSuccess} = useSelectWidgetDataQuery(getFilter('age_range_standard', 'none','all', noFilter));
  
  //Generation - age_range
  const{data: generationWidgetData, isSuccess: generationSuccess} = useSelectWidgetDataQuery(getFilter('age_range', selectedAudience?.audience, 'audience', filter));
  const{data: generationAllWidgetData, isSuccess: generationAllSuccess} = useSelectWidgetDataQuery(getFilter('age_range', 'none','all', noFilter));

  //Education (Full Breakout) - education_1 
  const{data: educationFullWidgetData, isSuccess: educationFullSuccess} = useSelectWidgetDataQuery(getFilter('education_1', selectedAudience?.audience, 'audience', filter));
  const{data: educationFullAllWidgetData, isSuccess: educationFullAllSuccess} = useSelectWidgetDataQuery(getFilter('education_1', 'none','all', noFilter));

  //Education (Condensed) - education_2
  const{data: educationCondensedWidgetData, isSuccess: educationCondensedSuccess} = useSelectWidgetDataQuery(getFilter('education_2', selectedAudience?.audience, 'audience', filter));
  const{data: educationCondensedAllWidgetData, isSuccess: educationCondensedAllSuccess} = useSelectWidgetDataQuery(getFilter('education_2', 'none','all', noFilter));
  
  //Ethnicity - ethnicity
  const{data: ethnicityWidgetData, isSuccess: ethnicitySuccess} = useSelectWidgetDataQuery(getFilter('ethnicity', selectedAudience?.audience, 'audience', filter));
  const{data: ethnicityAllWidgetData, isSuccess: ethnicityAllSuccess} = useSelectWidgetDataQuery(getFilter('ethnicity', 'none','all', noFilter));  
  
  //Hispanic Origin - ethnicity_hispanic
  const{data: hispanicOriginWidgetData, isSuccess: hispanicOriginSuccess} = useSelectWidgetDataQuery(getFilter('ethnicity_hispanic', selectedAudience?.audience, 'audience', filter));
  const{data: hispanicOriginAllWidgetData, isSuccess: hispanicOriginAllSuccess} = useSelectWidgetDataQuery(getFilter('ethnicity_hispanic', 'none','all', noFilter));  
  
  //Hispanic + Language - ethnicity_language_hispanic  
  const{data: hispanicLanguageWidgetData, isSuccess: hispanicLanguageSuccess} = useSelectWidgetDataQuery(getFilter('ethnicity_language_hispanic', selectedAudience?.audience, 'audience', filter));
  const{data: hispanicLanguageAllWidgetData, isSuccess: hispanicLanguageAllSuccess} = useSelectWidgetDataQuery(getFilter('ethnicity_language_hispanic', 'none','all', noFilter));  

  //Gender - gender
  const{data: genderWidgetData, isSuccess: genderSuccess} = useSelectWidgetDataQuery(getFilter('gender', selectedAudience?.audience, 'audience', filter));
  const{data: genderAllWidgetData, isSuccess: genderAllSuccess} = useSelectWidgetDataQuery(getFilter('gender', 'none','all', noFilter));  

  //Household Income - income_range
  const{data: householdIncomeWidgetData, isSuccess: householdIncomeSuccess} = useSelectWidgetDataQuery(getFilter('income_range', selectedAudience?.audience, 'audience', filter));
  const{data: householdIncomeAllWidgetData, isSuccess: householdIncomeAllSuccess} = useSelectWidgetDataQuery(getFilter('income_range', 'none','all', noFilter));  

  //Language - language
  const{data: languageWidgetData, isSuccess: languageSuccess} = useSelectWidgetDataQuery(getFilter('language', selectedAudience?.audience, 'audience', filter));
  const{data: languageAllWidgetData, isSuccess: languageAllSuccess} = useSelectWidgetDataQuery(getFilter('language', 'none','all', noFilter));  

  //Party - party
  const{data: partyWidgetData, isSuccess: partySuccess} = useSelectWidgetDataQuery(getFilter('party', selectedAudience?.audience, 'audience', filter));
  const{data: partyAllWidgetData, isSuccess: partyAllSuccess} = useSelectWidgetDataQuery(getFilter('party', 'none','all', noFilter));  

  //Home Ownership - home_owner_or_renter
  const{data: homeOwnershipWidgetData, isSuccess: homeOwndershipSuccess} = useSelectWidgetDataQuery(getFilter('home_owner_or_renter', selectedAudience?.audience, 'audience', filter));
  const{data: homeOwnershipAllWidgetData, isSuccess: homeOwndershipAllSuccess} = useSelectWidgetDataQuery(getFilter('home_owner_or_renter', 'none','all', noFilter));  

  //Marital Status - marital_status
  const{data: maritalStatusWidgetData, isSuccess: maritalStatusSuccess} = useSelectWidgetDataQuery(getFilter('marital_status', selectedAudience?.audience, 'audience', filter));
  const{data: maritalStatusAllWidgetData, isSuccess: maritalStatusAllSuccess} = useSelectWidgetDataQuery(getFilter('marital_status', 'none','all', noFilter));  

  //Presence of Children - presence_of_children
  const{data: presenceOfChildrenWidgetData, isSuccess: presenceOfChildenSuccess} = useSelectWidgetDataQuery(getFilter('presence_of_children', selectedAudience?.audience, 'audience', filter));
  const{data: presenceOfChildrenAllWidgetData, isSuccess: presenceOfChildenAllSuccess} = useSelectWidgetDataQuery(getFilter('presence_of_children', 'none','all', noFilter));  

  //Voter Frequency - vote_propensity
  const{data: voterFrequencyWidgetData, isSuccess: voteFrequencySuccess} = useSelectWidgetDataQuery(getFilter('vote_propensity', selectedAudience?.audience, 'audience', filter));
  const{data: voterFrequencyAllWidgetData, isSuccess: voteFrequencyAllSuccess} = useSelectWidgetDataQuery(getFilter('vote_propensity', 'none','all', noFilter));  

  // Likely Voter - likely_primary_voter 
  const{data: primaryWidgetData, isSuccess: primarySuccess} = useSelectWidgetDataQuery(getFilter('likely_primary_voter', selectedAudience?.audience, 'audience', filter));
  const{data: primaryAllWidgetData, isSuccess: primaryAllSuccess} = useSelectWidgetDataQuery(getFilter('likely_primary_voter', 'none','all', noFilter));

  // Likely Voter - likely_presidential_primary_voter 
  const{data: presidentialWidgetData, isSuccess: presidentialSuccess} = useSelectWidgetDataQuery(getFilter('likely_presidential_primary_voter', selectedAudience?.audience, 'audience', filter));
  const{data: presidentialAllWidgetData, isSuccess: presidentialAllSuccess} = useSelectWidgetDataQuery(getFilter('likely_presidential_primary_voter', 'none','all', noFilter));

  // Likely Voter - general_election_voter 
  const{data: generalWidgetData, isSuccess: generalSuccess} = useSelectWidgetDataQuery(getFilter('general_election_voter', selectedAudience?.audience, 'audience', filter));
  const{data: generalAllWidgetData, isSuccess: generalAllSuccess} = useSelectWidgetDataQuery(getFilter('general_election_voter', 'none','all', noFilter));

  useEffect(() => {
    if (  ageSuccess && ageAllSuccess
       && generationSuccess && generationAllSuccess
       && educationFullSuccess && educationFullAllSuccess
       && educationCondensedSuccess && educationCondensedAllSuccess
       && ethnicitySuccess && ethnicityAllSuccess
       && hispanicOriginSuccess && hispanicOriginAllSuccess
       && hispanicLanguageSuccess && hispanicLanguageAllSuccess
       && genderSuccess && genderAllSuccess
       && householdIncomeSuccess && householdIncomeAllSuccess
       && languageSuccess && languageAllSuccess
       && partySuccess && partyAllSuccess
       && homeOwndershipSuccess && homeOwndershipAllSuccess
       && maritalStatusSuccess && maritalStatusAllSuccess
       && presenceOfChildenSuccess && presenceOfChildenAllSuccess
       && voteFrequencySuccess && voteFrequencyAllSuccess
       && primarySuccess && primaryAllSuccess
       && presidentialSuccess && presidentialAllSuccess
       && generalSuccess && generalAllSuccess
    ) {
      downloadCsv();
    }
  }, [ageWidgetData, ageAllWidgetData, 
      generationWidgetData, generationAllWidgetData,
      educationFullWidgetData, educationFullAllWidgetData, 
      educationCondensedWidgetData, educationCondensedAllWidgetData,
      ethnicityWidgetData, ethnicityAllWidgetData,
      hispanicOriginWidgetData, hispanicOriginAllWidgetData, 
      hispanicLanguageWidgetData, hispanicLanguageAllWidgetData,
      genderWidgetData, genderAllWidgetData,
      householdIncomeWidgetData, householdIncomeAllWidgetData,
      languageWidgetData, languageAllWidgetData,
      partyWidgetData, partyAllWidgetData,
      homeOwnershipWidgetData, homeOwnershipAllWidgetData,
      maritalStatusWidgetData, maritalStatusAllWidgetData,
      presenceOfChildrenWidgetData, presenceOfChildrenAllWidgetData,
      voterFrequencyWidgetData, voterFrequencyAllWidgetData,
      primaryWidgetData, primaryAllWidgetData,
      presidentialWidgetData, presidentialAllWidgetData,
      generalWidgetData, generalAllWidgetData]
  );

  /*
  const logger = () => {
    console.log("Age: " + JSON.stringify(ageWidgetData));
    console.log("Age All: " + JSON.stringify(ageAllWidgetData));    
    console.log("Generation: " + JSON.stringify(generationWidgetData));
    console.log("Generation All: " + JSON.stringify(generationAllWidgetData));  
    console.log("Education (Full Breakout): " + JSON.stringify(educationFullWidgetData));
    console.log("Education (Full Breakout) All: " + JSON.stringify(educationFullAllWidgetData));  
    console.log("Education (Condensed): " + JSON.stringify(educationCondensedWidgetData));
    console.log("Education (Condensed) All: " + JSON.stringify(educationCondensedAllWidgetData));  
    console.log("Ethnicity: " + JSON.stringify(ethnicityWidgetData));
    console.log("Ethnicity All: " + JSON.stringify(ethnicityAllWidgetData));
    console.log("Hispanic Origin: " + JSON.stringify(hispanicOriginWidgetData));
    console.log("Hispanic Origin All: " + JSON.stringify(hispanicOriginAllWidgetData));
    console.log("Hispanic + Language: " + JSON.stringify(hispanicLanguageWidgetData));
    console.log("Hispanic + Language All: " + JSON.stringify(hispanicLanguageAllWidgetData));
    console.log("Gender: " + JSON.stringify(genderWidgetData));
    console.log("Gender All: " + JSON.stringify(genderAllWidgetData));
    console.log("Household Income: " + JSON.stringify(householdIncomeWidgetData))
    console.log("Household Income All: " + JSON.stringify(householdIncomeAllWidgetData));
    console.log("Language: " + JSON.stringify(languageWidgetData));
    console.log("Language All: " + JSON.stringify(languageAllWidgetData));
    console.log("Party: " + JSON.stringify(partyWidgetData));
    console.log("Party All: " + JSON.stringify(partyAllWidgetData));
    console.log("Home Ownership: " + JSON.stringify(homeOwnershipWidgetData));
    console.log("Home Ownership All: " + JSON.stringify(homeOwnershipAllWidgetData));
    console.log("Marital Status: " + JSON.stringify(maritalStatusWidgetData));
    console.log("Marital Status All: " + JSON.stringify(maritalStatusAllWidgetData));
    console.log("Presence of Children: " + JSON.stringify(presenceOfChildrenWidgetData));
    console.log("Presence of Children All: " + JSON.stringify(presenceOfChildrenAllWidgetData));
    console.log("Voter Frequency: " + JSON.stringify(voterFrequencyWidgetData));
    console.log("Voter Frequency All: " + JSON.stringify(voterFrequencyAllWidgetData));
    console.log("Likely Primary Voter: " + JSON.stringify(primaryWidgetData));
    console.log("Likely Primary Vote All: " + JSON.stringify(primaryAllWidgetData));
    console.log("Likely Presidential Primary Voter: " + JSON.stringify(presidentialWidgetData));
    console.log("Likely Presidential Vote All: " + JSON.stringify(presidentialAllWidgetData));
    console.log("Likely General Election Voter: " + JSON.stringify(generalWidgetData));
    console.log("Likely General Election Voter All: " + JSON.stringify(generalAllWidgetData));
  }
  */

  const buildWidgetData = (widgetData, allWidgetData, type) => {
    const newDataObject = createDataObject(widgetData, allWidgetData, widgetFields.filter(t => t.widget_type == type), type);
    return newDataObject;
  }

  const buildData = () => {
    let completeWidgetData = [];
    completeWidgetData.push(['Age', buildWidgetData(ageWidgetData, ageAllWidgetData, 'age_range_standard')]);
    completeWidgetData.push(['Generation', buildWidgetData(generationWidgetData, generationAllWidgetData, 'age_range')]);
    completeWidgetData.push(['Education (Full Breakout)', buildWidgetData(educationFullWidgetData, educationFullAllWidgetData, 'education_1')]);
    completeWidgetData.push(['Education (Condensed)', buildWidgetData(educationCondensedWidgetData, educationCondensedAllWidgetData, 'education_2')]); 
    completeWidgetData.push(['Ethnicity', buildWidgetData(ethnicityWidgetData, ethnicityAllWidgetData, 'ethnicity')]); 
    completeWidgetData.push(['Hispanic Origin', buildWidgetData(hispanicOriginWidgetData, hispanicOriginAllWidgetData, 'ethnicity_hispanic')]);  
    completeWidgetData.push(['Hispanic + Language', buildWidgetData(hispanicLanguageWidgetData, hispanicLanguageAllWidgetData, 'ethnicity_language_hispanic')]);  
    completeWidgetData.push(['Gender', buildWidgetData(genderWidgetData, genderAllWidgetData, 'gender')]);  
    completeWidgetData.push(['Household Income', buildWidgetData(householdIncomeWidgetData, householdIncomeAllWidgetData, 'income_range')]);  
    completeWidgetData.push(['Language', buildWidgetData(languageWidgetData, languageAllWidgetData, 'language')]);  
    completeWidgetData.push(['Party', buildWidgetData(partyWidgetData, partyAllWidgetData, 'party')]);  
    completeWidgetData.push(['Home Ownership', buildWidgetData(homeOwnershipWidgetData, homeOwnershipAllWidgetData, 'home_owner_or_renter')]);  
    completeWidgetData.push(['Marital Status', buildWidgetData(maritalStatusWidgetData, maritalStatusAllWidgetData, 'marital_status')]);  
    completeWidgetData.push(['Presence of Children', buildWidgetData(presenceOfChildrenWidgetData, presenceOfChildrenAllWidgetData, 'presence_of_children')]);  
    completeWidgetData.push(['Voter Frequency', buildWidgetData(voterFrequencyWidgetData, voterFrequencyAllWidgetData, 'vote_propensity')]);  
    return completeWidgetData;
  }

  const buildLikelyVoterData = () => {
    let completeLikelyVoterWidgetData = [];
    completeLikelyVoterWidgetData.push(['Primary', buildWidgetData(primaryWidgetData, primaryAllWidgetData, 'likely_primary_voter')]); 
    completeLikelyVoterWidgetData.push(['Pres. Primary', buildWidgetData(presidentialWidgetData, presidentialAllWidgetData, 'likely_presidential_primary_voter')]); 
    completeLikelyVoterWidgetData.push(['General Election', buildWidgetData(generalWidgetData, generalAllWidgetData, 'general_election_voter')]); 
    return completeLikelyVoterWidgetData;
  } 

  const downloadCsv = () => {
    let completeWidgetData = buildData();
    let completeLikelyVoterData = buildLikelyVoterData();
    //logger();
    let downloadTimestamp = downloadCSVTimestamp();
    let downloadFullTimestamp = downloadCSVFullTimestamp();
    let result  = "Audience Insights - " + formatAudienceTitle(selectedAudience.title) + "\n";
    result += "Date: ," + downloadFullTimestamp + "\n\n";
    result += "Category:," + selectedAudience.title + ", All Audiences\n\n";

    // Format Standard Data
    for (let i = 0; i < completeWidgetData.length; i++) {
      result += 'Demographic: ' + completeWidgetData[i][0] + '\n';
      for (let j = 0; j < completeWidgetData[i][1]['dataset1'].length; j++) {
        result += `"${completeWidgetData[i][1]['categories'][j]}", ${completeWidgetData[i][1]['dataset1'][j]}%, ${completeWidgetData[i][1]['dataset2'][j]}%\n`;
      }
      result += '\n';
    }

    // Handle Custom Likely Voter
    result += 'Demographic: Likely Voter \n';
    for (let k = 0; k < completeLikelyVoterData.length; k++) {
      if (completeLikelyVoterData[k][1]['categories'].length > 1) { // Primary and Presidential Primary return 2 categories: N & Y
        result += `"${completeLikelyVoterData[k][0]}", ${completeLikelyVoterData[k][1]['dataset1'][1]}%, ${completeLikelyVoterData[k][1]['dataset2'][1]}%\n`;
      }
      else { // General election only returns 1 category - Y
        result += `"${completeLikelyVoterData[k][0]}", ${completeLikelyVoterData[k][1]['dataset1'][0]}%, ${completeLikelyVoterData[k][1]['dataset2'][0]}%\n`;
      }
    }
    result += '\n';

    const url = window.URL.createObjectURL(new Blob([result])); 
    const link = document.createElement('a');
    link.href = url;

    const fileName = "Audience Insights - " + formatAudienceTitle(selectedAudience.title) + ' - ' + downloadTimestamp +".csv";
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setRunCSVDownloader(false);
  }

  return (
    <> 
    </>
  );
}

CompleteWidgetCSVDownloadLink.propTypes = {
  selectedAudience: PropTypes.object,
  setRunCSVDownloader: PropTypes.func
}