import React, {useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datamap from 'datamaps/dist/datamaps.usa.min.js';
import d3 from 'd3';
import Loading from "../UIElements/Loading";
import { selectUserName } from "../../features/auth/authSlice";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import { useGetUserByUsernameQuery, useUpdateUserInterestStatesMutation } from "../../app/api";
import { fullStateList } from "../../data/StateData";
import { preferenceChanged, selectPreferenceChange } from "../../features/currentView/currentViewSlice";

export default function StateMap() {


  const [userStates, setUserStates] = useState(null);
  const userName = useSelector(selectUserName);
  const preferenceChangeDetected = useSelector(selectPreferenceChange);
  const {data: user, isFetching: userFetching} = useGetUserByUsernameQuery({username: userName, preferenceChangeDetected: preferenceChangeDetected});  // preferenceChangeDetected is not a query attribute, sending only to trigger re-run of the query because username doesn't change
  const [updateUserStates] = useUpdateUserInterestStatesMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userFetching) {
      setUserStates(user.interest_states);
    }
  }, [userFetching])

  const paletteScale = d3.scale.linear()
        .domain([0, 1])
        .range(["#EFEFFF", "#adbdd7"]); // blue color

  const editedStateList = () => {
    let dataset = {};
    for (let key in fullStateList ) {
      if (userStates.includes(key)) {
        dataset[key] = {numberOfThings: 1, fillColor: paletteScale(1)};
      }
      else {
        dataset[key] = {numberOfThings: 0, fillColor: paletteScale(0)};
      }
    }
    return dataset;
  }

  const defaultStateList = () => {
      let dataset = {};
      for (let key in fullStateList ) {
        let iso = key,
            value = fullStateList[key];
        dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
      }
      return dataset;
  }

  const usersStatesSet = (userStates || 'all')  == 'all' ? defaultStateList() : editedStateList();

  useEffect(() => {

    if (userStates )  { 
      const element = document.getElementById('usa_map');
      if (element) {
        element.remove();
      }
      const map_element = document.createElement('div');
      map_element.id = 'usa_map';
      const parent_element = document.getElementById('usa_map_holder');
      parent_element.appendChild(map_element);

      const updateNow = (state) => {
        let newStateString = ''
        if (userStates.includes(state)) {
          newStateString = userStates.replace(',' + state,''); // more then one value
          newStateString = newStateString.replace(state + ',',''); // more then one value depending on order
          newStateString = newStateString.replace(state,''); // only one value
        }
        else {
          if (userStates.includes('all')){
            newStateString = state;
          }
          else {
            newStateString = userStates + ',' + state;
          }
        }

        newStateString = newStateString.replace(/ /g, '').length == 0 ? 'all' : newStateString;

        updateUserStates({id: user.id, interest_states: newStateString})
        setUserStates(newStateString);
        dispatch(saveCurrentView({type:'interestStates', value: newStateString}));
        dispatch(preferenceChanged());
      }

      const map = new Datamap({
        scope: 'usa',
        element: document.getElementById('usa_map'),
        done: function(datamap) {
            datamap.svg.selectAll('.datamaps-subunit')
            .on('click', function(g) {
                updateNow(g.id); 
            })
            .on('mouseover', function(){ 
              d3.select(this).style("cursor", "pointer"); 
              d3.select(this).style("fill", "#F6C250");})
            .on('mouseout', function(g){ 
              // if g.id value == 1, etc.
              d3.select(this).style("cursor", "default"); 
              if (usersStatesSet[g.id].numberOfThings == 1) {
                d3.select(this).style("fill","#adbdd7")
              }
              else {
                d3.select(this).style("fill","#EFEFFF")}
              }
            )
            ;
        },
        height: 400,
        geographyConfig: {
          highlightOnHover: true,
          borderColor: '#444',
          highlightBorderWidth: 1,
          borderWidth: 0.5,
        },
        data: usersStatesSet,
      });
      map.labels({fontFamily: 'Ubuntu, Poppins, Verdana'});
    }

  }, [userStates]);

  const loadingMap = () => {
    return (
      <>
        {(!user) && <Loading />}
        <div id='usa_map_holder' className='smaller' />
      </>
    )
  }


  return <>
    {loadingMap()}
  </>
}
