import React, {useEffect} from "react";
import { useRef } from "react";
import { saveAudience, selectHeadlineDateRange, selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetHeadlineDateRangeQuery } from "../../app/api";
import { selectDateRangeRan } from "../../features/audiences/audienceSlice";
import { clearGAFiltersBetweenQuery } from "../../features/currentView/currentViewSlice";
import { isPermissionsRO } from "../../features/auth/authSlice";
import PropTypes from "prop-types";

export default function GADateRange({disableRO=false}) {

  const hasROpermissions = useSelector(isPermissionsRO);

  const newStartDate = useRef();
  const newEndDate = useRef();

  const dispatch = useDispatch();
  const headlineDateRange = useSelector(selectHeadlineDateRange);
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  
  const headlineDateRangeRan = useSelector(selectDateRangeRan);
  const {data:dateRange}  = useGetHeadlineDateRangeQuery({skip: headlineDateRangeRan});

  const newDates = () => {
    dispatch(saveAudience({type: 'current-headline-date-range', value: {maxDate: newEndDate.current.value, minDate: newStartDate.current.value}}));
    dispatch(clearGAFiltersBetweenQuery());
  }

  const checkEndDate = () => {
    if (newStartDate.current.value > newEndDate.current.value) {
      newStartDate.current.value = newEndDate.current.value;
    }
  }

  const checkStartDate = () => {
    if (newEndDate.current.value < newStartDate.current.value) {
      newEndDate.current.value = newStartDate.current.value;
    }
  }

  useEffect( () => {
    if (dateRange && dateRange[0]) {
      dispatch(saveAudience({type: 'headline-date-range', value: {maxDate: dateRange[0].max_date, minDate: dateRange[0].min_date}}));
    }
  }, [dateRange])


  return (
    <>
    {(headlineDateRange && currentHeadlineDateRange) && 
      <div className='date-range'>
        <input readOnly={!disableRO && hasROpermissions} ref={newStartDate} min={headlineDateRange.minDate} max={headlineDateRange.maxDate} type='date' defaultValue={currentHeadlineDateRange.minDate} onChange={()=>{checkEndDate()}}/>
        <p className='range-to'>to</p>
        <input readOnly={!disableRO && hasROpermissions} ref={newEndDate} min={headlineDateRange.minDate} max={headlineDateRange.maxDate} type='date' defaultValue={currentHeadlineDateRange.maxDate} onChange={()=>{checkStartDate()}}/>
        {(disableRO || !hasROpermissions) && <button className='save-dates' onClick={() => { newDates() }}><p>Apply</p></button>}
      </div>
    }
    </>

  )
}

GADateRange.propTypes = {
  disableRO: PropTypes.bool, 
}