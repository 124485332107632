import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { createSearchParams, useNavigate } from "react-router-dom";
import { saveAudience } from "../../features/audiences/audienceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAddUserBookmarkMutation, useDeleteUserBookmarkMutation } from "../../app/api";
import { useGetUserBookmarksQuery } from "../../app/api";
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';
import { selectPreferenceChange, preferenceChanged} from "../../features/currentView/currentViewSlice";
import Topics from "./Topics";
import AudienceStatusFromList from "../UIElements/AudienceStatusFromList";

export default function AudienceTile({uniqueName, displayTitle, audienceId, audienceDesc, audienceCount, userId, cluster, uuid, myAudiences, recommended, fromAudienceExplore, isAudiencePage = false}) {

  const {data: userBookmarkData, refetch: queryBookmarks} = useGetUserBookmarksQuery({user_id: userId});
  const dispatch = useDispatch();
  const [addBookmark, {isSuccess:addBookmarkSuccess}] = useAddUserBookmarkMutation();
  const [deleteBookmark, {isSuccess:deleteBookmarkSuccess}] = useDeleteUserBookmarkMutation();
  const preferenceChange = useSelector(selectPreferenceChange);

  const navigate = useNavigate();

  useEffect(() => {    
   window.onpageshow = function(event) {
     if (event.persisted) {
       window.location.reload();
     }
   };
  }, []);

  useEffect( () => {
    queryBookmarks();
  }, [preferenceChange] );

  useEffect(()=>{
    if (addBookmarkSuccess) {
      dispatch(preferenceChanged());
    }
  }, [addBookmarkSuccess]);

  useEffect(()=>{
    if (deleteBookmarkSuccess) {
      dispatch(preferenceChanged());
    }
  }, [deleteBookmarkSuccess]);

  const bookmarkSelected = (name) => {
    const found = checkBookmarkList(name); 
    if (found) { return "fa-solid fa-bookmark search-bookmark selected"; }
    else {return "fa-light fa-bookmark search-bookmark ";}
  }

  const addBookmarkNow = (id) => {
    addBookmark({user_id: userId, audience_id: id});
  }

  const deleteBookmarkNow = (id) => {
    deleteBookmark({user_id: userId, audience_id: id});
  }

  const bookmarkButton = (name) => {
    const found = checkBookmarkList(name); 
    if (found) {
      return (
        <i onClick={()=>{deleteBookmarkNow(name)}} className={bookmarkSelected(name)}/>
      )
    }
    else {
      return (
        <i onClick={()=>{addBookmarkNow(name)}} className={bookmarkSelected(name)}/>
      )
    }
  }

  const checkBookmarkList = (id) => {
    var found;
    if (userBookmarkData) {
      found = userBookmarkData.find(t => t.audience_id === id);
    }
    return found; 
  }

  const audienceLink = () => {
    if (cluster == 1) {
      return (
        <div className='hover-point' onClick={() => {dispatch(saveAudience({type: 'selected', value:{title: displayTitle, audience: uniqueName, audience_id: audienceId, cluster: cluster}})); navigate({pathname: '/dashboard/cluster-audience', search: createSearchParams({uuid: uuid}).toString()});}}>
          <p className='column-list-option inline leftside'><b>{displayTitle}</b></p>
          <i className={fromAudienceExplore? 'hidden' : 'fa fa-solid fa-info-circle'} data-tooltip-id='i-tool'/>
          <Tooltip id='i-tool' className='i-tooltip'>Multi-Issue Psychographic Personas are premium audiences that categorize voters into mutually-exclusive cohorts based on their opinions on various political, economic, and social issues.</Tooltip>
        </div>
      )
    }
    else {
      return (
        <div className='hover-point' onClick={() => {dispatch(saveAudience({type: 'selected', value:{title: displayTitle, audience: uniqueName, audience_id: audienceId, cluster: cluster}})); navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: uuid}).toString()});}}>
          <p className='column-list-option inline leftside'><b>{displayTitle}</b></p>
        </div>
      )
    }
  }

  return (
    <div className='audience-tile'>
      <div className='audience-tile-title-wrapper'>
        {bookmarkButton(audienceId)}
        {audienceLink()}
      </div>
      <div className='audience-tile-description-wrapper'>
        <p className='title-desc leftside'> {audienceDesc}</p>
      </div>
      <div className='audience-tile-topics-wrapper'>
        <Topics type={'audience'} audience={uniqueName} recommended={recommended} />
      </div>
      <div className='audience-tile-count-platform-wrapper'>
        <i className='fa fa-line-chart'/>&nbsp;&nbsp;
        <p className=''>{audienceCount.toLocaleString('en',{useGrouping:true})} Records</p>
        <AudienceStatusFromList myAudiences={myAudiences} audienceId={audienceId} isAudiencePage={isAudiencePage}/>
      </div>
    </div>
  )
}

AudienceTile.propTypes = {
  displayTitle: PropTypes.string,
  uniqueName: PropTypes.string,
  audienceId: PropTypes.number,
  audienceDesc: PropTypes.string,
  audienceCount: PropTypes.number,
  userId: PropTypes.number,
  cluster: PropTypes.number,
  uuid: PropTypes.string,
  myAudiences: PropTypes.array, 
  recommended: PropTypes.bool,
  fromAudienceExplore: PropTypes.bool,
  isAudiencePage: PropTypes.bool
}