import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // users attributes 
  allUsers: [{}], 
  pendingUserList: [{}],
  allFeedback: [{}],
  userChange: 0,
  feedbackChange: 0,
};

export const adminListsSlice = createSlice({
  name: "adminLists",
  initialState,
  reducers: {
    saveLists: (state, action) => {
      const payload = action.payload;
      switch (payload.type) {
        case 'all-users':
          if (payload.value) {
            state.allUsers = payload.value;
            state.pendingUserList = payload.value.filter(t => t.role === 'PENDING')
          }
          break;
        case 'all-feedback':
          if (payload.value) {
            state.allFeedback = payload.value;
          }
          break;
      }
    },
    userChanged: (state) => {
      state.userChange = state.userChange+1;
    },
    feedbackChanged: (state) => {
      state.feedbackChange = state.feedbackChange+1;
    }
  },
})

export const selectAllUsers = (state) => state.adminLists.allUsers;
export const selectPendingUsers = (state) => state.adminLists.pendingUserList;
export const selectUserChange = (state) => state.adminLists.userChange;
export const selectAllFeedback = (state) => state.adminLists.allFeedback;
export const selectFeedbackChange = (state) => state.adminLists.feedbackChange;

export const { saveLists, userChanged, feedbackChanged } = adminListsSlice.actions;
export default adminListsSlice.reducer;
