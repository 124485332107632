import React from "react";
import { useSelector } from "react-redux";
import { selectUserRole, selectOverride } from "../../features/auth/roleSlice";

import { selectOrgNameString } from "../../features/auth/authSlice";
import logo from "../../images/cinq-logo.png";

export default function AdminHeader() {

  const userRole = useSelector(selectUserRole);
  const override = useSelector(selectOverride);
  const orgName = useSelector(selectOrgNameString) === 'null' ? undefined : useSelector(selectOrgNameString);


  const headerLinks = () => {
    if (userRole.includes('ADMIN') && override == 'NO') {
      return (
        <div className='client-header-option-div'>
          <div className='admin-header-name full-page'> 
             <div className='admin'> {userRole} {orgName && ' / ' + orgName }</div>
          </div>
        </div>
      )
    }
  }

  const headerView = () => { 
    if (userRole != "PENDING") { 
      return (
       <div>
         <div className="client-dashboard-top-header">
          <div className="body">
            <div className='header_logo_wrapper'>
              <img className='cinq-logo' src={logo}/>
            </div>
          </div>
          {headerLinks()}
         </div>
       </div>
      )
    }
    else {
      return (
       <div>
         <div className="client-dashboard-top-header">
          <div className="body">
            <div className='header_logo_wrapper'>
              <img className='cinq-logo' src={logo}/>
            </div>
          </div>
         </div>
       </div>
      )
    }
  }
  
  return (
    <>
      {headerView()}
    </>
  )
}