import React, {useRef, useState} from "react";
import { useQueryAthenaQuery } from "../../app/api";
import Loading from "../UIElements/Loading";

export default function Dev (){
  const queryTest = useRef();
  const [requestedData, setRequestedData] = useState(null);
  const [error, setError] = useState('');
  
  const {data: queryResults}  = useQueryAthenaQuery({query_string: 'show columns from adv.ga_agg'});
  const {data: queryGA4Results}  = useQueryAthenaQuery({query_string: 'show columns from adv.ga4_agg'});
  const {data: dataResults, isFetching: dataFetching, isError: dataError} = useQueryAthenaQuery({query_string: requestedData}, {skip: !requestedData});

  const printQueryResults = (results) => {
    return results.map((t, i) => (
      <div key={i} >{Object.values(t).map((v,i ) => ( <div className='bump-right-20 inline' key={i}>{v}</div> ))}</div>
    ))
  }

  const runQuery = () => {
    setError('');
    if (queryTest.current.value.toLowerCase().includes('delete') ) {
      setError('do not delete in athena!')
    }
    else {
      if (queryTest.current.value.toLowerCase().includes('update') || queryTest.current.value.toLowerCase().includes('alter') || queryTest.current.value.toLowerCase().includes('insert')) {
        setError('do not update in athena!')
      }
      else {
        if (!queryTest.current.value.toLowerCase().includes('select') ) {
          setError('please enter a valid query')
        }
        else {
          setRequestedData(queryTest.current.value);
        }
      }
    }
  }

  return (
    <div>
      <p>Dev</p>
      {error != '' && <p className='red'>{error}</p>}
      <input className='full-page' ref={queryTest} type={'text'}></input>
      <button className='top-bump-20' onClick={()=>{runQuery()}}>Submit</button>
      <div className='full-page'>
        <div className='quarter-page'>
          <p><b>ga_agg column names</b></p>
          {queryResults && printQueryResults(queryResults)}
        </div>
        <div className='quarter-page'>
          <p><b>ga4_agg column names</b></p>
          {queryGA4Results && printQueryResults(queryGA4Results)}
        </div>
        <div className='half-page'>
          <p><b>Query Results</b></p>
          {dataError && <p>Error with your query!</p>}
          {dataFetching && !dataError && <Loading/>}
          {dataResults && printQueryResults(dataResults)}
        </div>
      </div>
    </div>
  )

}