import React from "react";
import logo from "../../images/cinq-logo.png";
import linkedin_logo from "../../images/linkedin_logo.png"
import PropTypes from "prop-types";

export default function Footer({showAsPopup, handleFooterPopupState}) {

  const closeFooter = () => {
    handleFooterPopupState();
  }
  const footerCompany = () => {
      return (
        <div className="footer_company">        
          <img src={logo}></img>
          <a href="https://www.linkedin.com/company/cinqdi/">
            <img className="linked_in" src={linkedin_logo} />
          </a>
          <p>CinqDI is a platform by <a href="https://www.mvdigitalgroup.com/">MV Digital</a> </p>
          <p>One World Trade Center</p>
          <p>New York, NY 10007 </p>
        </div>
      )
  }

  const footerPrivacy = (showAsPopup) => {
      return (
        <div className="footer_privacy">
          <p>Use of and/or registration on any portion of this site constitutes acceptance of our <a href="https://www.cinqdi.com/privacy-policy/">Privacy Policy </a> and <a href="https://www.cinqdi.com/terms-of-use/">Terms of Use.</a></p>
          <p>© 2023 Advance Local Media LLC. All rights reserved (<a href="https://www.advancelocal.com/about/">About Us</a>).</p>
          <p>The material on this site may not be reproduced, distributed, transmitted, cached or otherwise used, except with the prior written permission of MV Digital Group.</p>
          <p><a href="mailto:support@cinqdi.com">support@cinqdi.com</a> | <a href="/support">Support</a></p>          
          <button className={showAsPopup ? ' fa fa-times': ' fa fa-times hidden'} onClick={() => closeFooter()}></button>
        </div>
      )
  }

  return (
    <footer className={showAsPopup ? 'show_as_popup' : ''}> 
      {footerCompany()}
      {footerPrivacy(showAsPopup, handleFooterPopupState)}
    </footer>
  )
}

Footer.propTypes = {
  showAsPopup: PropTypes.bool,
  handleFooterPopupState: PropTypes.func
}