import React, {useState} from "react";
import { useSelector } from "react-redux";
import { selectAllRequests } from "../../features/audiences/audienceSlice";
import { selectUserRole } from "../../features/auth/authSlice";
import {formatUserName} from "../format"
import {  
  useGetCountExportRequestsAllQuery,
  useUpdateAudienceStatusMutation,
  useDeletePlatformRequestMutation } from "../../app/api";

  import { isPermissionsRO } from "../../features/auth/authSlice";

export default function ActivationExports() {

  const hasROpermissions = useSelector(isPermissionsRO);
  const requests = useSelector(selectAllRequests);
  const [ hideNotReady, setHideNotReady ] = useState(false);

  const tabView = 'audience';
  const [ updateAudienceStatus ] = useUpdateAudienceStatusMutation();
  const {data: allRequests, refetch: refetchAllRequests} = useGetCountExportRequestsAllQuery(); 
  const userRole = useSelector(selectUserRole);
  const [ deletePlatformRequestObj, setDeletePlatformRequestObj] = useState();
  const [ deletePlatformRequest, {isSuccess:deleteRequestSuccess} ] = useDeletePlatformRequestMutation();

  console.log(`deleteRequestSuccess: ${deleteRequestSuccess}`);
  

  const deletePlatform = (platformRequest) => {
    deletePlatformRequest({platform_id: platformRequest.platform_id})
      .then(() => {
        // Action to perform after successful deletion
        setDeletePlatformRequestObj(); 
        refetchAllRequests();
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error deleting platform:', error);
      });
  }


  const activateExport = async (refNo) =>  {
    await updateAudienceStatus({ref_no: refNo, status_value: 'READY'});
    refetchAllRequests();
  }


  const arrayForSort = [...requests];

  const actionButton = (req) => {
    switch (req.user_audience_status) {
      case 'OPEN': 
        if (!hasROpermissions && userRole=='SUPERADMIN'){
          return (
            <td><button onClick={()=>{activateExport(
              req.uuid
              )}}>ACTIVATE</button></td>
          )
        }
        else {
          return (
            <td></td>
          )
        }
      default: 
        return <td></td>
    }
  }

  /**
   * TODO: This function seems to be outdated?  References req.status instead of req.user_audience_status
   */
  const button = (req) => {
    if (req.status == 'OPEN') {
        return (
          <td><button onClick={()=>{activateExport(req.uuid)}}>ACTIVATE</button></td>
        )
    }
  }


  const deletePopup = () => {
    if (deletePlatformRequestObj) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setDeletePlatformRequestObj()}}/>
            <div className='centered'>
              <h3> Delete Platform Request </h3>
              <p> Are you sure you want to delete Activation Request with Ref ID</p>
              <p> {deletePlatformRequestObj.reference_id}?</p>
              <button onClick={()=>{deletePlatform(deletePlatformRequestObj); setDeletePlatformRequestObj()}}> Delete </button>
            </div>
          </div>
        </div>
      )
    }
  }


  const distroResults = () => { 
    return (
      arrayForSort 
      .filter(t => t.request_type == 'DISTRIBUTION')
      .map((req, i) => (
        <tr key={i}>
          {button(req, 'distro')}
          <td>{req.reference_id}</td>
          <td>{req.title}</td>
          <td>{formatUserName(req)}</td>
          <td>{req.platform}</td>
          <td>{req.status}</td>
          <td><a href='#'>Assign to me</a></td>
        </tr>
      ))
    )
  }

  const formatDate = (tdate) => {
    const dateTime = new Date(tdate);
    return dateTime.toLocaleDateString("en-US")
  }

  const audienceResults = () => { 
    return (
      allRequests
      .map((req, i) => (
        <tr key={i}>
          { actionButton(req) }
          <td>{req.username}</td>
          <td>{req.user_role == 'DELETED' ? 'Inactive' : 'Active'}</td>
          <td>{req.title.split('||')[0]}</td>
          <td>{req.user_audience_status}</td>
          <td>{formatDate(req.created)}</td>
          <td>{req.reference_id}</td>
          {userRole == "SUPERADMIN" && <td><i className='fa-solid fa-trash hover-point' id={req.platform_id + "_delete"} onClick={()=>{setDeletePlatformRequestObj( req )}}> </i></td>}
          <td><a href='#' className='hidden'>Assign to me</a></td>
        </tr>
      ))
    )
  }

  const audienceResultsReadyOnly = () => { 
    return (
      allRequests
      .filter(r => r.user_audience_status == 'OPEN')
      .map((req, i) => (
        <tr key={i}>
          { actionButton(req) }
          <td>{req.username}</td>
          <td>{req.user_role == 'DELETED' ? 'Inactive' : 'Active'}</td>
          <td>{req.title.split('||')[0]}</td>
          <td>{req.user_audience_status}</td>
          <td>{formatDate(req.created)}</td>
          <td>{req.reference_id}</td>
          {userRole == "SUPERADMIN" && <td><i className='fa-solid fa-trash hover-point' id={req.platform_id + "_delete"} onClick={()=>{setDeletePlatformRequestObj( req )}}> </i></td>}
          <td><a href='#' className='hidden'>Assign to me</a></td>
        </tr>
      ))
    )
  }

  const expiredAudienceResults = () => { 
    return (
      arrayForSort 
      .filter(t => t.request_type == 'CUSTOM_AUDIENCE')
      .filter(t => t.status == 'EXPIRED')
      .map((req, i) => (
        <tr key={i}>
          <td></td>
          {button(req, 'audience')}
          <td>{req.reference_id}</td>
          <td>{req.title}</td>
          <td>{formatUserName(req)}</td>
          <td>{req.status}</td>
          <td><a href='#'>Assign to me</a></td>
        </tr>
      ))
    )
  }


 /* const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'fifth-page centered';
    }
    else {
      return 'fifth-page centered disabled';
    }
  }*/

  const tabViewScreen = () => {
    switch(tabView){
      case 'distro':
        return (
          <>
           <table>
             <thead>
               <tr>
                 <th>Name</th>
                 <th>Requestor</th>
                 <th>Status</th>
                 <th>Rep</th>
               </tr>
             </thead>
             <tbody>
               {!hasROpermissions && distroResults()}
             </tbody>
           </table>
          </>
        )
      case 'audience':
        return (
          <>
           <table style={{width: 'unset', borderSpacing: '10px'}}>
             <thead>
               <tr>
                 <th></th>
                 <th>Requestor</th>
                 <th>User Status</th>
                 <th>Audience</th>
                 <th>Status</th>
                 <th>Transaction Date</th>
                 <th>Ref ID</th>
                 {userRole == "SUPERADMIN" && <th></th>}
               </tr>
             </thead>
             <tbody>
               {allRequests && !hideNotReady && audienceResults()} 
               {allRequests && hideNotReady && audienceResultsReadyOnly()} 
               {expiredAudienceResults()} 
             </tbody>
           </table>
          </>
        )
    }
  }

  const audienceCount =  
    allRequests?.filter(t => t.user_audience_status == 'OPEN').length

  return (
    <div className='activate-form' >
      {!hasROpermissions && deletePopup()}
      <div className='admin-list-wrapper' style={{width: 'unset'}}>
        <div className='admin-list' style={{width: 'unset'}}>
          <h1> Activation Export Requests </h1>
          <h3>{audienceCount} Requests Ready to Activate</h3>
          <p className="green">{deleteRequestSuccess && <>Request Deleted</>}&nbsp;</p>
          <div className='link no-underline' onClick={() => {setHideNotReady(!hideNotReady)}}>{hideNotReady ? 'All Activations' : ' Pending Activations'} </div>
          <br/>
          {tabViewScreen()}
        </div>
      </div>
    </div>
  )
}