import React from "react";
import PropTypes from "prop-types";

export default function UserReportCSVDownloadLink({from_date, to_date, users, downloadType}){

  const downloadCsv = () => {

    let exportData = '';
    exportData += 'User Signup Report \n\n';
    exportData += 'From Date,' + from_date.current.value + ',\n';
    exportData += 'To Date,' + to_date.current.value + ',\n';
    if (downloadType == 'Init') { // Init == All. Set UserType value to 'All'
      exportData += 'User Type, All,\n\n';
    }
    else {
      exportData += 'User Type,' + downloadType + ',\n\n';
    }

    exportData += 'Email, Name, Signup Date, Role, Company Name, Organization Type, Job Function, Senority / Level, Gender, Age  \n';
    for (let i = 0; i < users.length; i++) {
      if (Date.parse(from_date.current.value) <= Date.parse(users[i].created) && Date.parse(users[i].created) <= Date.parse(to_date.current.value)) 
      exportData += `${users[i]['username']}` + "," + `${users[i]['first_name']} ${users[i]['last_name']}` + "," 
      + `${users[i]['created']}` + "," + `${users[i]['role']}` + "," + `${users[i]['company_name']}` + "," 
      + `${users[i]['org_type']}` + "," + `${users[i]['job_function']}` + "," 
      + removeEmbeddedComma(users[i]['job_level']) + "," + `${users[i]['demographic']}` + "," + `${users[i]['age']} \n`;
    }

    const url = window.URL.createObjectURL(new Blob([exportData])) 
    const link = document.createElement('a')
    link.href = url
    const fileName = `user_signup_report.csv`;
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const removeEmbeddedComma = (str) => {
    if (str == null) {
      return ''
    }
    else {
      return str.replace(',','');
    }
  }

  return (
    <>
      <button onClick={()=>{downloadCsv()}}> Download CSV </button>
    </>
  );
}

UserReportCSVDownloadLink.propTypes = {
  from_date: PropTypes.object,
  to_date: PropTypes.object,
  users: PropTypes.array,
  downloadType: PropTypes.string
}