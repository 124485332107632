import React from "react";
import PropTypes from "prop-types";
import { useGetAudienceCountQuery } from "../../../app/api";
import { HeadlineTile } from "../../UIElements/HeadlineTile";

export function AudienceHeadlineList({headlines, loadingHeadlines}) {

  const {data:audienceCount}  = useGetAudienceCountQuery();

  const maxViewers = Math.max.apply(Math, headlines?.map((o) => {return o.count}));

  const headlineList = () => {
    if (loadingHeadlines) {
      return <i className='fas fa-sync centered fa-spin'/>
    }
    else {
      if (headlines) {
        let arrayForSort = [...headlines];
        return arrayForSort?.sort((a,b) => a.count > b.count ? -1 : 1)
        .slice(0,10)
        .map((t,i) => (
          <HeadlineTile key={i} headline={t} audienceCount={audienceCount} grouped={false} maxViewers={maxViewers} fromAudienceArticles={true}/>
        ));
      }
      else {
        return <i className='fas fa-sync centered fa-spin'/>
      }
    }

  }

  return (
    <>
      {headlineList()}
    </>
  )

}

AudienceHeadlineList.propTypes = {
  headlines: PropTypes.array,
  loadingHeadlines: PropTypes.bool,
}