import React, { useState } from "react";
import { useForgotPasswordMutation } from "../../app/api";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
export default function ForgotPasswordForm() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sendResetPasswordEmail] = useForgotPasswordMutation();

  /**
   * Validate the email address entered
   */
  const validEmail = () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.match(validRegex);
  }

  /**
   * Handle submitting the Forgot Password form
   * 
   * @param {*} event 
   */
  async function onForgotPassword(event) {
    try {
      event.preventDefault();
      if (validEmail()) { 
        await sendResetPasswordEmail({email: email, send_email: true})
          .unwrap()
          .then(() => {
            const urlParams = new URLSearchParams({
              message: `An email has been sent to ${email} with instructions to reset your password`
            });
            navigate(`/login?${urlParams}`);
          })
          .catch((error) => {
            setMessage(`${error.data.detail}`);
          });
      } else {
        setMessage(`Please enter a valid email address`);
      }
      setEmail('');
    } catch (err) {
      setMessage(`Error sending Password Reset email: err[${JSON.stringify(err)}]`);
    }
  }

  return (
    <div className="landing">
      <div className={ message? 'auth-message' : 'hidden' }>
        { message && <p>{message}</p> }
      </div>
      <form className='auth-form'>
        <div className="landing_wrapper">
          <h2>Reset Password</h2>
          <div className='login_entry_wrapper'>
            <input
              autoFocus
              type="email"
              id="email"
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)} required
            />
          </div>
          <div className='submit_wrapper'>
            <button type="submit" onClick={onForgotPassword}>
              <p>Send Email</p>
            </button>
          </div>
          <div className="forgot-div">
            <p onClick={() => { navigate('/login') }} className="auth-link">CANCEL</p>
          </div>
        </div>
      </form>
    </div>
  )
}

ForgotPasswordForm.propTypes = {
  callback: PropTypes.func,
}