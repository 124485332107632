import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import LikelyVoterBarGraph from "./LikelyVoterBarGraph";
import BarGraph from "./BarGraph";
import GABarGraph from "./GABarGraph";
import LikelyVoterGABarGraph from "./LikelyVoterGABarGraph";
import DoughnutGraph from "./DoughnutGraph";
import WordCloudGraph from "./WordCloudGraph";
import { createDataObject } from "../../data/WidgetData";
import { selectGAWidgetData, selectWidgetData, selectAllWidgetData } from "../../features/currentView/currentViewSlice";
import { selectWidgetFields } from "../../features/currentView/currentViewSlice";
import { allWidgetFields } from "../../data/WidgetFields";

export default function WidgetDetail({passedWidgetType, widgetColumn, thisAudience, ga=false, widgetDisplayName, headlineFilter='none', filterByClick=false, refreshCounter=0}){

  if (refreshCounter > 100) {
    console.log('widgetDisplayName', widgetDisplayName);
  }
  const savedWidgetFields = useSelector(selectWidgetFields); 
  /**
   * TODO: Likely tidy up required following change from Query to data store for WidgetFields
   * BarGraph & LikelyVoterBarGraph load the WidgetFields from /data/WidgetFields and then add them to Redux (state.currentView.widgetFields)
   * GABarGraph and LikelyVoterGABarGraph just load the WidgetFields from /data/WidgetFields and use them directly
   * DoughnutGraph is passed a data object generated using these widgetFields
   */
  const widgetFields = savedWidgetFields ? (savedWidgetFields?.length > 0 ? savedWidgetFields : allWidgetFields) : allWidgetFields;

  // DoughnutGraph still uses only saved values, does not run on it's own like bar graph does
  // This is because you can only toggle doughnut after bar loads
  // If this changes then update doughnut to mimic bar
  const savedGAWidgetData = useSelector(selectGAWidgetData);
  const savedWidgetData = useSelector(selectWidgetData);

  const [widgetType, setWidgetType] = useState(passedWidgetType);

  // change type back to bar if filter changes due to comment above
  useEffect(() => {
    if (widgetType == 'doughnut') {
      setWidgetType('bar');
    }
  }, [savedGAWidgetData, savedWidgetData]);

  useEffect(() => {
    setWidgetType(passedWidgetType);
  },[passedWidgetType]);
  
  const savedAllWidgetData = useSelector(selectAllWidgetData);

  const dataObject = widgetType == 'wordcloud' ? [] 
     : ga ? 
       createDataObject(savedGAWidgetData[widgetColumn], [], widgetFields.filter(t => t.widget_type == widgetColumn), widgetColumn) 
       : createDataObject(savedWidgetData[widgetColumn], savedAllWidgetData[widgetColumn],widgetFields.filter(t => t.widget_type == widgetColumn), widgetColumn);


  const getWidget = () => {
   switch (widgetType) {
      case 'bar':
        switch (widgetColumn){
          case 'likely_voter':
            return ga ? <LikelyVoterGABarGraph type={widgetColumn} selectedAudience={thisAudience} headlineFilter={headlineFilter} /> : <LikelyVoterBarGraph type={widgetColumn} selectedAudience={thisAudience} refreshCounter={refreshCounter} />
          default: 
            return ga ? <GABarGraph type={widgetColumn} selectedAudience={thisAudience} headlineFilter={headlineFilter} filterByClick={filterByClick} refreshCounter={refreshCounter} /> : <BarGraph type={widgetColumn} selectedAudience={thisAudience} filterByClick={filterByClick} refreshCounter={refreshCounter} />
        }
      case 'doughnut':
        return <DoughnutGraph dataObject={dataObject} isPending={0} />
      case 'wordcloud':
        return <WordCloudGraph thisAudience={thisAudience}/>
    }
  }

 /* const getDownloadLink = () => {
    return <WidgetCSVDownloadLink widgetTitle={widgetDisplayName} widgetData={dataObject} selectedAudience={thisAudience} />
  } */

  return (
    <div >
      {dataObject && (
        <>
          {getWidget()}
        </>
      )}
    </div>
  );
}

WidgetDetail.propTypes = {
  passedWidgetType: PropTypes.string,
  widgetColumn: PropTypes.string,
  thisAudience: PropTypes.object,
  ga: PropTypes.bool,
  headlineFilter: PropTypes.string,
  filterByClick: PropTypes.bool,
  refreshCounter: PropTypes.number,
  widgetDisplayName: PropTypes.string,
}