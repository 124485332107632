import { createSlice } from "@reduxjs/toolkit";
import { api } from '../../app/api';
import { widgetDisplay } from "../../data/WidgetData";

export const createFilters = (isBlank, defaultValue, isArray) => {
  var filter = {};
  if (isArray) {
    if (isBlank) {
      widgetDisplay.map((t) => {
        filter[t.widget] = []; 
      });
    }
    else {
      widgetDisplay.map((t) => {
        filter[t.widget] = [defaultValue]; 
      });
    }
  }
  else {
    if (isBlank) {
      widgetDisplay.map((t) => {
        filter[t.widget] = []; 
      });
    }
    else {
      widgetDisplay.map((t) => {
        filter[t.widget] = defaultValue; 
      });
    }
  }
  return filter; 
}

const initWidgetFilters = createFilters(false,'all', true);
const initWidgetData= createFilters(false, 'none', true);
const initWidgetsPending= createFilters(false, 0, false);
const setAllWidgetsPending= createFilters(false, 1, false);
export const initFilterView = createFilters(false, 'closed', false);
export const noFilter = initWidgetFilters;

const initialState = {
  // current view attributes 
  searchString: '',
  activationReferenceNo : '',
  preferenceChange: 0,
  welcomePopUp: false,
  showFeedbackForm: false,
  audienceTab: {type: 'default', uuid: 'default'},
  currentAudienceUUID: 'default',
  widgetFilters: initWidgetFilters,
  comparisonWidgetFilters: initWidgetFilters,
  gaWidgetData: initWidgetData,
  widgetData: initWidgetData,
  allWidgetData: initWidgetData,
  gaWidgetsPending: setAllWidgetsPending,
  widgetsPending: setAllWidgetsPending,
  allWidgetsPending: setAllWidgetsPending,
  filterView: initFilterView,
  currentHeadline: '',
  logoutMessage: '',
  noFilters: 0,
  isStateFiltered: false,
  widgetFields: [],
  inTopics: ['Politics'],
  outTopics: ['none'],
  interestStates: 'all',
  loadedInterestStates: false,
  showAudienceCrossover: true,
};

const calcNoFilter = (currentFilter) => {
  return (currentFilter.age_range?.filter(t =>t != 'all').length || 0) + 
    (currentFilter.age_range_standard?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.education?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.education_1?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.education_2?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.ethnicity?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.ethnicity_hispanic?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.ethnicity_language_hispanic?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.gender?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.income_range?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.language?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.state?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.dma?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.party?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.county?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.us_congressional_district?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.state_house_district?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.state_senate_district?.filter(t=>t != 'all').length || 0) + 
    (currentFilter.home_owner_or_renter?.filter(t=>t != 'all').length || 0) +
    (currentFilter.marital_status?.filter(t=>t != 'all').length || 0) +
    (currentFilter.presence_of_children?.filter(t=>t != 'all').length || 0) +
    (currentFilter.vote_propensity?.filter(t=>t != 'all').length || 0) +
    (currentFilter.likely_primary_voter?.filter(t=>t != 'all').length || 0) +
    (currentFilter.likely_presidential_primary_voter?.filter(t=>t != 'all').length || 0) +
    (currentFilter.general_election_voter?.filter(t=>t != 'all').length || 0) ;
}

export const currentViewSlice = createSlice({
  name: "currentView",
  initialState,
  reducers: {
    saveCurrentView: (state, action) => {
      const payload = action.payload;
      switch (payload.type) {
        case 'activationReferenceNo':
          state.activationReferenceNo = payload.value;
          break;
        case 'welcomePopUp':
          state.welcomePopUp = payload.value;
          break;
        case 'showFeedbackForm':
          state.showFeedbackForm = payload.value;
          break;
        case 'searchString': 
          state.searchString = payload.value;
          break;
        case 'interestStates':
          state.interestStates = payload.value;
          break;
        case 'widgetFields':
          state.widgetFields = payload.value;
          break;
        case 'currentAudienceUUID':
          state.currentAudienceUUID = payload.value;
          break;
        case 'inTopics':
          state.inTopics = payload.value; 
          break;
        case 'outTopics':
          state.outTopics = payload.value; 
          break;
        case 'filterView':
          state.filterView = payload.value;
          break;
        case 'widgetFilters':
          state.widgetFilters = payload.value;
          state.widgetData= initWidgetData;
          state.gaWidgetData= initWidgetData;
          state.widgetsPending= setAllWidgetsPending;
          state.gaWidgetsPending= setAllWidgetsPending;
          state.noFilters = calcNoFilter(state.widgetFilters);
          state.isStateFiltered = state.widgetFilters.state.filter(t=>t != 'all').length  >= 1 ? true : false; 
          break;
        case 'comparisonWidgetFilters':
          state.comparisonWidgetFilters = payload.value;
          break;
        case 'audienceTab':
          state.audienceTab = payload.value;
          break;
        case 'currentHeadline':
          state.currentHeadline = payload.value;
          break;
        case 'logoutMessage':
          state.logoutMessage = payload.value;
          break;
        case 'showAudienceCrossover':
          console.log('changing showAudienceCrossover to ', !state.showAudienceCrossover);
          state.showAudienceCrossover = !state.showAudienceCrossover;
          break;
      }
    },
    preferenceChanged: (state) => {
      state.preferenceChange = state.preferenceChange+1;
    },
    clearFiltersBetweenQuery: (state) => {
      console.log('clearing between filters');
      state.gaWidgetData= initWidgetData;
      state.widgetData= initWidgetData;
      state.gaWidgetsPending= setAllWidgetsPending;
      state.widgetsPending= setAllWidgetsPending;
    },
    clearGAFiltersBetweenQuery: (state) => {
      console.log('clearing GA filters');
      state.gaWidgetData= initWidgetData;
      state.gaWidgetsPending= setAllWidgetsPending;
    },
    clearFilters: (state) => {
      //console.log('clearing filters');
      state.inTopics= ['Politics'];
      state.outTopics= ['none'];
      state.widgetFilters= initWidgetFilters;
      state.gaWidgetData= initWidgetData;
      state.widgetData= initWidgetData;
      state.allWidgetData= initWidgetData;
      state.gaWidgetsPending= initWidgetsPending;
      state.widgetsPending= initWidgetsPending;
      state.filterView = initFilterView;
      state.noFilters = 0;
      state.isStateFiltered = false;
      state.currentAudienceUUID = 'default';
      state.audienceTab = {type: 'default', uuid: 'default'};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.selectWidgetData.matchFulfilled, (state, action) => {

      if (action?.meta?.arg?.originalArgs?.select_type == 'audience') { 
        state.widgetsPending[action?.meta?.arg?.originalArgs?.widget_column] = 0; // set as no longer pending
      }
      else {
        state.allWidgetsPending[action?.meta?.arg?.originalArgs?.widget_column] = 0; // set as no longer pending
      }
      if (action?.payload[0]) {
        action.payload = sortWidgetData(action.payload);
        if (action.payload[0].select_type == 'all') {
          state.allWidgetData[action.payload[0].widget_column] = action.payload;
        }
        else {
          state.widgetData[action.payload[0].widget_column] = action.payload;
        }
      }
  }),
    builder.addMatcher(api.endpoints.selectGAWidgetData.matchPending, (state, action) => {
      state.gaWidgetsPending[action?.meta?.arg?.originalArgs?.widget_type] = 1; // set widget query as pending
  }),
    builder.addMatcher(api.endpoints.selectGAWidgetData.matchFulfilled, (state, action) => {
      state.gaWidgetsPending[action?.meta?.arg?.originalArgs?.widget_type] = 0; // set as no longer pending
      if (action?.payload[0]) {
        state.gaWidgetData[action.payload[0].widget_column] = action.payload;
      }
  }),
     builder.addMatcher(api.endpoints.selectGARegionData.matchPending, (state) => {
      state.gaWidgetsPending['state'] = 1; // set widget query as pending
  }),
    builder.addMatcher(api.endpoints.selectGARegionData.matchFulfilled, (state, action) => {
      state.gaWidgetsPending['state'] = 0;  // set as no longer pending
      if (action?.payload[0]) {
        state.gaWidgetData['state'] = action.payload;
      }
  }),
    builder.addMatcher(api.endpoints.addUserToCategory.matchFulfilled, (state) => {
      state.preferenceChange = state.preferenceChange+1;
    }),
    builder.addMatcher(api.endpoints.removeUserToCategory.matchFulfilled, (state) => {
      state.preferenceChange = state.preferenceChange+1;
    }),
    builder.addMatcher(api.endpoints.updateUser.matchFulfilled, (state) => {
      state.preferenceChange = state.preferenceChange+1;
    }) 
    builder.addMatcher(api.endpoints.getUserByUsername.matchFulfilled, (state, action) => {
      if (action?.meta?.arg?.originalArgs?.custom_override != '1'){
        const trimmedStates = action?.payload?.interest_states?.replace(/\s+/g,' ').trim();
        const states = trimmedStates.split(',');
        state.widgetFilters['state'] = states; 
        state.noFilters = calcNoFilter(state.widgetFilters);
      }
    })
  }
})

export const selectActivationReferenceNo = (state) => state.currentView.activationReferenceNo;
export const selectWelcomePopUp = (state) => state.currentView.welcomePopUp;
export const selectShowFeedbackForm = (state) => state.currentView.showFeedbackForm;
export const selectPreferenceChange = (state) => state.currentView.preferenceChange;
export const selectWidgetFilters = (state) => state.currentView.widgetFilters;
export const selectComparisonWidgetFilters = (state) => state.currentView.comparisonWidgetFilters;
export const selectWidgetData = (state) => state.currentView.widgetData;
export const selectGAWidgetData = (state) => state.currentView.gaWidgetData;
export const selectAllWidgetData = (state) => state.currentView.allWidgetData;
export const selectWidgetsPending = (state) => state.currentView.widgetsPending;
export const selectAllWidgetsPending = (state) => state.currentView.allWidgetsPending;
export const selectGAWidgetsPending = (state) => state.currentView.gaWidgetsPending;
export const selectFilterView = (state) => state.currentView.filterView;
export const selectAudienceTab = (state) => state.currentView.audienceTab;
export const selectCurrentHeadline = (state) => state.currentView.currentHeadline;
export const selectLogoutMessage = (state) => state.currentView.logoutMessage;
export const selectNoFilters = (state) => state.currentView.noFilters;
export const selectIsStateFiltered = (state) => state.currentView.isStateFiltered;
export const selectWidgetFields = (state) => state.currentView.widgetFields;
export const selectCurrentAudienceUUID = (state) => state.currentView.currentAudienceUUID;
export const selectInTopics = (state) => state.currentView.inTopics;
export const selectOutTopics = (state) => state.currentView.outTopics;
export const selectSearchString = (state) => state.currentView.searchString;
export const selectInterestStates = (state) => state.currentView.interestStates;
export const selectShowAudienceCrossover = (state) => state.currentView.showAudienceCrossover;

export const { saveCurrentView, preferenceChanged, clearFilters } = currentViewSlice.actions;
export const { clearFiltersBetweenQuery ,clearGAFiltersBetweenQuery } = currentViewSlice.actions;
export default currentViewSlice.reducer;


// pass audience as 'none' to get values for all
export const getFilter = (type, audience, select_type, filter, refreshCounter=0) => {
  if (filter) {
    return {audience: audience, 
        widget_column: type,
        age_range: filter.age_range?.length > 0 ? filter.age_range.toString() : 'all',
        age_range_standard_range: filter.age_range_standard?.length > 0 ? filter.age_range_standard.toString() : 'all',
        education_range: filter.education?.length > 0 ? filter.education.toString() : 'all',
        education_1_range: filter.education_1?.length > 0 ? filter.education_1.toString() : 'all',
        education_2_range: filter.education_2?.length > 0 ? filter.education_2.toString() : 'all',
        ethnicity_range: filter.ethnicity?.length > 0 ? filter.ethnicity.toString() : 'all',
        ethnicity_hispanic_range: filter.ethnicity_hispanic?.length > 0 ? filter.ethnicity_hispanic.toString() : 'all',
        ethnicity_language_hispanic_range: filter.ethnicity_language_hispanic?.length > 0 ? filter.ethnicity_language_hispanic.toString() : 'all',
        gender_range: filter.gender?.length > 0 ? filter.gender.toString() : 'all',
        income_range: filter.income_range?.length > 0 ? filter.income_range.toString() : 'all',
        language_range: filter.language?.length > 0 ? filter.language.toString() : 'all',
        state_range: filter.state?.length > 0 ? filter.state.toString() : 'all',
        dma_range: filter.dma?.length > 0 ? filter.dma.toString() : 'all',
        county_range: filter.county?.length > 0 ? filter.county.toString() : 'all',
        us_congressional_district_range: filter.us_congressional_district?.length > 0 ? filter.us_congressional_district.toString() : 'all',
        state_senate_district_range: filter.state_senate_district?.length > 0 ? filter.state_senate_district.toString() : 'all',
        state_house_district_range: filter.state_house_district?.length > 0 ? filter.state_house_district.toString() : 'all',
        party_range: filter.party?.length > 0 ? filter.party.toString() : 'all',
        religion_range: filter.religion?.length > 0 ? filter.religion.toString() : 'all',
        home_owner_or_renter_range: filter.home_owner_or_renter?.length > 0 ? filter.home_owner_or_renter.toString() : 'all',
        marital_status_range: filter.marital_status?.length > 0 ? filter.marital_status.toString() : 'all',
        presence_of_children_range: filter.presence_of_children?.length > 0 ? filter.presence_of_children.toString() : 'all',
        vote_propensity_range: filter.vote_propensity?.length > 0 ? filter.vote_propensity.toString() : 'all',
        likely_primary_voter_range: filter.likely_primary_voter?.length > 0 ? filter.likely_primary_voter.toString() : 'all',
        likely_presidential_primary_voter_range: filter.likely_presidential_primary_voter?.length > 0 ? filter.likely_presidential_primary_voter.toString() : 'all',
        general_election_voter_range: filter.general_election_voter?.length > 0 ? filter.general_election_voter.toString() : 'all',
        select_type: select_type,
        refreshCounter: refreshCounter
    } 
  }
}

export const sortWidgetData = (widgetData) => {
  const widget = widgetData[0].widget_column;
  const subOrdering = widgetDisplay.find(item => item.widget === widget)?.subOrder;

  if (subOrdering) {
    let result = widgetData.slice().sort((a, b) => {
      const indexA = a[widget] ? subOrdering.indexOf(a[widget]) : subOrdering.length;
      const indexB = b[widget] ? subOrdering.indexOf(b[widget]) : subOrdering.length;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    return result;
  }
  return widgetData;
}