import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { useSelector } from "react-redux";
import { widgetDisplay } from "../../data/WidgetData";
import { getFilter } from "../../features/currentView/currentViewSlice";
import { useSelectWidgetDataQuery } from "../../app/api";

export default function ComparisonRow({audiences, widget, callback}) {
  const filter = useSelector(selectWidgetFilters);
  const widgetTitle = widgetDisplay.filter(w => w.widget === widget)[0].displayName;
  const VISIBILITY_THRESHOLD = 1;
  
  const incrementCompleteWidgets = (data) => {
    callback(data); // tell the parent that the data is loaded
  }

  // TODO: This should be made more flexible and not fixed to 2
  const {
    data: audience1Data,
    isFetching: audience1DataFetching,
    isError: audience1DataError,
    isSuccess: audience1DataSuccess
  } = useSelectWidgetDataQuery(getFilter(widget, audiences[0].audience, 'audience', filter));
  const {
    data: audience2Data,
    isFetching: audience2DataFetching,
    isError: audience2DataError,
    isSuccess: audience2DataSuccess
  } = useSelectWidgetDataQuery(getFilter(widget, audiences[1].audience, 'audience', filter));

  const [widgetData, setWidgetData] = useState({});
  const [isDataProcessed, setIsDataProcessed] = useState(false);

  useEffect(() => {
    if (audience2DataFetching == false && audience1DataFetching == false) {
      if (audience2DataError == true || audience1DataError == true) {
        incrementCompleteWidgets({"widget":widget});
      }
    }

  }, [audience1DataError, audience2DataError]);

  /** Are the two Audiences merged into a single data object? */
  useEffect(() => {
    if (Object.keys(widgetData).length > 0) {
      setIsDataProcessed(true);
      incrementCompleteWidgets(widgetData);
    } else {
      setIsDataProcessed(false);
    }
  }, [widgetData]);


  useEffect(() => {
    if (!audience1DataFetching && !audience2DataFetching && audience1DataSuccess && audience2DataSuccess) {
      processAudienceWidgetData();
    }
  }, [audience1Data, audience2Data])

  const processAudienceWidgetData = () => {
    // Merge the two data sets into one
    const mergedAudienceData = [...audience1Data, ...audience2Data];
    // Sanitize the data for the UI
    const processedData = processData(mergedAudienceData);
    setWidgetData(processedData);
  }

  const processData = (data) => {
    const groupedData = data.reduce((acc, item) => {
        const { audience, widget_column, count } = item;
        const category = item[widget_column];

        if (!acc[audience]) {
            acc[audience] = { audience, total: 0, categories: {} };
        }

        if (!acc[audience].categories[category]) {
            acc[audience].categories[category] = { count: 0 };
        }

        acc[audience].categories[category].count += Number(count);
        acc[audience].total += Number(count);

        return acc;
    }, {});

    // Calculate percentages
    Object.values(groupedData).forEach((audience) => {
        Object.values(audience.categories).forEach((category) => {
            category.percentage = ((category.count / audience.total) * 100).toFixed(2);
        });
    });

    // Transform grouped data to the desired format
    const result = {
        widget: data[0].widget_column,
        audiences: Object.values(groupedData)
    };

    // Calculate all possible categories within widget
    const allCategories = new Set();
    result.audiences.forEach((audience) => {
        Object.keys(audience.categories).forEach((category) => {
            allCategories.add(category);
        });
    });

    // sanitise the result
    allCategories.forEach((category) => {
      const audience0Category = result.audiences[0].categories[category];
      const audience1Category = result.audiences[1].categories[category];
      
      if (audience0Category === undefined && audience1Category.count > 0) {
        result.audiences[0].categories[category] = {"count":"","percentage":"0"}
      } else if (audience1Category === undefined && audience0Category.count > 0) {
        result.audiences[1].categories[category] = {"count":"","percentage":"0"}
      }
    });
 
    return result;
  };

  /**
   * Prevent showing rows where both percentage values are below VISIBILITY_THRESHOLD
   */
  const valuesLargeEnoughToShow = (widgetCategory) => {
    return widgetData.audiences[0].categories[widgetCategory].percentage >= VISIBILITY_THRESHOLD 
      || widgetData.audiences[1].categories[widgetCategory].percentage >= VISIBILITY_THRESHOLD
  }

  const subOrderSort = (a, b) => {
    const subOrdering = widgetDisplay.find(item => item.widget === widgetData.widget)?.subOrder;
    return subOrdering ? subOrdering.indexOf(a) - subOrdering.indexOf(b) : 0;
  }

  /**
   * Take the merged widget data object and generate the HTML to present in the UI
   */
  const generateWidgetUIData = () => {
    return (
      Object.keys(widgetData.audiences[0].categories)
        .sort(subOrderSort)
        .map(widgetCategory => { 
        if (valuesLargeEnoughToShow(widgetCategory)) {
          return (
            <div key={widgetCategory} className='compare_item_wrapper'>
              {widgetData.audiences.map((audience, i) => {
                if (audience && audience.categories[widgetCategory]) {
                  return (
                    <div className='compare_widget_wrapper' key={i}>
                      <div className='widget_wrapper_bar' key={i} >
                        <div className='cluster-bar-view-compare' >
                          <div className='cluster-bar-compare' >
                            <h5>{widgetCategory !== 'null' ? widgetCategory : 'unknown'}</h5>
                            <div className='bar-count-wrapper'>
                              <div className='bar-weight-compare' style={{width: `${parseInt(audience.categories[widgetCategory].percentage)}%`}} />
                              <p>{audience.categories[widgetCategory].percentage}%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  // Handle case where counts data is missing
                  return <td key={audience}>N/A</td>;
                }
              })}
            </div>
          );
        }
      })
    );
  };

  return (
    <>
      {audience1DataError != true && audience2DataError != true ? (
        <>
          <li>
            <p className='compare-cluster-demo'>{widgetTitle}</p>
            <p className='compare-cluster-demo'>{widgetTitle}</p>
          </li>

          <li className='compare_widget_display_wrapper'>
            {isDataProcessed ? 
              generateWidgetUIData()
            :
              <tr><td><p>Loading...</p></td><td><p>Loading...</p></td></tr>
            }
          </li>
        </>
      ) : (
        null
      )}
    </>
  );

}

ComparisonRow.propTypes = {
  audiences: PropTypes.array,
  widget: PropTypes.string,
  callback: PropTypes.func
}
