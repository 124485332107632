import React, {useEffect, useState} from "react";
import { isWidgetHearted } from "../../userSettings";
import Widget from "../../Widgets/Widget";
import PropTypes from "prop-types";
import { widgetDisplay } from "../../../data/WidgetData";
import GAHeatMap from "../../Widgets/GAHeatMap";
import GADateRange from "../../UIElements/GADateRange";
import GAUSADensityMap from "../../UIElements/Maps/GAUSADensityMap";
import { selectCurrentHeadlineDateRange } from "../../../features/audiences/audienceSlice";
import { useSelector } from "react-redux";
import CompleteGAWidgetCSVDownload from "../../Widgets/CompleteGAWidgetCSVDownload";
import Loading from "../../UIElements/Loading";
export default function Readership({userWidgetData, selectedAudience}) {

  const [runCSVDownloader, setRunCSVDownloader] = useState(false);

  let showMap = true;
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const [keyString, setKeyString] = useState('');

  useEffect(() => {
    setKeyString(JSON.stringify(currentHeadlineDateRange))
  },[currentHeadlineDateRange]);

  const widgetList = 
    widgetDisplay
    .filter((t) => t.category=='demographics')
    .filter((t) => t.hasGA)
    .filter((t) => t.order > 0)
    .map((t,i) => (
      <div key={i} className='mini-half-page leftside widget-bump'>
        {selectedAudience && <Widget key={keyString} widgetType={'bar'} hearted={isWidgetHearted(userWidgetData, t.widget)} passedType={t.widget} selectedAudience={selectedAudience} ga={true} filterByClick={true} />}
      </div>
    ))

  const csvDownloader = () => {
    if (runCSVDownloader) {
      return (
        <div className='allWidgetCSVDownloader'>
          <Loading/>
          <CompleteGAWidgetCSVDownload  selectedAudience={selectedAudience} widgetList={widgetDisplay} setRunCSVDownloader={setRunCSVDownloader}></CompleteGAWidgetCSVDownload>
        </div>
      )
    }
    else {
      return (
        <div className='allWidgetCSVDownloader'>
          <button onClick={() => (setRunCSVDownloader(true))}>
            <i className='fa-light fa-arrow-down-to-bracket'></i>
            <p>Download</p>
          </button>   
        </div>
      )
    }
  }

  return (
    <div className='breakdown-page overview'>
      <div className='breakdown-display'>
        <div className='date-range-wrapper'>
          <GADateRange />
        </div>
        <div className='bumper-page'>
          {!showMap && selectedAudience && <GAHeatMap selectedAudience={selectedAudience}/>}
          {showMap && selectedAudience && <GAUSADensityMap selectedAudience={selectedAudience}/>}
        </div>
        <div className='other-widgets'>
          {widgetList}
          {csvDownloader()}
        </div>
      </div>
    </div>
  )
}

Readership.propTypes = {
  userWidgetData: PropTypes.array,
  selectedAudience: PropTypes.object,
}