import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//import { widgetDisplay } from "../../data/WidgetData";
import { useGetAudienceCrossoverQuery } from "../../app/api";

export default function AudienceCrossover({audiences, widget, callback, allAudiences}) {
  const VISIBILITY_THRESHOLD = 1;
  
  const incrementCompleteWidgets = (data) => {
    callback(data); // tell the parent that the data is loaded
  }

  const audience1SplitLoc = audiences[0].audience.lastIndexOf('_');
  const audience1 = audiences[0].audience.substring(0, audience1SplitLoc);
  const audience1Label = audiences[0].audience.substring(audience1SplitLoc + 1);

  const audience2SplitLoc = audiences[1].audience.lastIndexOf('_');
  const audience2 = audiences[1].audience.substring(0, audience2SplitLoc);
  const audience2Label = audiences[1].audience.substring(audience2SplitLoc + 1);

  const [mergedData, setMergedData] = useState(null);

  // TODO: This should be made more flexible and not fixed to 2
  const {
    data: audience1Data,
    isFetching: audience1DataFetching,
    isError: audience1DataError,
    isSuccess: audience1DataSuccess
  } = useGetAudienceCrossoverQuery({audience: audience1, label: audience1Label});
  const {
    data: audience2Data,
    isFetching: audience2DataFetching,
    isError: audience2DataError,
    isSuccess: audience2DataSuccess
  } = useGetAudienceCrossoverQuery({audience: audience2, label: audience2Label});


  useEffect(() => {
    if (audience2DataFetching == false && audience1DataFetching == false) {
      if (audience2DataError == true || audience1DataError == true) {
        incrementCompleteWidgets({"widget":widget});
      }
    }

  }, [audience1DataError, audience2DataError]);

  useEffect(() => {
    if (audience2DataSuccess && audience1DataSuccess && audience2Data && audience1Data) {

      // sanitize data and merge
      let newAudience1Array = [];
      audience1Data.map(t => {
        const thisAudience = allAudiences.find(r => r.audience == t._col0 );
        if (thisAudience && thisAudience.audience && thisAudience.active == 1) {
          if (thisAudience.audience != audiences[0].audience){
            newAudience1Array.push({'_col0': thisAudience.title, 'c':t.c }) ;
          }
        }
      })

      let newAudience2Array = [];
      audience2Data.map(t => {
        const thisAudience2 = allAudiences.find(r => r.audience == t._col0 );
        if (thisAudience2 && thisAudience2.audience && thisAudience2.active == 1) {
          if (thisAudience2.audience != audiences[1].audience){
            newAudience2Array.push({'_col0': thisAudience2.title, 'c':t.c }) ;
          }
        }
      })


      let newMergedArray = [];
      const maxLength = newAudience1Array.length > newAudience2Array.length ? newAudience1Array.length : newAudience2Array.length;
      for (let i = 0; i < maxLength; i++) {
        newMergedArray.push({aud1: newAudience1Array[i]?._col0, aud1Perc: newAudience1Array[i]?.c, aud2: newAudience2Array[i]?._col0, aud2Perc: newAudience2Array[i]?.c})
      }

      setMergedData(newMergedArray);
    }

  }, [audience1DataSuccess, audience2DataSuccess, audience1Data, audience2Data]);

  /**
   * Prevent showing rows where both percentage values are below VISIBILITY_THRESHOLD
   */
  const valuesLargeEnoughToShow = (count) => {
    // not sure we need this for audience crossover, defaulting to true for now
    return count >= VISIBILITY_THRESHOLD 
  }

  /**
   * Take the merged widget data object and generate the HTML to present in the UI
   */
  const generateWidgetUIData = () => {
      return (mergedData
        .map(row => { 
            return (
              <div key={(row.aud1 || row.aud2) + (row.aud1 ? '_1' : '_2')} className='compare_item_wrapper'>
                <div className='compare_widget_wrapper'>
                  <div className='widget_wrapper_bar crossover'>
                    <div className='cluster-bar-view-compare' >
                      <div className='cluster-bar-compare' >
                        { valuesLargeEnoughToShow(row.aud1Perc) && <>
                          <h5>{row.aud1}</h5>
                          <div className='bar-count-wrapper'>
                            <div className='bar-weight-compare' style={{width: `${parseInt(row.aud1Perc)}%`}} >
                              <p className={row.aud1Perc > 80 ? 'over-eighty' : ''}>{row.aud1Perc}%</p>
                            </div>
                          </div>
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='compare_widget_wrapper'>
                  <div className='widget_wrapper_bar crossover'>
                    <div className='cluster-bar-view-compare' >
                      <div className='cluster-bar-compare' >
                        { valuesLargeEnoughToShow(row.aud2Perc) && <>
                          <h5>{row.aud2}</h5>
                          <div className='bar-count-wrapper'>
                            <div className='bar-weight-compare' style={{width: `${parseInt(row.aud2Perc)}%`}} >
                              <p className={row.aud2Perc > 80 ? 'over-eighty' : ''}>{row.aud2Perc}%</p>
                            </div>
                          </div>
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
        })
      )
  };

  return (
    <>
          <li>
            <p className='compare-cluster-demo'>Audience Crossover</p>
            <p className='compare-cluster-demo'>Audience Crossover</p>
          </li>
          <li className='compare_widget_display_wrapper'>
            {mergedData ? 
              generateWidgetUIData()
            :
              <li>
                <p className='compare-cluster-demo loading'>Calculating...</p>
                <p className='compare-cluster-demo loading'>Calculating...</p>
              </li>
            }
          </li>
    </>
  );

}

AudienceCrossover.propTypes = {
  audiences: PropTypes.array,
  widget: PropTypes.string,
  callback: PropTypes.func,
  allAudiences: PropTypes.array
}
