import React, {useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { useGetUserCategoriesQuery, useGetUserByUsernameQuery, useRemoveUserToCategoryMutation } from "../../app/api";
import { useAllCategoriesQuery, useAddUserToCategoryMutation/*, useOrgListQuery */ } from "../../app/api";
import { useUpdateUserMutation } from "../../app/api";
import { save } from "../../features/auth/roleSlice";
import { useSelector } from "react-redux";
import { selectUserName } from "../../features/auth/authSlice";
import { selectFirstLogin } from "../../features/auth/roleSlice";
import CategoryButton from "../UIElements/CategoryButton";
import { selectPreferenceChange } from "../../features/currentView/currentViewSlice";
import ResetPasswordForm from "../../features/auth/ResetPasswordForm";
import { jobFunctions, seniorityLevels, demographics, ageGroups } from "../../routes/userSettings"

export default function Settings() {

  const dispatch = useDispatch();
  const username = useSelector(selectUserName);
  const is_first_login = useSelector(selectFirstLogin);
  const {data: userCategories, refetch: refetchCategories} = useGetUserCategoriesQuery({username: username});
  const {data: user, refetch: refetchUser} = useGetUserByUsernameQuery({username: username});
  const {data: categories} = useAllCategoriesQuery();
  const [addUserToCategory] = useAddUserToCategoryMutation();
  const [removeUserToCategory] = useRemoveUserToCategoryMutation();
  const [updateUser] = useUpdateUserMutation();
  const preference = useSelector(selectPreferenceChange);
  const firstName = useRef();
  const lastName = useRef();
  const jobFunction = useRef();
  const jobLevel = useRef();
  const demographic = useRef();
  const ageGroup = useRef();
  const categoryAnchor = useRef();
  //const { data: orgList } =  useOrgListQuery(); 

  // dropdown values 
  const [userJobFunction, setUserJobFunction] = useState('');
  const [userJobLevel, setUserJobLevel] = useState(''); 
  const [userDemographics, setUserDemographics] = useState(''); 
  const [userAge, setUserAge] = useState(''); 
  const [userOrgName, setUserOrgName] = useState('');

  const queryParameters = new URLSearchParams(window.location.search);
  const editCategories = queryParameters.get("interest-categories");

  const [showPopup, setShowPopup] = useState(false);
  const [showInformationSaved, setShowInformationSaved] = useState(false);

  useEffect(() => {
    if (user) {
      setUserJobFunction(user.job_function);
      setUserJobLevel(user.job_level);
      setUserDemographics(user.demographic);
      setUserAge(user.age);
      setUserOrgName(user.company_name);
    }
  }, [user]);
  
  useEffect(() => {
    if (editCategories) {
    window.scrollTo({
      top: categoryAnchor.current.offsetTop + 100,
      left: 0,
      behavior: "smooth",
    })
    }
  },[]);

  useEffect(() => {
    refetchCategories();
    refetchUser();
  }, [preference]);

  const listCategories = () => {
    let mutableArray = [ ...new Set(userCategories)];
    return (mutableArray || [])
      .sort((a,b) => a.title.localeCompare(b.title))
      .map((cat, i) => (
        <div className='widget_body_category_wrapper_item' key={i}>
          <i className='fa-solid fa-circle-x clickable' onClick={()=>{removeCategory(cat.id)}} />
          <p className=''>{cat.title}{i < (userCategories.length-1) ? ',':''}</p>
        </div>
      )
    )
  }

  const categoryOptions = () => {
    let mutableArray = [ ...new Set(categories)];
    return (mutableArray || [])
      .filter((cat) => { return userCategories && !userCategories.find((t) => t.title == cat.title) })
      .sort((a,b) => a.title.localeCompare(b.title))
      .map((cat, i) => (
        <div className='clickable' key={i} onClick={()=>{addCategory(cat.id)}}> 
          <CategoryButton label={cat.title} type={'add'}/>
        </div>
    ))
  }

  const addCategory = (id) => {
    addUserToCategory({user_id: user.id, category_id: id});
  }

  const removeCategory = (id) => {
    removeUserToCategory({user_id: user.id, category_id: id});
  }

  const updateUserNow = () => {
    user && updateUser({username: username, role: user.role, firstname: firstName.current.value, lastname: lastName.current.value, organizationType: user.org_type, jobFunction: jobFunction.current.value, jobLevel: jobLevel.current.value, demographic: user.demographic, age: user.age, org_id: user.org_id});
    dispatch(save({type: 'nameString', value: (firstName.current.value)}));
    setShowInformationSaved(true);
    setTimeout(function() { setShowInformationSaved(false); }, 3500);
  }

  const passwordPopUp = () => {
    if (showPopup) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setShowPopup(false)}}/>
            <div className='user-reset'>
              <ResetPasswordForm type={'USER'} email={user && user.username} setShowPopup={setShowPopup}/>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className='client_dashboard_view_header standard account-password'>
        <h3>Account Settings</h3>
        <div className='reset_password_wrapper'>
          <p className='link' onClick={() => { setShowPopup(true) }}>Change your password</p>
          <i className='fa-solid fa-lock'></i>
        </div>
      </div>
      <div className='client_dashboard_view_content'>
        { is_first_login == 'YES' && <h4 className="red">As this is your first login, please complete your account information</h4> }
        <div className='client_dashboard_view_content_widget'>
          <h2>General Information</h2>
          <div className='client_dashboard_view_content_widget_body'>
            <h3>Email / Username:<p>{username}</p></h3>
            <h3>Organization:<p>{userOrgName}</p></h3>
            <div className='widget_body_input_wrapper'>
              <div className='widget_body_item'>
                <h3>First Name</h3>
                <input ref={firstName} type='text' defaultValue={user && user.first_name}></input>
              </div>
              <div className='widget_body_item'>
                <h3>Last Name</h3>
                <input ref={lastName} type='text' defaultValue={user && user.last_name}></input>
              </div>
            </div>
            <div className='widget_body_input_wrapper'>
              <div className='widget_body_item'>
                <h3>Job Function</h3>
                <div>
                  <label>
                    <select ref={jobFunction} value={userJobFunction || ''} onChange={() => { setUserJobFunction(jobFunction.current.value) }}>
                      {jobFunctions.map((i) => <option key={i}>{i}</option>)}
                    </select>
                  </label>
                </div>
              </div>
              <div className='widget_body_item'>
                <h3>Seniority / Level</h3>
                <div className='dropdown'>
                  <label>
                    <select ref={jobLevel} value={userJobLevel || ''} onChange={() => { setUserJobLevel(jobLevel.current.value) }}>
                      {seniorityLevels.map((i) => <option key={i}>{i}</option>)}
                    </select>
                  </label>
                </div>
              </div>
              <div className='widget_body_item hidden'>
                <h3>Gender</h3>
                <div className='dropdown'>
                  <label>
                    <select ref={demographic} value={userDemographics || ''} onChange={() => { setUserDemographics(demographic.current.value) }}>
                      {demographics.map((i) => <option key={i}>{i}</option>)}
                    </select>
                  </label>
                </div>
              </div>
              <div className='widget_body_item hidden'>
                <h3>Age</h3>
                <div className='dropdown'>
                  <label>
                    <select ref={ageGroup} value={userAge || ''} onChange={() => { setUserAge(ageGroup.current.value) }}>
                      {ageGroups.map((i) => <option key={i}>{i}</option>)}
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div className='widget_body_input_wrapper submit'>
              <p className={showInformationSaved? 'settings-information-saved' : 'hidden'}>Information Saved!</p>
              <button onClick={() => { updateUserNow() }} className=''>Save</button>
            </div>
          </div>
        </div>
        <div className='client_dashboard_view_content_widget'>
          <h2>{user && user.first_name}&apos;s Interest Categories</h2>
          <div className='client_dashboard_view_content_widget_body'>
            <div id='interest-categories' ref={categoryAnchor}>
              <div className='widget_body_category_wrapper'>
                {listCategories()}
              </div>
              <div className='widget_body_category_wrapper'>
                {categoryOptions()}
              </div>
            </div>
          </div>
        </div>
        {passwordPopUp()}
      </div>
    </>
  )
}