import React, { useEffect } from "react";
import PropTypes from "prop-types"
import parseHTML from 'html-react-parser'

export default function ChatBox({ conversation, showGraphical }) {

  const scrollToBottom = () => {
    const chatbox_bottom_anchor = document.getElementById('chatbox_bottom_anchor');
    chatbox_bottom_anchor.scrollTop = chatbox_bottom_anchor.scrollHeight;
    chatbox_bottom_anchor.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  });

  return (
    <div className={showGraphical ? "ai_chatbox show_graphical" : "ai_chatbox"} >
      <ul id="ai_chatbox_ul" className="ai_chatbox_ul" >
        {parseHTML(conversation)}
        <div id="chatbox_bottom_anchor"></div>
      </ul>
    </div>
  )
}

ChatBox.propTypes = {
  conversation: PropTypes.string,
  showGraphical: PropTypes.bool
}