import { createSlice } from "@reduxjs/toolkit";
import { api } from '../../app/api';
import { clearFilters } from "../currentView/currentViewSlice"; 


const today = new Date();
let yesterday = new Date();
let backDate = new Date();
backDate.setDate(today.getDate() - 5);
yesterday.setDate(today.getDate() -1);
const defaultMaxDate = yesterday.toISOString().split('T')[0];
const defaultMinDate = backDate.toISOString().split('T')[0];

const initialState = {
  // audience attributes 
  runL2Agg: false,
  audienceList: [{}],
  audienceL2AggList: [{}],
  audienceCount: 0,
  categories: [{}], 
  openRequests: [{}],
  allRequests: [{}],
  allAudienceCounts: null,
  headlineDateRange: {maxDate: defaultMaxDate
     , minDate: defaultMinDate},
  currentHeadlineDateRange: {maxDate: defaultMaxDate
     , minDate: defaultMinDate},
  dateRangeRan: false,
};

export const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    saveAudience: (state, action) => {
      const payload = action.payload;
      switch (payload.type) {
        case 'categories':
          state.categories = payload.value;
          break;
        case 'open-requests':
          state.allRequests = payload.value;
          state.openRequests = payload.value.filter(t => t.status === 'OPEN');
          break;
        case 'audience-list':
          state.audienceList = payload.value;
          state.audienceCount = payload.value.length;
          break;
        case 'all-audience-counts':
          state.allAudienceCounts = payload.value;
          break;
        case 'headline-date-range':
          console.log('headline payload', payload);
          state.headlineDateRange = payload.value;
          break;
        case 'current-headline-date-range':
          console.log('current headline payload', payload);
          state.currentHeadlineDateRange = payload.value;
          break;
      }
    },
    clearDates: (state) => {
        state.headlineDateRange= {maxDate: defaultMaxDate, minDate: defaultMinDate};
        state.currentHeadlineDateRange= {maxDate: defaultMaxDate, minDate: defaultMinDate};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearFilters, (state) =>  {
      state.headlineDateRange= {maxDate: defaultMaxDate, minDate: defaultMinDate};
      state.currentHeadlineDateRange= {maxDate: defaultMaxDate, minDate: defaultMinDate};
    }),
    builder.addMatcher(api.endpoints.searchAudience.matchFulfilled, (state) => {
      state.audienceList = [{'title': 'jen'}];
    }),
    builder.addMatcher(api.endpoints.addCategory.matchFulfilled, (state, action) => {
      state.categories = action.payload;
    }),
    builder.addMatcher(api.endpoints.addUserAudience.matchFulfilled, () => {
      //state.allRequests = action.payload;
      //state.openRequests = action.payload.filter(t => t.status === 'OPEN');
    }),
    builder.addMatcher(api.endpoints.updateAudienceStatus.matchFulfilled, (state, action) => {
      state.allRequests = action.payload;
      state.openRequests = action.payload.filter(t => t.status === 'OPEN');
    }),
    builder.addMatcher(api.endpoints.getSelectAllAudiences.matchFulfilled, (state, action) => {
      state.audienceList = action.payload;
      state.runL2Agg = true;
    }),
    builder.addMatcher(api.endpoints.getSelectAllL2AggAudiences.matchFulfilled, (state, action) => {
      console.log('updating audienceL2AggList', state, action);
    }),
    builder.addMatcher(api.endpoints.getHeadlineDateRange.matchFulfilled, (state) => {
      state.dateRangeRan = true;
    })
    ;
  }
})

export const selectAudienceCount = (state) => state.audience.audienceCount;
export const selectAudienceList = (state) => state.audience.audienceList;
export const selectRunL2Agg = (state) => state.runL2Agg;
export const selectCategories = (state) => state.audience.categories;
export const selectOpenRequests = (state) => state.audience.openRequests;
export const selectAllRequests = (state) => state.audience.allRequests;
export const selectAllAudienceCounts = (state) => state.audience.allAudienceCounts;
export const selectHeadlineDateRange = (state) => state.audience.headlineDateRange;
export const selectCurrentHeadlineDateRange = (state) => state.audience.currentHeadlineDateRange;
export const selectDateRangeRan = (state) => state.dateRangeRan;

export const { saveAudience, getCategories, clearDates } = audienceSlice.actions;
export default audienceSlice.reducer;
