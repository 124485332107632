import React from "react"
import { ArcElement, Chart as ChartJS, Tooltip, Title, Legend } from "chart.js";
import {Doughnut} from 'react-chartjs-2';
import PropTypes from "prop-types";
import { donutColors } from "../../data/WidgetData";
//import ChartDataLabels from 'chartjs-plugin-datalabels';


export default function DoughnutGraph({dataObject, isPending}) {

  ChartJS.register(Title, Tooltip, ArcElement, Legend);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          color: '#000000',
          font: {
            size: 16,
          }
        }
      },
      tooltip: {
        titleFont: {
          size: 16,
        }, 
        bodyFont: {
          size: 16,
        }
      },
      datalabels: {
        display: true,
        color: '#ffffff',
        value: {
          color: '#ffffff',
        },
        formatter: function(value) {
          return value + '%';
        }, 
        font: {
          weight: 'bold',
          size: 16,
        },
      }
    },
    aspectRatio:1.7,
    elements: {
      arc: {
        borderWidth: 1,
      }
    },
  };

  const data = {
    maintainAspectRatio: false,
    labels: dataObject.categories,
    datasets: [
      {
        data: dataObject.dataset1,
        backgroundColor: donutColors, 
        hoverBackgroundColor: 'rgba(250, 218, 158)',
      },
    ],
  };

const isLoadingNow = () => {
  if (isPending == 1) {
    return <i className='fas fa-sync fa-spin'/>
  }
  else {
    if (isNaN(dataObject.dataset1[0])){
      return <Doughnut options={options} data={data} />
    }
    else {
      return <Doughnut options={options} data={data} />
    }
  }
} 
  return (
    <>
      {isLoadingNow()}
    </>
  );
}

DoughnutGraph.propTypes = {
  dataObject: PropTypes.object,
  isPending: PropTypes.number
}