import React , {useState, useRef, useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import { selectClientPage} from "../../features/directory/directorySlice";
import { useAddFeedbackMutation } from "../../app/api";
import { toTitleCase } from "../format";
import { selectUserId } from "../../features/auth/authSlice";
import { selectUserName } from "../../features/auth/authSlice";
import GetAudienceData from "../GetAudienceData";

export default function ClientFeedbackForm() {

  const dispatch = useDispatch();
  const clientPage = useSelector(selectClientPage);
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);

  const [starRating, setStarRating] = useState(3);
  const pageString = useRef();
  const feedbackString = useRef();
  const [thisAudience, setThisAudience] = useState({});

  const queryParameters = new URLSearchParams(window.location.search);
  const audienceUUID = queryParameters.get("uuid");
  const [addFeedback] = useAddFeedbackMutation();

  //TODO:  if selected an audience then navigate to other pages show other page names instead of audience title
  const pageName =thisAudience && thisAudience.title != '' ? thisAudience.title : readablePageName(clientPage);

  const [feedbackSent, setFeedbackSent] = useState(false);
  const [thisPageString, setThisPageString] = useState(pageName);

  useEffect( () => {
    setThisPageString(pageName);
  }, [pageName]);

  const starClass = (rating) => {
    if (starRating >= rating) {
      return "fa-solid fa-circle-exclamation ";
    }
    else {
      return "fa-light fa-circle";
    }
  }

  const starComment = () => {
    switch (starRating) {
      case 1:
        return 'Just letting you know!';
      case 2:
        return 'A nice to have';
      case 3:
        return "I hope the change is made but it's not a blocker";
      case 4:
        return 'It will considerably improve the system';
      case 5:
        return "I won't come back until it's done!";
    }
  }

  const starRatingComment = () => {
    switch (starRating) {
      case 1:
        return 'Critical Level 1';
      case 2:
        return 'Critical Level 2';
      case 3:
        return 'Critical Level 3';
      case 4:
        return 'Critical Level 4';
      case 5:
        return 'Critical Level 5';
    }
  }

  const submitFeedback = () => {
    addFeedback({user_id: userId, username: userName, page: pageString.current.value, comment: feedbackString.current.value, level: starRating });
    pageString.current.value = '';
    feedbackString.current.value = '';
    setStarRating(3);
    setFeedbackSent(true);
  }

  const formView = () => {
    if (feedbackSent) {
      setTimeout(()=>{dispatch(saveCurrentView({type:'showFeedbackForm', value:false}))}, 2000);
      setTimeout(()=>{setFeedbackSent(false);}, 2400);
      return (
        <>
          <div className='top-bump-40'/>
          <i className="fa-regular fa-thumbs-up"></i>
          <div className='top-bump-40'/>
          <div className='top-bump-40'/>
          <h1> Thank you! </h1>
          <div className='top-bump-40'/>
          <div className='top-bump-40'/>
          <p> Your feedback has been submitted to our team </p>
          <div className='top-bump-40'/>
          <div className='top-bump-40'/>
          <p> Please feel welcome to leave more! </p> 
          <div className='top-bump-40'/>
        </>
      )

    }
    else {
      return (
        <>
          <i className='fa-solid fa-xmark' onClick={()=>{console.log('clicked'); dispatch(saveCurrentView({type:'showFeedbackForm', value:false}))}}></i>
          <h1> We Value Your Feedback! </h1> 
          <div className='top-bump-40'/> <p> The page you are commenting on </p> 
          <div key={thisPageString}>
            <input ref={pageString} type='text' defaultValue={thisPageString}></input> 
          </div>
          <div className='top-bump-40'/>
          <p> Please let us know what you think </p>
          <textarea ref={feedbackString} className='text-area' type='text'></textarea>
          <p> How critical is this change request? </p>
          <div className='rating-stars'>
            <i className={starClass(1)} onMouseEnter={()=>{setStarRating(1)}}/>
            <i className={starClass(2)} onMouseEnter={()=>{setStarRating(2)}}/>
            <i className={starClass(3)} onMouseEnter={()=>{setStarRating(3)}}/>
            <i className={starClass(4)} onMouseEnter={()=>{setStarRating(4)}}/>
            <i className={starClass(5)} onMouseEnter={()=>{setStarRating(5)}}/>
          </div>
          <p>{starRatingComment()}</p>
          <p className='star-comment'>&quot;{starComment()}&quot;</p>
          <button id='feedback-button' className='blue' onClick={() => { submitFeedback() }}><p>Submit Feedback</p></button>
        </>
      )
    }
  }
  return (
    <>
      <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience}/>
      <div className='form activate-form'>
        {formView()}
      </div>
    </>
  );
}

export function readablePageName(pageName) {
  let returnString = '';
  switch(pageName) {
    case 'client-search':
      returnString = 'Dashboard';
      break;
    case 'audience-builder':
      returnString = 'Audience Builder';
      break;
    default: 
      returnString = toTitleCase(pageName || '');
      break;
  }

  return returnString + ' Page';
}