import React from "react";
import PropTypes from "prop-types";

export default function ChatFaqItem({ selected_faq, question, selectFaq, lockUser }) {

  const onClickHandler = () => {
    if (!lockUser) { // Only execute if user is unlocked
      selectFaq(selected_faq)
    }
  }

  const titleDescription = (lockUser) => {
    if (lockUser) {
      return "CINQ is responding"
    }
    else {
      return ""
    }
  }

  return (
    <div>
      <li className="ai_ask_li">
        <button title={titleDescription(lockUser)} className={`ai_ask_button ${lockUser ? 'lockUser' : ''}`} onClick={() => { onClickHandler() }}> {question} </button>
      </li>
    </div>
  )
}

ChatFaqItem.propTypes = {
  selected_faq: PropTypes.object,
  entry_id: PropTypes.number,
  question: PropTypes.string,
  selectFaq: PropTypes.func,
  lockUser: PropTypes.bool
}