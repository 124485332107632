import React, { useState } from "react";
import PropTypes from "prop-types";

export default function ChatTerminal({ faqs, prompt, processResponse, lockUser }) {

  const [terminalData, setTerminalData] = useState("");

  const handleInputChange = event => {
    if (!lockUser) {
      setTerminalData(event.target.value);
    }
  };

  const submitTerminalEntry = () => {
    processResponse(faqs, terminalData);
    setTerminalData('');
  }

  const handleSubmit = (lockUser) => {
    if (!lockUser) { // Only execute if user is unlocked
      submitTerminalEntry();
    }
  }

  const titleDescription = (lockUser) => {
    if (lockUser) {
      return "CINQ is responding"
    }
    else {
      return ""
    }
  }

  return (
    <div className="ai_terminal">
      <input
        type="text"
        className={`ai_terminal_input_field ${lockUser ? 'lockUser' : ''}`}
        id="ai_terminal_input"
        name="ai_terminal_input"
        value={terminalData}
        onChange={handleInputChange}
        placeholder={prompt}
        maxLength="255"
        onKeyDown={(e) => {
          if (e.key === "Enter")
            handleSubmit(lockUser);
        }}
        title={titleDescription(lockUser)}
      />
      < div className="ai_terminal_input_field_bar_wrapper">
        <div className="ai_terminal_input_field_bar" >
        </div>
      </div>
      <button title={titleDescription(lockUser)} className={`ai_terminal_submit_button ${lockUser ? 'lockUser' : ''}`} onClick={() => { handleSubmit(lockUser) }}>
        <div className="ai_terminal_submit_icon"></div>
      </button >
    </div>
  )
}

ChatTerminal.propTypes = {
  faqs: PropTypes.array,
  prompt: PropTypes.string,
  processResponse: PropTypes.func,
  lockUser: PropTypes.bool
}