// Original colors
const yellow = '#f5d372';
const pink = '#ba4c4b';
const lightpink = '#f59185';
const red = '#d43633';

/**
 * CinqDI official palate colors
 */
const seagrass     = '#56BBB1';
const seagrass60   = '#B2DDD5';
const mulberry     = '#B64B8D';
const mulberry60   = '#D392BA';
const teal         = '#2F5D62';
const teal80       = '#597D80';
const lemony       = '#F6C250';
const lemony60     = '#F6C25D';
const sourorange   = '#F4673C';
const sourorange60 = '#F8A48A';

export const donutColors = [seagrass, mulberry, seagrass60, mulberry60, teal, teal80, lemony, lemony60, sourorange, sourorange60];
export const gaugeColors = [yellow, red];

// group allows widgets to toggle between each other
// breakoutTranslate means the widget detail names need to map to other displayNames
// set order to -1 for the ones in the group you do not want to default display
// if widget it not in a group order must be > 1
// hasGA = true if mapping to GA data exists (some widgets are L2 only)
// hasL2 = true if mapping to L2 data exists (some widgets are GA only)
export const widgetDisplay = [
  {widget: 'age_range', displayName: 'Generation', partOfGroup: true, group: 1, breakoutTranslate: false, category: 'demographics', order: -1, hasGA: false, subOrder: ['Gen Z', 'Millennials', 'Gen X', 'Baby Boomers', 'Silent Generation'], hasL2: true,  compareOrder: 2 },
  {widget: 'age_range_standard', displayName: 'Age', partOfGroup: true, group: 1, breakoutTranslate: true, category: 'demographics', order: 1, hasGA: true, hasL2: true,compareOrder: 1,} ,
  {widget: 'education_1', displayName: 'Education (Full Breakout)', partOfGroup: true, group: 2, breakoutTranslate: true, category: 'demographics', order: 2, hasGA: false, subOrder: ["Grad/Prof Degree", "Bachelor's Degree", "Some College", "High School Graduate or Less", "Unknown"], hasL2: true, compareOrder: 8} ,
  {widget: 'education_2', displayName: 'Education (Condensed)', partOfGroup: true, group: 2, breakoutTranslate: true, category: 'demographics', order: -1, hasGA: true, hasL2: true, compareOrder: 8},
  {widget: 'ethnicity', displayName: 'Ethnicity', partOfGroup: true, group: 3, breakoutTranslate: true, category: 'demographics', order: 3, hasGA: true, subOrder: ['European', 'Hispanic and Portuguese', 'Likely African-American', 'East and South Asian', 'Other'], hasL2: true, compareOrder: 4},
  {widget: 'ethnicity_hispanic', displayName: 'Hispanic Origin', partOfGroup: true, group: 3, breakoutTranslate: true, category: 'demographics', order: -1, hasGA: false, hasL2: true, compareOrder: 999},
  {widget: 'ethnicity_language_hispanic', displayName: 'Hispanic + Language', partOfGroup: true, group: 3, breakoutTranslate: true, category: 'demographics', order: -1, hasGA: false, subOrder: ['Hispanic/Spanish Speaker', 'Hispanic/Not Likely Spanish Speaker', 'Not Hispanic'], hasL2: true, compareOrder: 999},
  {widget: 'gender', displayName: 'Gender', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 4, hasGA: true, hasL2: true, compareOrder: 3},
  {widget: 'income_range', displayName: 'Household Income', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 5, hasGA: true, subOrder: ['< $50,000', '$50,000 - $74,999','$75,000 - $99,999', '$100,000 - $149,999','$150,000 & up', 'Unknown'], hasL2: true, compareOrder: 9},
  {widget: 'language', displayName: 'Language', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 6, hasGA: false, hasL2: true, compareOrder: 999},
  {widget: 'state', displayName: 'State', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 7, hasGA: true, hasL2: true, compareOrder: 999},
  {widget: 'dma', displayName: 'DMA', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 8, hasGA: true, hasL2: true, compareOrder:999},
  {widget: 'county', displayName: 'County', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 9, hasGA: false, hasL2: true, compareOrder: 999},
  {widget: 'us_congressional_district', displayName: 'US Congressional District', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 10, hasGA: false, hasL2: true, compareOrder: 999},
  {widget: 'state_senate_district', displayName: 'State Senate District', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 11, hasGA: false, hasL2: true, compareOrder:999},
  {widget: 'state_house_district', displayName: 'State House District', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'geography', order: 12, hasGA: false, hasL2: true, compareOrder: 999},
  {widget: 'party', displayName: 'Party', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 13, hasGA: true, hasL2: true, compareOrder: 5},
  {widget: 'home_owner_or_renter', displayName: 'Home Ownership', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 15, hasGA: false, hasL2: true, compareOrder: 10},
  {widget: 'marital_status', displayName: 'Marital Status', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 16, hasGA: true, hasL2: true, compareOrder: 11},
  {widget: 'presence_of_children', displayName: 'Presence of Children', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 17, hasGA: true, hasL2: true, compareOrder: 12},
  {widget: 'vote_propensity', displayName: 'Voter Frequency', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 18, hasGA: false, subOrder: ['Low Propensity', 'Mid Propensity', 'High Propensity'], hasL2: true, compareOrder: 6},
  // voter widgets are needed for the filter but not to show on the page - they are shown joined by likely voter
  // thus "placeholder" category
  {widget: 'general_election_voter',            displayName: 'Likely General Election Voter',     partOfGroup: false, group: 0, breakoutTranslate: true,  category: 'placeholder',  order: 19,  compareOrder: 7,   hasGA: true, header: 'universe', headerTop: 'universe',  hasL2: true},
  {widget: 'likely_presidential_primary_voter', displayName: 'Likely Presidential Primary Voter', partOfGroup: false, group: 0, breakoutTranslate: true,  category: 'placeholder',  order: 20,  compareOrder: 999, hasGA: true, header: 'universe',                         hasL2: true},
  {widget: 'likely_primary_voter',              displayName: 'Likely Primary Voter',              partOfGroup: false, group: 0, breakoutTranslate: true,  category: 'placeholder',  order: 21,  compareOrder: 999, hasGA: true, header: 'universe',                         hasL2: true},
  {widget: 'likely_voter',                      displayName: 'Likely Voter',                      partOfGroup: false, group: 0, breakoutTranslate: false, category: 'demographics', order: 22,  compareOrder: 7,   hasGA: true,                                             hasL2: true, skipFilter: true},
  // end of voter widgets
  {widget: 'source',                            displayName: 'Media Source',                      partOfGroup: false, group: 0, breakoutTranslate: true,  category: 'demographics', order: 23,  compareOrder: 13,  hasGA: true, hasL2: false, subOrder: ['Google', 'Facebook', 'Flipboard','Twitter','Email Newsletter','Reddit','Yahoo','Bing','NewsBreak','MSN','Other']}
]

// NOTE:  
// If you remove a widget that is in the noFilters calc in currentViewSlice you also have to remove it there !!!!!
//
/* removed widgets ****
  {widget: 'religion', displayName: 'Religion', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 14, hasGA: false, subOrder: ['Christian', 'Jewish','Islamic','Hindu', 'Other', 'Unknown'], hasL2: true},
  {widget: 'general_election_voter', displayName: 'Likely General Election Voter', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 19, hasGA: true, header: 'universe', headerTop: 'universe', hasL2: true},
  {widget: 'likely_presidential_primary_voter', displayName: 'Likely Presidential Primary Voter', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 20, hasGA: true, header: 'universe', hasL2: true},
  {widget: 'likely_primary_voter', displayName: 'Likely Primary Voter', partOfGroup: false, group: 0, breakoutTranslate: true, category: 'demographics', order: 21, hasGA: true, header: 'universe', hasL2: true},
  {widget: 'education', displayName: 'Education', partOfGroup: true, group: 2, breakoutTranslate: false, category: 'demographics', order: -1, hasGA: false, hasL2: true},
**** */

// mapping from L2 to GA
export const gaMappings = [
  {type: 'age_range_standard', l2: '18 to 29' , ga: 'c3804'},
  {type: 'age_range_standard', l2:'30 to 39' , ga: 'c3806'},
  {type: 'age_range_standard', l2:'40 to 49' , ga: 'c3808'},
  {type: 'age_range_standard', l2:'50 to 64' , ga: 'c3809'},
  {type: 'age_range_standard', l2:'65 and over' , ga: 'c3810'},
  {type: 'education_2', l2:'No College Degree', ga: 'c3818'},
  {type: 'education_2', l2:'College Degree+', ga: 'c3805'},
  {type: 'language', l2:'Likely Spanish Speaker', ga: 'c3807'},
  {type: 'gender', l2:'M', ga: 'c3812'},
  {type: 'gender', l2:'F', ga: 'c3811'},
  {type: 'income_range', l2:'$100,000 - $149,999', ga: 'c3814'},
  {type: 'income_range', l2:'$75,000 - $99,999', ga: 'c3816'},
  {type: 'income_range', l2:'< $50,000', ga: 'c3813'},
  {type: 'income_range', l2:'$50,000 - $74,999', ga: 'c3815'},
  {type: 'income_range', l2:'$150,000 & up', ga: 'c3817'},
  {type: 'party', l2:'Democratic', ga: 'c3844'},
  {type: 'party', l2:'Other', ga: 'c3846'},
  {type: 'party', l2:'Republican', ga: 'c3845'},
  {type: 'marital_status', l2: 'Married', ga: 'c3819'},
  {type: 'marital_status', l2: 'Not Married', ga: 'c3820'},
  {type: 'presence_of_children', l2: 'Has children', ga: 'c3821'},
  {type: 'presence_of_children', l2: 'No children', ga: 'c3822'},
  {type: 'general_election_voter', l2: 'Y', ga: 'c3847'},
  {type: 'general_election_voter', l2: 'Y', ga: 'c3828'},
  {type: 'likely_presidential_primary_voter', l2: 'Y', ga: 'c3830'},
  {type: 'likely_presidential_primary_voter', l2: 'N', ga: 'c3830', negative: true},
  {type: 'likely_primary_voter', l2: 'Y', ga: 'c3829'},
  {type: 'likely_primary_voter', l2: 'N', ga: 'c3829', negative: true},
  {type: 'ethnicity', l2: 'Other', ga: 'c3865'},
  {type: 'ethnicity', l2: 'European', ga: 'c3861'},
  {type: 'ethnicity', l2: 'East and South Asian', ga: 'c3863'},
  {type: 'ethnicity', l2: 'Likely African-American', ga: 'c3862'},
  {type: 'ethnicity', l2: 'Hispanic and Portuguese', ga: 'c3864'},
]

// TODO: maybe for last 4 voter widgets "no" is setting them to 0?

export const gaWidgetSelect = (widget) => {

  const gaInfo = gaMappings.filter(t => t.type == widget);
  let query_string = '';

  let preSourceRollupString = '';
  sourceRollup.map(t => {preSourceRollupString = preSourceRollupString + ",'" + t.source + "'"} );
  const sourceRollupString = preSourceRollupString.replace(',','');
  
  if (widget == 'source') {
    query_string = " case when contains(array["+ sourceRollupString + "],traffic_source.source) then traffic_source.source else 'other' end as source, count( distinct fullvisitorId ) as count "
  }
  else {
    query_string = 'CASE';
    gaInfo.map((t => {
      if (t.negative) {
        query_string =  query_string + ' WHEN ' + t.ga + " = 0 THEN '" + t.l2 + "' ";
      }
      else {
        query_string =  query_string + ' WHEN ' + t.ga + " > 0 THEN '" + t.l2 + "' ";
      }
    }))

    query_string = query_string + " ELSE 'n/a' END as " + widget + " , COUNT( DISTINCT fullvisitorid) as count ";
  }

  return query_string;
}


export const gaWidgetGroupBy = (widget) => {
  const gaInfo = gaMappings.filter(t => t.type == widget);
  let query_string = '';

  let preSourceRollupString = '';
  sourceRollup.map(t => {preSourceRollupString = preSourceRollupString + ",'" + t.source + "'"} );
  const sourceRollupString = preSourceRollupString.replace(',','');

  if (widget == 'source') {
    query_string = " case when contains(array["+ sourceRollupString + "],traffic_source.source) then traffic_source.source else 'other' end "
  }
  else {
    query_string = 'CASE';
    gaInfo.map((t => {
      if (t.negative) {
        query_string =  query_string + ' WHEN ' + t.ga + " = 0 THEN '" + t.l2 + "' ";
      }
      else {
        query_string =  query_string + ' WHEN ' + t.ga + " > 0 THEN '" + t.l2 + "' ";
      }
    }))
    query_string = query_string + " ELSE 'n/a' END "; 
  }
    return query_string;
}


export const createGAQuery = (filter, dateRange = null, headline = null) => {
  let query_string = "";
  widgetDisplay
    .filter(t => t.hasGA)
    .map(t => {
      query_string = query_string + ' and ( ';
      filter[t.widget]?.map(l2ValueRaw => {
       const l2Value = l2ValueRaw.replace(/\|/g,','); 
       if (l2Value != 'all') {
         const mapper = gaMappings.find(ga => ga.l2 == l2Value && ga.type == t.widget);
         if (mapper) {
          if (mapper.ga == 'c3811') {
             query_string = query_string +  '(' + mapper.ga + ' > 0 and c3812 = 0) or ';

          }
          else {
            if (mapper.ga == 'c3812') {
              query_string = query_string +  '(' + mapper.ga + ' > 0 and c3811 = 0) or ';
            }
            else {
              if (mapper.negative) {
                query_string = query_string +  mapper.ga + ' = 0 or ';
              }
              else {
                query_string = query_string +  mapper.ga + ' > 0 or ';
              }
            } 
          }
         }
       }
    })
    query_string = query_string + ') ';
  })

  query_string = query_string.replace(/ and \( \) /g, '');
  query_string = query_string.replace(/or \)/g, ')');

  // state
  let state_string = ''
  filter.state.map(t => {
    if (t != 'all') {
      state_string = state_string + ",'" + stateMappings.find(s => s.stateShort == t)?.stateLong + "'";
    }
  });

  if (state_string != '') {
    state_string = ' and geo.region in ( ' + state_string.replace(',','') + ' )'
  } 
  
  query_string = query_string + state_string; 

  // county  <-- not in GA data currently
  /*   
  let county_string = ''
  filter.county.map(t => {
    if (t != 'all') {
      county_string = county_string + ",'" + t + "'";
    }
  });

  if (county_string != '') {
    county_string = ' and county in ( ' + county_string.replace(',','') + ' )'
  } 
  
  query_string = query_string + county_string; 
  */

  // visit date
  if (dateRange) {
    query_string = query_string + " and visit_date >= '" + dateRange.minDate + "' and visit_date <= '" + dateRange.maxDate + "' ";
  }

  if (query_string != '') {
    query_string = query_string.replace(' and', '');
  }
  else {
    query_string = 'all'
  }

  // headline
  if (headline && headline != 'none'){
    query_string = query_string + " and headline = '" + headline + "' ";
  }

  return query_string
}

export const defaultData = {
  no_of_bars: 2,
  categories: [],
  dataset1: [],
  dataset2: [],
  type: 'percentage',
  colors: [pink, lightpink],
}

export const createNullHeatmapObject = () => {
  let heatData = [];
  stateMappings.map((state) => {
    heatData[state.stateShort] =  0;
  })
  return heatData;
}

export const createHeatmapObject = (data) => {
  let heatData = [];
  stateMappings.map((state) => {
    heatData[state.stateShort] =  (data.find(t => t.state == state.stateShort)?.count || 0);
  })
  return heatData;
}

export const createGAHeatmapObject = (data) => {
  let heatData = [];
  stateMappings.map((state) => {
    heatData[state.stateShort] =  (data.find(t => t.state == state.stateLong)?.count || 0) ;
  })
  return heatData;
}

//import { sortWidgetData } from "../features/currentView/currentViewSlice";
export const createDataObject = (data, allData, fields, type) => {
  let categories = [];
  let dataset1 = [];
  let dataset2 = [];

  // roll up source data 
  if (type == 'source') {
    let newSourceObjects = [];
    let rollupComplete = [];
    let newFieldObjects = [];
    var objIndex = 0;
    var rollup;
    data.map(t => {
      rollup = sourceRollup.find(r => r.source?.toLowerCase() == t.source?.toLowerCase());
      if (rollup && rollupComplete.includes(rollup?.rollup)) {
        objIndex = newSourceObjects.findIndex((obj => obj.source == rollup?.rollup ));
        let newCount = parseInt(newSourceObjects[objIndex].count) + parseInt(t.count);
        let selectType = newSourceObjects[objIndex].select_type;
        newSourceObjects[objIndex] = {select_type: selectType, widget_column: 'traffic_source.source', source: rollup?.rollup, count: newCount}; 
      }
      else {
        rollupComplete.push(rollup?.rollup);
        newSourceObjects.push({select_type: t.select_type, widget_column: 'traffic_source.source', source: rollup?.rollup, count: parseInt(t.count)});
      }
    })
    data = newSourceObjects.sort((a,b) => { return b.count - a.count }).slice(0,10);
    data.map(t => {
      newFieldObjects.push({widget_field: t.source, widget_type: 'source'});
    })
    fields = newFieldObjects; 
  }

  const sumdata1 = data ? data.reduce((partialSum, a) => partialSum + parseInt(a.count), 0) : 1;
  const sumdata2 = allData ? allData.reduce((partialSum, a) => partialSum + parseInt(a.count), 0): 1;

  // sort by widget fields
  const subOrdering = widgetDisplay.find(item => item.widget === type)?.subOrder;

  const getDigits = () => {
    if (type == 'source' ) {
      return 1
    }
    else {
      return 0
    }
  }

  if (data && data[0] && type) {
    // source has to be dynamic by order highest to lowest
    if (type == 'source') {
      data.filter(t => t.source != 'Other').map(t => {
        categories.push(t.source);
        dataset1.push(((t.count * 100)/sumdata1).toFixed(getDigits())); // now that we had to break out source anyway getDigits is not really needed
      })
      data.filter(t => t.source == 'Other').map(t => {
        categories.push(t.source);
        dataset1.push(((t.count * 100)/sumdata1).toFixed(getDigits())); // now that we had to break out source anyway getDigits is not really needed
      })
    }
    else {
      if (subOrdering) {
        subOrdering.map(t => {
          categories.push(t);
          if (data[0] == 'none') {
            dataset1.push(0);
          }
          else {
            dataset1.push(parseFloat(((data?.find(record => record[type] == t)?.count || 0)*100)/sumdata1).toFixed(getDigits()));
          }
          dataset2.push(parseFloat(((allData?.find(record => record[type] == t)?.count || 0)*100)/sumdata2).toFixed(getDigits()));
        })
      }
      else {
        fields && fields.sort((a,b) => a.widget_field.localeCompare(b.widget_field)).map((t) => {
          categories.push(t.widget_field);
          if (data[0] == 'none') {
            dataset1.push(0);
          }
          else {
            dataset1.push(parseFloat(((data?.find(record => record[type] == t.widget_field)?.count || 0)*100)/sumdata1).toFixed(getDigits()));
          }
          dataset2.push(parseFloat(((allData?.find(record => record[type] == t.widget_field)?.count || 0)*100)/sumdata2).toFixed(getDigits()));
        });
      }
    }
    return {
      no_of_bars: 2,
      categories: categories,
      dataset1: dataset1,
      dataset2: dataset2,
      type: 'percentage',
      colors: [pink, lightpink],
    } 
  }
  else {
    return {
      no_of_bars: 2,
      categories: ['no results'],
      dataset1: [0],
      dataset2: [0],
      type: 'percentage',
      colors: [pink, lightpink],
    } 
  }
}


// Likely voter is special - 3 widgets in one
export const createLikelyVoterDataObject = (data, allData, fields, type) => {
  let categories = [];
  let dataset1 = [];
  let dataset2 = [];

  const sumdata1 = data ? data.reduce((partialSum, a) => partialSum + parseInt(a.count), 0) : 1;
  const sumdata2 = allData ? allData.reduce((partialSum, a) => partialSum + parseInt(a.count), 0): 1;

  // sort by widget fields
  const subOrdering = widgetDisplay.find(item => item.widget === type)?.subOrder;

  const getDigits = () => {
    return 0
  }

  const getLabel = (widgetLabel) => {
    if (type == 'general_election_voter' && widgetLabel == 'Y'){ // should all be Y
      return ('General Election');
    }
    if (type == 'likely_presidential_primary_voter' && widgetLabel == 'Y'){ // should all be Y
      return ('Pres. Primary');
    }
    if (type == 'likely_primary_voter' && widgetLabel == 'Y'){ // should all be Y
      return ('Primary');
    }
    return widgetLabel;
  }

  if (data && data[0] && type) {
    if (subOrdering) {
      subOrdering.map(t => {
        categories.push(t);
        if (data[0] == 'none') {
          dataset1.push(0);
        }
        else {
          dataset1.push(parseFloat(((data?.find(record => record[type] == t)?.count || 0)*100)/sumdata1).toFixed(getDigits()));
        }
        dataset2.push(parseFloat(((allData?.find(record => record[type] == t)?.count || 0)*100)/sumdata2).toFixed(getDigits()));
      })
    }
    else {
      fields && fields.filter(t => t.widget_field != 'N').sort((a,b) => a.widget_field.localeCompare(b.widget_field)).map((t) => {
        categories.push(getLabel(t.widget_field));
        if (data[0] == 'none') {
          dataset1.push(0);
        }
        else {
          dataset1.push(parseFloat(((data?.find(record => record[type] == t.widget_field)?.count || 0)*100)/sumdata1).toFixed(getDigits()));
        }
        dataset2.push(parseFloat(((allData?.find(record => record[type] == t.widget_field)?.count || 0)*100)/sumdata2).toFixed(getDigits()));
      });
    }

    return {
      no_of_bars: 2,
      categories: categories,
      dataset1: dataset1,
      dataset2: dataset2,
      type: 'percentage',
      colors: [pink, lightpink],
    } 
  }
  else {
    return {
      no_of_bars: 2,
      categories: ['no results'],
      dataset1: [0],
      dataset2: [0],
      type: 'percentage',
      colors: [pink, lightpink],
    } 
  }
}

export const stateMappings = [
    { stateShort: 'AL' , stateLong:'Alabama'},
    { stateShort: 'AK' , stateLong:'Alaska'},
    { stateShort: 'AZ' , stateLong:'Arizona'},
    { stateShort: 'AR' , stateLong:'Arkansas'},
    { stateShort: 'CA' , stateLong:'California'},
    { stateShort: 'CO' , stateLong:'Colorado'},
    { stateShort: 'CT' , stateLong:'Connecticut'},
    { stateShort: 'DC' , stateLong:'District of Columbia'},
    { stateShort: 'DE' , stateLong:'Delaware'},
    { stateShort: 'FL' , stateLong:'Florida'},
    { stateShort: 'GA' , stateLong:'Georgia'},
    { stateShort: 'HI' , stateLong:'Hawaii'},
    { stateShort: 'ID' , stateLong:'Idaho'},
    { stateShort: 'IL' , stateLong:'Illinois'},
    { stateShort: 'IN' , stateLong:'Indiana'},
    { stateShort: 'IA' , stateLong:'Iowa'},
    { stateShort: 'KS' , stateLong:'Kansas'},
    { stateShort: 'KY' , stateLong:'Kentucky'},
    { stateShort: 'LA' , stateLong:'Louisiana'},
    { stateShort: 'ME' , stateLong:'Maine'},
    { stateShort: 'MD' , stateLong:'Maryland'},
    { stateShort: 'MA' , stateLong:'Massachusetts'},
    { stateShort: 'MI' , stateLong:'Michigan'},
    { stateShort: 'MN' , stateLong:'Minnesota'},
    { stateShort: 'MS' , stateLong:'Mississippi'},
    { stateShort: 'MO' , stateLong:'Missouri'},
    { stateShort: 'MT' , stateLong:'Montana'},
    { stateShort: 'NE' , stateLong:'Nebraska'},
    { stateShort: 'NV' , stateLong:'Nevada'},
    { stateShort: 'NH' , stateLong:'New Hampshire'},
    { stateShort: 'NJ' , stateLong:'New Jersey'},
    { stateShort: 'NM' , stateLong:'New Mexico'},
    { stateShort: 'NY' , stateLong:'New York'},
    { stateShort: 'NC' , stateLong:'North Carolina'},
    { stateShort: 'ND' , stateLong:'North Dakota'},
    { stateShort: 'OH' , stateLong:'Ohio'},
    { stateShort: 'OK' , stateLong:'Oklahoma'},
    { stateShort: 'OR' , stateLong:'Oregon'},
    { stateShort: 'PA' , stateLong:'Pennsylvania'},
    { stateShort: 'RI' , stateLong:'Rhode Island'},
    { stateShort: 'SC' , stateLong:'South Carolina'},
    { stateShort: 'SD' , stateLong:'South Dakota'},
    { stateShort: 'TN' , stateLong:'Tennessee'},
    { stateShort: 'TX' , stateLong:'Texas'},
    { stateShort: 'UT' , stateLong:'Utah'},
    { stateShort: 'VT' , stateLong:'Vermont'},
    { stateShort: 'VA' , stateLong:'Virginia'},
    { stateShort: 'WA' , stateLong:'Washington'},
    { stateShort: 'WV' , stateLong:'West Virginia'},
    { stateShort: 'WI' , stateLong:'Wisconsin'},
    { stateShort: 'WY' , stateLong:'Wyoming'},
]

export const sourceRollup = [
  {source: 'lehighvalleylive.com', rollup: 'lehighvalleylive.com'},
  {source: 'advancelocal.arcpublishing.com', rollup: 'advancelocal.arcpublishing.com'},
  {source: 'annarbor.com', rollup: 'annarbor.com'},
  {source: 'theoregonian.com', rollup: 'theoregonian.com'},
  {source: 'njam', rollup: 'nj.com'},
  {source: 'aol', rollup: 'aol.com'},
  {source: 'ao', rollup: 'aol.com'},
  {source: 'search.aol.com', rollup: 'aol.com'},
  {source: 'aol.com', rollup: 'aol.com'},
  {source: 'appleid.apple.com', rollup: 'Apple'},
  {source: 'bing', rollup: 'Bing'},
  {source: 'cnn.com', rollup: 'cnn.com'},
  {source: 'duckduckgo', rollup: 'DuckDuckGo'},
  {source: 'Sailthru', rollup: 'Email Newsletter'},
  {source: 'sailthru', rollup: 'Email Newsletter'},
  {source: 'facebook', rollup: 'Facebook'},
  {source: 'lm.facebook.com', rollup: 'Facebook'},
  {source: 'm.facebook.com', rollup: 'Facebook'},
  {source: 'l.facebook.com', rollup: 'Facebook'},
  {source: 'fb', rollup: 'Facebook'},
  {source: 'facebook.com', rollup: 'Facebook'},
  {source: 'flipboard', rollup: 'Flipboard'},
  {source: 'flipboard.com', rollup: 'Flipboard'},
  {source: 'google', rollup: 'Google'},
  {source: 'news.google.com', rollup: 'Google'},
  {source: 'google-ads', rollup: 'Google'},
  {source: 'accounts.google.com', rollup: 'Google'},
  {source: 'mail.google.com', rollup: 'Google'},
  {source: 'classroom.google.com', rollup: 'Google'},
  {source: 'instagram', rollup: 'Instagram'},
  {source: 'l.instagram.com', rollup: 'Instagram'},
  {source: 'legacy.com', rollup: 'Legacy'},
  {source: 'm.legacy.com', rollup: 'Legacy'},
  {source: 'linkedin.com', rollup: 'LinkedIn'},
  {source: 'msn.com', rollup: 'MSN'},
  {source: 'newsbreakapp.com', rollup: 'NewsBreak'},
  {source: 'pinterest.com', rollup: 'Pinterest'},
  {source: 'politico.com', rollup: 'Politico'},
  {source: 'reddit.com', rollup: 'Reddit'},
  {source: 'out.reddit.com', rollup: 'Reddit'},
  {source: 'old.reddit.com', rollup: 'Reddit'},
  {source: '247sports.com', rollup: '247sports.com'},
  {source: 'tiderinsider.com', rollup: 'tiderinsider.com'},
  {source: 'detroitlions.com', rollup: 'detroitlions.com'},
  {source: 'foxsports.com', rollup: 'foxsports.com'},
  {source: 'rutgers.forums.rivals.com', rollup: 'rutgers.forums.rivals.com'},
  {source: 'rollbamaroll.com', rollup: 'rollbamaroll.com'},
  {source: 'syracusefan.com', rollup: 'syracusefan.com'},
  {source: 'tidefans.com', rollup: 'tidefans.com'},
  {source: 'fanrecap.com', rollup: 'fanrecap.com'},
  {source: 'espn.com', rollup: 'espn.com'},
  {source: 'njschoolsports.com', rollup: 'njschoolsports.com'},
  {source: 'auburn.forums.rivals.com', rollup: 'auburn.forums.rivals.com'},
  {source: 'twitter', rollup: 'Twitter'},
  {source: 't.co', rollup: 'Twitter'},
  {source: 'en.wikipedia.org', rollup: 'Wikipedia'},
  {source: 'en.m.wikipedia.org', rollup: 'Wikipedia'},
  {source: 'yahoo', rollup: 'Yahoo'},
  {source: 'sports.yahoo.com', rollup: 'Yahoo'},
  {source: 'us.search.yahoo.com', rollup: 'Yahoo'},
  {source: 'my.yahoo.com', rollup: 'Yahoo'},
  {source: 'accounts.youtube.com', rollup: 'YouTube'},
  {source: 'youtube.com', rollup: 'YouTube'},
  {source: 'drudgereport.com', rollup: 'drudgereport.com'},
  {source: 'nextdoor.com', rollup: 'nextdoor.com'},
  {source: 'ground.news', rollup: 'ground.news'},
  {source: 'citizenfreepress.com', rollup: 'citizenfreepress.com'},
  {source: 'on3.com', rollup: 'on3.com'},
  {source: 'smartnews.com', rollup: 'smartnews.com'},
  {source: 'other', rollup: 'Other'}
];
