import React from "react";
import PropTypes from "prop-types";

export default function AudienceStatusFromList({myAudiences, audienceId, isAudiencePage=false}){

  const status =  myAudiences?.find((t) => t.audience_id == audienceId);
  const platformActiveCount =  myAudiences?.filter((t) => t.audience_id == audienceId)
                               .filter((t) => t.activation_status != 'DELETED')?.length;

  const platformWord = () => {
    if (platformActiveCount > 1) { return "platforms"}
    else {return "platform"}
  }

  return (
    <div className='platform_activations'>
      {status && !isAudiencePage && <><i className='fa-solid fa-bolt'></i><p className='audience-status inline bump-right-20'> {platformActiveCount} {platformWord(platformActiveCount)}</p></>}
    </div>
  )

}

export const isActive = (myAudiences, audienceId) => {
  return  myAudiences?.filter((t) => t.audience_id == audienceId)
                               .filter((t) => t.activation_status != 'DELETED')?.length >= 1;
}

AudienceStatusFromList.propTypes =  {
  myAudiences: PropTypes.array,
  audienceId: PropTypes.number,
  isAudiencePage: PropTypes.bool
}