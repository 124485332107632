export const defaultStateList= {
      'AL':0 , 
      'AK':0 , 
      'AZ':0 , 
      'AR':0 , 
      'CA':0 , 
      'CO':0 , 
      'CT':0 , 
      'DC':0 , 
      'DE':0 , 
      'FL':0 , 
      'GA':0 , 
      'HI':0 , 
      'ID':0 ,
      'IL':0 , 
      'IN':0 , 
      'IA':0 , 
      'KS':0 , 
      'KY':0 , 
      'LA':0 , 
      'MA':0 , 
      'MD':0 , 
      'ME':0 , 
      'MI':0 , 
      'MN':0 , 
      'MS':0 ,
      'MO':0 , 
      'MT':0 , 
      'NC':0 , 
      'ND':0 , 
      'NE':0 , 
      'NH':0 , 
      'NV':0 , 
      'NJ':0 , 
      'NM':0 , 
      'NY':0 , 
      'OH':0 , 
      'OK':0 ,
      'OR':0 , 
      'PA':0 , 
      'RI':0 , 
      'SC':0 , 
      'SD':0 , 
      'TN':0 , 
      'TX':0 , 
      'UT':0 , 
      'VA':0 , 
      'VT':0 , 
      'WA':0 , 
      'WV':0 ,
      'WI':0 , 
      'WY':0 
    };

export const fullStateList= {
      'AL':1 , 
      'AK':1 , 
      'AZ':1 , 
      'AR':1 , 
      'CA':1 , 
      'CO':1 , 
      'CT':1 , 
      'DC':1 ,
      'DE':1 , 
      'FL':1 , 
      'GA':1 , 
      'HI':1 , 
      'ID':1 ,
      'IL':1 , 
      'IN':1 , 
      'IA':1 , 
      'KS':1 , 
      'KY':1 , 
      'LA':1 , 
      'MA':1 , 
      'MD':1 , 
      'ME':1 , 
      'MI':1 , 
      'MN':1 , 
      'MS':1 ,
      'MO':1 , 
      'MT':1 , 
      'NC':1 , 
      'ND':1 , 
      'NE':1 , 
      'NH':1 , 
      'NV':1 , 
      'NJ':1 , 
      'NM':1 , 
      'NY':1 , 
      'OH':1 , 
      'OK':1 ,
      'OR':1 , 
      'PA':1 , 
      'RI':1 , 
      'SC':1 , 
      'SD':1 , 
      'TN':1 , 
      'TX':1 , 
      'UT':1 , 
      'VA':1 , 
      'VT':1 , 
      'WA':1 , 
      'WV':1 ,
      'WI':1 , 
      'WY':1 
    };
