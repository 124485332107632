import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../app/api";
import { save } from "./roleSlice";

const initialState = {
  // The access token used to authenticate requests to the API
  accessToken: window.localStorage.getItem("token") || undefined,

  // The authenticated username
  username: window.localStorage.getItem("username") || undefined,

  // The authenticated userId
  userId: window.localStorage.getItem("userId") || undefined,
  
  // The authenticated userRole
  userRole: window.localStorage.getItem("userRole") || undefined,
  
  orgNameString: window.localStorage.getItem("orgNameString") || undefined,
  orgId: window.localStorage.getItem("orgId") || undefined,

  permissions: '',  // do not save permissions in window localStorage

};

export const selectAccessToken = (state) => state.auth.accessToken;
export const selectUserName = (state) => state.auth.username;
export const selectUserId = (state) => state.auth.userId;
export const selectUserRole = (state) => state.auth.userRole;
export const selectOrgNameString = (state) => state.auth.orgNameString;
export const selectOrgId = (state) => state.auth.orgId;
export const selectPermissions = (state) => state.auth.permissions;

// permissions
// all = all permissions
// ro = read only
// aa = activate audience
// uc = ui controls 
// ma = manage audience 
// mu = manage user

export const isPermissionsRO = (state) => {
  if (state.auth.permissions.includes('ro')) {
    return true;
  }
  else {
    return false;
  }
};

export const isPermissionsAA = (state) => {
  if (state.auth.permissions.toLowerCase().includes('all') || state.auth.permissions.toLowerCase().includes('aa')) {
    return true;
  }
  else {
    return false;
  }
};

export const isPermissionsUC = (state) => {
  if (state.auth.permissions.toLowerCase().includes('all') || state.auth.permissions.toLowerCase().includes('uc')) {
    return true;
  }
  else {
    return false;
  }
};

export const isPermissionsMA = (state) => {
  if (state.auth.permissions.toLowerCase().includes('all') || state.auth.permissions.toLowerCase().includes('ma')) {
    return true;
  }
  else {
    return false;
  }
};

export const isPermissionsMU = (state) => {
  if (state.auth.permissions.toLowerCase().includes('all') || state.auth.permissions.toLowerCase().includes('mu')) {
    return true;
  }
  else {
    return false;
  }
};

// Save the access token to local storage
export function saveAccessToken(accessToken) {
  window.localStorage.setItem("token", accessToken);
}

// Save the access token, userId and username to local storage
export function saveCredentials(accessToken, username, userId, userRole, orgNameString, orgId) {
  console.log('saving Credentials',accessToken, username, userId);
  saveAccessToken(accessToken);
  window.localStorage.setItem("username", username);
  window.localStorage.setItem("userId", userId);
  window.localStorage.setItem("userRole", userRole);
  if (orgNameString) {window.localStorage.setItem("orgNameString", orgNameString)};
  if (orgId) {window.localStorage.setItem("orgId", orgId)};
}

// Remove the access token, userId and username from local storage
function removeCredentials() {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("username");
  window.localStorage.removeItem("userId");
  window.localStorage.removeItem("userRole");
  window.localStorage.removeItem("orgNameString");
  window.localStorage.removeItem("orgId");
  window.localStorage.removeItem("override");
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      // This private reducer is not exported. The logout function below is the
      // public interface to log out, which also removes credentials from local
      // storage.
      state.accessToken = undefined;
      state.username = undefined;
      state.userId = undefined;
      state.userRole = undefined;
      state.orgNameString = undefined;
      state.orgId = undefined;
      state.permissions = '';
    },
    savePermissions: (state, action) => {
      const payload = action.payload;
      state.permissions = payload.value;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      // Get the authentication from a successful login response from the API
      console.log('login!', action.payload);
      console.log('login!', action.payload.user);
      state.accessToken = action.payload.access_token;
      state.userId = action.payload.user?.userId;
      state.userRole = action.payload.user?.userRole;
      state.username = action.meta.arg.originalArgs.username;
      state.orgNameString = action.payload.user?.companyName;
      state.orgId = action.payload.user?.orgId;
      state.permissions = action.payload.user?.permissions || '';
    });
    builder.addMatcher(api.endpoints.loginAdmin.matchFulfilled, (state, action) => {
      // Get the authentication from a successful login response from the API
      console.log('login admin!', action.payload);
      state.accessToken = action.payload.access_token;
      state.accessToken = action.payload.user?.userId;
      state.userId = action.payload.user?.userId;
      state.userRole = action.payload.user?.userRole;
      state.username = action.meta.arg.originalArgs.username;
      state.orgNameString = action.payload.user?.companyName;
      state.orgId = action.payload.user?.orgId;
      state.permissions = action.payload.user?.permissions || '';
    });
  }
});

// Log out of the application.
//
// Call this instead of dispatching the logout() action directly, since this
// function also removes the token from the browser local storage.
export function logout() {
  return function logoutThunk(dispatch) {
    removeCredentials();
    dispatch(api.util.resetApiState());
    dispatch(authSlice.actions.logout());
    dispatch(save({type: 'override', value: "NO"}));
    dispatch(save({type: 'firstLogin', value: "NO"}));
  };
}


export const {savePermissions} = authSlice.actions;
export default authSlice.reducer;
