import React from "react";
import Gauge from "../../Widgets/Gauge";
import PropTypes from "prop-types";
import { societalData, clusterSocietalData } from "../../../data/ClusterData";
import { dialData, clusterDialData } from "../../../data/ClusterData";
import { factorData, clusterFactorData} from "../../../data/ClusterData";
import { useNavigate, createSearchParams } from "react-router-dom";
import { clusterDemoData, clusterDemoList } from "../../../data/ClusterData";

export default function Cluster({audience, showFactors=false}) {

  const navigate = useNavigate();
  const audienceDialData = clusterDialData.filter((t) => t.audience == audience.audience);
  const audienceSocietalData = clusterSocietalData.filter((t) => t.audience == audience.audience);
  const audienceFactorData = clusterFactorData.filter((t) => t.audience == audience.audience);
  const audienceDemoData = clusterDemoData.filter((t) => t.audience == audience.audience);
  
  const dials = () => {
    return dialData.map((t,i) => (
      <div key={i}>
        <Gauge dataNum={audienceDialData.find((r) => r.name == t.name)?.value} name={t.name} title={t.display}/>
      </div>

    ))
  }

  const barWithValue = (thisDisplay,thisValue) => {
    return (
      <div className='cluster-bar-view'>
        <h2>{thisDisplay}</h2>
        <div className=''>
          <div className='cluster-bar-wrapper'>
            <p>{thisValue}</p>
            <div className='cluster-bar'>
              <div className='bar-weight' style={{width: (((thisValue * 100)/100) * 125)/100}}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const societalList = () => {
    return societalData.map((t, i) => (
      <div key={i}>
        {barWithValue(t.display, audienceSocietalData.find((r) => r.name == t.name).value )}
      </div> 
    ))
  }

  const minusBar = (thisValue) => {
    if (thisValue < 0) {
      return (
        <div className='bar-weight' style={{width: (((Math.abs(thisValue) * 100)/2) * 60)/100}}/>
      )
    }
  }

  const plusBar = (thisValue) => {
    if (thisValue > 0) {
      return (
        <div className='bar-weight' style={{width: (((thisValue * 100)/2) * 60)/100}}/>
      )
    }
  }

  const factorBarWithValue = (thisDisplay, thisValue) => {
    return (
      <div className='factor-bar-view'>
        <h2>{thisDisplay}</h2>
        <div className='factor-bar minus'>
          {minusBar(thisValue)}
        </div>
        <div className='factor-bar plus'>
          {plusBar(thisValue)}
        </div>
        <p>{thisValue}</p>
      </div>
    )
  }

  const factorList = () => {
    return factorData.map((t, i) => (
      <div key={i}>
        {factorBarWithValue(t.display, audienceFactorData.find((r) => r.name == t.name).value )}
      </div> 
    ))
  }

  const demoDetails = (name) => {
    return audienceDemoData.filter(rec => rec.name == name)
      .map((z,i) => (
        <div key={i}>
          {z.percent ? barWithValue(z.sub, z.value ) : <p className='cluster-demo alone'>{z.value}</p>}
        </div> 
      ))
  }
  
  const demographics = () => {
    return clusterDemoList
      .map((t,i) => (
        <div key={i}>
          <p className='cluster-demo'> {t} </p>
          {demoDetails(t)}
        </div>
      ))
  }


  return (
    <>
      <h4 className=''>{audience.title}</h4>
      <div className='cluster-dials'>
        <div className='dials'>
          {dials()}
        </div>
      </div>
      <p className='cluster_link' onClick={()=>{
        navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: audience.uuid, cView: 0}).toString()});
        //navigate(0);
        }}>
        Explore<br/> {audience.title}
      </p>
      <div className="demographics">
        <h3 className=''>Demographics</h3>
        {demographics()}
      </div>
      <div className='social-attitudes'>
        <h3 className='centered'>Social Attitudes</h3>
        {societalList()}
      </div>            
      <div className={showFactors ? 'factors' : 'factors hidden'}>
        <h3 className='centered'>Factors</h3>
        {factorList()}
      </div>
    </>
  )
}

Cluster.propTypes = {
  audience: PropTypes.object,
  showFactors: PropTypes.bool,
}