import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AudienceStatusFromList from "./AudienceStatusFromList";
import { useGetUserAudiencePlatformsQuery } from "../../app/api";
import { selectUserId } from "../../features/auth/authSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { useNavigate, createSearchParams } from "react-router-dom";
import { isPermissionsRO } from "../../features/auth/authSlice";
import { isPermissionsAA } from "../../features/auth/authSlice";

export default function AudienceStatus({audienceId, audienceUUID, hasFilter, showNewCallback, isCustom, audienceFilter}){

  const hasROpermissions = useSelector(isPermissionsRO);
  const hasAApermissions = useSelector(isPermissionsAA);

  const navigate = useNavigate();

  const userId = useSelector(selectUserId);
  const { data: activations } =  useGetUserAudiencePlatformsQuery({user_id: userId, audience_id: audienceId}, {skip: !audienceId});

  const currentFilters = useSelector(selectWidgetFilters);

  const isActive = () => {
     return  activations?.filter((t) => t.audience_id == audienceId)
                               .filter((t) => t.activation_status == 'ACTIVE')?.length >= 1;
  }

  const ref =  activations?.filter((t) => t.audience_id == audienceId)
                               .find((t) => t.activation_status == 'ACTIVE')?.reference_id;

  

  const searchParams = () => {
    if (isActive()) {
      return createSearchParams({uuid: audienceUUID, ref: ref, custom: isCustom ? 1 : 0}).toString();
    }
    else {
      return createSearchParams({uuid: audienceUUID, custom: isCustom ? 1 : 0}).toString();
    }
  }

  const getButtons = () => {
    if (isCustom) {
      if (JSON.stringify(currentFilters) == audienceFilter) {
        return (
          <>
            <button id="activate_audience_button " className="" onClick={() => { navigate({ pathname: '/dashboard/audience/activate', search: searchParams() }); }}>Activate</button>
          </>
        )
      }
      else {
        return (
          <>
            <button id="activate_audience_button" className="" onClick={() => { navigate({ pathname: '/dashboard/audience/activate', search: searchParams() }) }}>{isActive() ? hasFilter ? 'Activate Original' : 'Activate' : hasFilter ? 'Activate Original' : 'Activate'}</button>
            {hasFilter && <button onClick={()=>{showNewCallback()}} className='save_new_audience_button'>Save New</button>}
          </>
        )
      }
    }
    else {
      return (
        <>
          <button id="activate_audience_button" className="" onClick={() => { navigate({ pathname: '/dashboard/audience/activate', search: searchParams() }) }}>{isActive() ? hasFilter ? 'Activate Original' : 'Activate' : hasFilter ? 'Activate Original' : 'Activate'}</button >
          {hasFilter && <button onClick={() => { showNewCallback() }} className='save_new_audience_button'><p>Save New</p></button>}
        </>
      )
    }
  }

  return (
    <>
      <AudienceStatusFromList myAudiences={activations} audienceId={audienceId}/>
      {(!hasROpermissions || hasAApermissions) && getButtons()}
    </>
  )

}

AudienceStatus.propTypes =  {
  audienceId: PropTypes.number,
  audienceUUID: PropTypes.string,
  hasFilter: PropTypes.bool,
  showNewCallback: PropTypes.func,
  isCustom: PropTypes.bool,
  audienceFilter: PropTypes.object,
}