import React, {useEffect, useState} from "react";
import { useGetHeadlineGroupsQuery } from "../../app/api";
import { useSelector } from "react-redux";
import { useGetHeadlineAthenaGroupsQuery } from "../../app/api";
import { HeadlineTile } from "../UIElements/HeadlineTile";
import { topicMappings } from "../../data/HeadlineData";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import PropTypes from "prop-types";
import { createGAQuery } from "../../data/WidgetData";
import GADateRange from "../UIElements/GADateRange";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import Topics from "./SearchBreakdown/Topics";
import TopicFilter from "../UIElements/TopicFilter";
import Loading from "../UIElements/Loading";
import { selectSearchString } from "../../features/currentView/currentViewSlice";
import ReactPaginate from 'react-paginate'

export default function Insights({useAudience = false, audience_code}) {

  const filter = useSelector(selectWidgetFilters);
  const searchString = useSelector(selectSearchString);

  const {data:headlines}  = useGetHeadlineGroupsQuery();
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const filter_query = createGAQuery(filter, currentHeadlineDateRange);

  const [inTopicString, setInTopicString] = useState('all');
  const [outTopicString, setOutTopicString] = useState('none');
  const hasExplore = inTopicString != 'all' || outTopicString != 'none';

  const [mergedHeadlines, setMergedHeadlines] = useState([]);
  const [headlinesLoading, setHeadlinesLoading] = useState(true);

  const {data: athenaHeadlines ,isFetching: headlinesFetching}  = useGetHeadlineAthenaGroupsQuery({type: 'one-audience', headline: 'all', headline_search: searchString == '' ? 'all': searchString, include: inTopicString, exclude:outTopicString, audience_code: useAudience ? audience_code : 'all', filter_query: filter_query }, {skip: (!headlinesLoading || !currentHeadlineDateRange )});

  const savedInTopics = useSelector(selectInTopics);
  const [inTopics, setInTopics] = useState(savedInTopics);
  
  const savedOutTopics = useSelector(selectOutTopics);
  const [outTopics, setOutTopics] = useState(savedOutTopics);

 /* useEffect(() => {
    refetchHeadlines();
  }, [filter_query]);*/

  useEffect(() => {
    setInTopics(savedInTopics);
  },[savedInTopics]);

  useEffect(() => {
    setOutTopics(savedOutTopics);
  },[savedOutTopics]);

  useEffect(() => {
    if (inTopics.length > 0 && inTopics[0].length > 0 ) {
      let newArray = mergeByTopic(inTopics, topicMappings);
      setInTopicString(newArray.join(",")); 
    }
    else {
      setInTopicString('all'); 
    }

  }, [inTopics]);

  useEffect(() => {
    if (outTopics.length > 0) {
      let newArray = mergeByTopic(outTopics, topicMappings);
      setOutTopicString(newArray.join(",")); 
    }
    else {
      setOutTopicString('none'); 
    }

  }, [outTopics]);

  useEffect( () => {
    setHeadlinesLoading(true);

  }, [inTopicString, outTopicString, filter_query, searchString]);

  useEffect( () => {
      if (headlines && athenaHeadlines && !headlinesFetching) {
        const map = new Map();
        headlines?.forEach(item => map.set(item.headline, item));
        athenaHeadlines?.forEach(item => map.set(item.headline, {...map.get(item.headline), ...item}));
        const mergedArr = Array.from(map.values());
        setMergedHeadlines(mergedArr.filter(t => t.all_count)); // only list headlines that are active from athena   

        setHeadlinesLoading(false);
      }
  }, [headlines, athenaHeadlines]);

  // Pagination of headlines
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(mergedHeadlines?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % mergedHeadlines?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
      setItemOffset(newOffset);
  };

  const headlineList = () => {
    if (headlinesLoading) {
      return (
        <>
          <Loading margin={true}/>
        </>
      )
    }
    else {
      if (mergedHeadlines.length > 0) {
        let arrayForSort = [...mergedHeadlines];
        arrayForSort = hasExplore ? arrayForSort.filter(t => t.topics) : arrayForSort;
        return arrayForSort?.sort((a,b) => parseInt(a.all_count) > parseInt(b.all_count) ? -1 : 1)
          .slice(itemOffset,endOffset)
          .map((t,i) => (
            <HeadlineTile key={i} headline={t} grouped={true} />
          ));
      }
      else {
        return <p>No Results.</p>
      }
    }
  }


  return (
    <>
      <div className='client_dashboard_view_header standard'>
        <div className='client_dashboard_view_header_wrapper'>
          { !useAudience ? <h3>Insights Into What is Trending</h3> : <h3></h3>}
          { useAudience ? <GADateRange disableRO={false}/> : <div className='date-range-wrapper'> <GADateRange disableRO={true}/> </div> }
        </div>
      </div>    
      <div className={useAudience? "client_dashboard_view_content maptree_dashboard_view_scroll no_padding" : 'client_dashboard_view_content content_menu_wrapper'}>
        {useAudience && <Topics audience_code = {audience_code}  />}
        {!useAudience &&
          <div className='client_dashboard_view_content_menu'>
            {!useAudience && <TopicFilter audience_code = {audience_code} /> }
          </div>
        }
        <div className={useAudience? '' : 'client_dashboard_view_content_view_with_menu no_padding_view'}>  
          {useAudience && <h3 className='topic_headlines'>Headlines</h3>}

          {headlineList()}
          {!headlinesLoading && <div className='paginate_bar'>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </div>
          } 
        </div>
      </div>
    </>
  )

}

export const mergeByTopic = (topics, topicMappings) => {
    let newArray = [];
    topics.map(t => {
      topicMappings.filter(r=> r.category == t)
      .map(r => {
        newArray.push(r.affinity_topic);
      })
    });
    return newArray; 
  }

Insights.propTypes = {
  useAudience: PropTypes.bool,
  audience_code: PropTypes.string,
}
