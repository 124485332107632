import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { saveDirectory, selectClientPage } from "../../features/directory/directorySlice";
import { saveCurrentView, selectShowFeedbackForm } from "../../features/currentView/currentViewSlice";
import { saveAudience } from "../../features/audiences/audienceSlice";
import { useNavigate } from "react-router-dom";
import { clearFilters } from "../../features/currentView/currentViewSlice";

export default function ClientMenu() {

  const dispatch = useDispatch();
  const clientPage = useSelector(selectClientPage);
  const showFeedbackForm = useSelector(selectShowFeedbackForm);

  const navigate = useNavigate();

  const clearFiltersNow = () => {
    dispatch(clearFilters());
  }


  const isSelectedMenu = (classList, pageName) => {
    if (pageName==clientPage) {
      return classList + ' selected';
    } 
    else {
      return classList;
    }
  }

  // #TODO:  Send clientPage as path route so back button will work

  return (
    <div className="client_dashboard_menu">
      <div className="client_dashboard_menu_page_navs">
        <div className={isSelectedMenu('client-menu-option top','client-search')} onClick={()=> {
          dispatch(saveAudience({type:'selected', value: {title: '', audience: ''}}));
          dispatch(saveDirectory({type:'clientPage', value:'client-search'}));
          clearFiltersNow();
          navigate('/dashboard');
          }}>
            <i className='fa-solid fa-lightbulb' />
            <p>Home</p>
        </div>
        <div className={isSelectedMenu('client-menu-option','audience-builder')} onClick={()=> {
          dispatch(saveCurrentView({type: 'audienceTab', value:'compare'}));
          dispatch(saveDirectory({type:'clientPage', value:'audience-builder'}));
          clearFiltersNow();
          navigate('/dashboard');
          }}>
            <i className='fa-solid fa-users' />
            <p>Audiences</p>
        </div>
        <div className={isSelectedMenu('client-menu-option','insights')} onClick={()=> {
          dispatch(saveCurrentView({type: 'audienceTab', value:'insights'}));
          dispatch(saveDirectory({type:'clientPage', value:'insights'}));
          clearFiltersNow();
          navigate('/dashboard/insights');
          }}>
            <i className='fa-solid fa-microphone' />
            <p>Readership</p>
        </div>
        <div className={isSelectedMenu('client-menu-option','audience')} onClick={()=> {
          dispatch(saveDirectory({type:'clientPage', value:'audience'}));
          clearFiltersNow();
          navigate('/dashboard/your-audiences');
          }}>
            <i className='fa-solid fa-bolt' />
            <p>Activations</p>
        </div>
        {/* <div className={isSelectedMenu('client-menu-option', 'ai') } onClick={() => {
          dispatch(saveDirectory({ type: 'clientPage', value: 'ai' }));
          clearFiltersNow();
          navigate('/dashboard/ai');
          }}>
            <i className='fa-solid fa-message' />
            <p>Q&A</p>
        </div>        */}
        <div className={isSelectedMenu('client-menu-option','setting')} onClick={()=> {
          dispatch(saveDirectory({type:'clientPage', value:'setting'}));
          clearFiltersNow();
          navigate('/dashboard');
          }}>
            <i className='fa-solid fa-cogs' />
            <p>Account</p>
        </div>
        <div className={isSelectedMenu('client-menu-option','methodology')} onClick={()=> {
          dispatch(saveDirectory({type:'clientPage', value:'methodology'}))
          navigate('/dashboard');
          }}>
            <i className='fa-solid fa-info-circle' />
            <p>Info</p>
        </div>
      </div>
      <div className="client_dashboard_menu_page_links">
        <ul>
          <li onClick={()=> dispatch(saveCurrentView({type:'showFeedbackForm', value: !showFeedbackForm}))}>
            <i className='fa-solid fa-message-pen' />
          </li>
          <li onClick={() => dispatch(logout())}>  
            <i className='fa-solid fa-sign-out' />
          </li>
        </ul>
      </div>
    </div>
  )
}
