import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { selectSearchString } from "../../../features/currentView/currentViewSlice";
import Cluster from "./Cluster"
import PropTypes from "prop-types";
import { useGetSelectAllAudiencesQuery } from "../../../app/api";

export default function ClusterView({audience, anyUUID=false, showFactors=false}) {

  const [audienceListData, setAudienceListData] = useState([]);
  const { data: audienceData, isSuccess: audienceSuccess } =  useGetSelectAllAudiencesQuery();
  const searchString = useSelector(selectSearchString);


  useEffect( () => {
    if (audienceSuccess) {
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess] );

  // show selected audience first in cluster list
  const thisCluster = () => {
      return audienceListData
      .filter((t) => t.cluster == 1)
      .filter((t) => t.audience == audience?.audience)
      .map((t,i) => (
        <li key={i}>
          <div className={anyUUID ? false : t.audience == audience?.audience   ? 'cluster-display background-cinq-light-yellow-see-through': 'cluster-display'} key={i} >
            <Cluster key={i} audience={{title: t.title, uuid: t.uuid, audience: t.audience}} showFactors={showFactors}/>
          </div>
        </li>
      ))
  }

  const multiClusters = () => {
      return audienceListData
      .filter((t) => t.cluster == 1)
      .filter((t) => t.audience != audience?.audience)
      .filter(t => t.title.toLowerCase().includes(searchString.toLowerCase()))
      .map((t,i) => (
        <li key={i}>
          <div className={anyUUID ? false : t.audience == audience?.audience   ? 'cluster-display background-cinq-light-yellow-see-through': 'cluster-display'}>
            <Cluster key={i} audience={{title: t.title, uuid: t.uuid, audience: t.audience}} showFactors={showFactors}/>
          </div>
        </li>
      ))
  }

  const clusters = () => {
    if (anyUUID) {
      return audienceListData
      .filter((t) => t.cluster == 1)
      .map((t,i) => (
        <li className={anyUUID ? false : t.audience == audience?.audience   ? 'cluster-td background-cinq-light-yellow-see-through': 'cluster-td'} key={i} >
         <Cluster key={i} audience={{title: t.title, uuid: t.uuid, audience: t.audience}} showFactors={showFactors}/>
        </li>
      ))
    }
    else {
      return (
        <>
          {thisCluster()}
          {multiClusters()}
        </>
      );
    }
    
  }

  return (
    <div className="cluster_display_wrapper">
      <ul className='cluster-table'>
        {clusters()}
      </ul>
    </div>
  )
}

ClusterView.propTypes =  {
  audience: PropTypes.object,
  setAudienceClusterFirst: PropTypes.func,
  setAudienceUUID: PropTypes.func,
  anyUUID: PropTypes.bool,
  showFactors: PropTypes.bool,
}