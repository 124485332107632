import React from "react";
import PropTypes from "prop-types";

import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { useDispatch, useSelector } from "react-redux";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";

import { isPermissionsRO } from "../../features/auth/authSlice";

// type == 'add' or 'remove' or 'display'
export default function CategoryButton({label, type, noHover}) {

  const hasROpermissions = useSelector(isPermissionsRO);

  const inTopics = useSelector(selectInTopics);
  const outTopics = useSelector(selectOutTopics);
  const dispatch = useDispatch();

  const action = () => {
    if (!hasROpermissions) {
      switch (type) {
        case 'add':
          return <i className='fa-solid fa-plus'></i>
        case 'out':
          return <i className='fa-solid fa-times'></i>
        case 'remove':
          return <i className='fa-solid fa-times'></i>
        default: 
          return <></>
      }
    }
  }

  const adjustInTopics = () => {
    if (!hasROpermissions) {
      let newArray = [...inTopics];
      let index = newArray.indexOf(label);
      if(index !== -1) { // exists
        newArray.splice(index,1);
        dispatch(saveCurrentView({type:'inTopics', value: newArray}));
      }
      else { // add
        newArray = [...inTopics, label];
        dispatch(saveCurrentView({type:'inTopics', value: newArray}));
      }
    }
  }

  const adjustOutTopics = () => {
    if (!hasROpermissions) {
      let newArray = [...outTopics];
      let index = newArray.indexOf(label);
      if(index !== -1) { // exists
        newArray.splice(index,1);
        dispatch(saveCurrentView({type:'outTopics', value: newArray}));
      }
      else { // add
        newArray = [...outTopics, label];
        dispatch(saveCurrentView({type:'outTopics', value: newArray}));
      }
    }
  }

  return (
    <>
    { type == 'out' ? 
      <div onClick={() => {adjustOutTopics()}} className={(type=='display' || noHover) ? 'category-button background-red text-white no-hover' : 'category-button background-red text-white'}>
        {action()} 
        {label}
      </div>
      :
      <div onClick={() => {adjustInTopics()}} className={(type=='display' || noHover) ? 'category-button no-hover' : 'category-button'}>
        {action()} 
        {label}
      </div>
    }
    </>
  )
}

CategoryButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  noHover: PropTypes.bool,
  inTopics: PropTypes.array,
}