import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {downloadCSVTimestamp, downloadCSVFullTimestamp, formatAudienceTitle} from "../userSettings"; 
import { useSelectGAWidgetDataQuery } from "../../app/api";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createDataObject } from "../../data/WidgetData";
import { selectWidgetFields } from "../../features/currentView/currentViewSlice";
// GA data for corresponding widgets
import { createGAQuery, gaWidgetSelect, gaWidgetGroupBy } from "../../data/WidgetData";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { sourceRollup } from "../../data/WidgetData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import { topicMappings } from "../../data/HeadlineData";

/*  
Readership Insights 

Age - age_range_standard
Ethnicity - ethnicity
Gender - gender
Household Income - home_owner_or_renter
Party - party
Marital Status - marital_status
Presence of Children - presence_of_children
Likely Voter - likely_primary_voter 
Likely Voter - likely_presidential_primary_voter 
Likely Voter - general_election_voter 
Media Source - source
*/

export default function CompleteGAWidgetCSVDownloadLink({selectedAudience, headlineFilter='none', setRunCSVDownloader}){
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = useSelector(selectWidgetFields);
  const currentDateRange = useSelector(selectCurrentHeadlineDateRange);
  const gaQueryString = createGAQuery(filter, currentDateRange, headlineFilter);
  const savedTopics = useSelector(selectInTopics);
  const savedOutTopics = useSelector(selectOutTopics);

  const mediaSourceFields = () => { 
    let newArray = [];
    sourceRollup.map(t => {
      newArray.push(t.source);
    });
    return newArray;
  }

  const formatTopicsForQuery = (topics) => {
    if (topics.length > 0 && topics[0].length > 0 ) {
      let newArray = mergeByTopic(topics, topicMappings);
      return newArray.join(",") || 'all'
    }
  }

  // Data queries

  //Age - age_range_standard 
  const{data: ageWidgetData, isSuccess: ageSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('age_range_standard'),
    group_by_query_string: gaWidgetGroupBy('age_range_standard'),
    widget_type: 'age_range_standard', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Age - age_range_standard
  const{data: ageAllWidgetData, isSuccess: ageAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('age_range_standard'),
    group_by_query_string: gaWidgetGroupBy('age_range_standard'),
    widget_type: 'age_range_standard', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });


  //Ethnicity - ethnicity 
  const{data: ethnicityWidgetData, isSuccess: ethnicitySuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('ethnicity'),
    group_by_query_string: gaWidgetGroupBy('ethnicity'),
    widget_type: 'ethnicity', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Ethnicity - ethnicity
  const{data: ethnicityAllWidgetData, isSuccess: ethnicityAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('ethnicity'),
    group_by_query_string: gaWidgetGroupBy('ethnicity'),
    widget_type: 'ethnicity', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });    
   
    
  //Gender - gender 
  const{data: genderWidgetData, isSuccess: genderSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('gender'),
    group_by_query_string: gaWidgetGroupBy('gender'),
    widget_type: 'gender', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Gender - gender
  const{data: genderAllWidgetData, isSuccess: genderAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('gender'),
    group_by_query_string: gaWidgetGroupBy('gender'),
    widget_type: 'gender', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });        
 
    
  //Household Income - income_range 
  const{data: householdIncomeWidgetData, isSuccess: householdIncomeSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('income_range'),
    group_by_query_string: gaWidgetGroupBy('income_range'),
    widget_type: 'income_range', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Household Income - income_range
  const{data: householdIncomeAllWidgetData, isSuccess: householdIncomeAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('income_range'),
    group_by_query_string: gaWidgetGroupBy('income_range'),
    widget_type: 'income_range', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });        
  

  // Party - party 
  const{data: partyWidgetData, isSuccess: partySuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('party'),
    group_by_query_string: gaWidgetGroupBy('party'),
    widget_type: 'party', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Party - party
  const{data: partyAllWidgetData, isSuccess: partyAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('party'),
    group_by_query_string: gaWidgetGroupBy('party'),
    widget_type: 'party', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });        
      

  //Marital Status - marital_status 
  const{data: maritalStatusWidgetData, isSuccess: maritalStatusSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('marital_status'),
    group_by_query_string: gaWidgetGroupBy('marital_status'),
    widget_type: 'marital_status', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Marital Status - marital_status
  const{data: maritalStatusAllWidgetData, isSuccess: maritalStatusAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('marital_status'),
    group_by_query_string: gaWidgetGroupBy('marital_status'),
    widget_type: 'marital_status', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });               


  //Presence of Children - presence_of_children 
  const{data: presenceOfChildrenWidgetData, isSuccess: presenceOfChildenSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('presence_of_children'),
    group_by_query_string: gaWidgetGroupBy('presence_of_children'),
    widget_type: 'presence_of_children', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Presence of Children - presence_of_children
  const{data: presenceOfChildrenAllWidgetData, isSuccess: presenceOfChildenAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('presence_of_children'),
    group_by_query_string: gaWidgetGroupBy('presence_of_children'),
    widget_type: 'presence_of_children', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });               


 //Media Source - source 
  const{data: mediaSourceWidgetData, isSuccess: mediaSourceSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('source'),
    group_by_query_string: gaWidgetGroupBy('source'),
    widget_type: 'source', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: mediaSourceFields(),
  });

  //All Media Source - source
  const{data: mediaSourceAllWidgetData, isSuccess: mediaSourceAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('source'),
    group_by_query_string: gaWidgetGroupBy('source'),
    widget_type: 'source', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: mediaSourceFields(),
  });      


  //Likely Voter - likely_primary_voter 
  const {data: primaryWidgetData, isSuccess: primarySuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('likely_primary_voter'),
    group_by_query_string: gaWidgetGroupBy('likely_primary_voter'),
    widget_type: 'likely_primary_voter', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_coded,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Likely Voter - likely_primary_voter 
  const {data: primaryAllWidgetData, isSuccess: primaryAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('likely_primary_voter'),
    group_by_query_string: gaWidgetGroupBy('likely_primary_voter'),
    widget_type: 'likely_primary_voter', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });    
  

  //Likely Voter - likely_presidential_primary_voter 
  const {data: presidentialWidgetData, isSuccess: presidentialSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('likely_presidential_primary_voter'),
    group_by_query_string: gaWidgetGroupBy('likely_presidential_primary_voter'),
    widget_type: 'likely_presidential_primary_voter', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Likely Voter - likely_presidential_primary_voter 
  const {data: presidentialAllWidgetData, isSuccess: presidentialAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('likely_presidential_primary_voter'),
    group_by_query_string: gaWidgetGroupBy('likely_presidential_primary_voter'),
    widget_type: 'likely_presidential_primary_voter', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });


  //Likely Voter - general_election_voter 
  const {data: generalWidgetData, isSuccess: generalSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('general_election_voter'),
    group_by_query_string: gaWidgetGroupBy('general_election_voter'),
    widget_type: 'general_election_voter', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code,
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });  

  //Likely Voter - general_election_voter     
  const {data: generalAllWidgetData, isSuccess: generalAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('general_election_voter'),
    group_by_query_string: gaWidgetGroupBy('general_election_voter'),
    widget_type: 'general_election_voter', 
    audience_code: 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  }); 


  useEffect(() => {
    if (  ageSuccess && ageAllSuccess
       && ethnicitySuccess && ethnicityAllSuccess
       && genderSuccess && genderAllSuccess
       && householdIncomeSuccess && householdIncomeAllSuccess
       && partySuccess && partyAllSuccess
       && maritalStatusSuccess && maritalStatusAllSuccess
       && presenceOfChildenSuccess && presenceOfChildenAllSuccess
       && primarySuccess && primaryAllSuccess
       && presidentialSuccess && presidentialAllSuccess
       && generalSuccess && generalAllSuccess
       && mediaSourceSuccess && mediaSourceAllSuccess
    ) {
        downloadCsv();
    }}, [ageWidgetData, ageAllWidgetData, 
      ethnicityWidgetData, ethnicityAllWidgetData,
      genderWidgetData, genderAllWidgetData,
      householdIncomeWidgetData, householdIncomeAllWidgetData,
      partyWidgetData, partyAllWidgetData,
      maritalStatusWidgetData, maritalStatusAllWidgetData,
      presenceOfChildrenWidgetData, presenceOfChildrenAllWidgetData,
      primaryWidgetData, primaryAllWidgetData,
      presidentialWidgetData, presidentialAllWidgetData,
      generalWidgetData, generalAllWidgetData,
      mediaSourceWidgetData, mediaSourceAllWidgetData]
  );


  /* 
    const logger = () => {
    console.log("Age: " + JSON.stringify(ageWidgetData));
    console.log("Age All: " + JSON.stringify(ageAllWidgetData));    
    console.log("Ethnicity: " + JSON.stringify(ethnicityWidgetData));
    console.log("Ethnicity All: " + JSON.stringify(ethnicityAllWidgetData));
    console.log("Gender: " + JSON.stringify(genderWidgetData));
    console.log("Gender All: " + JSON.stringify(genderAllWidgetData));
    console.log("Household Income: " + JSON.stringify(householdIncomeWidgetData))
    console.log("Household Income All: " + JSON.stringify(householdIncomeAllWidgetData));
    console.log("Party: " + JSON.stringify(partyWidgetData));
    console.log("Party All: " + JSON.stringify(partyAllWidgetData));
    console.log("Marital Status: " + JSON.stringify(maritalStatusWidgetData));
    console.log("Marital Status All: " + JSON.stringify(maritalStatusAllWidgetData));
    console.log("Presence of Children: " + JSON.stringify(presenceOfChildrenWidgetData));
    console.log("Presence of Children All: " + JSON.stringify(presenceOfChildrenAllWidgetData));
    console.log("Likely Primary Voter: " + JSON.stringify(primaryWidgetData));
    console.log("Likely Primary Vote All: " + JSON.stringify(primaryAllWidgetData));
    console.log("Likely Presidential Primary Voter: " + JSON.stringify(presidentialWidgetData));
    console.log("Likely Presidential Primary Voter All: " + JSON.stringify(presidentialAllWidgetData));   
    console.log("Likely General Election Voter: " + JSON.stringify(generalWidgetData));
    console.log("Likely General Election Voter All: " + JSON.stringify(generalAllWidgetData));
    console.log("Media Source: " + JSON.stringify(mediaSourceWidgetData));
    console.log("Media Source All: " + JSON.stringify(mediaSourceAllWidgetData));
  } 
  */

  const buildWidgetData = (widgetData, allWidgetData, type) => {
    const newDataObject = createDataObject(widgetData, allWidgetData, widgetFields.filter(t => t.widget_type == type), type);
    return newDataObject;
  }

  const buildData = () => {
    let completeWidgetData = [];
    completeWidgetData.push(['Age', buildWidgetData(ageWidgetData, ageAllWidgetData, 'age_range_standard')]);
    completeWidgetData.push(['Ethnicity', buildWidgetData(ethnicityWidgetData, ethnicityAllWidgetData, 'ethnicity')]); 
    completeWidgetData.push(['Gender', buildWidgetData(genderWidgetData, genderAllWidgetData, 'gender')]);  
    completeWidgetData.push(['Household Income', buildWidgetData(householdIncomeWidgetData, householdIncomeAllWidgetData, 'income_range')]);  
    completeWidgetData.push(['Party', buildWidgetData(partyWidgetData, partyAllWidgetData, 'party')]);  
    completeWidgetData.push(['Marital Status', buildWidgetData(maritalStatusWidgetData, maritalStatusAllWidgetData, 'marital_status')]);  
    completeWidgetData.push(['Presence of Children', buildWidgetData(presenceOfChildrenWidgetData, presenceOfChildrenAllWidgetData, 'presence_of_children')]);  
    completeWidgetData.push(['Likely Voter', '']); // Place holder to build custom Likely Voter
    completeWidgetData.push(['Media Source', buildWidgetData(mediaSourceWidgetData, mediaSourceAllWidgetData, 'source')]); 
    return completeWidgetData;
  }

  const buildLikelyVoterData = () => {
    let completeLikelyVoterWidgetData = [];
    completeLikelyVoterWidgetData.push(['Primary', buildWidgetData(primaryWidgetData, primaryAllWidgetData, 'likely_primary_voter')]); 
    completeLikelyVoterWidgetData.push(['Pres. Primary', buildWidgetData(presidentialWidgetData, presidentialAllWidgetData, 'likely_presidential_primary_voter')]); 
    completeLikelyVoterWidgetData.push(['General Election', buildWidgetData(generalWidgetData, generalAllWidgetData, 'general_election_voter')]); 
    return completeLikelyVoterWidgetData;
  } 

  const downloadCsv = () => {
    let completeWidgetData = buildData();
    let completeLikelyVoterData = buildLikelyVoterData();
    //logger();
    let downloadTimestamp = downloadCSVTimestamp();
    let downloadFullTimestamp = downloadCSVFullTimestamp();
    let result  = "Readership Insights - " + formatAudienceTitle(selectedAudience.title) + "\n";
    result += "Date: ," + downloadFullTimestamp + "\n\n";
    result += "Category:," + selectedAudience.title + "\n\n";

    for (let i = 0; i < completeWidgetData.length; i++) {
      result += 'Demographic: ' + completeWidgetData[i][0] + '\n';
      if (completeWidgetData[i][0] == 'Likely Voter') { // Account for custom Likely Voter data with order structure
        for (let k = 0; k < completeLikelyVoterData.length; k++) {
          if (completeLikelyVoterData[k][1]['categories'].length > 1) { // Primary and Presidential Primary return 2 categories: N & Y
            result += `"${completeLikelyVoterData[k][0]}", ${completeLikelyVoterData[k][1]['dataset1'][1]}%\n`;
          }
          else { // General election only returns 1 category - Y
            result += `"${completeLikelyVoterData[k][0]}", ${completeLikelyVoterData[k][1]['dataset1'][0]}%\n`;
          }
        }
      } else { // Format for standard data 
        for (let j = 0; j < completeWidgetData[i][1]['dataset1'].length; j++) {
          result += `"${completeWidgetData[i][1]['categories'][j]}", ${completeWidgetData[i][1]['dataset1'][j]}%\n`;
        }
      }
      result += '\n';
    }

    const url = window.URL.createObjectURL(new Blob([result])); 
    const link = document.createElement('a');
    link.href = url;

    const fileName = "Readership Insights - " + formatAudienceTitle(selectedAudience.title) + ' - ' + downloadTimestamp +".csv";
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setRunCSVDownloader(false);
  }

  return (
    <>
    </>
  );
}

CompleteGAWidgetCSVDownloadLink.propTypes = {
  selectedAudience: PropTypes.object,
  headlineFilter: PropTypes.string,
  setRunCSVDownloader: PropTypes.func
}