import PropTypes from "prop-types";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import { widgetDisplay } from "../../data/WidgetData";
import { selectIsStateFiltered, selectNoFilters, selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import FilterButton from "./FilterButton";
const WidgetFilter = React.lazy(() => import( "../Widgets/WidgetFilter"));

export default function Filter({selectedAudience}) {

  const noFilters = useSelector(selectNoFilters); 
  const isStateFiltered = useSelector(selectIsStateFiltered); 
  const [universeOpen, setUniverseOpen] = useState(false);

  const universeHeader = (t) => {
    if (t.headerTop == 'universe' ) {
      calibrateUniverse();
      return ( 
        <div className='likely_voter_universe_filter_primary_wrapper'>
          <i className={universeOpen? 'fa-solid fa-minus' : 'fa-solid fa-plus'}></i>
          <p className='filter-header' onClick={() => { setUniverseOpen(universeOpen? false : true); toggleUniverse();}}>Likely Voter Universes</p>
        </div>
      )
    }
    else {
      return (<></>)
    }
  }

  const calibrateUniverse = () => {
    if (universeOpen) {
      const u = document.getElementsByClassName('universe');

      for (var i = 0; i < u.length; i++ ) {
        u[i].classList.add('show');
      }
    }
  }
  const toggleUniverse = () => {
    // todo: change to react way
    const u = document.getElementsByClassName('universe');
    const general_election_voter = document.getElementsByClassName('general_election_voter');
    const likely_presidential_primary_voter = document.getElementsByClassName('likely_presidential_primary_voter');
    const likely_primary_voter = document.getElementsByClassName('likely_primary_voter');
    for (var i = 0; i < u.length; i++ ) {
      if (u[i].classList.contains('show')) {
        u[i].classList.remove('show');
        general_election_voter[0].firstChild.classList.add('hidden');
        likely_presidential_primary_voter[0].firstChild.classList.add('hidden');
        likely_primary_voter[0].firstChild.classList.add('hidden');
        console.log(general_election_voter + likely_presidential_primary_voter + likely_primary_voter);
      }
      else {
        u[i].classList.add('show');
        if (general_election_voter[0].firstChild.classList.contains('hidden')) {
          general_election_voter[0].firstChild.classList.remove('hidden');
        }
        if (likely_presidential_primary_voter[0].firstChild.classList.contains('hidden')) {
          likely_presidential_primary_voter[0].firstChild.classList.remove('hidden');
        }
        if (likely_primary_voter[0].firstChild.classList.contains('hidden')) {
          likely_primary_voter[0].firstChild.classList.remove('hidden');
        }
      }
    }
  }

  const widgetList = 
    widgetDisplay
    .filter((t)=> t.widget != 'county')
    .filter((t)=> t.widget != 'us_congressional_district')
    .filter((t)=> t.widget != 'state_senate_district')
    .filter((t)=> t.widget != 'state_house_district')
    .map((t,i) => (
      <div key={i}>
        {universeHeader(t)}
        <WidgetFilter selectedAudience={selectedAudience} type={t.widget} headerClass={t.header || ''} />
      </div>
    ));


  const widgetWithStateList = 
    widgetDisplay
    .filter((t)=> t.widget != 'county')
    .filter((t)=> t.widget != 'us_congressional_district')
    .filter((t)=> t.widget != 'state_senate_district')
    .filter((t)=> t.widget != 'state_house_district')
    .map((t,i) => (
      <div key={i}>
        {universeHeader(t)}
        <WidgetFilter selectedAudience={selectedAudience} type={t.widget} headerClass={t.header || ''}/>
      </div>
    ));

  const widgetFilter = 
    <div>
      <h3 className=' hidden'> FILTER ({noFilters}) </h3>
      <p className='filter-header no-hover' >Demographics</p>
      {isStateFiltered ? widgetWithStateList : widgetList}
    </div>

  return (
      <div className='full-page ' key={noFilters}>
        {widgetFilter}
      </div>
  ) 
}

export const widgetFilterList = () => {
  const filter = useSelector(selectWidgetFilters);

  const buttonEdit = (t,type,i) => {
    const display = widgetDisplay.find(t => t.widget == type)
    return (
      <FilterButton key={i} label={display.displayName + ': ' + t} filter={filter} type={type} baseLabel={t}/>
    )
  }
  const widgetFilterListString = (type) => {
    
    if (filter[type]?.length >= 0) {
      return filter[type].filter((t) => t != 'all').map((t,i) => ( 
        buttonEdit(t,type,i)
      ))
    }

  }
  
  const widgetFilterLists = 
    widgetDisplay
    .map((t,i) => (
      <div className='audience-state-filter-buttons-wrapper' key={i}>
        {widgetFilterListString(t.widget)}
      </div>
    ) );

  return (
    <>
      {widgetFilterLists}
    </>
  )
}

export const widgetFilterListDisplay = () => {
  const filter = useSelector(selectWidgetFilters);

  const widgetFilterListString = (type) => {
    
    if (filter[type]?.length >= 0) {
      return filter[type].filter((t) => t != 'all').map((t,i) => ( 
        <p key={i}> <b>{type}</b>: {t.replaceAll('|',',')} </p>
      ))
    }

  }
  
  const widgetFilterLists = 
    widgetDisplay
    .map((t,i) => (
      <div key={i}>
        {widgetFilterListString(t.widget)}
      </div>
    ) );

  return (
    <>
      {widgetFilterLists}
    </>
  )
}

Filter.propTypes = {
  selectedAudience: PropTypes.object,
}