import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as maptilersdk from '@maptiler/sdk';
import "@maptiler/sdk/dist/maptiler-sdk.css";
//import { statesWithCoords } from './states';
import { useSelectGARegionDataQuery } from '../../../app/api';
import { selectCurrentHeadlineDateRange } from '../../../features/audiences/audienceSlice';
import { selectWidgetFilters } from '../../../features/currentView/currentViewSlice';
import { useGeoEntryByStateShortNameQuery } from '../../../app/api';
import { selectInTopics } from '../../../features/currentView/currentViewSlice';
import { createGAQuery } from '../../../data/WidgetData';
import { mergeByTopic } from '../../ClientDashboard/Insights';
import { topicMappings } from '../../../data/HeadlineData';
import { stateMappings } from '../../../data/WidgetData';
import PropTypes from "prop-types";

export default function GAUSADensityMap({selectedAudience}) {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const usa  = { lng: -94.28, lat: 38.45 };
  const [zoom] = useState(3);
  maptilersdk.config.apiKey = 'AM2VpZpbcGT2iWDZIu8n';

  const filter = useSelector(selectWidgetFilters);
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const savedTopics = useSelector(selectInTopics);
  const [inTopicString, setInTopicString] = useState('all');
  const [outTopicString] = useState('none');
  const [gaQueryString, setGaQueryString] = useState(createGAQuery(filter, currentHeadlineDateRange));

  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [geoLoaded, setGeoLoaded] = useState(false);
  const [zoomedState, setZoomedState] = useState({});

  const {data: cinqStateData} = useSelectGARegionDataQuery({
    type: 'state',
    filter_query_string: gaQueryString,
    audience_code: selectedAudience?.ga_code || selectedAudience?.base_ga_code,
    include: inTopicString,
    exclude: outTopicString
    }, 
    {skip: !selectedAudience, refetchOnMountOrArgChange: true});

  const {data: cinqCityData, refetch: runCityData} = useSelectGARegionDataQuery({
    type: 'city',
    filter_query_string: gaQueryString,
    audience_code: selectedAudience?.ga_code || selectedAudience?.base_ga_code,
    include: inTopicString,
    exclude: outTopicString
    }, 
    {skip: !selectedAudience || !zoomedState?.name, refetchOnMountOrArgChange: true});

  const {data: singleStateData, isSuccess: singleStateDataSuccess, refetch: refetchGeo} = useGeoEntryByStateShortNameQuery({shortName: filter.state}, {skip: filter.state == 'all', refetchOnMountOrArgChange: true});

  var hoveredStateId = null;

  // GA only
  useEffect(() => {
    if (savedTopics.length > 0 && savedTopics[0].length > 0 ) {
      let newArray = mergeByTopic(savedTopics, topicMappings);
      setInTopicString(newArray.join(",")); 
    }
    else {
      setInTopicString('all'); 
    }

  }, [savedTopics]);

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentHeadlineDateRange));
  }, [filter]) 

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentHeadlineDateRange));
  }, [currentHeadlineDateRange])

  //location move:  {zoom: zoom, center: [lng, lat]}

  useEffect(() => {
    if (singleStateDataSuccess) {
      setGeoLoaded(true);
      const thisState = singleStateData.find(t => t.type =='state')
      setZoomedState(thisState);
    }
  }, [singleStateData]);

  useEffect(() => {
    if (zoomedState?.name && dataLoaded) {
      map.current.flyTo({zoom:zoomedState.zoom, center:[zoomedState.lng, zoomedState.lat], speed: 0.7});
      runCityData();
    }
    else {
      if (zoomedState?.name && map.current){
        map.current.flyTo({zoom:zoomedState.zoom, center:[zoomedState.lng, zoomedState.lat], speed: 0.7});
      }
    }
  }, [zoomedState]);

  useEffect( () => {
    if (dataLoaded) {
      const newSingleState = filter.state.filter(t=>t != 'all').length  == 1 ? true : false; 
      if (!newSingleState) {
        if (map.current) {
          map.current.flyTo({zoom:3, center:[usa.lng, usa.lat], speed: 0.7});
          setZoomedState({});
        }
      }
      else {
        if (geoLoaded){
          refetchGeo();
        }
      }
    }

  }, [map.current, filter]);

  const mouseMove = (e) => {
    if (e.features.length > 0) {
      if (hoveredStateId) {
        map.current.setFeatureState(
          { source: 'states', sourceLayer: 'administrative', id: hoveredStateId },
          { hover: false }
        );
      }
     hoveredStateId = e.features[0].id;
     map.current.setFeatureState(
        { source: 'states', sourceLayer: 'administrative', id: e.features[0].id },
        { hover: true }
      );
      document.getElementById('pd').innerHTML = `<h3>${e.features[0].properties.name}</h3>`;
    }
    //document.getElementById('pd').innerHTML = `<h3>${e.features[0].properties.name}</h3>
    //  <p><strong><em>${e.features[0].state.countFill}</strong> records</em></p>`;
  }

  const mouseLeave = () => {
   if (hoveredStateId) {
      map.current.setFeatureState(
        { source: 'states', sourceLayer: 'administrative', id: hoveredStateId },
          { hover: false }
        );
     }
    hoveredStateId = null;
    document.getElementById('pd').innerHTML = `<p></p>`;
  }

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current =  new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.DATAVIZ.LIGHT,
      center: [usa.lng, usa.lat],
      zoom: zoom
    });

    map.current.scrollZoom.disable(); // disable zoom

    map.current.on('load', function () { setMapHasLoaded(true); });

  }, [usa.lng, usa.lat, zoom]);

  const calcColorThis = (count) => {
    let maxValue = 0;
    const dataToUse = zoomedState?.name ? cinqCityData : cinqStateData;
    dataToUse.map((t) => {
    const valueOfCount = t.count;
      maxValue = Math.max(maxValue, valueOfCount);
    });
    const colorInterval = maxValue > 0 ? Math.floor(maxValue/8) : 100;
    var newColor ='';
    switch(true) {
      case (count < colorInterval * 1) :
        newColor = "#FFEDA0";
        break;
      case (count >= colorInterval * 1 && count < colorInterval * 2):
        newColor = "#FED976";
        break;
      case (count >= colorInterval * 2 && count < colorInterval * 3):
        newColor = "#FEB24C";
        break;
      case (count >= colorInterval * 3 && count < colorInterval * 4):
        newColor = "#FD8D3C";
        break;
      case (count >= colorInterval * 4 && count < colorInterval * 5):
        newColor = "#FC4E2A";
        break;
      case (count >= colorInterval * 5 && count < colorInterval * 6):
        newColor =  "#E31A1C";
        break;
      case (count >= colorInterval * 6 && count < colorInterval * 7):
        newColor =  "#BD0026";
        break;
      case (count >= colorInterval * 7):
        newColor =  "#800026";
        break;
    }
    return newColor;
 }


  useEffect(() => {
    const runNow = zoomedState?.name ? cinqCityData : cinqStateData;
    if (runNow && mapHasLoaded ) {
        setDataLoaded(true);

        if (map.current.getSource('states')) {
          if (map.current.getLayer('usa-states')) {
            console.log('clearing usa-states');
            map.current.removeLayer('usa-states');
          }
          if (map.current.getLayer('state-fills')) {
            console.log('clearing state-fills');
            map.current.removeLayer('state-fills');
          }
          if (map.current.getLayer('state-borders')) {
            console.log('clearing state-borders');
            map.current.removeLayer('state-borders');
          }
        
          // map.current.getSource('states').setData(newGeoJson); <-- reset data here
        }

        
        else {
          map.current.addSource('states', {
            'type': 'vector',
            'url': 'https://api.maptiler.com/tiles/countries/tiles.json?key=AM2VpZpbcGT2iWDZIu8n'
          }); 
        }
       
  // Find the id of the first symbol layer in the map style
        // This is so we can insert under the map labels
        const layers = map.current.getStyle().layers;
        let firstSymbolId;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol') {
            firstSymbolId = layers[i].id;
            break;
          }
        }
        
        if (zoomedState?.name) {
          cityLayers(firstSymbolId);
        }
        else {
          stateLayers(firstSymbolId);
        }
  }}, [cinqStateData, cinqCityData, zoomedState, mapHasLoaded]);

   const cityLayers = (firstSymbolId)  => {

        // state level is administrative level 1 w/ level_0 US
        // county level is administrative level 2 w/ level_0 US and level_1 {state long name}
        map.current.addLayer({
           'id': 'usa-states',
           'type': 'fill',
           'source': 'states',
           'source-layer': 'administrative',
           'filter': [
             'all',
             ['==', 'level', 3],
             ['==', 'level_0', 'US'],
             ['==', 'level_1', zoomedState?.name]
           ],
           'paint': {
            'fill-color': ['case',
               ['==', ['feature-state', 'colorFill'], null],
                '#FFFFFF00',
               ['feature-state', 'colorFill']
              ],
            'fill-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      1,
                      0.8
                  ], 
            'fill-outline-color': '#000'
           }
         },
         firstSymbolId
        );

      if (map.current.isSourceLoaded('states')) {
        setCities();
      } else {
        map.current.on('sourcedata', afterLoad);
      }

      map.current.on('mousemove', 'usa-states', function (e) { 
        mouseMove(e);
      });

      map.current.on('mouseleave', 'usa-states', function () { 
        mouseLeave();
      });
  }

  const setCities = () => {
      // Join the data to coresponding features
        var countries = map.current.querySourceFeatures('states', {
          sourceLayer: 'administrative',
          filter: 
                [ 'all', 
                 ['==', 'level', 3],
                 ['==', 'level_0', 'US'],
                 ['==', 'level_1', zoomedState?.name]
                ]
        });

        // reset to 0
        countries.forEach(function(row) {
            map.current.setFeatureState({
              source: 'states',
              sourceLayer: 'administrative',
              id: row.id
            }, {
              countFill: 0 ,
              colorFill: "#FFFFFF00"
            });
        });

        countries.forEach(function(row) {
          const thisCityName = row.properties.name.toLowerCase();
          const thisCinqRecord = cinqCityData.find(t => t.city.toLowerCase() == thisCityName);
          if (thisCinqRecord) {
            const colorThis =  calcColorThis(parseInt(thisCinqRecord.count));
            map.current.setFeatureState({
              source: 'states',
              sourceLayer: 'administrative',
              id: row.id
            }, {
              countFill: thisCinqRecord.count ,
              colorFill: colorThis 
            });
          }
        });
 
    }

  const stateLayers = (firstSymbolId)  => {
       // state level is administrative level 1 w/ level_0 US
        // county level is administrative level 1 w/ level_0 US and level_1 {state long name}
        // city level is administrative level 2
        map.current.addLayer({
           'id': 'usa-states',
           'type': 'fill',
           'source': 'states',
           'source-layer': 'administrative',
           'filter': [
             'all',
             ['==', 'level', 1],
             ['==', 'level_0', 'US']
           ],
           'paint': {
            'fill-color': ['case',
               ['==', ['feature-state', 'colorFill'], null],
                '#FFFFFF00',
               ['feature-state', 'colorFill']
              ],
            'fill-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      1,
                      0.8
                  ], 
            'fill-outline-color': '#000'
           }
         },
         firstSymbolId
        );

      if (map.current.isSourceLoaded('states')) {
        setStates();
      } else {
        map.current.on('sourcedata', afterLoad);
      }

      map.current.on('mousemove', 'usa-states', function (e) { 
        mouseMove(e);
      });

      map.current.on('mouseleave', 'usa-states', function () { 
        mouseLeave();
      });
  }


  const setStates = () => {
      // Join the data to coresponding features
        var countries = map.current.querySourceFeatures('states', {
          sourceLayer: 'administrative',
          filter: 
                [ 'all', 
                 ['==', 'level', 1],
                 ['==', 'level_0', 'US']
                ]
        });

        // clear data
        countries.forEach(function(row) {
          map.current.setFeatureState({
            source: 'states',
            sourceLayer: 'administrative',
            id: row.id
          }, {
            countFill: 0 ,
            colorFill: "#FFFFFF00"
          });
        });

 
        countries.forEach(function(row) {
          const thisStateName = row.properties.code.replace('US-','');
          const stateRecord = stateMappings.find(s => s.stateShort == thisStateName);
          const thisCinqRecord = cinqStateData.find(t => t.state.toLowerCase() == stateRecord.stateLong.toLowerCase());
          if (thisCinqRecord) {
            const colorThis =  calcColorThis(parseInt(thisCinqRecord.count));
            map.current.setFeatureState({
              source: 'states',
              sourceLayer: 'administrative',
              id: row.id
            }, {
              countFill: thisCinqRecord.count ,
              colorFill: colorThis 
            });
          }
        });

 
    }

  const afterLoad = (e) => {
        if (e.sourceId === 'states' && e.isSourceLoaded) {
          if (zoomedState?.name) {
            setTimeout(setCities, 1000);
          }
          else {
            setTimeout(setStates, 1000);
          }
          map.current.off('sourcedata', afterLoad);
        }
      }

  return (
    <div id='usa-map-html' className="map-wrap">
      <div ref={mapContainer} id='map' className="map" />
      <div className="overlay left" id="info">
        <div id="pd"><p></p></div>
      </div>
      <div id="legend" className="overlay right">
        <h4>audience density</h4>
        
        <div>
         <span style={{backgroundColor: "#FFEDA0"}}></span>
         <span style={{backgroundColor: "#FED976"}}></span>
         <span style={{backgroundColor: "#FEB24C"}}></span>
         <span style={{backgroundColor: "#FD8D3C"}}></span>
         <span style={{backgroundColor: "#FC4E2A"}}></span>
         <span style={{backgroundColor: "#E31A1C"}}></span>
         <span style={{backgroundColor: "#BD0026"}}></span>
         <span style={{backgroundColor: "#800026"}}></span>
        </div>
        <div className="labels">
          <label>low</label>
          <label></label>
          <label></label>
          <label></label>
          <label></label>
          <label></label>
          <label></label>
          <label>high</label>
        </div>
      </div>
    </div>
  );
}

GAUSADensityMap.propTypes = {
  selectedAudience: PropTypes.object,
}