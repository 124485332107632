import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import "../styles/application/app.scss";
import { logout } from "../features/auth/authSlice";
import ClientMenu from "./ClientDashboard/ClientMenu";
import ClientFeedbackForm from "./ClientDashboard/ClientFeedbackForm";
import ClientHeader from "./ClientDashboard/ClientHeader";
import { selectShowFeedbackForm } from "../features/currentView/currentViewSlice";
import { saveCurrentView } from "../features/currentView/currentViewSlice";
import { savePermissions } from "../features/auth/authSlice";
import { selectUserRole } from "../features/auth/authSlice";
import { selectOverride } from "../features/auth/roleSlice";
import { selectUserName } from "../features/auth/authSlice";
import { useGetUserByUsernameQuery } from "../app/api";
import AdminHeader from "./AdminDashboard/AdminHeader";
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';
import GetSystemData from "./GetSystemData";
import Footer from "./Home/Footer";

export default function Root() {
  const location = useLocation();
  const showFeedbackForm = useSelector(selectShowFeedbackForm);
  const userRole = useSelector(selectUserRole);
  const override = useSelector(selectOverride);
  const userName = useSelector(selectUserName);
  const [showFooterPopup, setShowFooterPopup] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const customAudience = queryParameters.get("custom");
  const dispatch = useDispatch();
  const [onDashboardPage, setOnDashboardPage] = useState(location.pathname.includes('/dashboard')); 
  const {data: user} = useGetUserByUsernameQuery({username: userName, custom_override: customAudience}, {skip: !onDashboardPage});

  useEffect (() => {
    setOnDashboardPage(location.pathname.includes('/dashboard'));
  }, [location.pathname]);

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (token && user?.role !== 'CLIENT') {
      return !isTokenExpired(token);
    } 
    else {
      return false;
    }
  }

  const isTokenExpired = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const { exp } = JSON.parse(jsonPayload);
    const expired = Date.now() >= exp * 1000
    return expired
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(logout());
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    if (user && user.interest_states != 'all') {
      dispatch(saveCurrentView({type:'interestStates', value:user.interest_states}));
    }
    if (user) {
      dispatch(savePermissions({value: user.permissions}));
    }
  }, [user]);

  useEffect(() => {
    // send hubspot on URL change for prod only
    if (window.location.hostname.includes('platform.cinqdi')) {
      fetch('/js.hs-scripts.com/4007119.js?businessUnitId=1350030')
      .then (response => response.json())
      .then (body => {
        console.log('body response is ', body);
      });
    }
  }, [location]);

  const expandedClass = () => {
    if (showFeedbackForm) {
      return "feedback feedback-expanded";
    }
    else {
      return "";
    }
  }

  const showFooter = (showFooterPopup, handleFooterPopupState) => {
    if (showFooterPopup) {
      return (
        <Footer 
          showAsPopup={true} 
          handleFooterPopupState={handleFooterPopupState} 
        />
      )
    }
  }

    const handleFooterPopupState = () => {
    if (showFooterPopup) {
      setShowFooterPopup(false);
    }
    else {
      setShowFooterPopup(true);
    }
  }

  const showFeedback = () => {
    if (showFeedbackForm) {
    return (
      <div className={expandedClass("feedback-form")} id="feedback-form">
        <ClientFeedbackForm/> 
      </div>
    )
    }
  }

  const pageRender = () => {
    if ((location.pathname.includes('/dashboard') && userRole == 'CLIENT') || 
        (location.pathname.includes('/dashboard') && userRole?.includes('ADMIN') && override == 'YES')) 
    {
      return (
        <div className='client_dashboard'>
          <ClientHeader/>
          <div className={"client_dashboard_body"} id="client-dashboard">
            <ClientMenu />
            <div className="client_dashboard_view" id="client-body">
              <Outlet /> 
            </div>
            {showFeedback()}
          </div>
          <i className='fa-solid fa-shield-keyhole privacy-footer-button' onClick={()=>{handleFooterPopupState();}}/>
          {showFooter(showFooterPopup, handleFooterPopupState)}
          <i data-tooltip-id='email-help' className='fas fa-question-circle email-helper' onClick={()=>{ window.location = 'mailto:support@cinqdi.com'}}/>
          <Tooltip id='email-help'>Need help?  Email support@cinqdi.com</Tooltip>
        </div>
      )
    }
    if (location.pathname.includes('/dashboard') && userRole?.includes('ADMIN')){
      return (
        <div className='admin_dashboard'>
          <AdminHeader/>
          <Outlet /> 
        </div>
      )
    }
    return <Outlet/ >
  }

  return (
    <div>
      <GetSystemData/>
      {pageRender()}
    </div>
  );
}
