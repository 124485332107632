import React, {useEffect, useState} from "react";
import { selectNameString  } from "../../features/auth/roleSlice";
import { useSelector, useDispatch } from "react-redux";
import { preferenceChanged } from "../../features/currentView/currentViewSlice";
import { useGetUserBookmarksQuery } from "../../app/api";
import { selectUserId } from "../../features/auth/authSlice";
import { toTitleCase } from "../format";
import { useGetSelectAllAudiencesQuery, useGetUserCategoriesQuery, useGetAudienceCategoriesListQuery } from "../../app/api";
import AudienceTile from "../UIElements/AudienceTile";
import { selectPreferenceChange, clearFilters, saveCurrentView} from "../../features/currentView/currentViewSlice";
import Topics from "../UIElements/Topics";
import { TrendingHeadlines } from "./Headlines/TrendingHeadlines";
import { useGetCountRequestsQuery } from "../../app/api";
import GetAudienceData from "../GetAudienceData";
import { selectUserName } from "../../features/auth/authSlice";
import { Link } from "react-router-dom";
import { selectSearchString } from "../../features/currentView/currentViewSlice";
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';
import StateMap from "../Widgets/StateMap";
import { selectInterestStates } from "../../features/currentView/currentViewSlice";
import { cinqAIKeywords } from "../../data/CinqData";
import Loading from "../UIElements/Loading";

import { isPermissionsRO } from "../../features/auth/authSlice";

export default function ClientHome() {
 
  const dispatch = useDispatch();
  const hasROpermissions = useSelector(isPermissionsRO);

  const userId = useSelector(selectUserId);
  const username = useSelector(selectNameString);
  const userNameOfficial = useSelector(selectUserName);
  const searchString = useSelector(selectSearchString);
  const interestStates = useSelector(selectInterestStates);
  const [overviewScrollableHeight, setOverviewScrollableHeight] = useState(50);

  const searchStringDescResults = cinqAIKeywords
    .filter(t => t.keywords.toLowerCase().includes(searchString.toLowerCase()))
    .map (t => (
      t.audience
    ));

  const {data: userCategories} = useGetUserCategoriesQuery({username: userNameOfficial}, {skip: !userNameOfficial});
  const {data: audienceCategories} = useGetAudienceCategoriesListQuery();

  const preferenceChange = useSelector(selectPreferenceChange);
  const {data: userBookmarkData, refetch: queryBookmarks} = useGetUserBookmarksQuery({user_id: userId});
  const { data: audienceData, isSuccess: audienceSuccess, isFetching: audienceIsFetching } =  useGetSelectAllAudiencesQuery({state: interestStates, preferenceChangeDetected: preferenceChange}, {skip: !interestStates}); // preferenceChange only triggering query re-run

  const [audienceListData, setAudienceListData] = useState([]);

  const [tabView, setTabView] = useState('recommended');

  const [submittedRequests, setSubmittedRequests] = useState(0);
  const [activeRequests, setActiveRequests] = useState(0);

  const [myAudiences, setMyAudiences] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const { data: activations, isSuccess: activationSuccess } =  userId && useGetCountRequestsQuery({user_id: userId});

  useEffect( () => {
    dispatch(clearFilters());
    dispatch(saveCurrentView({type:'currentAudienceUUID', value:'default'}));
  },[])

  useEffect( () => {
    if (activations) {
      let active_platform_count = 0;
      let pending_platform_count = 0;
      let submitted_platform_count = 0;
     

      let active_platforms = activations.filter(t => t.no_of_active_platforms > 0);
      let pending_platforms = activations.filter(t => t. no_of_pending_platforms > 0);
      let submitted_platforms = activations.filter(t => t. no_of_submitted_platforms > 0);

       /* Not needed yet but added for cancelled platforms
         let cancelled_platform_count = 0;
         let cancelled_platforms = activations.filter(t => t. no_of_cancelled_platforms > 0); 
       */
     
      if (active_platforms) {
        active_platforms.forEach((val) => active_platform_count += val['no_of_active_platforms']);
      }
      if (pending_platforms) {
        pending_platforms.forEach((val) => pending_platform_count += val['no_of_pending_platforms']);
      }
      if (submitted_platforms) {
        submitted_platforms.forEach((val) => submitted_platform_count += val['no_of_submitted_platforms']);
      }
      setSubmittedRequests(pending_platform_count + submitted_platform_count);
      setActiveRequests(active_platform_count);
    }}
    , [activationSuccess]);

  useEffect( () => {
    if (audienceSuccess) {
      adjustOverviewScrollableHeight();
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess] );

  useEffect( () => {
    console.log('triggered w ', interestStates, audienceData);
    if (audienceData) {
      setAudienceListData([]);
      dispatch(preferenceChanged());
      //refetchAudience();
    }
  }, [interestStates])

  useEffect( () => {
    queryBookmarks();
  }, [preferenceChange] );

  const callbackMyAudiences = (mA) => {
    setMyAudiences(mA);
  }

  const searchResults = () => {
    return  (
      <div className='client_dashboard_view_header'>
        {header}
        {dashboardDiv}
      </div>
    );
  }

  const bookmarkAudience = (audience_id) => {
    return audienceData.find(t => t.id == audience_id);
  }

  const bookmarkList = () => {
    if ((userBookmarkData||[]).length > 0 ) {
      return (
        userBookmarkData
          .filter(t =>  bookmarkAudience(t.audience_id).title?.toLowerCase().includes(searchString.toLowerCase()) ||       searchStringDescResults.toString().includes(bookmarkAudience(t.audience_id).audience) ) 
          .map((bookmark, i) => (
          <li key={i} >
            <AudienceTile uniqueName={bookmarkAudience(bookmark.audience_id).audience} displayTitle={bookmarkAudience(bookmark.audience_id).title} audienceId={bookmark.audience_id} audienceDesc={bookmarkAudience(bookmark.audience_id).description} audienceCount={parseInt(bookmarkAudience(bookmark.audience_id).count)} cluster={bookmarkAudience(bookmark.audience_id).cluster} userId={parseInt(userId)} uuid={bookmarkAudience(bookmark.audience_id).uuid} myAudiences={myAudiences} recommended={false}/>
          </li>
        )
      ))
    }
    else {
      return (
        <p> No Saved Bookmarks.</p>
      )
    }

  }

  const allAudiences = () => {
    return (
      audienceListData
      .filter(t => t.active == 1)
      .filter(t => t.title )
      .filter(t =>  t.title.toLowerCase().includes(searchString.toLowerCase()) ||       searchStringDescResults.toString().includes(t.audience) ) 
      .map((t, i) => (
        <div key={i} >
          <AudienceTile uniqueName={t.audience} displayTitle={t.title} audienceId={t.id} audienceDesc={t.description} audienceCount={parseInt(t.count)} userId={parseInt(userId)} cluster={t.cluster} uuid={t.uuid} myAudiences={myAudiences}/>
        </div>
      ))
    )
  }

  const recommendedAudiences = () => {

    let audienceList = [];
    userCategories?.map(t => {
      //t.id is category id
      audienceCategories?.map(a => {
        //a.id is category id
        if (a.id == t.id) {
          if (audienceList.indexOf(a.audience) == -1) {
            audienceList.push(a.audience);
          }
        }
      })
    })

    console.log('audienceListData', audienceListData);
    console.log('audienceList', audienceList);
    const finalMergedArr = audienceListData.filter((e) => {
      return audienceList.some((t) => {
        return t === e.audience;
      })
    })

    if (finalMergedArr.length > 0){
    return finalMergedArr
      .filter(t => t.active == 1)
      .filter(t => t.title )
      .filter(t =>  t.title.toLowerCase().includes(searchString.toLowerCase()) ||       searchStringDescResults.toString().includes(t.audience) ) 
      .map((t, i) => (
        <li key={i} >
          <AudienceTile uniqueName={t.audience} displayTitle={t.title} audienceId={t.id} audienceDesc={t.description} audienceCount={parseInt(t.count)} userId={parseInt(userId)} cluster={t.cluster} uuid={t.uuid} myAudiences={myAudiences} recommended={true}/>
        </li>
      ))
    }
    else {
      return (
        <div className='bumper-page'>
          <Link to='account-settings/?interest-categories=y' className='link inline no-underline'>Select categories</Link> 
          <p className='inline'> that interest you to receive personalized Cinq recommended audiences </p>
        </div>
      )
    }
  };

  const dashStats = 
    <div className='dash_stats_wrapper'>
      <div className='dash_stat'>
        <h1>{submittedRequests}</h1>
        <h2>SUBMITTED</h2>
      </div>
      <div className='dash_stat'>
        <h1>{activeRequests}</h1>
        <h2>ACTIVE </h2>
      </div>
      {!hasROpermissions && <div className='dash_stat hover-point' data-tooltip-id='state-info' onClick={()=>{setShowPopup(true)}}>
        <i className='fa-solid fa-location-dot'/>
        <h2>{(interestStates || 'all') == 'all' ? 'ALL STATES' : interestStates }</h2>
        <Tooltip id='state-info' className='i-tooltip'>Narrow down states you are interested in</Tooltip>
      </div>
      }
      {showPopup && 
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <h2>Choose Your State of Interest</h2>
            <StateMap/>
            <div className='popup_submit_wrapper'>
              <button onClick={()=>{setShowPopup(false)}}>Close</button>
            </div>
          </div>
        </div>
      }
      <div className='alerts bump-left-120 hidden'>
        <h3>ALERTS</h3>
        <br/>
        <div className='alert'>
          <p>Pragmatic conservatives <br/> expires in 1 week </p>
          <p className='link'>Renew</p>
        </div>
        <div className='alert'>
          <p>Custom Facebook Audience <br/> expires in 15 days </p>
          <p className='link'>Renew</p>
        </div>
      </div>
    </div>

  const tabClass = (classList, thisTab) => {
    if (thisTab == tabView) {
      return classList + ' selected';
    }
    else {
      return classList + ' disabled';
    }
  }

  const tabViewScreen = () => {
    if (audienceIsFetching) {
      return (
        <Loading margin={true}/>
      )
    }
    else {
      if (tabView == 'bookmarks') {
        return (
          <div className='dashboard-audience-list'>
            <ul>
              {audienceSuccess && bookmarkList()}
            </ul>
          </div>
        ) 
      }
      if (tabView == 'headlines') {
        return (
          <TrendingHeadlines />
        )
      }
      if (tabView == 'all') {
        return (
          <div className='dashboard-audience-list'>
            {audienceSuccess && allAudiences()} 
          </div>
        )
      }
      return (
        <div className='dashboard-audience-list'>
          <ul>
            {audienceSuccess && recommendedAudiences()}
          </ul>
        </div>
      )
    }
  }

  const tabs = 
    <div className="client_dashboard_header_menu">
        <div className={tabClass('client_dashboard_header_menu_tab','recommended')} onClick = {() => {setAudienceListData(audienceData); setTabView('recommended')}}>
            <h3 >Cinq Recommended</h3>
        </div>
        <div className={tabClass('client_dashboard_header_menu_tab hidden','all')} onClick = {() => {setAudienceListData(audienceData); setTabView('all')}}>
            <h3 >All Audiences</h3>
        </div>
        <div className={tabClass('client_dashboard_header_menu_tab', 'headlines')} onClick = {() => {setTabView('headlines')}}>
            <h3 > Headlines Recommended </h3>
        </div>
        <div className={tabClass('client_dashboard_header_menu_tab', 'bookmarks')} onClick = {() => {setTabView('bookmarks')}}>
            <h3 > Bookmarks </h3>
        </div>
        <div className='dashboard-search'>
        </div>
    </div>

  /* Main Dashboard Page */
  const dashboardDiv = 
  <div>
    <div>
      {dashStats}
      {tabs}
      <div id='overview_topic_list'>
        <Topics type={'user'} />
      </div>
    </div>
    <div>
      <div className='client_dashboard_view_content no_padding dashboard_view_scroll' style={{height: overviewScrollableHeight}}>
        {tabViewScreen()}
      </div>
    </div>
    </div>

  const header = 
        <h3>Welcome, {toTitleCase(username)} </h3>
  ;

  function adjustOverviewScrollableHeight() {
    let screenHeight = document.getElementById('root').clientHeight;
    let primaryHeaderHeight = 64; 
    let secondaryHeaderHeight = 64;
    let overviewDashboardHeight = 173 //Overview Dashboard (dashboard stats and menu tabs)
    let topicOverviewHeight = document.getElementById('overview_topic_list');
    setOverviewScrollableHeight(screenHeight - primaryHeaderHeight - secondaryHeaderHeight - overviewDashboardHeight - topicOverviewHeight.clientHeight); 
  }
  
  return (
    <div className="client-search">
      <GetAudienceData getAudience={false} getMyAudiences={true} activeAudienceCallback={callbackMyAudiences} />
      {searchResults()}
    </div>
  );
}

