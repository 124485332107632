import React, {useState} from "react";
import PropTypes from "prop-types";

export default function SignUpCategoryButton({category, manageCategory}) {

  const [buttonState, setButtonState] = useState(false);

  const showState = (buttonState) => {
      if (buttonState == false) {
        return <i className='fa-solid fa-plus'></i>
      }
      else if (buttonState == true) {
        return <i className='fa-solid fa-times'></i>
      }
  }

    const controlState = (buttonState) => {
      if (buttonState == false) {
        setButtonState(true);
      }
      else if (buttonState == true) {
        setButtonState(false);
      }
  }

  return (
    <li className={buttonState ? 'selected': ''}  onClick={() => { controlState(buttonState); manageCategory(buttonState, category);}}>
      <p className=''>
        {category.title}
        {showState(buttonState)} 
      </p>
    </li>
  )
}

SignUpCategoryButton.propTypes = {
  category: PropTypes.object, 
  manageCategory: PropTypes.func,
}