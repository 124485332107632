
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRole: '',
  override: window.localStorage.getItem("override") || 'NO',
  nameString: '',
  firstLogin: 'NO'
}

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    save: (state, action) => {
      // save payload should be an object {type: type, value: value}
      const payload = action.payload;
      switch (payload.type) {
        case 'role': 
          state.userRole = payload.value;
          break;
        case 'override': 
          state.override = payload.value;
          window.localStorage.setItem("override", payload.value);
          break;
        case 'nameString':
          state.nameString = payload.value;
          break;
        case 'firstLogin':
          state.firstLogin = payload.value;
          break;
      }
    },
  },
})

export const selectUserRole = (state) => state.role.userRole;
export const selectOverride = (state) => state.role.override;
export const selectNameString = (state) => state.role.nameString;
export const selectFirstLogin = (state) => state.role.firstLogin;

export const { save } = roleSlice.actions;
export default roleSlice.reducer;
