import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip} from "chart.js";
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from "prop-types";
import { getFilter } from "../../features/currentView/currentViewSlice";
import { useSelectWidgetDataQuery } from "../../app/api";
import { allWidgetFields } from "../../data/WidgetFields";
import { createDataObject } from "../../data/WidgetData";
import { useSelector } from "react-redux";
//import { selectWidgetData, selectAllWidgetData, selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectWidgetFields } from "../../features/currentView/currentViewSlice";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import Loading from "../UIElements/Loading";
import { noFilter } from "../../features/currentView/currentViewSlice";
import { widgetDisplay } from "../../data/WidgetData";


export default function BarGraph({type, selectedAudience, filterByClick=false, refreshCounter=0}) {
 
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = useSelector(selectWidgetFields);
  const dispatch = useDispatch();

  const [myWidgetData, setMyWidgetData] = useState([]);
  const [myAllWidgetData, setMyAllWidgetData] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);

  const{data: widgetData, refetch: refetchWidgets, isFetching:widgetsFetching} = useSelectWidgetDataQuery(getFilter(type, selectedAudience?.audience, 'audience', filter, refreshCounter));

  const{data: allWidgetData, isFetching:allWidgetsFetching} = useSelectWidgetDataQuery(getFilter(type, 'none','all', noFilter, refreshCounter), {skip: myAllWidgetData.length > 0});

  const widgetFieldsByQuery = allWidgetFields;

  const [dataObject, setDataObject] = useState(null);

  useEffect(() => {
    if (hasFetched) {
      refetchWidgets();
    }

  },[filter])

  useEffect(() => {
    if (!widgetsFetching) {
      setMyWidgetData(widgetData);
      if (!hasFetched) {
        setHasFetched(true);
      }
    }
  }, [widgetsFetching])

  useEffect(() => {
    if (!allWidgetsFetching) {
      setMyAllWidgetData(allWidgetData);
    }
  }, [allWidgetsFetching])



  useEffect(() => {
    if (widgetFieldsByQuery && widgetFields.length == 0){
      dispatch(saveCurrentView({type:'widgetFields', value: widgetFieldsByQuery })) 
    }
  }, [widgetFieldsByQuery]);

  useEffect(() => {
    setDataObject(null);
  }, [filter]);

  useEffect(() => {
    if( myWidgetData && myAllWidgetData && widgetFields.length > 0) {
      const newDataObject = createDataObject(myWidgetData, allWidgetData, widgetFields.filter(t => t.widget_type == type), type);
      setDataObject(newDataObject);
    }
  }, [myWidgetData, myAllWidgetData, widgetFields.length]);

  // Bar Graph dataObject parameters:
  // title: to be display along the top of the widget
  // maxes: {value: value of max %, desc: descripition between, cat: category of max}
  // no_of_bars: 1 or 2 
  // categories: [name, name] names of categories
  // dataset1: [data, data] data by category
  // dataset2: [data, data] if applicable 
  // type: 'percentage' or 'count'
  // colors: [color1, color2]

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartDataLabels);

  const options = {
    indexAxis: 'y' ,
      layout: {
        padding: {
        right: 16
      }
    },
    scales: {
      x: {
        display: false,
        offset: true,
      },
      y: {
        display: true,
        grid: {
          display: false,
        }, 
        ticks: {
          color: '#000000',
          font: {
            size: 14,
          }
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    onHover: () => {
      let displayInfo = widgetDisplay.find(t => t.widget == type);
      if (displayInfo && displayInfo.hasGA == true && displayInfo.hasL2 == true && displayInfo.skipFilter != true && filterByClick){
        var el = document.getElementById(type);
        el.style.cursor = "pointer" ;
      }
    },
    onClick: (e, activeEls) => {
      let datasetIndex = activeEls[0].datasetIndex;
      let dataIndex = activeEls[0].index;
      let datasetLabel = e.chart.data.datasets[datasetIndex].label;
      let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
      let label = e.chart.data.labels[dataIndex];
      console.log("In click", datasetLabel, label, value);
      let displayInfo = widgetDisplay.find(t => t.widget == type);
      if (displayInfo && displayInfo.hasGA == true && displayInfo.hasL2 == true && displayInfo.skipFilter != true && filterByClick){
        toggleFilterField(filter, type, label, dispatch, saveCurrentView);
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#000000',
        anchor: 'end',
        align: 'right',
        offset: 0,
        formatter: function(value) {
          return value + '%';
        }, 
        font: {
          size: 14,
        },
      },
      tooltip: {
        titleFont: {
          size: 13,
        }, 
        bodyFont: {
          size: 13,
        }
      },
    },
  };

const labels = dataObject?.categories;

const data = {
  labels,
  datasets: [
    {
      label: 'This Audience',
      data: dataObject?.dataset1,
      backgroundColor: '#2F5D62' 
    },
    {
      label: 'All Audiences',
      data: dataObject?.dataset2,
      backgroundColor: '#56BBB1',
    },
  ],
};

  return (
    <>
      { dataObject ? 
        <Bar key={dataObject} id={type} plugins={[ChartDataLabels]} options={options} data={data} refreshCounter={refreshCounter} />
        :
        <Loading/>
      }
    </>
  );
}

BarGraph.propTypes = {
  type: PropTypes.string,
  selectedAudience: PropTypes.object, 
  filterByClick: PropTypes.bool,
  refreshCounter: PropTypes.number,
}

export const toggleFilterField = (filter, type, value, dispatch, saveCurrentView) => {
  console.log('filter is: ', filter, type, value);
  console.log('array to look at: ', filter[type]);
  var newArray = [];
  var noFilter = false;
  var hasFilterValue = false;

  console.log('newArray count is:', newArray.length);
  // parse through existing filter
  filter[type].map( t=> {
    if (t == 'all') { // no filters yet
      noFilter = true; 
    }
    if (t == value) {
      hasFilterValue = true;
    }
  })

  value = value.replace(/,/g,'|');
  if (noFilter) {
    newArray.push(value);
  }
  else {
    if (hasFilterValue) {
      // remove filter from existing
      let preArray = [...filter[type]];
      // check to make sure you didn't clear the last filter
      if (preArray.length == 1 && preArray[0] == value) {
        newArray = ['all'];
      }
      else {
        const index = newArray.indexOf(value); 
        newArray = preArray.splice(index,1);
      }
    }
    else {
      // add filter to existing 
      newArray = [...filter[type]];
      newArray.push(value);
    }
  }

  // check to make sure you didn't clear the last filter
  // (shouldn't happen because of check above)
  if (newArray.length == 0) {
    newArray = ['all'];
  }

  console.log('newArray is: ', newArray);

  let newFilter = {...filter};
  newFilter[type] = newArray;

  console.log('newFilter is: ', newFilter);
  dispatch(saveCurrentView({type:'widgetFilters', value:newFilter}));
}