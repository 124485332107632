import React from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../features/auth/authSlice";
import LoginForm from "../features/auth/LoginForm";
import { Navigate } from "react-router-dom"
import Logo from "../images/advance_local.png";

export default function AdminLogin() {
  // If already logged in, redirect to the client dashboard page
  if (useSelector(selectUserName)) {
    return <Navigate replace to="dashboard" />;
  }


  // Render the login form
  return (
    <div className='centered'>
      <img src={Logo} id='login-logo' />
      <div className='login-form admin'>
        <h2>ADMIN ACCESS</h2>
        <LoginForm loginType={'ADMIN'}/>
      </div>
    </div>
  );
}
