import React, {useState, useRef, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import GetAudienceData from "../GetAudienceData";
import { selectUserId } from "../../features/auth/authSlice";
import { liveRampPlatforms } from "../../data/ActivateData";
import { 
  useAddPlatformActivationMutation, 
  useSendEmailMutation, 
  useAddUserPlatformMutation, 
  useSendAudienceActivationEmailMutation} from "../../app/api";
import { faker } from "@faker-js/faker";

import { useNavigate, createSearchParams } from "react-router-dom";
import { saveDirectory } from "../../features/directory/directorySlice";
import ReportingTermsPopup from "./ReportingTermsPopup"
import { useGetUserAudiencePlatformsQuery, useRunStartAudienceBuildMutation, useGetConfigurableValueQuery } from "../../app/api";
import { useGetUserAudienceByAudienceIdAndUserIdQuery } from "../../app/api";

import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { formatAudienceTitle, numberWithCommas } from "../userSettings";

export default function LiveRamp() {

  const userId = useSelector(selectUserId);
  const [dataAuth, setDataAuth] = useState(null);
  const [error, setError] = useState('');
  const currentFilter = useSelector(selectWidgetFilters);

  const queryParameters = new URLSearchParams(window.location.search);
  const audienceUUID = queryParameters.get("uuid");
  const platformId = queryParameters.get("p_id");
  const startDate = queryParameters.get("start");
  const endDate = queryParameters.get("end");
  const plannedUsage = queryParameters.get("usage");
  const thisPlatform = liveRampPlatforms.find(t => t.id == platformId);

  const customAudience = queryParameters.get("custom");
  const [thisAudience, setThisAudience] = useState({});

  const [addPlatform] = useAddUserPlatformMutation();
  const [addPlatformActivation,{isSuccess: navPage}]  = useAddPlatformActivationMutation();
  const [sendEmail] = useSendEmailMutation();
  const [sendAudienceActivationEmail] = useSendAudienceActivationEmailMutation();
  const {data: newActivationEmailRecipient} = useGetConfigurableValueQuery({configurable_name: 'email.new_activation.recipient'});

  const [runAudienceBuild] = useRunStartAudienceBuildMutation(); 
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showAgreeToTerms, setShowAgreeToTerms] = useState(false);
  const [showSubmittedReviewPopup, setShowSubmittedReviewPopup] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToTermsErrorStatus, setAgreeToTermsErrorStatus] = useState(false);
  const [billingEmailFormatErrorStatus, setBillingEmailFormatErrorStatus] = useState(false);
  const [contactEmailFormatErrorStatus, setContactEmailFormatErrorStatus] = useState(false);
  const [myActivations, setMyActivations] = useState([]);

  const { data: activations, isSuccess: activationSuccess } =  useGetUserAudiencePlatformsQuery({user_id: userId, audience_id: thisAudience.id}, {skip: !thisAudience.id, refetchOnMountOrArgChange: true});

  // get user audience info
  const {data: userAudienceData} = useGetUserAudienceByAudienceIdAndUserIdQuery({audience_id: thisAudience.id, user_id: userId }, {skip: !thisAudience.id, refetchOnMountOrArgChange: true});

  const [thisUserAudience, setThisUserAudience] = useState({});

  useEffect(()=> {
    if (userAudienceData && thisAudience) {
      setThisUserAudience(userAudienceData);
    }
  }, [userAudienceData, thisAudience]);

  useEffect( () => {
    if (activations) {
      setMyActivations(activations);
    }}
    , [activationSuccess]);

  const list = 
    myActivations.map((t,i) => (
      <p key={i} className=''>{liveRampPlatforms.find(l => l.id == t.platform_id)?.text}</p>
    ));

  const activeList = () => {
    if (myActivations.length > 0) {
      return (
        <div>
          <b>*Active or In Progress for:</b>
          {list}
        </div>
      )
    }
  }

  // form parameters
  const platformIdentifier = useRef();
  const cookieIdentifier = useRef();
  const idfaIdentifier = useRef();
  const aaidIdentifier = useRef();
  const contactEmail = useRef();
  const platformAccountId = useRef();
  const authorizeData = useRef();
  const billingName = useRef();
  const billingEmail = useRef();

  const identifierSettings = () => {
    return (
       <div className='contact_information_wrapper'>  
         <div className='contact_information_value_wrapper'>
          <input type='checkbox' 
            name='identifier' ref={platformIdentifier} className='radio inline' defaultChecked='checked'/> Platform ID 
        </div>
        <div className='hidden'>
          <input type='checkbox' 
              name='identifier' ref={cookieIdentifier} className='radio inline'/> Cookies
          <br/>
          <input type='checkbox' 
              name='identifier' ref={idfaIdentifier}className='radio inline'/> IDFA 
          <br/>
          <input type='checkbox' 
              name='identifier' ref={aaidIdentifier} className='radio inline'/> AAID 
          <br/>
        </div>
     </div>
    )
  }

  const destinationSettings = () => {
    return (
      <div className='contact_information_wrapper'> 
        <div className='contact_information_input'>
          <div className='contact_information_label_wrapper'>
            <label name='id'>Account ID</label>
          </div>
          <div className='contact_information_value_wrapper'>
            <input onClick={() => {clearErrors()}} type='text' ref={platformAccountId} name='id'></input>
          </div>
        </div>
      </div>
    )
  }

  const authField = (type, text) => {
    if (text.toLowerCase() == 'i agree') {
      switch(type) {
        case 'data':
          setDataAuth(Date.now());
          break;
      }
    }
    else {
      switch(type) {
        case 'data':
          setDataAuth(null);
          break;
      }
    }
  }

  const showAuth = (value) => {
    if (value) {
      const thisDate =  '' + new Date(value);
      return (
        <div className='authorized_timestamp_wrapper'>
          <i className='fa fa-circle-check'/>
          <p className='timestamp'>Authorized on {thisDate} </p>
        </div>
      )
    }
  }

  const handleAgreeToTerms = () => {
    setAgreeToTerms(true);
    setShowAgreeToTerms(false);
  }
  const showAgreeToTermsPopup = () => {
    setShowAgreeToTerms(true);
  }
  const dismissAgreeToTermsPopup = () => {
    setShowAgreeToTerms(false);
  }

  const authorization = () => {
    return (
      <div className='contact_information_wrapper'> 
        <div className='contact_information_input'>
          <div className='contact_information_label_wrapper'>
            <label name='data'>Data Usage Reporting Requirement</label>
          </div>
          <div className='contact_information_value_wrapper'>
            <input onClick={() => {clearErrors()}}  ref={authorizeData} className='inline' type='text' name='data' onChange={(e)=>{authField('data', e.target.value)}}></input>
            {showAuth(dataAuth)} 
            <p className={dataAuth? 'hidden' : 'under-label'}>(type <b>I Agree</b>)</p>
          </div>
        </div>
        <div className='contact_information_input'>
          <div className='agree-to-reporting-terms'>
            <div className={showAgreeToTerms? 'reporting-terms-popup show' : 'reporting-terms-popup'}>
              <div className='reporting-terms-popup-content'>
                <ReportingTermsPopup dismissAgreeToTermsPopup={dismissAgreeToTermsPopup} handleAgreeToTerms={handleAgreeToTerms}/>
              </div>
            </div>
            <div className='reporting-terms-show-popup-wrapper'>
              <button className='reporting-terms-show-popup-link' onClick={() => showAgreeToTermsPopup()}>Agree to Usage & Reporting Terms</button>
              <i className={agreeToTerms? 'fa-solid fa-circle-check approved agreed-to-term' : 'fa-solid fa-circle-xmark not_agreed-to-term'}></i>
            </div>
            <label className={agreeToTermsErrorStatus ? 'error show': 'error'}>Review and Agree to Terms </label>        
          </div>
        </div>
      </div>
    )
  }

  const contactInformation = () => {

    return (
      <div className='contact_information_wrapper'>
        <div className='contact_information_input'>
          <div className='contact_information_label_wrapper'>
            <label>Billing Contact Name</label>
          </div>
          <div className='contact_information_value_wrapper'>
            <input ref={billingName} onClick={() => {clearErrors()}} className='' type='text' name='billing-name'></input>
          </div>
        </div>
        <div className='contact_information_input'>
          <div className='contact_information_label_wrapper'>
            <label>Billing Email Address</label>
          </div>
          <div className='contact_information_value_wrapper'>
            <input ref={billingEmail} onClick={() => {clearErrors()}} className='' type='email' name='billing-email'></input>
            <label className={billingEmailFormatErrorStatus ? 'error show': 'error'}>Enter valid email </label>        
          </div>
        </div>
        <div className='contact_information_input'>
          <div className='contact_information_label_wrapper'>
            <label name='email'>Contact Email Address</label>
          </div>
          <div className='contact_information_value_wrapper'>
            <input ref={contactEmail} onClick={() => {clearErrors()}} type='email' name='email'></input>
            <label className={contactEmailFormatErrorStatus ? 'error show': 'error'}>Enter valid email </label>
          </div>
        </div>
      </div>
    )
  }

  const clearErrors = () => {
    setError('');
    platformAccountId.current.className = '';
    billingName.current.className = '';
    billingEmail.current.className = '';
    contactEmail.current.className = '';
    authorizeData.current.className = 'inline';
    setBillingEmailFormatErrorStatus(false);
    setContactEmailFormatErrorStatus(false);
    setAgreeToTermsErrorStatus(false); 
  }

  const isEmail = (userName) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userName);

  const addDestinationNow =  async () => {
    if (!platformAccountId.current.value || !(authorizeData.current.value.toLowerCase() == 'i agree') || !contactEmail.current.value || !isEmail(contactEmail.current.value) || !billingName.current.value || !billingEmail.current.value || !isEmail(billingEmail.current.value) || !agreeToTerms) {
      if (!platformAccountId.current.value) {
        console.log('in error and className is', platformAccountId.current.className);
        platformAccountId.current.className = 'error-input';
      }
      if ( !(authorizeData.current.value.toLowerCase() == 'i agree')){
        authorizeData.current.className = 'inline error-input';
      }
      if ( !billingName.current.value){
        billingName.current.className = 'error-input';
      }
      if ( !billingEmail.current.value){
        billingEmail.current.className = 'error-input';
      }
      if (!contactEmail.current.value) {
        contactEmail.current.className = 'error-input';
      }
      if (!isEmail(billingEmail.current.value)) {
        setBillingEmailFormatErrorStatus(true); 
      }
      if (!isEmail(contactEmail.current.value)) {
        setContactEmailFormatErrorStatus(true); 
      }
      if (!agreeToTerms) {
        setAgreeToTermsErrorStatus(true);
      }
      
      setError('Missing Required Fields');
    }
    else {

      // TODO: do not need to add if not new
      // The intention of user_platforms is to save the user's accountIds associated with each platform so we can let them choose from an existing list when activating
      const userPlatformId = await addPlatform({user_id: userId, platform_id: platformId, platform_desc: thisPlatform.text, account_id: platformAccountId.current.value});

      const contactEmailConst = contactEmail.current.value;
      const platformAccountIdConst = platformAccountId.current.value;
      const uniqueIDConst = generateUnique();

      // add platform activation details 
      // TODO: might be helpful to add user_id here 
      const platformAdded = await addPlatformActivation(
        { reference_id: thisUserAudience.reference_id,
          audience_id: thisAudience?.id,
          user_platform_id: userPlatformId.data.user_platform_id,
          platform_id_identify: platformIdentifier.current.checked ? 1 : 0, 
          cookies_identify: cookieIdentifier.current.checked ? 1 : 0,
          idfa_identify: idfaIdentifier.current.checked ? 1 : 0,
          aaid_identify: aaidIdentifier.current.checked ? 1 : 0,
          unique_id: uniqueIDConst, 
          client_name: '', 
          usage_agree: dataAuth,
          payment_model_agree: dataAuth, 
          payment_requirement_agree: dataAuth, 
          contact_email: contactEmailConst,
          start_date: startDate,
          end_date: endDate,
          planned_usage: plannedUsage,
          billing_rate: thisPlatform.rate,
          billing_name: billingName.current.value,
          billing_email: billingEmail.current.value,
        });
      if (!platformAdded) {
        console.log('error adding platform');
      }

      // If audience not in DataLake, we must request Datalake to build
      if (thisAudience.s3_path) {
        // Audience has already been built before, send an email with platform activation info
        // Do not need to wait to send activation request
        console.log('not building audience, already have file', thisAudience.s3_path);
        sendAudienceActivationEmail({uuid: thisAudience.uuid});
        // also send to customer that we got their submission
        sendEmail(
          {
            template_name: 'new_activation',
            recipient_to: contactEmailConst,
            recipient_cc: newActivationEmailRecipient.config_value,
            //subject: `New Activation: ${thisAudience?.title} - ${thisUserAudience.reference_id}`, 
            subject: `Thank you for submitting your audience`, 
            template_variables: {
              contact_email: contactEmailConst,
              platform: thisPlatform?.text,
              audience: thisAudience?.title, 
              timeframe: `${startDate} - ${endDate}`,
              status: 'PENDING',
              account_id: platformAccountIdConst || 'unknown',
              reference_id: uniqueIDConst, 
              billing_rate: '$' + thisPlatform.rate?.toFixed(2),
              number_of_records: numberWithCommas(thisUserAudience.records_uploaded)
            }
          }
        );
      }
      else {
        // Request audience build 
        console.log('requesting audience build', currentFilter, thisAudience.audience, thisAudience.uuid);
        try {
          runAudienceBuild({filter: JSON.stringify(currentFilter), audience: thisAudience.audience, unique_id: thisAudience.uuid })
        }
        catch(e) {
          console.log('there was an error running audience build', e);

        }

        // Send acknowlegint submission email
        sendEmail(
          {
            template_name: 'new_activation',
            recipient_to: contactEmailConst,
            recipient_cc: newActivationEmailRecipient.config_value,
            //subject: `New Activation: ${thisAudience?.title} - ${thisUserAudience.reference_id}`, 
            subject: `Thank you for submitting your audience`, 
            template_variables: {
              contact_email: contactEmailConst,
              platform: thisPlatform?.text,
              audience: thisAudience?.title, 
              timeframe: `${startDate} - ${endDate}`,
              status: 'PENDING',
              account_id: platformAccountIdConst || 'unknown',
              reference_id: uniqueIDConst,
              billing_rate: '$' + thisPlatform.rate?.toFixed(2),
              number_of_records: numberWithCommas(thisUserAudience.records_uploaded)
            }
          }
        );
      }
    }
  }


  useEffect( () => { 
    if (navPage) {
      setShowSubmittedReviewPopup(true);
    }}
    , [navPage]
  );

  const confirmSubmittedReviewPopup = () => {
      dispatch(saveDirectory({type:'clientPage', value:'audience'}));
      navigate('/dashboard/your-audiences');
      navigate(0);
  }

  return ( 
    <> 
      {showSubmittedReviewPopup && 
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <h2>Audience Submitted</h2>
            <p>{formatAudienceTitle(thisAudience?.title)} has been Submitted for Review</p>
            <div className='popup_submit_wrapper'>
              <button onClick={()=>{confirmSubmittedReviewPopup()}}>OK</button>
            </div>
          </div>
        </div>
      }

      <div className="client_dashboard_view_content content_menu_wrapper no_title">      
        <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience} getAudience={true} getCustomAudience={customAudience == 1 ? true : false } />
        <div className='client_dashboard_view_content_menu'>
          <div className='secondary_sidebar activations_sidebar'>
            <h1> Activate { formatAudienceTitle(thisAudience?.title) }</h1>
            <div className='activation_sidebar_content_wrapper'>
              <div className='records_wrapper'>
                <i className='fa fa-line-chart'/>
                <h3 className=''> {numberWithCommas(thisUserAudience?.records_uploaded)} Records </h3>  
              </div>
              <div className='activation_summary_wrapper'>
                <p className='audience-description'> {thisAudience?.description} </p>
                <p>You are activating audience <b>{formatAudienceTitle(thisAudience?.title)}</b> to <b>{thisPlatform?.text}</b></p>
              </div>
              <ul className='activation_details_wrapper'>
                <li>
                  <b>Start Date:</b>
                  <p>{startDate}</p>
                </li>
                <li>
                  <b>End Date:</b>
                  <p>{endDate}</p>
                </li>
                <li className='hidden'>
                  <b>Usage Type:</b>
                  <p>{plannedUsage}</p>
                </li>
                <li>
                  <b>Billing Rate:</b>
                  <p>${thisPlatform?.rate.toFixed(2)} CPM </p>
                </li>
              </ul>
              <div className='activation_history_wrapper'>
                {activeList()}
              </div>
            </div>
          </div>  
        </div>
        <div className='client_dashboard_view_content_view_with_menu'>  
          <div className="confirm-activation">          
            <div className='confirm_activation_title_img_wrapper'>
              <h3 className=''>Configure Your {thisPlatform.text} Destination Account</h3>
              <img src={"../../images/activation/"+thisPlatform.image}/>
            </div>
            <div className='destination-form'>
              <p className='error'>{error}</p>
              <div className='form-details'>
                <h4 className='full-page'>IDENTIFIER SETTINGS</h4>
                {identifierSettings()}
                <h4 className='full-page'>DESTINATION SPECIFIC SETTINGS</h4>
                {destinationSettings()}
                <h4 className='full-page'>CONTACT INFORMATION</h4>
                {contactInformation()}
                <h4 className='full-page'>AUTHORIZATION</h4>
                {authorization()}              
                <div className='confirm_activation_button_wrapper'>
                  <button className='' onClick={() => { addDestinationNow() }}><p>Activate</p></button>
                  <button className='cancel' onClick={() => { navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: thisAudience.uuid, cView: 0 }).toString() }); }}><p>Cancel</p></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const generateUnique = () => {
  //TODO: make sure unique
  return faker.datatype.number({min:10000000, max: 99999999});
}
