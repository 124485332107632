import React, {useState, useRef} from "react";
import { selectCategories } from "../../features/audiences/audienceSlice";
import { useAddCategoryMutation } from "../../app/api";
import { useSelector } from "react-redux";

export default function Categories() {
  
  const categories = useSelector(selectCategories);
  const [searchString, setSearchString] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [ addNewCategory ] = useAddCategoryMutation();
  const searchField = useRef();
  const newCategoryString = useRef();
  const newCategoryDesc = useRef();

  const filteredList =  
    categories.filter(t => t.title.toLowerCase().includes(searchString.toLowerCase())); 

  function searchCats() {
    setSearchString(searchField.current.value);
  }

  const showCategoryList = () =>  {
    return (filteredList || []).map((item, i) => (
      <tr key={i}>
        <td className='header' > {item.title} </td>
        <td className='desc'> {item.description} </td>
        <td className='centered'> &nbsp; </td>
        <td className='centered'> &nbsp; </td>
      </tr>
    ));
  }

  const saveNewCategory = () => {
    addNewCategory({title: newCategoryString.current.value, desc: newCategoryDesc.current.value});
    setShowPopup(false);
  }

  const newPopUp = () => {
    if (showPopup) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setShowPopup(false)}}/>
            <div className='centered'>
              <h3> Add New Category </h3>
            </div>
            <div>
              <label> Name </label>
              <input type='text' ref={newCategoryString} ></input>
              <label> Description </label>
              <input type='text' ref={newCategoryDesc} ></input>
              <button onClick={()=>{saveNewCategory()}}> Save </button>
            </div>
          </div>
        </div>
      )
    }
  }
  return (
   <div >
      <h3> Categories </h3>
      <div className='search-div'>
        <input type='text' ref={searchField} onChange={()=>{searchCats()}} className='search-text' placeholder='Try searching a keyword or phrase'>
        </input>
        <i className="fa-solid fa-magnifying-glass"></i>
      </div>
      <div className='full-page admin-categories'>
        <p className='column-search-result-header'> {(filteredList || []).length} Results </p>
        <div className='admin-category-table'>
          <table className='search-result-table'>
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th className='centered hidden'>Audiences</th>
                <th className='centered hidden'>Users</th>
              </tr>
            </thead>
            <tbody>
              { showCategoryList() }
            </tbody>
          </table>
        </div>
      </div>
      <div className='full-page'>
        <button className='hidden' onClick={()=>{setShowPopup(true)}}> Create new </button>
        {newPopUp()}
      </div>
   </div>
  );
}