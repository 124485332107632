import React, {useState} from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ExampleFile from '../../files/insights.csv';
import ClusterView from "./SearchBreakdown/ClusterView";
import GetAudienceData from "../GetAudienceData";
import PropTypes from "prop-types";


// This page displays both the cluster view (if applicable) & the widgets view for an audience
export default function ClientAudienceClusterView({anyUUID = false}) {

  const [exportPopup, setExportPopup] = useState(false);
  
  const navigate = useNavigate();


  const queryParameters = new URLSearchParams(window.location.search);

  const audienceUUID = queryParameters.get("uuid");
  const customAudience = queryParameters.get("custom");

  const [thisAudience, setThisAudience]  = useState(null); 


  const downloadPopup = () => {
    if (exportPopup) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' >
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setExportPopup(false)}}/>
            <div className='centered'>
              <h3> Download Insights for {thisAudience?.title} </h3>
              <select>
                <option value="" disabled defaultValue>Choose File Type</option>
                <option value='CSV'>CSV</option>
                <option value='PDF'>PDF</option>
              </select>
              <a href={ExampleFile} rel="noreferrer" download="Insights" target="_blank" onClick={()=>{setExportPopup(false)}}>
                <button className=''>Download Now</button>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }
 
  const audienceTabs = () => {
    return (
      <>
        <p className=''> This is a <b>clustered</b> audience </p>
        <button className="" onClick={() => { navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: audienceUUID, cView: 0 }).toString() }) }}>
          <p>Explore</p>
        </button>
      </>
    )
  }

  const audienceSummary = 
    <>
      {downloadPopup()}
      <h3> {thisAudience?.title}</h3>
      <p className='audience-description'> {thisAudience?.description} </p>
      <div className='audience-record-summary hidden'>
        <i className='fa-light fa-bookmark rightside left-buffer'/>
        <i className="fa-solid fa-cloud-download rightside" onClick={()=> {setExportPopup(true);}}/>
      </div>
      {audienceTabs()}
    </>;

  return (
    <>
      <div className="client_dashboard_view_content content_menu_wrapper no_title">
        {!anyUUID && <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience} getAudience={true} getCustomAudience={customAudience == 1 ? true: false}/>}
        {!anyUUID ?
          <div className='client_dashboard_view_content_menu'>
            {audienceSummary}
          </div>
          : <></> 
        }

        <div className='client_dashboard_view_content_view_with_menu no_padding_view'> 
          {(thisAudience || anyUUID) && <ClusterView audience={thisAudience} showFactors={anyUUID} />}
        </div>
      </div>
    </>
  ) 
}

ClientAudienceClusterView.propTypes = {
  anyUUID: PropTypes.bool,
}