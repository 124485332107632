import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import Insights from "./Insights";
import Demographics from "./SearchBreakdown/Demographics";
import Overview from "./SearchBreakdown/Overview";
import Readership from "./SearchBreakdown/Readership";
//import Geography from "./SearchBreakdown/Geography";
import { Suspense } from "react";
import { useAddAudienceMutation, useAddUserAudienceMutation, useSelectWidgetDataQuery } from "../../app/api";
import { createGAQuery } from "../../data/WidgetData";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { selectUserId } from "../../features/auth/authSlice";
import { getFilter, selectAudienceTab, selectCurrentAudienceUUID, selectNoFilters,  selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import ExampleFile from '../../files/insights.csv';
import { generateUnique } from "../DestinationAccounts/LiveRamp";
import GetAudienceData from "../GetAudienceData";
import AudienceStatus from "../UIElements/AudienceStatus";
import Filter, { widgetFilterList } from "../UIElements/Filter";
import HeadlineArticle from "./Headlines/HeadlineArticle";
import CategoryButton from "../UIElements/CategoryButton";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import TopicFilter from "../UIElements/TopicFilter";
import { widgetDisplay } from "../../data/WidgetData";
import { formatAudienceTitle, numberWithCommas } from "../userSettings";

import { isPermissionsRO } from "../../features/auth/authSlice";


// This page displays both the cluster view (if applicable) & the widgets view for an audience
export default function ClientAudienceView() {

  const hasROpermissions = useSelector(isPermissionsRO);

  const [showSaveAudience, setShowSaveAudience] = useState(false);
  const audienceTab = useSelector(selectAudienceTab);
  const [tabView, setTabView] = useState('demographics');
  const [exportPopup, setExportPopup] = useState(false);
  const [showFilter, setShowFilter] = useState({show: false, type: 'filter'});
  
  const navigate = useNavigate();

  const filter = useSelector(selectWidgetFilters);
  const noFilters = useSelector(selectNoFilters); 
  const noTopics = 0;

  const headlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const inTopics = useSelector(selectInTopics);
  const outTopics = useSelector(selectOutTopics);

  createGAQuery(filter, headlineDateRange);

  const queryParameters = new URLSearchParams(window.location.search);

  const urlAudienceId= queryParameters.get("uuid");
  const customAudience = queryParameters.get("custom");

  const [thisAudience, setThisAudience]  = useState(null); 
  const [audienceUUID, setAudienceUUID] = useState(urlAudienceId);
  const currentAudienceUUID = useSelector(selectCurrentAudienceUUID);

  const userId = useSelector(selectUserId);
  const [customAudienceName, setCustomAudienceName] = useState('');
  const [customAudienceNameErrorStatus, setCustomAudienceNameErrorStatus] = useState(false);

  /* user widget hearts temporarily turned off ***
  const {data: userWidgetData, refetch: queryWidgets} = useGetUserWidgetsQuery({user_id: userId});
  const userWidgetDataForAudience = userWidgetData && userWidgetData.filter(t => (t.audience ||'').includes(thisAudience?.audience));
  *** */

  const [addAudience] = useAddAudienceMutation();
  const [addUserAudience] = useAddUserAudienceMutation();

  const [selectedWidgetList, setSelectedWidgetList] = useState([]);
  const [selectedFilterDescriptions, setSelectedFilterDescriptions] = useState('');

  const {data: filterRecordCount, refetch: runSelectCountQuery, isFetching: allCountFetching } = useSelectWidgetDataQuery(getFilter('all', thisAudience?.audience, 'audience', filter), {skip: (!thisAudience || !filter)});

  useEffect ( () => {
    if (currentAudienceUUID != 'default') {
      setAudienceUUID(currentAudienceUUID);
    }

  }, [currentAudienceUUID]);

  useEffect ( () => {
    if (audienceTab.type != 'default') {
      setTabView(audienceTab.type);
    }
  }, [audienceTab]);

  useEffect ( () => {
    setTabView('demographics');  // reset
    fetchWidgetList();
  },[]);

  useEffect( () => {
    if (thisAudience) {
      runSelectCountQuery();
      fetchWidgetList();
    }
  }, [filter] );
  
  const togglePopUP = () => {
    generateAudienceSummaryForCustomSave();
    setShowSaveAudience(!showSaveAudience);
  }

  // show banner if filter narrowed down geo
  const hasNarrowGeo = () => {
    if (filter['county'] != 'all' || 
        filter['dma'] != 'all' || 
        filter['us_congressional_district'] != 'all' || 
        filter['state_house_district'] != 'all' || 
        filter['state_senate_district'] != 'all' ) 
    { return true }
    else {
      return false;
    }
  }

  /** 
  * Update Widget list based on filter selection
  */
  const fetchWidgetList = () => {
    let widget_list = [];
    widgetDisplay
      .map((type) => {
        widget_list.push([type.widget.toLowerCase(), type.displayName])
    })
    setSelectedWidgetList(widget_list);
    console.log(selectedWidgetList);
  }

  const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'full-page side-tab';
    }
    else {
      return 'full-page side-tab disabled';
    }
  }

  const generateAudienceSummaryForCustomSave = () => {
    /*  MVDIG-450 - Remove filters from description
    let concatFilterString = "";
    for (var key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        if (filter[key] != 'all') {
          concatFilterString += generateFilterTitle(key) + ': ' + formatFilterKey(filter[key]) + '. '; 
          console.log(concatFilterString);
        }
      } 
    } 
    setSelectedFilterDescriptions(concatFilterString); 
    */
    setSelectedFilterDescriptions(thisAudience.description);
  }

  /*  MVDIG-450 - Remove filters from description

  const generateFilterTitle = (title) => {
    for (let i= 0; i < selectedWidgetList.length; i++) {
      if (selectedWidgetList[i][0] === title ) {
        return selectedWidgetList[i][1];
      } 
    }
  }

  const formatFilterKey = (key_unformatted) => {
    let keyStringConcat = '';
    for (let j = 0; j < key_unformatted.length; j++) {
     if (j == key_unformatted.length - 1) {
      keyStringConcat += key_unformatted[j] 
     }
     else {
      keyStringConcat += key_unformatted[j] + ', ' 
     }
    }
    return keyStringConcat.replaceAll("|",",");
  } 
  */

  const saveNewAudience = async () => {
    if (customAudienceName == '') { // Empty submission check | throw error 
      setCustomAudienceNameErrorStatus(true) 
    } 
    else {
      let description_format = selectedFilterDescriptions;
      if (description_format.length > 200) {
        description_format = description_format.substring(0,200);
      }
      const newUUID = generateUnique();
      const {data: result}= await addAudience({title: customAudienceName + '||' + newUUID, description: description_format, active: 0, custom: 1, audience: thisAudience.audience, filter_query: JSON.stringify(filter), job_status: 'hold', base_audience_id: thisAudience.id, base_ga_code: thisAudience.ga_code, uuid: newUUID, custom_user_id: userId, orig_uuid: thisAudience.uuid, orig_custom: thisAudience.custom, audience_cpm: thisAudience.audience_cpm  });
      const userResult = await addUserAudience({user_id: userId, audience_id: result.audience_id, reference_id: generateUnique(), title: thisAudience.title, records_uploaded: filterRecordCount ? filterRecordCount[0].count : 0, request_type: 'CUSTOM' });
      console.log(userResult);
      navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: newUUID, custom: 1}).toString()});
      navigate(0);
    }
  }


  const v1 = 
      filterRecordCount && <p className=''>Record Count of {filterRecordCount && filterRecordCount[0] ? numberWithCommas(filterRecordCount[0]?.count) : 0} out of {numberWithCommas(thisAudience?.count)}</p> 

  const v2 = 
      filterRecordCount && <p className=''>Record Count of {numberWithCommas(filterRecordCount[0].count)}</p>
  const saveVerbiage = () => {

    if (filterRecordCount && filterRecordCount[0].count > 0){
      if (thisAudience?.count) {
        return v1
      }
      else {
        return v2 
      }
    }
    else {
      <p>Calculating Records...</p> 
    }
  }

  const saveAudience = () => {
    return (
      <div className={showSaveAudience ? 'popup_overlay expanded': 'hidden'}>
        <div className='popup_widget save_audience' >
          <button id='fa-times' className='fa fa-times close' onClick={()=>{togglePopUP()}}/>
          <h3> Save New Audience </h3>
          <p id="widget-filter-description">{formatAudienceTitle(thisAudience?.title)}</p>
          {saveVerbiage()}
          <input type='text' maxLength={255} onChange={handleCustomAudienceNameChange} value={customAudienceName} placeholder='Customize Name'></input>
          <label className={customAudienceNameErrorStatus ? 'error show': 'error'}>Enter audience name</label>
          <div className='entry_wrapper'>
            <div className='popup_submit_wrapper'>
              {filterRecordCount && filterRecordCount[0].count > 0 && <button className='' onClick={() => { saveNewAudience() }}> <p>Save</p> </button> }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleCustomAudienceNameChange = (event) => {
    const value = event.target.value;
    setCustomAudienceName(value);
    if (customAudienceNameErrorStatus && value.length > 0) {
      setCustomAudienceNameErrorStatus(false);
    }
  };

  const downloadPopup = () => {
    if (exportPopup) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' >
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setExportPopup(false)}}/>
            <div className='centered'>
              <h3> Download Insights for {thisAudience?.title} </h3>
              <select>
                <option value="" disabled defaultValue>Choose File Type</option>
                <option value='CSV'>CSV</option>
                <option value='PDF'>PDF</option>
              </select>
              <a href={ExampleFile} rel="noreferrer" download="Insights" target="_blank" onClick={()=>{setExportPopup(false)}}>
                <button className=''>Download Now</button>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }
 
  const tabViewScreen = () => {
    switch(tabView){
      case 'overview':
        return (
          <Overview selectedAudience={thisAudience}/>
        )
      case 'demographics':
        return <Demographics selectedAudience={thisAudience} />
      case 'readership':
        return <><Readership  selectedAudience={thisAudience} /></>; 
      case 'headlines':
        return <Insights useAudience={true} audience_code={thisAudience.ga_code || thisAudience.base_ga_code} showHeading={false}/>; 
      case 'geography':
        return <div className='buffer-page top-bump-40 bump-left-40'>Coming Soon...</div>
      case 'article': 
        return <HeadlineArticle passedHeadline={audienceTab.value} selectedAudience={thisAudience} />
    }
  }

  const audienceTabs = () => {
    return (
      <div className='top-bump-60'>
        <div className={tabClass('demographics')} onClick = {() => {setTabView('demographics')}}>
          <h3>Audience Insights</h3>
        </div>
        <div className={tabClass('readership')} onClick = {() => {setTabView('readership')}}>
          <h3>Readership Insights</h3>
        </div>
        <div className={tabClass('headlines')} onClick = {() => {setTabView('headlines')}}>
          <h3>Headlines</h3>
        </div>
      </div>
    )
  }

  const audienceSummary = 
    <>
      {downloadPopup()}
      <p className='audience-description'> 
        {thisAudience?.limited != 1 && (tabView == 'demographics' ? 'Registered Voters who are ' : 'Trending Content Among News Readers who are ') }
        { thisAudience?.description.charAt(0).toLowerCase() + thisAudience?.description.slice(1) } 
      </p>
      <AudienceStatus audienceId={thisAudience?.id} audienceUUID={audienceUUID} hasFilter={noFilters > 0 ? true : false}  showNewCallback={togglePopUP} isCustom={thisAudience?.custom == 1 ? true : false} audienceFilter={thisAudience?.filter_query}/>
      <div className='audience-record-summary hidden'>
        <i className='fa-light fa-bookmark rightside left-buffer'/>
        <i className="fa-solid fa-cloud-download rightside" onClick={()=> {setExportPopup(true);}}/>
        <br/>
        <br/>
      </div>
    </>;

  const viewingCount = () => {
    if (allCountFetching) {
      return <h3 className='inline'> Loading Records... </h3> 
    }
    else {
      if (noFilters > 0 ) {
        if (customAudience == 1) {
          if (JSON.stringify(filter) == thisAudience?.filter_query) {
            return <div className=''><h3 className='inline'>  {filterRecordCount && filterRecordCount[0] ? parseInt(filterRecordCount[0]?.count).toLocaleString('en', {useGrouping:true}) : 0}  Records </h3></div> 
          }
          else {
            return <div className=''><h3 className='inline'> Viewing Adjusted Filtered Records  {filterRecordCount && filterRecordCount[0] ? parseInt(filterRecordCount[0]?.count).toLocaleString('en', {useGrouping:true}) : 0}  Records </h3></div> 
          }
        } 
        else {
          return <div className=''><h3 className='inline'> Viewing {filterRecordCount && filterRecordCount[0] ? parseInt(filterRecordCount[0]?.count).toLocaleString('en', {useGrouping:true}) : 0} of {parseInt(thisAudience?.count).toLocaleString('en', {useGrouping:true})} Records </h3></div>

        }
      }
      else {
        return <div className=''><h3 className='inline'> {parseInt(thisAudience?.count).toLocaleString('en', {useGrouping:true})} Records </h3></div> 
      }
    }
  }

  const getFormattedDate = (thisDate) => {
    return thisDate.substring(5,7) + '/' + thisDate.substring(8,10) + '/'  + thisDate.substring(0,4);
  }

  return (
    <>
    <div className='client_dashboard_view_content content_menu_wrapper no_title'>
      {saveAudience()}
      <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience} getAudience={true} getCustomAudience={customAudience == 1 ? true: false}/>
      <div className='client_dashboard_view_content_menu  menu_with_header_body'>
        <div className='content_menu_header'>
          <h1 className={thisAudience?.title.length > 20 ? 'small-font': ''}> {formatAudienceTitle(thisAudience?.title)}</h1>
          <div className='filter-tabs'>
            <div className={showFilter.show ? 'tab closed' : 'tab'}>
              <div className="filter-toggle " onClick= {()=>{setShowFilter({show: false , type:'insights'})}} >
                INSIGHTS 
              </div>
            </div>
            {!hasROpermissions && <div className={(showFilter.show && showFilter.type == 'filter') ? 'tab right1' : 'tab right1 closed'}>
              <div className="filter-toggle " onClick= {()=>{setShowFilter({show: true, type: 'filter'})}} >
                FILTER <p className='hidden'>({noFilters})</p>
              </div>
            </div>
            }
            {!hasROpermissions && 
            <div className={(showFilter.show && showFilter.type == 'topics') ? 'tab right2' : 'tab right2 closed'}>
              <div className="filter-toggle " onClick= {()=>{setShowFilter({show: true, type: 'topics'})}} >
                TOPICS <p className='hidden'>({noTopics})</p>
              </div>
            </div>
            }
          </div>
        </div>
        <div className='content_menu_body'>
          {audienceSummary}
          <Suspense fallback={<p>Loading..</p>}>       
            {!showFilter.show && <div className='top-bump-20'>{audienceTabs()}</div>}
          </Suspense>
          <Suspense fallback={<p>Loading..</p>}>       
            <div className={(showFilter.show && showFilter.type == 'filter') ? 'widget-filter-list-view filter' : 'hidden'}>
              <div className={showFilter.show ? '': 'hidden'}> 
                {thisAudience && <Filter selectedAudience={thisAudience}/>}
              </div>
            </div>
          </Suspense>
          <Suspense fallback={<p>Loading..</p>}>       
            <div className={ (showFilter.show && showFilter.type == 'topics') ? 'widget-filter-list-view' : 'hidden'}>
              <div className={showFilter.show ? '': 'hidden'}> 
                {thisAudience && <TopicFilter/>}
              </div>
            </div>
          </Suspense>
          {thisAudience?.cluster == 1 && 
            <div className='cluster-description'>
              <i className='fa fa-solid fa-info-circle'/> 
              <p>Multi-issue Psychographic Personas are premium audiences that categorize voters into mutually-exclusive cohorts based on their opinions on various political, economic and social issues.  This is 1 of 10 Role of Government Personas. </p>
            </div>
          }
        </div>
      </div> 
    <div className='client_dashboard_view_content_view_with_menu no_padding_view'>
      <div className='audience-breakdown'>
        <div className='audience-state-filter-list-wrapper'>
          {widgetFilterList()}
        </div>
        <div className='audience-record-details'>
          <div className='audience-record-wrapper'>
            {tabView == 'demographics' ? <i className='fa fa-line-chart inline'/> : <i className='fa fa-line-chart inline white'/>}
            {tabView == 'demographics' && viewingCount()}
            </div>
          <h3 className='tab-description'>{tabView == 'demographics' ? 'Audience Insights' : 'Date Range: ' + getFormattedDate(headlineDateRange.minDate) + ' to ' +  getFormattedDate(headlineDateRange.maxDate)}</h3>
        </div>
        {(tabView != 'demographics' && (inTopics.length > 0 || outTopics.length > 0)) &&
          <>
            <div className='cat-topics full-page'>
              <h3>Topics:</h3> {inTopics.map((t,i) => (<CategoryButton key={i} type={'remove'} label={t}/>))} {outTopics.filter(t => t != 'none').map((t,i) => (<CategoryButton key={i} type={'out'} label={t}/>))} 
            </div>
          </>
        }
        {(tabView != 'demographics' && (hasNarrowGeo())) &&
          <>
            <div className='warning-banner full-page centered'>
              Insights available at state level only 
            </div>
          </>
        }
      </div>
      <div className={tabView == 'article'  ? 'article-page' : 'widgets'}>
        {tabViewScreen()}
      </div>
    </div>
   </div>
  </>
  ) 
}
