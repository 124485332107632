import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../app/api";

const initialState = {
  clientPage: "client-search",
  adminPage: "dashboard",
}

export const directorySlice = createSlice({
  name: "directory",
  initialState,
  reducers: {
    saveDirectory: (state, action) => {
      const payload = action.payload;
      switch (payload.type) {
        case 'title':
          state.selectedTitle = payload.value;
          break;
        case 'clientPage':
          state.clientPage = payload.value;
          break;
        case 'adminPage':
          state.adminPage = payload.value;
          break;
        }
      }
    },
   extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      console.log('login!', action.payload);
      // Reset to basic page after log in
      state.clientPage= "client-search";
      state.adminPage= "dashboard";
    });
  },
});

export const selectClientPage = (state) => state.directory.clientPage;
export const selectAdminPage = (state) => state.directory.adminPage;

export const { saveDirectory } = directorySlice.actions;
export default directorySlice.reducer;
