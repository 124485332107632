import React from "react";
//import { useSelector } from "react-redux";
//import { selectUserName } from "../features/auth/authSlice";
import ResetPasswordForm from "../features/auth/ResetPasswordForm";
import Header from "../routes/Home/Header";
import Footer from "../routes/Home/Footer";
import PropTypes from "prop-types";
import { logout } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";

export default function ResetPassword({invited}) {
  const dispatch = useDispatch();
  dispatch(logout());

  // if user already has a password, invalidate the link

  return (
    <div className='login-page full-page centered'>
      <Header />
      <img src="https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/Icon-KO.png" alt="" title="Icon-KO" className='login-logo hidden'></img>
      <ResetPasswordForm invited={invited} />
      <Footer showAsPopup={false} ></Footer>
    </div >
  )
}

ResetPassword.propTypes = {
  invited: PropTypes.bool,
};
