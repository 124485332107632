import { widgetDisplay } from "../data/WidgetData";

export function isWidgetHearted(widgetData, widget){
  var found = false; 
  if (widgetData){
    found = widgetData.find(t => t.widget_name === widget);
  }
  if (found) {return true}
  else {return false};
}

export function formatAudienceTitle(title){
  if (title && title.indexOf("||") > 1) {
    return title.substr(0, title.indexOf("||"));
  }
  else {
    return title;
  }
}

export function formatAudienceTitleForActivationsList(title) {
  let formattedTitle = "";
  if (title) {
    formattedTitle = title.replace(' - ids','').replace(' - walk','').replace(' - facebook','').replace(' - phone','').replace(' - mail','');
    if (formattedTitle && formattedTitle.indexOf("||") > 1) {
      return formattedTitle.substr(0, formattedTitle.indexOf("||"));
    }
    else {
      return formattedTitle;
    }
  }
}

export function downloadCSVTimestamp() {
  let dateNow = new Date();
  let dateNowformatted = [
      (dateNow.getMonth()+1).padLeft(),
      dateNow.getDate().padLeft(),
      dateNow.getFullYear().padLeft()].join('/');

  return dateNowformatted
}

export function downloadCSVFullTimestamp() {
  let dateNow = new Date();
  let dateNowFullformatted = [
      (dateNow.getMonth()+1).padLeft(),
      dateNow.getDate().padLeft(),
      dateNow.getFullYear().padLeft()].join('-')+' '+
      [dateNow.getHours().padLeft(),
      dateNow.getMinutes().padLeft(),
      dateNow.getSeconds().padLeft() ].join('-');

  return dateNowFullformatted
}

Number.prototype.padLeft = function(base,char){
    var length = (String(base || 10).length - String(this).length)+1;
    return length > 0? new Array(length).join(char || '0') + this : this;
}

export function numberWithCommas(x) {
  if (x) {
    return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
  }
  else {
    return '';
  }
}

export function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}

// For Activations Export (does not ingest full audience record, just audience.filter_query)
export function formatSelectedFiltersActivations(filter_query) {
  if (filter_query) {
    let concatFilterString = "";
    let filters = JSON.parse(filter_query)
    let widgetList = fetchWidgetList();
    for (var key in filters) {
      if (filters[key] != 'all') {
        concatFilterString += generateFilterTitle(key, widgetList) + ': ' + formatFilterKey(filters[key]) + '. '; 
        console.log(concatFilterString);
      }
    }
    return concatFilterString; 
  }
}

export function formatSelectedFilters(audience) {
  if (audience && audience.filter_query) {
    let concatFilterString = "";
    let filters = JSON.parse(audience.filter_query)
    let widgetList = fetchWidgetList();
    for (var key in filters) {
      if (filters[key] != 'all') {
        concatFilterString += generateFilterTitle(key, widgetList) + ': ' + formatFilterKey(filters[key]) + '. '; 
        console.log(concatFilterString);
      }
    }
    return concatFilterString; 
  }
}

const generateFilterTitle = (title, widgetList) => {
  for (let i= 0; i < widgetList.length; i++) {
    if (widgetList[i][0] === title ) {
      return widgetList[i][1];
    } 
  }
}

const formatFilterKey = (key_unformatted) => {
  let keyStringConcat = '';
  for (let j = 0; j < key_unformatted.length; j++) {
    if (j == key_unformatted.length - 1) {
      keyStringConcat += key_unformatted[j] 
    }
    else {
      keyStringConcat += key_unformatted[j] + ', ' 
    }
  }
  return keyStringConcat.replaceAll("|",",");
}
  
const fetchWidgetList = () => {
  let widget_list = [];
  widgetDisplay
    .map((type) => {
      widget_list.push([type.widget.toLowerCase(), type.displayName])
    })
  return widget_list;
}

const organizationTypes = ['', 'Agency', 'Advertiser', 'Brand', 'Technology', 'Media', 'Research/Polling', 'Advocacy' ];
const jobFunctions = ['', 'Account Management', 'Administrative', 'Analytics', 'Audience Development/Circulation', 'Brand Management', 'Business Development', 
  'Digital/Online Media Management', 'Ecommerce', 'Editorial', 'Education', 'Event Management', 'Finance', 'Human Resources', 'Information Technology', 'Innovation', 'Management', 
  'Marketing', 'Media Buyer/Planning', 'Operations', 'Product Management', 'Production', 'Public Relations', 'Research', 'Sales', 'Other'];
const seniorityLevels = ['','C-Suite', 'Board/Chair', 'Managing Director/General Manager', 'Vice President' , 'Director', 'Manager', 'Account Executive', 'Strategist', 'Analyst', 'Non-Manager (Associate, Coordinator)', 'Professor/Student', 'Other']
const demographics = ['', 'Male', 'Female', 'Non-binary', 'I identify in another way']
const ageGroups = ['', '18 - 24', '25 - 34', '35 - 44', '45 - 54', '55+']

export { organizationTypes, jobFunctions, seniorityLevels, demographics, ageGroups };