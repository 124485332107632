import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import audienceReducer from "../features/audiences/audienceSlice";
import authReducer from "../features/auth/authSlice";
import counterReducer from "../features/counter/counterSlice";
import roleReducer from "../features/auth/roleSlice";
import directoryReducer from "../features/directory/directorySlice";
import currentViewReducer from "../features/currentView/currentViewSlice";
import adminListsReducer from "../features/adminLists/adminListsSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    audience: audienceReducer,
    auth: authReducer,
    counter: counterReducer,
    currentView: currentViewReducer,
    directory: directoryReducer,
    role: roleReducer,
    adminLists: adminListsReducer
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(api.middleware),
});
