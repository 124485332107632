import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useAddUserWidgetsMutation, useDeleteUserWidgetsMutation } from "../../app/api";
import { preferenceChanged } from "../../features/currentView/currentViewSlice";
import { selectUserId } from "../../features/auth/authSlice";
import WidgetDetail  from "./WidgetDetail";
import { widgetDisplay } from "../../data/WidgetData";
import { selectSearchString } from "../../features/currentView/currentViewSlice";


export default function Widget({widgetType, hearted, passedType, selectedAudience, ga=false, headlineFilter='none', filterByClick=false}){


  const [type, setType] = useState(passedType);
  const [refreshCounter, setRefreshCounter] = useState(0); // this is used to trigger refresh on toggle back since old value still in cache
  const searchString = useSelector(selectSearchString);

  // type passed into Widget maps to the different widget types from the data - ethnicity, age_range, etc.
  // here we manage toggling between widget "groups"
  const widgetDisplayDetails = widgetDisplay.find((t) => t.widget == type);
  const isWidgetGroup = ga ? false : (widgetDisplayDetails?.partOfGroup || false);
  const widgetGroupList = isWidgetGroup ? widgetDisplay.filter((t) => t.group == widgetDisplayDetails.group) : [];


  const [selectedWidget, setSelectedWidget] = useState(widgetType);
  const [showExpand, setShowExpand] = useState(false);
  const userId = useSelector(selectUserId);
  const [addUserWidget, isSuccess] = useAddUserWidgetsMutation();
  const [deleteUserWidget, {isSuccess:deleteSuccess}] = useDeleteUserWidgetsMutation();
  const dispatch = useDispatch();

  useEffect(()=>{
    if (isSuccess) {
      dispatch(preferenceChanged());
    }
  }, [isSuccess])

  useEffect(()=>{
    if (deleteSuccess) {
      dispatch(preferenceChanged());
    }
  }, [deleteSuccess])
 
  const addWidgetToUser = (widget_name, audience_id) => {
    addUserWidget({
      user_id: userId, 
      widget_name: widget_name, 
      audience_id: audience_id});
  }

  const deleteWidgetFromUser = (widget_name, audience_id) => {
    deleteUserWidget({
      user_id: userId, 
      widget_name: widget_name, 
      audience_id: audience_id});
  }

  const getWidget = () => {
    return <WidgetDetail refreshCounter={refreshCounter} passedWidgetType={selectedWidget} widgetColumn={type} thisAudience={selectedAudience} ga={ga} widgetDisplayName={widgetDisplayDetails.displayName} headlineFilter={headlineFilter} filterByClick={filterByClick} />
  }

  const toggleWidget = () => {
    setSelectedWidget(selectedWidget == 'bar' ? 'doughnut' : 'bar');
  }

  /*const toggleClass = () => {
    return selectedWidget == 'bar' ? 'widget-summary bar' : 'widget-summary doughnut';
  }*/

  const loadEllipse = () => {
    if (selectedWidget == 'wordcloud') {
      if (showExpand) {
        return (
          <i className='fa-solid fa-ellipsis'>
            <div className='popup' onClick={()=>{setShowExpand(false)}}> Close </div>
          </i>
        )
      }
      else {
        return (
          <i className='fa-solid fa-ellipsis'>
            <div className='popup' onClick={()=>{setShowExpand(true)}}> Full Screen </div>
          </i>
        )
      }
    }
    else {
      if (type != 'likely_voter') { // Prevents the Donut graph option for Likely Voter 
        return (
          <i className='fa-solid fa-ellipsis'>
            <div className='popup' onClick={()=>{toggleWidget()}}> Toggle Widget </div>
          </i>
        )
      }
    }
  }

  const divClassName = () => {
    // put this back in if you want to filter widgets by search field
    //const hiddenString = widgetDisplayDetails.displayName.toLowerCase().includes(searchString.toLowerCase()) ? '' : 'hidden';
    const hiddenString = searchString == '' ? '' : ''; 
    if (selectedWidget == 'wordcloud' && showExpand) {
      return "widget full-popup " + hiddenString
    }
    else {
      if (selectedWidget == 'wordcloud') {
        return "widget wordcloud " + hiddenString
      }
      else { 
        return "widget " + hiddenString
      }
    }
  }

  const heart = () => {
    if (hearted) {
      return <i onClick = {() => {deleteWidgetFromUser(type, selectedAudience.id)}} className='fa-solid fa-heart hidden'></i>
    }
    else {
      return <i onClick = {() => {addWidgetToUser(type, selectedAudience.id )}} className='fa-regular fa-heart hidden'></i>
    }
  }

  const widgetGroupOptions = () => {
    return widgetGroupList.map((t,i) => (
      <option key={i} value={t.widget} >{t.displayName}</option>
    ))
  }

  const mapWidgetToDisplayName = () => {
    if (isWidgetGroup) {
      return (
        <div>
          <select value={type} name={type+'-select'} onChange={(e)=>{setType(e.target.value); setRefreshCounter(refreshCounter+1)}}>
            {widgetGroupOptions()}
          </select>
        </div>
      )
    }
    else {
      return <p className='leftside black'> {widgetType == 'wordcloud' ? 'Topics' : widgetDisplayDetails.displayName} </p>
    }
  }

  const widgetIcons = () => {
    return (
      <div className='widget-icons'>
        {loadEllipse()}
        {heart()}
      </div>
    )
  }

  return (
    <div className={divClassName()}>
      <div className='header'>
        {widgetDisplayDetails && mapWidgetToDisplayName()} 
        {widgetIcons()}
      </div>
      <div className='widget-div'>
        {getWidget()}
      </div>
    </div>
  );
}

Widget.propTypes = {
  widgetType: PropTypes.string,
  hearted: PropTypes.bool,
  passedType: PropTypes.string,
  selectedAudience: PropTypes.string,
  ga: PropTypes.bool,
  headlineFilter: PropTypes.string,
  filterByClick: PropTypes.bool,
}