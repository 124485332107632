import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { createGAQuery } from "../../../data/WidgetData";
import { AudienceHeadlineList } from "./AudienceHeadlineList";
import { useGetGaAudienceDetailsQuery, useGetHeadlineAthenaGroupsQuery } from "../../../app/api";
import { useGetHeadlineGroupsQuery } from "../../../app/api";
import { useNavigate, createSearchParams } from "react-router-dom";
import { selectCurrentHeadlineDateRange } from "../../../features/audiences/audienceSlice";
import { selectWidgetFilters } from "../../../features/currentView/currentViewSlice";

export default function AudienceHeadlines({audience, showHeading}) {

  const filter = useSelector(selectWidgetFilters);
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);

  const [filterQuery, setFilterQuery] = useState('');

  const {data:headlines, isLoading: loadingHeadlines} = useGetHeadlineAthenaGroupsQuery({type: 'one-audience', headline: 'all', headline_search: 'all', include: 'all', exclude: 'all', audience_code: audience, filter_query: filterQuery}); 

  const {data:audienceInfo, isLoading: audienceInfoLoading} = useGetGaAudienceDetailsQuery({audience_code: audience })
  const navigate = useNavigate();

  const {data: allLocalHeadlines} = useGetHeadlineGroupsQuery();

  const [mergedHeadlines, setMergedHeadlines] = useState([]);

  //const maxViewers = Math.max.apply(Math, headlines?.map((o) => {return o.all_count}));

  const [audienceName, setAudienceName] = useState(audience);
  const [audienceDesc, setAudienceDesc] = useState('');

  useEffect( () => {
    setFilterQuery(createGAQuery(filter, currentHeadlineDateRange)); // this filter is for audience bars, not widgets
  }, [filter])

  useEffect( () => {
      if (headlines && allLocalHeadlines) {
        const map = new Map();
        headlines?.forEach(item => map.set(item.headline, item));
        allLocalHeadlines?.forEach(item => map.set(item.headline, {...map.get(item.headline), ...item}));
        const mergedArr = Array.from(map.values());
        setMergedHeadlines(mergedArr.filter(t => t.all_count)); // only list headlines that are active from athena   
      }
  }, [headlines, allLocalHeadlines]);


  useEffect(() => {
    if (audienceInfo  && !audienceInfoLoading) {
      setAudienceDesc(audienceInfo.description);
      setAudienceName(audienceInfo.title);
    }
  }, [audienceInfo]);
  

  return (
    <>
      <div className={showHeading ? 'article-static-top' : 'hidden'}>
        <div className='article-summary'>
          <div className='article-summary-title-wrapper'>
            <h1>{audienceName} Headlines</h1>
            <button className='article-view-button' onClick={() => {navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: audienceInfo?.uuid, cView: 0, custom: audienceInfo?.custom}).toString()});}}><p>View Audience</p></button>
          </div>
          <div className='selected_audience_loading_wrapper audience_data'>          
            {loadingHeadlines && <div className='centered'><i className='fas fa-sync centered fa-spin'/></div>}
          </div>
          <p className='audience-desc'>{audienceDesc}</p>
        </div>  
      </div>
      <div className={'article-bottom audience-list'}>
        {!loadingHeadlines && <AudienceHeadlineList audience={audience} headlines={mergedHeadlines} isLoadingHeadlines={loadingHeadlines}/> }
        <div className='selected_audience_loading_wrapper'>
          {loadingHeadlines && <div className='centered'><i className='fas fa-sync centered fa-spin'/></div>}
        </div>
      </div>
    </>
  )
}

AudienceHeadlines.propTypes = {
  audience: PropTypes.string,
  showHeading: PropTypes.bool,
}