import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { selectUserId } from "../features/auth/authSlice";
import { useGetSelectAllAudiencesQuery, useGetUserAudiencePlatformsQuery, useGetSelectCustomAudiencesQuery } from "../app/api";
import { saveCurrentView } from "../features/currentView/currentViewSlice";

export default function GetAudienceData({getAudience, getMyAudiences, getCustomAudience, audienceUUID, callback, activeAudienceCallback, overrideUserId=false}) {


  const userId = useSelector(selectUserId);

  const dispatch = useDispatch();
  
  // custom will override thisAudience
  // All available preset audiences in system  - getAudiences has to equal true
  const {data: systemAudienceData, isFetching: audiencesFetching} = useGetSelectAllAudiencesQuery(); 

  // All platforms created for the user - getMyAudiences has to equal true
  const {data: activeAudienceData, isFetching: activeAudienceFetching} = useGetUserAudiencePlatformsQuery({user_id: overrideUserId? overrideUserId : userId, audience_id: 0 }, {skip: !getMyAudiences});

  // All custom audiences in system for user  - getCustomAudiences has to equal true
  const {data: customAudienceData, isFetching: customAudiencesFetching} = useGetSelectCustomAudiencesQuery({user_id: overrideUserId? overrideUserId : userId}); 

  const [ audienceData, setAudienceData] = useState([{description:'', count:0, title:'', audience:''}]); 


  const thisAudience = (audienceData?.find(t => t.uuid == audienceUUID));
  const thisCustomAudience = (customAudienceData?.find(t => t.uuid == audienceUUID));


  useEffect(() => {
    if (!audiencesFetching) {
      setAudienceData(systemAudienceData);
    }
  }, [audiencesFetching]);

  useEffect(() => {
    if (!activeAudienceFetching) {
      if (getMyAudiences){
        activeAudienceCallback(activeAudienceData);
      }
    }
  }, [activeAudienceFetching]);

  // custom overrides preset
  useEffect(() => {
    if (thisAudience) {
      if (getAudience && !getCustomAudience) {
        callback(thisAudience);
      }
    }
  }, [thisAudience]);

  // return custom if requested
  useEffect(() => {
    if (!customAudiencesFetching && thisCustomAudience) {
      if (getCustomAudience) {
        dispatch(saveCurrentView({type: 'widgetFilters', value: JSON.parse(thisCustomAudience.filter_query)})); 
        callback(thisCustomAudience);
      }
    }
  }, [thisCustomAudience]);

  return (
    <>
    </>
  )

} 

GetAudienceData.propTypes = {
  getAudience: PropTypes.bool,
  getMyAudiences: PropTypes.bool, 
  getCustomAudience: PropTypes.bool, 
  audienceUUID: PropTypes.string,
  callback: PropTypes.func,
  activeAudienceCallback: PropTypes.func, 
  overrideUserId: PropTypes.string
}

