import React from "react";
import PropTypes from "prop-types";

export default function ReportingTermsPopup({dismissAgreeToTermsPopup, handleAgreeToTerms}) {

  return (
    <div className="reporting-terms-popup-wrapper">
      <h1>DATA USER & REPORTING TERMS <br/> AND CONDITIONS</h1>
      <ul>
        <li>
          <span>1.</span> 
          <p>These terms and conditions govern your (&quot;<b>User</b>&quot;) use of data (&quot;<b>Data</b>&quot;) provided to you through the
CinqDI platform (&quot;<b>Platform</b>&quot;).</p>
        </li>
        <li>
          <span>2.</span>
          <h3>PERMITTED DATA USE.</h3>
          <p>User may use the Data for targeting, analytics, and reporting, and for no other
purposes. User shall only use the Data in compliance with all applicable laws, rules, and regulations governing
such Data (including all relevant privacy laws).</p>
        </li>
        <li>
          <span>3.</span> 
          <h3>TERM.</h3> 
          <p>12 months or until CinqDI ceases to onboard its data file with LiveRamp, whichever comes first. </p>
        </li>
        <li>
          <span>4.</span>
          <h3>TERMINATION AND SUSPENSION</h3>
          <p className="sub_paragraph">A party may terminate this Agreement immediately upon notice if the other party breaches any material
term of this Agreement and fails to cure that breach within 30 days of notice from the non-breaching party. In
addition, either party may terminate this Agreement on 60 days’ notice for any reason or no reason. In addition,
CinqDI reserves the right to suspend or terminate User’s access to the Platform and/or User’s ability to target
campaigns on the basis of the Data at any time. In the event of any termination of this Agreement, CinqDI will
be permitted (in its sole discretion) to complete any active campaigns that were initiated before effective date
of such termination.</p>
        </li>
        <li>
          <span>5.</span>
          <h3>FEES AND REPORTING</h3>
          <ul>
            <li>
              <span>5.1</span>
              <p>$2.50 (U.S. Dollars) per thousand impressions (CPM) and $0.02 (U.S. Dollars) per record for a
data append. Pricing may be changed as long as both parties clearly agree to such change in writing. When a
custom audience is requested a one-time onboarding fee may be required.</p>
            </li>
            <li>
              <span>5.2</span>
              <p>User will submit reporting to CinqDI within 30 days after the end of every month that a
campaign is live. User’s report will show the full volume and billings for the Data, and the amount to be paid
to CinqDI along with information on the particular CinqDI segments accessed by Users on a per job basis.</p>
            </li>
            <li>
              <span>5.3</span>
              <p>CinqDI will provide invoices to User reflecting amount owed. User’s ad server statistics shall
serve as the basis of calculating campaign delivery for invoicing. User will pay all undisputed amounts within 30
days from receiving an invoice.</p>
            </li>
          </ul>
        </li>
        <li>
          <span>6.</span>
          <h3>EMAIL ADDRESSES FOR NOTICES</h3>
          <p className="sub_paragraph" >User is required to provide a billing contact and email address when activating or exporting any
audience datasets.</p>
        </li>
      </ul>
      <div className="reporting-terms-popup-buttons">
        <button className='cancel' onClick={() => dismissAgreeToTermsPopup()}><p>Cancel</p></button>
        <button className='agree' onClick={() => handleAgreeToTerms()}><p>I Agree</p></button>
      </div>
    </div>
  )
}

ReportingTermsPopup.propTypes = {
  dismissAgreeToTermsPopup: PropTypes.func,
  handleAgreeToTerms: PropTypes.func
}