import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
//import { useSelectWidgetFieldsQuery } from "../../app/api";  Replacing with object to test performance
import { selectWidgetFilters, selectFilterView, saveCurrentView } from "../../features/currentView/currentViewSlice";
//import { selectPreferenceChange, preferenceChanged } from "../../features/currentView/currentViewSlice";
import { stateMappings, widgetDisplay } from "../../data/WidgetData";
import { useGetSelectCdsQuery, useGetSelectCountiesQuery } from "../../app/api";
import { useGetSelectStateHouseDistrictQuery, useGetSelectStateSenateDistrictQuery } from "../../app/api";
import { Suspense } from "react";
import { allWidgetFields } from "../../data/WidgetFields";

// county being handled manually
// us_congressional_district being handled manually

export default function WidgetFilter({type, headerClass, isDuplicateState}){

  const filter = useSelector(selectWidgetFilters);
  const dispatch = useDispatch();
  const [filterViews, setFilterViews] = useState(useSelector(selectFilterView));


  // testing with JSON object for performance
  //const { data: allWidgetFields, isSuccess: fieldSuccess } =  useSelectWidgetFieldsQuery({skip: type != 'state'}); 

  const [ stateCountyFilterDisplay, setStateCountyFilterDisplay] = useState([]);
  const [ stateCdsFilterDisplay, setStateCdsFilterDisplay] = useState([]);
  const [ stateSenateFilterDisplay, setStateSenateFilterDisplay] = useState([]);
  const [ stateHouseFilterDisplay, setStateHouseFilterDisplay] = useState([]);

 const {data: counties} = useGetSelectCountiesQuery();
 const {data: cds} = useGetSelectCdsQuery();
 const {data: senate} = useGetSelectStateSenateDistrictQuery();
 const {data: house} = useGetSelectStateHouseDistrictQuery();


 useEffect( () => {
  let newObject = {};
  stateMappings.map(t => {
    newObject[t.stateShort] = 'closed';
  });
  setStateCountyFilterDisplay(newObject);
  setStateCdsFilterDisplay(newObject);
  setStateSenateFilterDisplay(newObject);
  setStateHouseFilterDisplay(newObject);
 },[]);

 const saveCountyFilterView = (thisType) => {
    let newObj = {};
    newObj = {...stateCountyFilterDisplay, [thisType]: toggleFilter(stateCountyFilterDisplay, thisType)}
    setStateCountyFilterDisplay(newObj);
 }

 const saveCdsFilterView = (thisType) => {
    let newObj = {};
    newObj = {...stateCdsFilterDisplay, [thisType]: toggleFilter(stateCdsFilterDisplay, thisType)}
    setStateCdsFilterDisplay(newObj);
 }

 const saveSenateFilterView = (thisType) => {
    let newObj = {};
    newObj = {...stateSenateFilterDisplay, [thisType]: toggleFilter(stateSenateFilterDisplay, thisType)}
    setStateSenateFilterDisplay(newObj);
 }

 const saveHouseFilterView = (thisType) => {
    let newObj = {};
    newObj = {...stateHouseFilterDisplay, [thisType]: toggleFilter(stateHouseFilterDisplay, thisType)}
    setStateHouseFilterDisplay(newObj);
 }

 const saveFilterView = (thisType) => {
    let newObj = {};
    newObj = {...filterViews, [thisType]: toggleFilter(filterViews, thisType)}
    setFilterViews(newObj);
    dispatch(saveCurrentView({type:'filterView', value:newObj}));
 }

  const toggleFilter = (thisFilterView, thisType) => 
  {
    if (thisFilterView[thisType] == 'open') {
      return 'closed';
    }
    else {
      return 'open'
    }

  }

  const displayName = (widget) => {
    return widgetDisplay.find((t) => t.widget == widget).displayName;
  }

  const hasGAandL2 = (widget) => {
    // only filter on widgets that are both GA and L2
    return (widgetDisplay.find((t) => t.widget == widget).hasGA && widgetDisplay.find((t) => t.widget == widget).hasL2 && !widgetDisplay.find((t) => t.widget == widget).skipFilter);
  }

  const senateWidget = (widgetType, thisState) => {
    if (widgetType == 'state') {
      return (
        <Suspense fallback={<p>Loading..</p>}>
          <div className='widget-filter full-page'>
            <div className='leftside label' onClick = {()=>{ saveSenateFilterView(thisState);}}>
              {toggleIcon(stateSenateFilterDisplay, thisState)} 
              {displayName('state_senate_district')}  
            </div>
          </div>
          <div className={'filter-view county ' + stateSenateFilterDisplay[thisState]}>
            <ul>
              {senateFilter(thisState)}
            </ul>
          </div>
        </Suspense>
      )
    }
  }

  const houseWidget = (widgetType, thisState) => {
    if (widgetType == 'state') {
      return (
        <Suspense fallback={<p>Loading..</p>}>
          <div className='widget-filter full-page'>
            <div className='leftside label' onClick = {()=>{ saveHouseFilterView(thisState);}}>
              {toggleIcon(stateHouseFilterDisplay, thisState)} 
              {displayName('state_house_district')}  
            </div>
          </div>
          <div className={'filter-view county ' + stateHouseFilterDisplay[thisState]}>
            <ul>
              {houseFilter(thisState)}
            </ul>
          </div>
        </Suspense>
      )
    }
  }

  const cdsWidget = (widgetType, thisState) => {
    if (widgetType == 'state') {
      return (
        <Suspense fallback={<p>Loading..</p>}>
          <div className='widget-filter full-page'>
            <div className='leftside label' onClick = {()=>{ saveCdsFilterView(thisState);}}>
              {toggleIcon(stateCdsFilterDisplay, thisState)} 
              {displayName('us_congressional_district')}  
            </div>
          </div>
          <div className={'filter-view county ' + stateCdsFilterDisplay[thisState]}>
            <ul>
              {cdsFilter(thisState)}
            </ul>
          </div>
        </Suspense>
      )
    }
  }

  const cdsFilter = (thisState) => {
    return cds?.filter(t => t.state === thisState)
      .sort((a,b) => a.us_congressional_district.localeCompare(b.us_congressional_district))
      .map((t,i) => (
        <li key={i}>
          <label>
            {isChecked(filter,'us_congressional_district', t.county) ? 
              <>
                <input type='checkbox' id={t.us_congressional_district} defaultChecked className='us_congressional_district' onClick={()=>{toggleField(filter,'us_congressional_district',dispatch)}}></input>
              </>
            :
              <input type='checkbox' id={t.us_congressional_district} className='us_congressional_district' onClick={()=>{toggleField(filter,'us_congressional_district',dispatch)}}></input>
            }
            {t.us_congressional_district}
          </label>
        </li>
      ))
  }

  const countyWidget = (widgetType, thisState) => {
    if (widgetType == 'state') {
      return (
        <Suspense fallback={<p>Loading..</p>}>
          <div className='widget-filter full-page'>
            <div className='leftside label' onClick = {()=>{ saveCountyFilterView(thisState);}}>
              {toggleIcon(stateCountyFilterDisplay, thisState)} 
              {displayName('county')}  
            </div>
          </div>
          <div className={'filter-view county ' + stateCountyFilterDisplay[thisState]}>
            <ul>
              {countyFilter(thisState)}
            </ul>
          </div>
        </Suspense>
      )
    }
  }

  const countyFilter = (thisState) => {
    return counties?.filter(t => t.state === thisState)
      .sort((a,b) => a.county.localeCompare(b.county))
      .map((t,i) => (
        <li key={i}>
          <label>
            {isChecked(filter,'county', t.county) ? 
              <>
                <input type='checkbox' id={t.county} defaultChecked className='county' onClick={()=>{toggleField(filter,'county',dispatch)}}></input>
              </>
            :
              <input type='checkbox' id={t.county} className='county' onClick={()=>{toggleField(filter,'county',dispatch)}}></input>
            }
            {t.county}
          </label>
        </li>
      ))
  }

  const senateFilter = (thisState) => {
    return senate?.filter(t => t.state === thisState)
      .sort((a,b) => a.state_senate_district.localeCompare(b.state_senate_district))
      .map((t,i) => (
        <li key={i}>
          <label>
            {isChecked(filter,'state_senate_district', t.state_senate_district) ? 
              <>
                <input type='checkbox' id={t.state_senate_district} defaultChecked className='state_senate_district' onClick={()=>{toggleField(filter,'state_senate_district',dispatch)}}></input>
              </>
            :
              <input type='checkbox' id={t.state_senate_district} className='state_senate_district' onClick={()=>{toggleField(filter,'state_senate_district',dispatch)}}></input>
            }
            {t.state_senate_district}
          </label>
        </li>
      ))
  }

  const houseFilter = (thisState) => {
    return house?.filter(t => t.state === thisState)
      .sort((a,b) => a.state_house_district.localeCompare(b.state_house_district))
      .map((t,i) => (
        <li key={i}>
          <label>
            {isChecked(filter,'state_house_district', t.state_house_district) ? 
              <>
                <input type='checkbox' id={t.state_house_district} defaultChecked className='state_house_district' onClick={()=>{toggleField(filter,'state_house_district',dispatch)}}></input>
              </>
            :
              <input type='checkbox' id={t.state_house_district} className='state_house_district' onClick={()=>{toggleField(filter,'state_house_district',dispatch)}}></input>
            }
            {t.state_house_district}
          </label>
        </li>
      ))
  }

  const subOrderSort = (a, b) => {
    const subOrdering = widgetDisplay.find(item => item.widget === type)?.subOrder;
    return subOrdering ? subOrdering.indexOf(a.widget_field) - subOrdering.indexOf(b.widget_field) : 0;
  };

  const widgetFilter = (thisType) => {
    return allWidgetFields.filter(t => t.widget_type === thisType)
      .sort((a, b) => a.widget_field.localeCompare(b.widget_field))
      .sort(subOrderSort)
      .filter((t) =>  thisType == 'state' ? stateMappings.find(s => s.stateShort == t.widget_field) : true)
      .map((t, i) => (
        (isDuplicateState != 'true' ? 
          <li key={i}>
            <label>
              {isChecked(filter, thisType, t.widget_field) ? 
                <>
                  <input type='checkbox' id={t.widget_field} defaultChecked className={thisType} onClick={()=>{toggleField(filter,thisType,dispatch)}}></input>
                </>
              :
                <input type='checkbox' id={t.widget_field} className={thisType} onClick={()=>{toggleField(filter,thisType,dispatch)}}></input>
              }
              {t.widget_field}
            </label>
            {isChecked(filter,thisType, t.widget_field) && countyWidget(t.widget_type, t.widget_field)} 
            {isChecked(filter,thisType, t.widget_field) && cdsWidget(t.widget_type, t.widget_field)} 
            {isChecked(filter,thisType, t.widget_field) && senateWidget(t.widget_type, t.widget_field)} 
            {isChecked(filter,thisType, t.widget_field) && houseWidget(t.widget_type, t.widget_field)} 
          </li>
        :
          /** This is a temporary workaround for the Compare page, as there are two 'State' filters which interfere with each other.  
           * The workaround is to use one as usual as a master data point, and the second simply simulates clicking the master.  
           * Once the design is finalised, we will revert to a single filter, in the correct location */
          <li key={i}>
            <label>
              {isChecked(filter, thisType, t.widget_field) ? 
                <>
                  <input type='checkbox' id={t.widget_field+'-second'} defaultChecked className={thisType+'-second'} onClick={()=>{clickMasterState(t.widget_field)}}></input>
                </>
              :
                <input type='checkbox' id={t.widget_field+'-second'} className={thisType+'-second'} onClick={()=>{clickMasterState(t.widget_field)}}></input>
              }
              {t.widget_field}
            </label>
          </li>
        )

      ))
  }

  const clickMasterState = (masterId) => {
    console.log(`~~ clicking the master object with ID ${masterId}`);
    const checkbox = document.getElementById(masterId);
    checkbox.click();
  }

  const toggleIcon = (thisFilterView,thisType) => {
    if (thisFilterView[thisType] == 'open') {
      return <i className='fa-solid fa-minus bump-right-10'/>
    } 
    else {
      return <i className='fa-solid fa-plus bump-right-10'/>
    }
  }

  const filterTypeCount = (thisType) => {
    if (filter[thisType]?.filter(t=>t!='all').length > 0) {
      return <p className='inline'>&nbsp;({filter[thisType].length})</p>
    }
  }
         
  const filterHeading = () => {
    return (
      <>
        <div className='widget-filter full-page'>
          <div className={'leftside label ' + headerClass} onClick = {()=>{saveFilterView(type.toLowerCase());}}>
            {toggleIcon(filterViews,type)} 
            {displayName(type)}  
            {filterTypeCount(type)} 
          </div>
        </div>
        <div className={'filter-view ' + type + ' ' + filterViews[type]} style={{width: isDuplicateState == 'true' && '80px', marginTop: isDuplicateState == 'true' && 'unset', paddingRight: isDuplicateState == 'true' && '30px'}}>
          <ul>
            {widgetFilter(type)}
          </ul>
        </div>
      </>
    )
  }

  return (
    <div>
      {hasGAandL2(type) && filterHeading()}
    </div>
  );
}

WidgetFilter.propTypes = {
  type: PropTypes.string,
  headerClass: PropTypes.string,
  isDuplicateState: PropTypes.string // The Compare page temporarily has TWO State filters, which will interfere with each other unless we flag one of them
}


export const isChecked = (filter, type, value) => {

  const newValue = value?.replace(/,/g,'|');
  const index = filter[type]?.indexOf(newValue);
  if (index !== -1) {
    return true;
  }
  else {
    return false;
  }
}

export const toggleField = async (filter,type,dispatch) => {

    let newObject = filter;
    let newArray = [];

    console.log('filter is', filter);

    const elementsByClass = document.getElementsByClassName(type);
    const elements = Array.from(elementsByClass); 
    elements.map((element) => {
      if (element.checked) {
          newArray.push(element.id.replace(/,/g,'|'));
        }
      });

    newArray = newArray.length == 0 ? ['all'] : newArray;

    newObject = { ...newObject, [type]: newArray};
    
    console.log('filter newObject is', newObject);

    dispatch(saveCurrentView({type:'widgetFilters', value:newObject}));

  }
