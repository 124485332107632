import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetSelectAllAudiencesQuery, useAddAudienceMutation, useUpdateAudienceMutation, useUpdateAudienceToOrgsMutation, useOrgListQuery } from "../../app/api";
import { selectCategories } from "../../features/audiences/audienceSlice";
import { numberWithCommas } from "../userSettings";
import { selectUserRole } from "../../features/auth/roleSlice";
import { isPermissionsUC } from "../../features/auth/authSlice";

export default function Audiences() {

  const { data: allAudiences, isFetching: isUpdating, isSuccess: allSuccess, refetch: queryAudiences } =  useGetSelectAllAudiencesQuery();
  const categories = useSelector(selectCategories);
  const [ addNewAudience ] = useAddAudienceMutation();
  const [ updateAudience ] = useUpdateAudienceMutation();
  const userRole = useSelector(selectUserRole);
  const [updateAudienceToOrgs, { isSuccess: updateSuccess }] = useUpdateAudienceToOrgsMutation();
  const [localAudiences, setLocalAudiences] = useState([]);
  const hasUCpermissions = useSelector(isPermissionsUC);

  const loggedInAdminRole = useSelector(selectUserRole) === 'null' ? 0 : useSelector(selectUserRole);

  const canEdit = (hasUCpermissions && (loggedInAdminRole === 'SUPERADMIN')) 


  useEffect(() => {
    // reload on page return
    if (localAudiences) {
      queryAudiences();
    }
  }, [])

  useEffect(() => {
    if (allSuccess && allAudiences) {
      setLocalAudiences(allAudiences);
    }
  }, [allSuccess, allAudiences]);

  const handleOrgChange = async (e, uuid) => {
    const selectedOrgId = e.target.value;

    // Optimistically update the local state
    setLocalAudiences((prev) =>
      prev.map((audience) =>
        audience.uuid === uuid ? { ...audience, org_id: selectedOrgId } : audience
      )
    );

    try {
      await updateAudienceToOrgs({ audience_uuid: uuid, org_id: selectedOrgId }).unwrap();
    } catch (error) {
      console.error('Error updating org:', error);
      // Rollback the optimistic update on error
      setLocalAudiences((prev) =>
        prev.map((audience) =>
          audience.uuid === uuid ? { ...audience, org_id: allAudiences.find(a => a.uuid === uuid).org_id } : audience
        )
      );
    }
  };


  const { data: orgList } =  useOrgListQuery();

  const categorySelect = () => {
    return categories.map((t,i) => (
      <option key={i}>{t.title}</option>
    ))
  }

  const markToSave = (id, clear) => {
    if (clear) {
      document.getElementById(id+"_save").classList = "fa-solid fa-save ";

    }
    else {
      document.getElementById(id+"_save").classList = "fa-solid fa-save green";
    }
  }

  const saveAudience = (id, audience) => {
    let audienceExists = id && allAudiences.some( record => record.id === id) 
    let title = document.getElementById(id+"_title").value;
    let desc = document.getElementById(id+"_desc").value;
    let active = document.getElementById(id+"_checkbox").checked;
    let category = document.getElementById(id+"_category").value;

    if (audienceExists) {
      updateAudience({id: id, title: title, description: desc, active: active  ? 1 : 0, audience: audience, custom: 0});
    }
    else {
      addNewAudience({title: title, description: desc, active: active  ? 1 : 0, audience: audience, custom:0, deleted_by:0, category: category});
    }

    queryAudiences();
    markToSave(id, true);
  }

  const checkBox = (id, active) => {
    if (active == 1) {
      return <input readOnly = {!canEdit} id={id + "_checkbox"} onChange={()=>{markToSave(id,false)}} className='switch-input' type='checkbox' defaultChecked />
    }
    else {
      return <input readOnly = {!canEdit}  id={id + "_checkbox"} onChange={()=>{markToSave(id,false)}} className='switch-input' type='checkbox' />
    }
  }

  const audienceList = () => {
    if (allSuccess) {
      return localAudiences.map((audience,i) => (
        <tr key={i}>
          {userRole == "SUPERADMIN" && <td>{audience.audience}</td>}
          <td>{numberWithCommas(audience.count)}</td>
          <td><input readOnly = {!canEdit} id={audience.id+"_title"} type='text' defaultValue={audience.title} onChange={()=>{markToSave(audience.id,false)}}/></td>
          <td><input  readOnly = {!canEdit} id={audience.id+"_desc"} className='large' type='text' defaultValue={audience.description} onChange={()=>{markToSave(audience.id,false)}}/></td>
          <td>
            <select disabled={canEdit ? null : true} onChange={(e) => handleOrgChange(e, audience.uuid)} value={audience.org_id || ""}>
              <option value=""></option>
              {orgList ? orgList.filter((org) => (org.org_status == 1)).map((org, i) => <option key={i + "_" + org.id} value={org.id}>{org.name}</option>) : <option value=""></option>}
            </select>
          </td>
          <td className='hidden'><select id={audience.id+"_category"} onChange={()=>{markToSave(audience.id,false)}}> {categorySelect()} </select></td>
          <td className='checkbox-switch hidden'>
            <label className="switch">
              {checkBox(audience.id,audience.active)}
	            <span className="switch-label" data-on="Show" data-off="Hide"></span> 
	            <span className="switch-handle"></span> 
            </label> 
            </td>
          <td><i id={audience.id+"_save"} className='fa-solid fa-save hidden' onClick = {() => {saveAudience(audience.id, audience.audience)}}></i></td>
        </tr>
      ))
    }
  }

  return (
    <div className='audience-display'>
      <h3> Audience List {allSuccess ? '': 'Loading'} </h3>
      <p className='green'>&nbsp;{isUpdating && <>Fetching...</>}{updateSuccess && !isUpdating && <> Audience updated </>}</p>
      <div className='form'>
        <div className='full-page'>
          <table>
            <thead>
              <tr>
                {userRole == "SUPERADMIN" && <th >Internal ID</th>}
                <th>Record Count</th>
                <th>Title</th>
                <th>Description</th>
                <th>Organization</th>
                <th className='hidden'>Category</th>
                <th className='hidden'>Activate</th>
              </tr>
            </thead>
            <tbody>
              {audienceList()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
