import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip} from "chart.js";
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from "prop-types";
import { getFilter } from "../../features/currentView/currentViewSlice";
import { useSelectWidgetDataQuery } from "../../app/api";
import { allWidgetFields } from "../../data/WidgetFields";
import { createLikelyVoterDataObject } from "../../data/WidgetData";
import { useSelector } from "react-redux";
//import { selectWidgetData, selectAllWidgetData, selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { selectWidgetFields } from "../../features/currentView/currentViewSlice";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import Loading from "../UIElements/Loading";
import { noFilter } from "../../features/currentView/currentViewSlice";
import { toggleFilterField } from "./BarGraph";

// for clickable filter, I do not need to check widgetDisplay here because this Bar Graph is specific to only one type of filter (voter)
export default function LikelyVoterBarGraph({type, selectedAudience}) {

  if (type=='placeholder') { //  I just don't want to use this or get rid of it yet
    console.log('type here is a placeholder since this widget is really 3 widgets in one', type);
  }

  const createDataObject = createLikelyVoterDataObject; // override for likely voter widget
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = useSelector(selectWidgetFields);
  const dispatch = useDispatch();

  const [myPrimaryWidgetData, setMyPrimaryWidgetData] = useState([]);
  const [myAllPrimaryWidgetData, setMyAllPrimaryWidgetData] = useState([]);
  const [primaryHasFetched, setPrimaryHasFetched] = useState(false);

  const [myPresidentialWidgetData, setMyPresidentialWidgetData] = useState([]);
  const [myAllPresidentialWidgetData, setMyAllPresidentialWidgetData] = useState([]);
  const [presidentialHasFetched, setPresidentialHasFetched] = useState(false);

  const [myGeneralWidgetData, setMyGeneralWidgetData] = useState([]);
  const [myAllGeneralWidgetData, setMyAllGeneralWidgetData] = useState([]);
  const [generalHasFetched, setGeneralHasFetched] = useState(false);


  const{data: primaryWidgetData, refetch: refetchPrimaryWidgets, isFetching:primaryWidgetsFetching} = useSelectWidgetDataQuery(getFilter('likely_primary_voter', selectedAudience?.audience, 'audience', filter));
  const{data: allPrimaryWidgetData, isFetching:allPrimaryWidgetsFetching} = useSelectWidgetDataQuery(getFilter('likely_primary_voter', 'none','all', noFilter), {skip: myAllPrimaryWidgetData.length > 0});

  const{data: presidentialWidgetData, refetch: refetchPresidentialWidgets, isFetching:presidentialWidgetsFetching} = useSelectWidgetDataQuery(getFilter('likely_presidential_primary_voter', selectedAudience?.audience, 'audience', filter));
  const{data: allPresidentialWidgetData, isFetching:allPresidentialWidgetsFetching} = useSelectWidgetDataQuery(getFilter('likely_presidential_primary_voter', 'none','all', noFilter), {skip: myAllPresidentialWidgetData.length > 0});

  const{data: generalWidgetData, refetch: refetchGeneralWidgets, isFetching:generalWidgetsFetching} = useSelectWidgetDataQuery(getFilter('general_election_voter', selectedAudience?.audience, 'audience', filter));
  const{data: allGeneralWidgetData, isFetching:allGeneralWidgetsFetching} = useSelectWidgetDataQuery(getFilter('general_election_voter', 'none','all', noFilter), {skip: myAllGeneralWidgetData.length > 0});

  const widgetFieldsByQuery = allWidgetFields;

  const [primaryDataObject, setPrimaryDataObject] = useState(null);
  const [presidentialDataObject, setPresidentialDataObject] = useState(null);
  const [generalDataObject, setGeneralDataObject] = useState(null);
  const [officialMergedObject, setOfficialMergedObject] = useState(null);

  useEffect(() => {
    if (primaryHasFetched) {
      refetchPrimaryWidgets();
    }
  },[filter])

  useEffect(() => {
    if (presidentialHasFetched) {
      refetchPresidentialWidgets();
    }
  },[filter])

  useEffect(() => {
    if (generalHasFetched) {
      refetchGeneralWidgets();
    }
  },[filter])

  useEffect(() => {
    if (!primaryWidgetsFetching) {
      setMyPrimaryWidgetData(primaryWidgetData);
      if (!primaryHasFetched) {
        setPrimaryHasFetched(true);
      }
    }
  }, [primaryWidgetsFetching]);

  useEffect(() => {
    if (!presidentialWidgetsFetching) {
      setMyPresidentialWidgetData(presidentialWidgetData);
      if (!presidentialHasFetched) {
        setPresidentialHasFetched(true);
      }
    }
  }, [presidentialWidgetsFetching]);

  useEffect(() => {
    if (!generalWidgetsFetching) {
      setMyGeneralWidgetData(generalWidgetData);
      if (!generalHasFetched) {
        setGeneralHasFetched(true);
      }
    }
  }, [generalWidgetsFetching]);

  useEffect(() => {
    if (!allPrimaryWidgetsFetching) {
      setMyAllPrimaryWidgetData(allPrimaryWidgetData);
    }
  }, [allPrimaryWidgetsFetching])

  useEffect(() => {
    if (!allPresidentialWidgetsFetching) {
      setMyAllPresidentialWidgetData(allPresidentialWidgetData);
    }
  }, [allPresidentialWidgetsFetching])

  useEffect(() => {
    if (!allGeneralWidgetsFetching) {
      setMyAllGeneralWidgetData(allGeneralWidgetData);
    }
  }, [allGeneralWidgetsFetching])

  useEffect(() => {
    if (widgetFieldsByQuery && widgetFields.length == 0){
      dispatch(saveCurrentView({type:'widgetFields', value: widgetFieldsByQuery })) 
    }
  }, [widgetFieldsByQuery]);

  useEffect(() => {
    setPrimaryDataObject(null);
  }, [filter]);

  useEffect(() => {
    setPresidentialDataObject(null);
  }, [filter]);

  useEffect(() => {
    setGeneralDataObject(null);
  }, [filter]);

  useEffect(() => {
    if(!primaryWidgetsFetching && !allPrimaryWidgetsFetching && !primaryDataObject) {
      const newPrimaryDataObject = createDataObject(myPrimaryWidgetData, allPrimaryWidgetData, widgetFields.filter(t => t.widget_type == 'likely_primary_voter'), 'likely_primary_voter');
      setPrimaryDataObject(newPrimaryDataObject);
    }
  }, [myPrimaryWidgetData, myAllPrimaryWidgetData, widgetFields.length]);

  useEffect(() => {
    if(!presidentialWidgetsFetching && !allPresidentialWidgetsFetching && !presidentialDataObject) {
      const newPresidentialDataObject = createDataObject(myPresidentialWidgetData, allPresidentialWidgetData, widgetFields.filter(t => t.widget_type == 'likely_presidential_primary_voter'), 'likely_presidential_primary_voter');
      setPresidentialDataObject(newPresidentialDataObject);
    }
  }, [myPresidentialWidgetData, myAllPresidentialWidgetData, widgetFields.length]);

  useEffect(() => {
    if(!generalWidgetsFetching && !allGeneralWidgetsFetching && !generalDataObject) {
      const newGeneralDataObject = createDataObject(myGeneralWidgetData, allGeneralWidgetData, widgetFields.filter(t => t.widget_type == 'general_election_voter'), 'general_election_voter');
      setGeneralDataObject(newGeneralDataObject);
    }
  }, [myGeneralWidgetData, myAllGeneralWidgetData, widgetFields.length]);

  useEffect(() => {
    if (primaryDataObject && presidentialDataObject && generalDataObject) {
      let mergedObject = {categories: [], colors: [], dataset1: [], dataset2: [], no_of_bars: 2, type: "percentage"};
      primaryDataObject.categories.map(t => {mergedObject.categories.push(t)});
      presidentialDataObject.categories.map(t => {mergedObject.categories.push(t)});
      generalDataObject.categories.map(t => {mergedObject.categories.push(t)});
      primaryDataObject.colors.map(t => {mergedObject.colors.push(t)});
      presidentialDataObject.colors.map(t => {mergedObject.colors.push(t)});
      generalDataObject.colors.map(t => {mergedObject.colors.push(t)});
      primaryDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      presidentialDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      generalDataObject.dataset1.map(t => {mergedObject.dataset1.push(t)});
      primaryDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      presidentialDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      generalDataObject.dataset2.map(t => {mergedObject.dataset2.push(t)});
      setOfficialMergedObject(mergedObject);
    }
  }, [primaryDataObject, presidentialDataObject, generalDataObject]);
  

  // Bar Graph dataObject parameters:
  // title: to be display along the top of the widget
  // maxes: {value: value of max %, desc: descripition between, cat: category of max}
  // no_of_bars: 1 or 2 
  // categories: [name, name] names of categories
  // dataset1: [data, data] data by category
  // dataset2: [data, data] if applicable 
  // type: 'percentage' or 'count'
  // colors: [color1, color2]

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartDataLabels);

  const options = {
    indexAxis: 'y' ,
    layout: {
      padding: {
        right: 16
      }
    },
    scales: {
      x: {
        display: false,
        offset: true,
      },
      y: {
        display: true,
        grid: {
          display: false,
        }, 
        ticks: {
          color: '#000000',
          font: {
            size: 14,
          }
        } 
      }
    },
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    onHover: () => {
        var el = document.getElementById(type);
        el.style.cursor = "pointer" ;
    },
    onClick: (e, activeEls) => {
      let datasetIndex = activeEls[0].datasetIndex;
      let dataIndex = activeEls[0].index;
      let datasetLabel = e.chart.data.datasets[datasetIndex].label;
      let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
      let label = e.chart.data.labels[dataIndex];
      console.log("In click", datasetLabel, label, value);
      let voter_type = (label == 'Primary') ? 'likely_primary_voter' : 
         (label == 'General Election') ? 'general_election_voter'  :
         'likely_presidential_primary_voter';
      toggleFilterField(filter, voter_type, 'Y', dispatch, saveCurrentView);
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#000000',
        anchor: 'end',
        align: 'right',
        offset: 0,
        formatter: function(value) {
          return value + '%';
        }, 
        font: {
          size: 14,
        },
      }, 
      tooltip: {
        titleFont: {
          size: 13,
        }, 
        bodyFont: {
          size: 13,
        }
      },
    },
  };

const labels = officialMergedObject?.categories;

const data = {
  labels,
  datasets: [
    {
      label: 'This Audience',
      data: officialMergedObject?.dataset1,
      backgroundColor: '#2F5D62' 
    },
    {
      label: 'All Audiences',
      data: officialMergedObject?.dataset2,
      backgroundColor: '#56BBB1'
    },
  ],
};

  return (
    <>
      {officialMergedObject && primaryDataObject && presidentialDataObject && generalDataObject ? 
        <Bar id={type} key={officialMergedObject} plugins={[ChartDataLabels]} options={options} data={data} />
        :
        <Loading/>
      }
    </>
  );
}

LikelyVoterBarGraph.propTypes = {
  type: PropTypes.string,
  selectedAudience: PropTypes.object, 
}