import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {downloadCSVTimestamp, downloadCSVFullTimestamp} from "../userSettings"; 
import { useSelectGAWidgetDataQuery } from "../../app/api";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createDataObject } from "../../data/WidgetData";
import { allWidgetFields } from "../../data/WidgetFields";
// GA data for corresponding widgets
import { createGAQuery, gaWidgetSelect, gaWidgetGroupBy } from "../../data/WidgetData";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import { sourceRollup } from "../../data/WidgetData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import { topicMappings } from "../../data/HeadlineData";

/*  
Article Insights
    
Gender - gender
Age - age_range_standard
Party - party
Ethnicity - ethnicity    
Media Source - source
*/

export default function CompleteArticleGAWidgetCSVDownload({selectedAudience, headlineFilter='none', setRunCSVDownloader}){
  const filter = useSelector(selectWidgetFilters);
  const widgetFields = allWidgetFields; 
  const currentDateRange = useSelector(selectCurrentHeadlineDateRange);
  const gaQueryString = createGAQuery(filter, currentDateRange, headlineFilter);
  const savedTopics = useSelector(selectInTopics);
  const savedOutTopics = useSelector(selectOutTopics);

  const mediaSourceFields = () => { 
    let newArray = [];
    sourceRollup.map(t => {
      newArray.push(t.source);
    });
    return newArray;
  }

  const formatTopicsForQuery = (topics) => {
    if (topics.length > 0 && topics[0].length > 0 ) {
      let newArray = mergeByTopic(topics, topicMappings);
      return newArray.join(",") || 'all'
    }
  }

  // Data queries

  //Gender - gender 
  const{data: genderWidgetData, isSuccess: genderSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('gender'),
    group_by_query_string: gaWidgetGroupBy('gender'),
    widget_type: 'gender', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Gender - gender
  const{data: genderAllWidgetData, isSuccess: genderAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('gender'),
    group_by_query_string: gaWidgetGroupBy('gender'),
    widget_type: 'gender', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });        


  //Age - age_range_standard 
  const{data: ageWidgetData, isSuccess: ageSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('age_range_standard'),
    group_by_query_string: gaWidgetGroupBy('age_range_standard'),
    widget_type: 'age_range_standard', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Age - age_range_standard
  const{data: ageAllWidgetData, isSuccess: ageAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('age_range_standard'),
    group_by_query_string: gaWidgetGroupBy('age_range_standard'),
    widget_type: 'age_range_standard', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });


  // Party - party 
  const{data: partyWidgetData, isSuccess: partySuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('party'),
    group_by_query_string: gaWidgetGroupBy('party'),
    widget_type: 'party', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Party - party
  const{data: partyAllWidgetData, isSuccess: partyAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('party'),
    group_by_query_string: gaWidgetGroupBy('party'),
    widget_type: 'party', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });        


  //Ethnicity - ethnicity 
  const{data: ethnicityWidgetData, isSuccess: ethnicitySuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('ethnicity'),
    group_by_query_string: gaWidgetGroupBy('ethnicity'),
    widget_type: 'ethnicity', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });

  //All Ethnicity - ethnicity
  const{data: ethnicityAllWidgetData, isSuccess: ethnicityAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('ethnicity'),
    group_by_query_string: gaWidgetGroupBy('ethnicity'),
    widget_type: 'ethnicity', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: 'none',
  });    
   

 //Media Source - source 
  const{data: mediaSourceWidgetData, isSuccess: mediaSourceSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('source'),
    group_by_query_string: gaWidgetGroupBy('source'),
    widget_type: 'source', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: mediaSourceFields(),
  });

  //All Media Source - source
  const{data: mediaSourceAllWidgetData, isSuccess: mediaSourceAllSuccess} = useSelectGAWidgetDataQuery({
    filter_query_string: gaQueryString,
    widget_query_string: gaWidgetSelect('source'),
    group_by_query_string: gaWidgetGroupBy('source'),
    widget_type: 'source', 
    audience_code: selectedAudience.ga_code || selectedAudience.base_ga_code || 'all',
    include: formatTopicsForQuery(savedTopics),
    exclude: formatTopicsForQuery(savedOutTopics),
    source_list: mediaSourceFields(),
  });      

  
  useEffect(() => {
    if (  
          genderSuccess && genderAllSuccess
       && ageSuccess && ageAllSuccess
       && ethnicitySuccess && ethnicityAllSuccess
       && partySuccess && partyAllSuccess
       && mediaSourceSuccess && mediaSourceAllSuccess
    ){
       downloadCsv();
    }
  }, [genderWidgetData, genderAllWidgetData,
      ageWidgetData, ageAllWidgetData, 
      partyWidgetData, partyAllWidgetData,
      ethnicityWidgetData, ethnicityAllWidgetData,
      mediaSourceWidgetData && mediaSourceAllWidgetData]
  );

  /* 
  const logger = () => {
    console.log("Gender: " + JSON.stringify(genderWidgetData));
    console.log("Gender All: " + JSON.stringify(genderAllWidgetData));
    console.log("Age: " + JSON.stringify(ageWidgetData));
    console.log("Age All: " + JSON.stringify(ageAllWidgetData));    
    console.log("Party: " + JSON.stringify(partyWidgetData));
    console.log("Party All: " + JSON.stringify(partyAllWidgetData));
    console.log("Ethnicity: " + JSON.stringify(ethnicityWidgetData));
    console.log("Ethnicity All: " + JSON.stringify(ethnicityAllWidgetData));
    console.log("Media Source: " + JSON.stringify(mediaSourceWidgetData));
    console.log("Media Source All: " + JSON.stringify(mediaSourceAllWidgetData));
  } 
  */
  
  const buildWidgetData = (widgetData, allWidgetData, type) => {
    const newDataObject = createDataObject(widgetData, allWidgetData, widgetFields.filter(t => t.widget_type == type), type);
    return newDataObject;
  }

  const buildData = () => {
    let completeWidgetData = [];
    completeWidgetData.push(['Gender', buildWidgetData(genderWidgetData, [], 'gender')]);   
    completeWidgetData.push(['Age', buildWidgetData(ageWidgetData, [], 'age_range_standard')]);
    completeWidgetData.push(['Party', buildWidgetData(partyWidgetData, [], 'party')]);  
    completeWidgetData.push(['Ethnicity', buildWidgetData(ethnicityWidgetData, [], 'ethnicity')]); 
    completeWidgetData.push(['Media Source', buildWidgetData(mediaSourceWidgetData, mediaSourceAllWidgetData, 'source')]); 
    return completeWidgetData;
  }

  const downloadCsv = () => {
    let completeWidgetData = buildData();
    //logger();
    let downloadTimestamp = downloadCSVTimestamp();
    let downloadFullTimestamp = downloadCSVFullTimestamp();
    let result  = "Readership Insights \n";
    result += "Date: ," + downloadFullTimestamp + "\n\n";

    for (let i = 0; i < completeWidgetData.length; i++) {
      result += 'Demographic: ' + completeWidgetData[i][0] + '\n';
      for (let j = 0; j < completeWidgetData[i][1]['dataset1'].length; j++) {
        result += `"${completeWidgetData[i][1]['categories'][j]}", ${completeWidgetData[i][1]['dataset1'][j]}%\n`;
      }
      result += '\n';
    }

    const url = window.URL.createObjectURL(new Blob([result])); 
    const link = document.createElement('a');
    link.href = url;

    const fileName = "Readership Insights - " + downloadTimestamp + ".csv";
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setRunCSVDownloader(false);
  }

  return (
    <>
    </>
  );
}

CompleteArticleGAWidgetCSVDownload.propTypes = {
  selectedAudience: PropTypes.string,
  headlineFilter: PropTypes.string,
  setRunCSVDownloader: PropTypes.func
}