import React from "react";

import TopicTreeMap from "../../Widgets/TopicTreeMap";
import PropTypes from "prop-types";

export default function Topics({audience_code}) {
  return (
    <>
      <TopicTreeMap audience_code={audience_code} type={'topics'}/>
    </>
  )

}

Topics.propTypes = {
  audience_code: PropTypes.string,
}