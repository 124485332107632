import React from "react";

export default function Metrics() {

  return (
    <div>
      <h1>Metrics</h1>
    </div>
  )
}
