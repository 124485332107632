export const liveRampPlatforms = [
  {
    id: 1,
    text: 'Facebook',
    value: 'Facebook',
    image: 'facebook.png',
    rate: 2.50,
    active: 1

  },
  {
    id: 2,
    text: 'Pinterest',
    value: 'Pinterest',
    image: 'pinterest.jpg',
    rate: 2.50,
    active: 0
  },
  {
    id: 3,
    text: 'Google Ad Manager',
    value: 'Google Ad Manager',
    image: 'google_admanager.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 4,
    text: 'Xandr',
    value: 'Xandr',
    image: 'xandr.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 5,
    text: 'Snapchat',
    value: 'Snapchat',
    image: 'snapchat.png',
    rate: 2.50,
    active: 0
  },
  {
    id: 6,
    text: 'LinkedIn',
    value: 'LinkedIn',
    image: 'linkedin.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 7,
    text: 'TikTok',
    value: 'TikTok',
    image: 'tiktok.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 8,
    text: 'X',
    value: 'X',
    image: 'x.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 9,
    text: 'Lotame',
    value: 'Lotame',
    image: 'lotame.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 10,
    text: 'StackAdapt',
    value: 'StackAdapt',
    image: 'stackadapt.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 11,
    text: 'The Trade Desk',
    value: 'The Trade Desk',
    image: 'thetradedesk.jpg',
    rate: 2.50,
    active: 1
  },
  {
    id: 12,
    text: 'Liveramp',
    value: 'Liveramp',
    image: 'liveramp.jpg',
    rate: 2.50,
    active: 1
  },
  {
    id: 13,
    text: 'Display and Video 360',
    value: 'Display and Video 360',
    image: '360.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 14,
    text: 'Google Ads',
    value: 'Google Ads',
    image: 'google_ads.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 15,
    text: 'Basis',
    value: 'Basis',
    image: 'basis.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 16,
    text: 'Adobe',
    value: 'Adobe',
    image: 'adobe.png',
    rate: 2.50,
    active: 1
  },
  {
    id: 17,
    text: 'adform',
    value: 'adform',
    image: 'adform.png',
    rate: 2.50,
    active: 1
  },
];