import React from "react";
import { useNavigate } from "react-router-dom";

import ForgotPasswordForm from "../features/auth/ForgotPasswordForm";
import Header from "../routes/Home/Header";
import Footer from "../routes/Home/Footer";

export default function ForgotPassword() {
  const navigate = useNavigate();

  return (
    <div className='login-page full-page centered'>
      <Header />
      <img src="https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/Icon-KO.png" alt="" title="Icon-KO" className='login-logo hidden' onClick={() => { navigate('/') }}></img>
      <ForgotPasswordForm />
      <Footer showAsPopup={false} ></Footer>
    </div >
  )
}
