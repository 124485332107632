import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { useUpdateUserMutation, useOrgListQuery } from "../../app/api";
import { selectAllUsers, selectPendingUsers, userChanged, /* selectUserReportDateRange */ } from "../../features/adminLists/adminListsSlice";
import UserReportCSVDownloadLink from "./UserReportCSVDownloadLink";
import InviteNewUser from "./InviteNewUser";
import { selectUserRole, selectOrgId } from "../../features/auth/authSlice";
import { isPermissionsMU } from "../../features/auth/authSlice";
import { selectUserName } from "../../features/auth/authSlice";

export default function EditUsers() {

  const hasMUPermissions = useSelector(isPermissionsMU);

  const userRole = useSelector(selectUserRole);
  const userOrgId = useSelector(selectOrgId);

  const filterByOrgId = userRole == "ADMIN";
  const users = useSelector(selectAllUsers);
  const userName = useSelector(selectUserName); // this user name
  const activeUserCount = users.filter((t) => (filterByOrgId ? t.org_id == userOrgId : true) && t.role !== 'DELETED' && t.role !== 'PENDING').length
  const pendingUsers = useSelector(selectPendingUsers);
  const pendingUsersAdmin = pendingUsers.filter((t) => t.org_id == userOrgId);

  const dispatch = useDispatch();
  const [deleteUser, setDeleteUser] = useState('');
  const { data: orgList } =  useOrgListQuery();
  const [tabView, setTabView] = useState('users');
  const [downloadType, setDownloadType] = useState('Init'); // Sets init css class for 3 state toggle.

  // permissions
  const ro = useRef(new Array());
  const aa = useRef(new Array());
  const uc = useRef(new Array());
  const ma = useRef(new Array());
  const mu = useRef(new Array());


  // TODO:  change to use user id instead of username 

  const newUserReportFromDate = useRef();
  const newUserReportToDate = useRef();

  const today = new Date();
  let defaultFromDateUserReport = new Date();
  defaultFromDateUserReport.setDate(today.getDate() - 30);
  let defaultHistoricalFromDateUserReport = new Date();
  defaultHistoricalFromDateUserReport.setDate(today.getDate() - 2190); // 6 years. This should be change to a genesis date to prevent missing data in the future 

  const defaultUserReportMaxDate = today.toISOString().split('T')[0];
  const defaultUserReportMinDate = defaultFromDateUserReport.toISOString().split('T')[0];
  const defaultUserReportHistoricalMinDate = defaultHistoricalFromDateUserReport.toISOString().split('T')[0];

  const [changeUser] = useUpdateUserMutation();

  const deletePopup = () => {
    if (deleteUser != '') {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setDeleteUser('')}}/>
            <div className='centered'>
              <h3> Delete User </h3>
              <p> Are you sure you want to delete </p>
              <p> {deleteUser}?</p>
              <button onClick={()=>{allowSave(null, deleteUser, 'DELETED', '', '', '', '', '', '', '', '', 0); setDeleteUser('')}}> Delete </button>
            </div>
          </div>
        </div>
      )
    }
  }

  // if the event name is roles, 

  async function allowSave(event, username, uRole, fname, lname, cName, oType, jFunction, jLevel, uDemographic, uAge, uOrgId) {

    //console.log(`~~~~~~~~~~~~~~~~~~~ org_id ${uOrgId}`);

    //console.log('event', event);

    // Figure out which field is being changed and pull it from the input event
    // TODO: This could be refactored to be more generic for future fields
    if (event && event.target.name == 'user-role') {
      uRole = event ? event.target.value : uRole;
    } else if (event && event.target.name == 'user-org') {
      uOrgId = event ? event.target.value : uOrgId;
    }

    // Update user
    let role = uRole;
    let firstname = fname ? fname : '';
    let lastname = lname ? lname : '';
    let companyName = cName ? cName: '';
    let organizationType = oType ? oType: ''; 
    let jobFunction = jFunction ? jFunction : '';
    let jobLevel = jLevel ? jLevel : ''; 
    let demographic = uDemographic ? uDemographic : '';
    let age = uAge ? uAge : ''; 
    let org_id = uOrgId ? uOrgId : 0;
    await changeUser({username, role, firstname , lastname, companyName, organizationType, jobFunction, jobLevel, demographic, age, org_id}); 
    dispatch(userChanged());
    document.getElementById(username + "_save").classList = "fa-solid fa-save green";
  }

  const savePermissions = async (u, refObj, permType, idx) => {
    console.log('user permissions is', u.permissions);
    console.log('this checkbox is', refObj, permType,idx, refObj.checked, aa.current[idx]);

    // build new permissions
    let newPerm = '_';
    if (permType != 'aa' && ro.current[idx]) { // cannot be read only w/ aa
      newPerm = newPerm +  (ro.current[idx].checked ? '_ro_' : ''); 
    }
    if (permType != 'ro' && aa.current[idx]) { // cannot be aa w/ read only
      newPerm = newPerm + (aa.current[idx].checked ? '_aa_' : ''); 
    }
    if (uc.current[idx]) {
      newPerm = newPerm +  (uc.current[idx].checked ? '_uc_' : ''); 
    }
    if (ma.current[idx]) {
      newPerm = newPerm +  (ma.current[idx].checked ? '_ma_' : ''); 
    }
    if (mu.current[idx]) {
      newPerm = newPerm + (mu.current[idx].checked ? '_mu_' : ''); 
    }

    await changeUser({username: u.username, role: u.role, permissions: newPerm }); 
    dispatch(userChanged());
  }

  const userInfo = () => {
    return users
        .filter((t) => (userRole === 'ADMIN' ? t.org_id === userOrgId : true) && t.role !== 'DELETED' && t.role !== 'PENDING')
        .filter( t => t.username != userName)
        .map((item, i) => (
      <tr key={i + "_" + item.role}>
        <td className='user-list-email'><p className='user-name'>{item.username}</p> </td>
        <td>
          {(item.role == 'SUPERADMIN' && userRole != 'SUPERADMIN') ?
          <p>SUPERADMIN</p>
          :
          <select onChange = {(e)=>{allowSave(e, item.username, item.role, item.first_name, item.last_name, item.company_name, item.org_type, item.job_function, item.job_level, item.demographic, item.age, item.org_id)}} value={item.role} name='user-role'>
            {userRole == "SUPERADMIN" && <option value="SUPERADMIN" > Super Admin </option>}
            <option value="ADMIN" > Admin </option>
            <option value="CLIENT"> User </option>
            <option value="PENDING"> Pending </option>
            <option value="DELETED"> Deleted </option>
          </select>
          }
        </td>
        <td><p> {item.first_name} {item.last_name}</p></td>
        {userRole == "SUPERADMIN" &&
          <td>
           <select onChange = {(e)=>{allowSave(e, item.username, item.role, item.first_name, item.last_name, item.company_name, item.org_type, item.job_function, item.job_level, item.demographic, item.age, item.org_id)}} value={item.org_id} name='user-org'>
              <option value=""></option>
              {orgList ? orgList.map((org, i) => <option key={i + "_" + org.id} value={org.id}> {org.name} </option>) : <option value=""> </option>}
            </select>
          </td>
        }
        {userRole == "SUPERADMIN"? <td>{item?.job_function}</td> : ''}
        {userRole == "SUPERADMIN"? <td>{item?.job_level}</td> : ''}
        <td className='permissions-td u-td'><input ref={(e) => ro.current[i] = e} onChange={()=>savePermissions(item, ro.current[i], 'ro', i)} type='checkbox' checked={hasPermissions('ro', item.permissions) ? 'checked' : ''}></input></td>
        <td className='permissions-td u-td'><input ref={(e) => aa.current[i] = e} onChange={()=>savePermissions(item, aa.current[i], 'aa', i)} type='checkbox' checked={hasPermissions('aa', item.permissions) ? 'checked' : ''}></input></td>
        <td className='permissions-td u-td hidden'><input ref={(e) => ma.current[i] = e} onChange={()=>savePermissions(item, ma.current[i], 'ma', i)}type='checkbox' checked={hasPermissions('ma', item.permissions) ? 'checked' : ''} disabled={item.role === 'CLIENT'}></input></td>
        {userRole == "SUPERADMIN" && <td className='permissions-td u-td '><input ref={(e) => uc.current[i] = e} onChange={()=>savePermissions(item, uc.current[i], 'uc', i)} type='checkbox' checked={hasPermissions('uc', item.permissions) ? 'checked' : ''}  disabled={item.role != 'SUPERADMIN'}></input></td>}
        <td className='permissions-td u-td'><input ref={(e) => mu.current[i] = e} onChange={()=>savePermissions(item, mu.current[i], 'mu', i)}type='checkbox' checked={hasPermissions('mu', item.permissions) ? 'checked' : ''} disabled={item.role === 'CLIENT'}></input></td>
        
        <td className='permissions-td a-td'><i className='fa-solid fa-trash hover-point' onClick={()=>{setDeleteUser(item.username)}}></i></td>
      </tr>
    ));
  }

  const tabViewScreen = () => {
    if (tabView == 'users') {
      return (
        <div className='user-list-display' style={{width: '100%'}}> 
          <table className='user-list-table'>
            <thead>
              <tr>
                <th className='user-list-email'>User Email</th>
                <th>Role</th>
                <th>Name</th>
                {userRole == "SUPERADMIN" &&
                  <th>Organization</th>
                }
                {userRole == "SUPERADMIN"? <th>Job <br/>Function</th> : ''}
                {userRole == "SUPERADMIN"? <th>Senority /<br/>Level</th> : ''}
                <th className='permissions-td u-td'>Read<br/>Only</th>
                <th className='permissions-td u-td'>Activate<br/>Audiences</th>
                <th className='permissions-td u-td hidden'>Manage<br/>Audiences</th>
                {userRole == "SUPERADMIN" && <th className='permissions-td a-td '>UI<br/>Controls</th>}
                <th className='permissions-td a-td'>Invite<br/>Users</th>
                <th className='permissions-td a-td'></th>
              </tr>
            </thead>
            <tbody>
              {userInfo()}
            </tbody>
          </table>
        </div>
      );
    } else if (tabView === 'invite') { 
      return (
        <div className='user-list-display'> 
          <InviteNewUser />
        </div>
      );
    }
    else { 
      return (
        <div className='user-list-display' style={{width: '100%', marginTop: "25px"}}> 
          <table className='user-list-table'>
            <thead>
              <tr>
                <th>User Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Approve</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {pendingUserInfo()}
            </tbody>
          </table>
        </div>
      )
    }
  }

  const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'fifth-page centered';
    }
    else {
      return 'fifth-page centered disabled';
    }
  }

  const formatUsersByUserType = (users) => {
    switch(downloadType) {
      case 'All':
        return users;

      case 'Active':
        return users?.filter(u => u.role != 'DELETED');
       
      case 'Inactive':
        return users?.filter(u => u.role == 'DELETED');
        
      default:
        return users;
    }
  }

  const buildDownloader = () => {
    return (          
      <div className="user-report-downloader">
        <label>Signup Report</label>
        <div className='user-report-downloader-wrapper'>
          <div className="user-report-downloader-options-wrapper">
            <div className='options-row'>
              <input ref={newUserReportFromDate} min={defaultUserReportHistoricalMinDate} max={defaultUserReportMaxDate} type='date' defaultValue={defaultUserReportMinDate}/>
              <input ref={newUserReportToDate} min={defaultUserReportHistoricalMinDate} max={defaultUserReportMaxDate} type='date' defaultValue={defaultUserReportMaxDate} />
            </div>
            <div className='options-row'>
              <div className="user_filter_toggle">
                <input type="radio" name="toggle"  onClick={() => {setDownloadType('All')}} id="download_user_all_toggle" />
                <label htmlFor="download_user_all_toggle" className={ downloadType == 'Init' ? 'init' : ''}>All Users</label>
                <input type="radio" name="toggle" onClick={() => {setDownloadType('Active')}} id="download_user_active_toggle" />
                <label htmlFor="download_user_active_toggle">Active</label>
                <input type="radio" name="toggle" onClick={() => {setDownloadType('Inactive')}} id="download_user_inactive_toggle" />
                <label htmlFor="download_user_inactive_toggle" className='no-border'>Inactive</label>
                <div className={downloadType == 'All' || downloadType == 'Init'? 'user_toggle_selector no-border' : 'user_toggle_selector'}>&nbsp;</div>
              </div>
              <UserReportCSVDownloadLink from_date={newUserReportFromDate} to_date={newUserReportToDate} users={(formatUsersByUserType(users))} downloadType={downloadType} />
            </div>
          </div>
        </div>
      </div>
    )
  }
 
  //console.log('pending users', pendingUsers);
  const pendingUserInfo = () => {
    console.log('pending', pendingUsers);
    let formatPendingUsers = [];
    if (pendingUsers.length > 0 ) {
      if (userRole == 'SUPERADMIN'){
        formatPendingUsers = pendingUsers
      }
      else {
        formatPendingUsers = pendingUsersAdmin
      }
      return formatPendingUsers
        .map((item, i) => (
        <tr key={i + "_" + item.role}>
          <td><p className='user-name'>{item.username}</p></td>
          <td><p> {item.first_name}</p></td>
          <td><p> {item.last_name}</p></td>
          <td><i className='fa-solid fa-check-to-slot green' id={item.username + "_save"} onClick={()=>{allowSave(null, item.username, 'CLIENT', item.first_name, item.last_name, item.company_name, item.org_type, item.job_function, item.job_level, item.demographic, item.age, item.org_id)}} name='user-pending'> </i></td>
          <td><i className='fa-solid fa-trash hover-point' id={item.username + "_save"} onClick={()=>{setDeleteUser( item.username )}}> </i></td>
        </tr>
      ));
    }
    else {
      return (
        <tr>
          <td>
            <div className='full-page'>
              <h3>No pending users at this time.</h3>
            </div>
          </td>
        </tr>
      )
    }
  }


  const hasPermissions = (permissionType, permissions) => {
    if (permissionType?.toLowerCase() == 'ro'){
      if (permissions?.toLowerCase().includes('ro')) {
        return true;
      }
    }
    else {
      if (permissions?.toLowerCase().includes('all') || permissions?.toLowerCase().includes(permissionType?.toLowerCase())) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  return (
    <div>
      {deletePopup()}
      <h1>Users</h1>
      {false && <h3>Users: {JSON.stringify(users)}</h3>}
      {buildDownloader()}
      <div className='full-page tabs'>
        <div className={tabClass('users')} onClick = {() => {setTabView('users')}}>
          <h3 className="hover-point"> Users ({activeUserCount})</h3>
        </div>
        <div className={tabClass('pending')} onClick = {() => {setTabView('pending')}}>
          <h3 className="hover-point"> {pendingUsers.length > 0 ? userRole=='SUPERADMIN'? pendingUsers.length : pendingUsersAdmin.length  : '' } &nbsp; Pending</h3>
        </div>
        {hasMUPermissions && <div className={tabClass('invite')} onClick = {() => {setTabView('invite')}}>
          <h3 className="hover-point"> Invite</h3>
        </div>
        }
      </div>
      <div className='break'/>
      {tabViewScreen()}
    </div>
  )
}