import React from "react";
import logo from "../../images/logo-header.png";

export default function Header() {

  return (
    <div className="login_header"> 
      <div className="login_header_wrapper"> 
        <a href="/"><img  src={logo}></img></a>
        <a className="contact" href="https://www.cinqdi.com/contact/"><b>CONTACT</b></a>
      </div>
    </div>
  )
}
