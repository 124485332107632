import React, {useState} from "react";
import { useSelector } from "react-redux";
import { selectOrgNameString, selectOrgId, selectUserRole, selectUserName } from "../../features/auth/authSlice";
import { useInviteUserMutation } from "../../app/api";


export default function InviteNewUser() {
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('CLIENT');

  const [inviteUser] = useInviteUserMutation();

  const loggedInAdminOrgName = useSelector(selectOrgNameString) === 'null' ? "" : useSelector(selectOrgNameString);
  const loggedInAdminOrgId = useSelector(selectOrgId) === 'null' ? 0 : useSelector(selectOrgId);
  const loggedInAdminRole = useSelector(selectUserRole) === 'null' ? 0 : useSelector(selectUserRole);
  const loggedInAdminUsername = useSelector(selectUserName)

  const [submitMessage, setSubmitMessage] = useState();

  async function handleSubmit(event) {
    setSubmitMessage("Submitting, please wait...");
    event.preventDefault();
    try {
      await inviteUser({admin_user: loggedInAdminUsername, username: inviteEmail, org_id: loggedInAdminOrgId, company_name: loggedInAdminOrgName, user_role: selectedRole}).unwrap();
      setSubmitMessage("We've sent an invitation to '" + inviteEmail + "', inviting them to join our platform.  Once they accept the invitation, they'll have the opportunity to sign up with their personal details and participate in projects.");
    } catch (error) {
      setSubmitMessage("Error - user already exists");
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleEmailChange = (event) => {
    setInviteEmail(event.target.value);
  };

  return (
    <>
      <h3>Invite New User</h3>

      <form onSubmit={handleSubmit}>
        <table style={{width: 'unset'}}>
          <tbody>
            <tr>
              <td>
                <input
                  type="email"
                  value={inviteEmail}
                  onChange={handleEmailChange}
                  placeholder="Enter an email address"
                  required
                />
              </td>
              <td>
                <select id="role" value={selectedRole} onChange={handleRoleChange}>
                  <option value="CLIENT">User</option>
                  <option value="ADMIN">Admin</option>
                  {loggedInAdminRole === 'SUPERADMIN' ? <option value="SUPERADMIN">Super Admin</option> : null}
                </select>
              </td>
              <td>
                <button className="invite-users" type="submit"><p>Invite</p></button>
              </td>
            </tr>
          </tbody>
        </table>

      </form>
      <br />
      <p>{submitMessage}</p>
    </>
  )
}