import React , {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllFeedback, feedbackChanged } from "../../features/adminLists/adminListsSlice";
import { useUpdateFeedbackMutation } from "../../app/api";
import { selectUserName } from "../../features/auth/authSlice";
import { timeSince } from "../format";

export default function Feedback() {

  const feedback = useSelector(selectAllFeedback);
  const [tabView, setTabView] = useState('open');
  const [ updateFeedback ] = useUpdateFeedbackMutation();
  const userName = useSelector(selectUserName);
  const dispatch = useDispatch();

  const saveFeedback = (id) => {
    const status = document.getElementById(id+'_status').value;
    const admin_comment = document.getElementById(id+'_comment').value; 
    updateFeedback({id: id, status: status, admin_comment: admin_comment, assigned_to: userName});
    document.getElementById(id+'_status').value = 'OPEN';
    document.getElementById(id+'_comment').value = ''; 
    dispatch(feedbackChanged());
  }

  const thisDate = (d) => {
    return timeSince(new Date(d)) ;
  }
  const feedbackRows = () => {
    return feedback
      .filter((t) => t.status == 'OPEN')
      .map((t, i) => (
      <tr key={i}>
        <td>{t.level}</td>
        <td>{t.page}</td>
        <td>{t.user_comment}</td>
        <td>{t.username}</td>
        <td>{thisDate(t.created)} </td> 
        <td >
          <input id={t.id + "_comment"} className='small'></input>
        </td>
        <td>
          <select className='small' id={t.id+"_status"} onChange = {()=>{console.log('hi')}} key={t.status} name='user-role'>
            <option value="OPEN" > Open </option>
            <option value="IN-PROGRESS"> In-Progress </option>
            <option value="COMPLETED"> Completed </option>
          </select>
        </td>
        <td>
          <i className='fa-solid fa-save save-icon'  onClick={() => {saveFeedback(t.id)}}></i>
        </td>
      </tr>
    ));
  }

  const feedbackInProgressRows = () => {
    return feedback
      .filter((t) => t.status == 'IN-PROGRESS')
      .map((t, i) => (
      <tr key={i}>
        <td>{t.level}</td>
        <td>{t.page}</td>
        <td>{t.user_comment}</td>
        <td>{t.username}</td>
        <td >
          <input id={t.id + "_comment"} className='small' onChange={()=>{console.log('change')}} value={t.admin_comment}></input>
        </td>
        <td>
          <select className='small' id={t.id+"_status"} onChange = {()=>{console.log('change')}} key={t.status} name='user-role'>
            <option value="IN-PROGRESS"> In-Progress </option>
            <option value="COMPLETED"> Completed </option>
          </select>
        </td>
        <td>{t.assigned_to}</td>
        <td>
          <i className='fa-solid fa-save save-icon'  onClick={() => {saveFeedback(t.id)}}></i>
        </td>
      </tr>
    ));
  }

  const feedbackCompletedRows = () => {
    return feedback
      .filter((t) => t.status == 'COMPLETED')
      .map((t, i) => (
      <tr key={i}>
        <td>{t.level}</td>
        <td>{t.page}</td>
        <td>{t.user_comment}</td>
        <td>{t.username}</td>
        <td >
          {t.admin_comment}
        </td>
        <td>{t.assigned_to}</td>
        <td>
          {t.updated} 
        </td>
      </tr>
    ));
  }

  const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'fifth-page centered';
    }
    else {
      return 'fifth-page centered disabled';
    }
  }

  const tabViewScreen = () => {
    if (tabView == 'open') {
      return (
        <table className='feedback'>
          <thead>
            <tr>
              <th> Rating </th>
              <th> Page </th>
              <th> Comment </th>
              <th> Reported By </th>
              <th> Since </th>
              <th> Notes </th>
              <th> Status </th>
            </tr>
          </thead>
          <tbody>
            {feedbackRows()}
          </tbody>
        </table>
      )
    }
    if (tabView == 'in-progress') {
      return (
        <table className='feedback'>
          <thead>
            <tr>
              <th> Rating </th>
              <th> Page </th>
              <th> Comment </th>
              <th> Reported By </th>
              <th> Notes </th>
              <th> Status </th>
              <th> Reviewed By </th>
            </tr>
          </thead>
          <tbody>
            {feedbackInProgressRows()}
          </tbody>
        </table>
      )
    }
    if (tabView == 'completed') {
      return (
        <table className='feedback'>
          <thead>
            <tr>
              <th> Rating </th>
              <th> Page </th>
              <th> Comment </th>
              <th> Reported By </th>
              <th> Notes </th>
              <th> Reviewed By </th>
              <th> Completed On </th>
            </tr>
          </thead>
          <tbody>
            {feedbackCompletedRows()}
          </tbody>
        </table>
      )
    }
  }

  return (
    <div>
      <div className='activate-form'>
      <h1>Feedback</h1>
        <div className='full-page tabs'>
          <div className={tabClass('open')} onClick = {() => {setTabView('open')}}>
            <h3> Open Comments - {feedback.filter((t)=> t.status == 'OPEN').length} </h3>
          </div>
          <div className={tabClass('in-progress')} onClick = {() => {setTabView('in-progress')}}>
            <h3> In Progress - {feedback.filter((t)=> t.status == 'IN-PROGRESS').length}</h3>
          </div>
          <div className={tabClass('completed')} onClick = {() => {setTabView('completed')}}>
            <h3> Completed - {feedback.filter((t)=> t.status == 'COMPLETED').length} </h3>
          </div>
        </div>
        <div className='break'/>
        {tabViewScreen()}
      </div>
    </div>
  )
}