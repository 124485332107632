import React from "react";
import PropTypes from "prop-types";

export default function Loading ({margin}) {
  return (
    <div className={margin? 'loading full-screen-margin' : 'loading'}></div>
  )
}

Loading.propTypes = {
  margin: PropTypes.bool,
}