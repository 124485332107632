import React, {useCallback} from 'react'
import { useSelector } from 'react-redux';
import WordCloud from 'react-d3-cloud'
import PropTypes from "prop-types";
import { useSelectAudienceTopicsQuery } from '../../app/api';
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createGAQuery } from "../../data/WidgetData";
import { selectCurrentHeadlineDateRange } from '../../features/audiences/audienceSlice';

export default function WordCloudGraph({thisAudience}){

  const filter = useSelector(selectWidgetFilters);
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const filter_query = createGAQuery(filter, currentHeadlineDateRange);


  const {data: topics, isSuccess: topicSuccess} = useSelectAudienceTopicsQuery({audience_code: thisAudience?.ga_code, filter_query: filter_query, headline_search: 'all'})

  let wordcloudList = []
  if (topics) {
    const maxAmt = topics.reduce((partialSum, a) => partialSum + parseInt(a.all_count), 0);


    console.log('topics', topics);
    // translate count to range of 0 to 100
    topics.map((t) => {
      if (t.all_count > 0) {
        wordcloudList.push({text: t.topic, value: Math.round(((parseInt(t.all_count)*100)/maxAmt) * 100)})
      }
    });
  }

  const fontSize = useCallback((word) => Math.log2(word.value) * 5, []);
  const rotate = useCallback((word) => word.value % 360, []);

  const isLoadingNow = ()  => {
    if (!topicSuccess) {
      return <i className='fas fa-sync fa-spin hidden'/>
    }
    else {
      return <> {topicSuccess && <WordCloud
        data={wordcloudList}
        width={400}
        height={400}
        font="Times"
        fontWeight="bold"
        fontSize={fontSize}
        spiral="rectangular"
        rotate={rotate}
        padding={5}
        random={Math.random}
      />}
      </>
    }
  }

  return (
    <>
    {isLoadingNow()}
    </>
  )
}

WordCloudGraph.propTypes = {
  thisAudience: PropTypes.object,
}