import React from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../features/auth/authSlice";
import { Navigate } from "react-router-dom";

export default function Resolve() {
  // If logged in, go to the client dashboard page
  console.log('Resolve is looking for user', useSelector(selectUserName));
  if (useSelector(selectUserName)) {
    return <Navigate replace to="dashboard" />;
  }

  // Otherwise go to the Home Page 
  return <Navigate replace to="login" />;

}
