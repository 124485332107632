import React from "react"
import GaugeChart from 'react-gauge-chart';
import PropTypes from "prop-types";
import { gaugeColors } from "../../data/WidgetData";

export default function Gauge({dataNum, name, title}) {
 
 
  const chartStyle = {
    width: 120,
  }

  const needleColor = '#BEBEBE';

  return (
    <div className='gauge'> 
      <GaugeChart id={name} nrOfLevels={20} percent={dataNum/100} style={chartStyle} textColor={'#000000'} colors={gaugeColors} 
      needleColor={needleColor} needleBaseColor={needleColor} hideText={true}/>
      <p className='gauge-title centered'>{dataNum}</p>
      <p className='gauge-title second centered'>{title}</p>
    </div>
  );
}

Gauge.propTypes = {
  dataNum: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
}