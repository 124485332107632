import React from "react";
import ClientDashboard from "../ClientDashboard";
import { useDispatch } from "react-redux";
import { saveCurrentView } from "../../../features/currentView/currentViewSlice";


export default function ArticlePath(){

  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const headlineString = queryParameters.get("headline");
  const audienceString = queryParameters.get("aud");

  var thisParamString;
  var routePath = '';

  if (headlineString) {
    routePath = 'headline-article';
    dispatch(saveCurrentView({type: 'currentHeadline', value: headlineString}));
  }
  else {
    routePath = 'audience-headlines';
    thisParamString = audienceString;
  }

  return (
    <ClientDashboard requestedPage={routePath} thisParamString={thisParamString}/>
  )
}