import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAudienceList } from '../../features/audiences/audienceSlice';
import ComparisonTable from './ComparisonTable';
import { widgetDisplay } from "../../data/WidgetData";
import WidgetFilter from '../Widgets/WidgetFilter';
import PropTypes from "prop-types";
import { saveCurrentView } from '../../features/currentView/currentViewSlice';

export default function Compare({allAudiences}) {

  const [selectedWidgetList, setSelectedWidgetList] = useState([]);
  const [compareFilterCheckboxStates, setCompareFilterCheckboxStates] = useState({});
  const [selectedAudiences, setselectedAudiences] = useState([]);
  const audienceList = useSelector(selectAudienceList);
  const maxSelections = 2;
  const [geographyState, setGeographyState] = useState('open');

  const dispatch = useDispatch();

  /** 
   * When the user changes the filter, update the list of widgets
   */
  useEffect(() => {
    fetchWidgetList();
  }, [compareFilterCheckboxStates])

  /** 
   * Update Widget list based on filter selection
   */
  const fetchWidgetList = () => {
    let widget_list = [];
    widgetDisplay
      .filter((t)=> t.widget != 'us_congressional_district')
      .filter((t)=> t.widget != 'state_senate_district')
      .filter((t)=> t.widget != 'state_house_district')
      .filter((t)=> t.widget != 'state')
      .filter((t)=> t.widget != 'county')
      .filter((t)=> t.widget != 'dma')
      .filter((t)=> t.widget != 'ethnicity_language_hispanic')
      .filter((t)=> t.widget != 'general_election_voter')
      .filter((t)=> t.widget != 'likely_presidential_primary_voter')
      .filter((t)=> t.widget != 'likely_primary_voter')
      .filter((t)=> t.widget != 'likely_voter')
      .filter((t)=> t.widget != 'source')
      .filter((t)=> compareFilterCheckboxStates[t.widget] != false)
      .map((type) => {
        widget_list.push(type.widget.toLowerCase())
    })
    setSelectedWidgetList(widget_list);
  }

  /**
   * A button for each Audience
   */
  const audienceOptions = () => {
    let mutableArray = [ ...new Set(audienceList)];
    return (mutableArray || [])
      .filter((t) => t.active == 1)
      .filter((t) => t.title)
      .map((audience, i) => (
        <li key={i} onClick={()=>{toggleAudience({id: audience.id, uuid: audience.uuid, audience: audience.audience, title: audience.title, count: audience.count})}}> 
          <div className='category-button' id={'audience_' + audience.id}>
            {audience.title}
          </div>
        </li>
    ))
  }

  /**
   * User has chosen an Audience
   */
  const toggleAudience = (audience) => {
    if (!selectedAudiences.some(item => item.id === audience.id)) {
      if (selectedAudiences.length < maxSelections) {
        setselectedAudiences(prevItems => [...prevItems, audience]);
        document.getElementById('audience_' + audience.id).style.background='#F2F2F2';
        document.getElementById('audience_' + audience.id).style.color='#08beb1';
      }
    } else if (selectedAudiences.some(item => item.id === audience.id)) {
      removeAudience(audience.id);
    }
  }

  /**
   * User has deselected an Audience
   */
  const removeAudience = (audience_id) => {
    const newList = selectedAudiences.filter(item => item.id !== audience_id);
    setselectedAudiences(newList);
    if (newList.length < 2) { 
      document.getElementById('download-csv-link').style.display = 'none';
      //This should be refactored to use react state logic 
    }
    document.getElementById('audience_' + audience_id).style.background='#FAFAFA';
    document.getElementById('audience_' + audience_id).style.color='#000000';
  }

  const toggleShowAudienceCrossover = () => {
    dispatch(saveCurrentView({type:'showAudienceCrossover', value: 'toggle'}));
  }

  // Handler to toggle checkbox state
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCompareFilterCheckboxStates({
      ...compareFilterCheckboxStates,
      [name]: checked,
    });
  };

  const handleDownloadComparison = () => {
    document.getElementById('comparison-csv-download').click();
  }

  const geographyFilter = () => {
    return (
      <div>
        <WidgetFilter type='state' />
      </div>
    )
  }

  // Handler to toggle Geography checkbox state
  const toggleGeography = (event) => {
    if (geographyState == 'open') {
      setGeographyState('closed');
    }
    else {
      setGeographyState('open');
    }
    handleCheckboxChange(event);
  };


  const demographicFilter = () => {
    let filterCheckboxes = widgetDisplay
      .sort((a, b) => a.compareOrder - b.compareOrder)
      .filter((t)=> t.widget != 'us_congressional_district')
      .filter((t)=> t.widget != 'state_senate_district')
      .filter((t)=> t.widget != 'state_house_district')
      .filter((t)=> t.widget != 'dma')
      .filter((t)=> t.widget != 'source')
      .filter((t)=> t.widget != 'ethnicity_language_hispanic')
      .filter((t)=> t.widget != 'likely_voter')
      .filter((t)=> t.category === 'demographics')
      .map((widget,i) => (
        <>
          {widget.widget == "education_1" &&
            <tr key={'geo' + i}>
              <td>
                <div className='leftside inline label' style={{fontSize: '15px'}}>
                  Geography
                </div>
                <div className={'filter-view ' + geographyState} style={{width: 'unset'}}>
                  <ul>
                    <WidgetFilter type='state' isDuplicateState='true' />
                  </ul>
                </div>
              </td>
              <td style={{verticalAlign: 'top'}}>
                <input
                  type="checkbox"
                  name='state'
                  defaultChecked='checked'
                  onChange={toggleGeography}
                  style={{minWidth: 'unset', height: 'unset'}}
                />
              </td>
            </tr>
          }
          <tr key={i}>
            <td>
              <div style={{fontSize: '15px'}}>
                {widget.displayName}
              </div>
            </td>
            <td>
              <input
                type="checkbox"
                name={widget.widget}
                defaultChecked='checked'
                onChange={handleCheckboxChange}
                style={{minWidth: 'unset', height: 'unset'}}
              />
            </td>
          </tr>
        </>

      ));

    return (
      <table style={{width: '100%'}}>
        <tbody>
          {filterCheckboxes}
          <tr>
            <td>
              <div style={{fontSize: '14px'}}>
                Audience Crossover 
              </div>
            </td>
            <td>
            <input
              type="checkbox"
              name="acct-crossover"
              defaultChecked='checked'
              onChange={toggleShowAudienceCrossover}
              style={{minWidth: 'unset', height: 'unset'}}
            />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <>
      <div className='client_dashboard_view_content content_menu_wrapper no_title'>
        <div className='client_dashboard_view_content_menu compare_menu'>
          <div className="compare_audience_options">
            <h3 className=''>Compare & Activate</h3>
            <label>Step 1: Choose Your Audiences </label>
            <label className='subtext'>Select two audiences to compare</label>
            <ul>
              {audienceOptions()}
            </ul>
          </div>
          <div className='compare_geography_options'>
            <h3>Geography</h3>
            <label>Step 2: Choose Your Geography</label>
            <label className='subtext'>Select a target audience to filter your results</label>
            {geographyFilter()}
          </div>
          <div className='compare_demographic_options'>
            <h3>Demographics</h3>
            <label>Step 3: Choose Your Demographics</label>
            <label className='subtext'>Select at least 2 demographic subgroups to display</label>
            {demographicFilter()}
          </div>
        </div>

        <div className='client_dashboard_view_content_view_with_menu' style={{paddingTop: 'unset'}}>   
          <button className='menu_right_action_wrapper downloader' id ='download-csv-link' onClick={()=>{handleDownloadComparison()}}>
            <i className='fa-light fa-arrow-down-to-bracket inline'> </i>
            <p className=''>Download</p>
          </button>  
          <div className='' id='comparison-results'>
            {selectedAudiences.length === maxSelections
              ?
              <ComparisonTable audiences={selectedAudiences} widgetList={selectedWidgetList} allAudiences={allAudiences} />
              :
              <div className='full-page centered disabled'>
                <h2>Please choose {maxSelections} Audiences to compare</h2>
              </div>
            }
          </div>
        </div>
      </div> 
    </>
   );
};

Compare.propTypes = {
  allAudiences: PropTypes.array,
}