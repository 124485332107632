import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { toggleField } from "../Widgets/WidgetFilter";

export default function FilterButton({label, filter, type, baseLabel}) {

  const dispatch = useDispatch();

  const toggleThis = () => {
    const checkBox = document.getElementById(baseLabel);
    checkBox.checked = !checkBox?.checked;
    toggleField(filter, type, dispatch);
  }

  const close = () => {
    if (label.toLowerCase() != 'all') {
      return (
        <i className='fa-solid fa-close' onClick={()=>{toggleThis()}}></i>
      )
    }
  }
  return (
    <div className='filter-button'>
      {label.replace(/\|/g,',')}
      {close()}
    </div>
  )
}

FilterButton.propTypes = {
  label: PropTypes.string,
  filter: PropTypes.object,
  type: PropTypes.string,
  baseLabel: PropTypes.string,
}