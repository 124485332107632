import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Root from "./routes/Root";
import Error from "./routes/Error";
import Resolve from "./routes/Resolve";
import Dashboard from "./routes/Dashboard";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthRequired from "./features/auth/AuthRequired";
import Login from "./routes/Login";
import AdminLogin from "./routes/AdminLogin";
import ResetPassword from "./routes/ResetPassword";
import ForgotPassword from "./routes/ForgotPassword";
import ReportingTerms from "./routes/ReportingTerms";
import UsageGuidelines from "./routes/UsageGuidelines";
import ArticlePath from "./routes/ClientDashboard/Headlines/ArticlePath";
import ClientAudienceView from "./routes/ClientDashboard/ClientAudienceView";
import ClientAudienceClusterView from "./routes/ClientDashboard/ClientAudienceClusterView";
import Activated from "./routes/ClientDashboard/Activated";
import LiveRamp from "./routes/DestinationAccounts/LiveRamp";
import YourAudiences from "./routes/ClientDashboard/YourAudiences";
import YourAudiencePlatforms from "./routes/ClientDashboard/YourAudiencePlatforms";
import SignUp from "./features/auth/SignUp";
import Settings from "./routes/ClientDashboard/Settings";
import Insights from "./routes/ClientDashboard/Insights";
import AiBot from "./routes/ClientDashboard/AI/AiBot";
import Support from "./routes/Support";
import TagManager from "react-gtm-module";

if (window.location.hostname.includes('platform.cinqdi')) {
  TagManager.initialize({gtmId: "GTM-PNVQ7X3G" });
}

// Front-end routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Resolve />,
      },
      {
        path: "admin",
        element: <AdminLogin />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:id/:token",
        element: <ResetPassword />,
      },
      {
        path: "new-invite/:id/:token",
        element: <ResetPassword invited={true} />,
      },
      {
        path: "reporting-terms",
        element: <ReportingTerms />,
      },
      {
        path: "usage-guidelines",
        element: <UsageGuidelines />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        element: <AuthRequired />,
        children: [
          {
            path: "admin/dashboard",
            element: <Dashboard />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "dashboard/your-audiences",
            element: <YourAudiences />,
          },
          {
            path: "dashboard/your-audience-platforms",
            element: <YourAudiencePlatforms />,
          },
          {
            path: "dashboard/audience/activate",
            element: <Activated />,
          },
          {
            path: "dashboard/audience/confirm-activate",
            element: <LiveRamp />,
          },
          {
            path: "dashboard/audience",
            element: <ClientAudienceView />,
          },
          {
            path: "dashboard/cluster-audience",
            element: <ClientAudienceClusterView />,
          },
          {
            path: "dashboard/article",
            element: <ArticlePath />,
          },
          {
            path: "dashboard/article/audience",
            element: <ArticlePath />,
          },
          {
            path: "dashboard/account-settings",
            element: <Settings />,
          },
          {
            path: "dashboard/insights",
            element: <Insights />,
          },
          {
            path: "/dashboard/ai",
            element: <AiBot />,
          },
        ],
      },
    ],
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
