import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectActivationReferenceNo } from "../../features/currentView/currentViewSlice";
import { selectAllRequests } from "../../features/audiences/audienceSlice";
import { useUpdateAudienceStatusMutation } from "../../app/api";
import { saveDirectory } from "../../features/directory/directorySlice";

export default function ActivationDistro() {

  const refNo = useSelector(selectActivationReferenceNo);
  const requests = useSelector(selectAllRequests);
  const dispatch = useDispatch();

  const [ updateAudienceStatus ] = useUpdateAudienceStatusMutation();
  const activateAudience = () => {
    updateAudienceStatus({ref_no: refNo, status_value: 'PROCESSED'});
    dispatch(saveDirectory({type: 'adminPage', value:'activations'}));
  }

  const thisRequest = requests.find(t => t.reference_id === refNo);

  const namePlaceholder = thisRequest.platform + " - " + thisRequest.start_date;

  return (
    <div className='activate-form' >
      <h1> Data Marketplace Segment Distribution </h1>
      <p> Reference No: <b>{refNo}</b> </p>
      <h3> Configure {(thisRequest||{platform:'none'}).platform} Destination Account </h3>
      <div className='half-page'>
        <h3> GENERAL SETTINGS </h3>
        <label>End Date : </label>
        <input type='date'  placeholder='End Date' className='inline left-buffer' value={thisRequest.end_date}/>
        <div className='break'/>
        <h3> IDENTIFIER SETTINGS </h3>
        <input type='checkbox' className='inline checkbox'></input>
        <label>Platform ID</label>
        <div className='break'/>
        <h3> DESTINATION-SPECIFIC SETTINGS </h3>
        <label>Share Account ID</label>
        <p className='mandatory'>*</p>
        <input type='text'  className='long' value={thisRequest.advertiser_id} ></input>
        <label>Destination Account Name</label>
        <p className='mandatory'>*</p>
        <input type='text'  className='long' placeholder={namePlaceholder}></input>
        <button onClick={()=>{activateAudience()}} >Activate</button>
      </div>
      <div className='half-page'>
        <p> END DATE </p>
        Set an end date if you want to automatically stop delivering data once that date is reached.  
        On the specified end date, deliveries stop and the destination account becomes inactive.
        <div className='break'/>
        <p> IDENTIFIER SETTINGS </p>
        Select the types of identifiers to send to this destination
        <div className='break'/>
        <div className='break'/>
        <div className='break'/>
        <p> 1. SHARE ACCOUNT ID </p>
        The Facebook ad account ID(s) that will use the segments being distributed.
        If entering more then one ID, separate the IDs with commas.
        <div className='break'/>
        <p>DESTINATION ACCOUNT NAME</p>
        Pick a unique and memorable name for this account.  We have suggested one for you but you can use any 
        name you wish as long as it is less than 150 characters in length. 
      </div>
    </div>
  )
}