export const allWidgetFields = [
    {
        "widget_field": "Y",
        "widget_type": "likely_presidential_primary_voter"
    },
    {
        "widget_field": "N",
        "widget_type": "likely_presidential_primary_voter"
    },
    {
        "widget_field": "< $50,000",
        "widget_type": "income_range"
    },
    {
        "widget_field": "Unknown",
        "widget_type": "income_range"
    },
    {
        "widget_field": "$50,000 - $74,999",
        "widget_type": "income_range"
    },
    {
        "widget_field": "$100,000 - $149,999",
        "widget_type": "income_range"
    },
    {
        "widget_field": "Christian",
        "widget_type": "religion"
    },
    {
        "widget_field": "Married",
        "widget_type": "marital_status"
    },
    {
        "widget_field": "Unknown",
        "widget_type": "presence_of_children"
    },
    {
        "widget_field": "Democratic",
        "widget_type": "party"
    },
    {
        "widget_field": "Millennials",
        "widget_type": "age_range"
    },
    {
        "widget_field": "Gen Z",
        "widget_type": "age_range"
    },
    {
        "widget_field": "Silent Generation",
        "widget_type": "age_range"
    },
    {
        "widget_field": "Baby Boomers",
        "widget_type": "age_range"
    },
    {
        "widget_field": "Less than HS Diploma - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Bach Degree - Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Bach Degree - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Less than HS Diploma - Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Grad Degree - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "HS Diploma - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Not Hispanic",
        "widget_type": "ethnicity_hispanic"
    },
    {
        "widget_field": "Some College",
        "widget_type": "education_1"
    },
    {
        "widget_field": "65 and over",
        "widget_type": "age_range_standard"
    },
    {
        "widget_field": "Likely Renter",
        "widget_type": "home_owner_or_renter"
    },
    {
        "widget_field": "Likely Homeowner",
        "widget_type": "home_owner_or_renter"
    },
    {
        "widget_field": "GU",
        "widget_type": "state"
    },
    {
        "widget_field": "CO",
        "widget_type": "state"
    },
    {
        "widget_field": "MA",
        "widget_type": "state"
    },
    {
        "widget_field": "LA",
        "widget_type": "state"
    },
    {
        "widget_field": "WI",
        "widget_type": "state"
    },
    {
        "widget_field": "DC",
        "widget_type": "state"
    },
    {
        "widget_field": "AP",
        "widget_type": "state"
    },
    {
        "widget_field": "AS",
        "widget_type": "state"
    },
    {
        "widget_field": "NC",
        "widget_type": "state"
    },
    {
        "widget_field": "AR",
        "widget_type": "state"
    },
    {
        "widget_field": "MN",
        "widget_type": "state"
    },
    {
        "widget_field": "TN",
        "widget_type": "state"
    },
    {
        "widget_field": "WASHINGTON DC (HAGRSTWN) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SAN DIEGO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LITTLE ROCK-PINE BLUFF DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FT. SMITH-FAY-SPRNGDL-RGRS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "EL PASO (LAS CRUCES) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "EUREKA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WACO-TEMPLE-BRYAN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CLEVELAND-AKRON (CANTON) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FT. MYERS-NAPLES DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREAT FALLS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HARTFORD & NEW HAVEN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PARKERSBURG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DOTHAN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ROCHESTR-MASON CITY-AUSTIN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BEND OR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHAMPAIGN&SPRNGFLD-DECATUR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "NEW YORK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TOPEKA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ELMIRA (CORNING) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TAMPA-ST. PETE (SARASOTA) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LOUISVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JOHNSTOWN-ALTOONA-ST COLGE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GRAND RAPIDS-KALMZOO-B.CRK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SOUTH BEND-ELKHART DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ALPENA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "AUGUSTA-AIKEN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FARGO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SPOKANE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WICHITA FALLS & LAWTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PORTLAND OR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BOWLING GREEN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PRESQUE ISLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CLARKSBURG-WESTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BUTTE-BOZEMAN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ABILENE-SWEETWATER DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ANCHORAGE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JACKSON TN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "OKLAHOMA CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SAN ANGELO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ERIE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MEDFORD-KLAMATH FALLS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LAREDO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CORPUS CHRISTI DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREENVILLE-N.BERN-WASHNGTN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MONTGOMERY-SELMA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "KNOXVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FLINT-SAGINAW-BAY CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SIOUX FALLS(MITCHELL) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ST. JOSEPH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "NORTH PLATTE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "YOUNGSTOWN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HONOLULU DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FRESNO-VISALIA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FT. WAYNE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HARRISBURG-LNCSTR-LEB-YORK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MACON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TRI-CITIES TN-VA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ALBANY GA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BATON ROUGE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MINNEAPOLIS-ST. PAUL DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREEN BAY-APPLETON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TYLER-LONGVIEW(LFKN&NCGD) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "EUGENE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "Y",
        "widget_type": "general_election_voter"
    },
    {
        "widget_field": "$75,000 - $99,999",
        "widget_type": "income_range"
    },
    {
        "widget_field": "$150,000 & up",
        "widget_type": "income_range"
    },
    {
        "widget_field": "Other",
        "widget_type": "language"
    },
    {
        "widget_field": "Low Propensity",
        "widget_type": "vote_propensity"
    },
    {
        "widget_field": "Mid Propensity",
        "widget_type": "vote_propensity"
    },
    {
        "widget_field": "High Propensity",
        "widget_type": "vote_propensity"
    },
    {
        "widget_field": "Has children",
        "widget_type": "presence_of_children"
    },
    {
        "widget_field": "Other",
        "widget_type": "religion"
    },
    {
        "widget_field": "College Degree+",
        "widget_type": "education_2"
    },
    {
        "widget_field": "Some College - Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "18 to 29",
        "widget_type": "age_range_standard"
    },
    {
        "widget_field": "50 to 64",
        "widget_type": "age_range_standard"
    },
    {
        "widget_field": "40 to 49",
        "widget_type": "age_range_standard"
    },
    {
        "widget_field": "High School Graduate or Less",
        "widget_type": "education_1"
    },
    {
        "widget_field": "NH",
        "widget_type": "state"
    },
    {
        "widget_field": "NM",
        "widget_type": "state"
    },
    {
        "widget_field": "VI",
        "widget_type": "state"
    },
    {
        "widget_field": "WV",
        "widget_type": "state"
    },
    {
        "widget_field": "AA",
        "widget_type": "state"
    },
    {
        "widget_field": "KS",
        "widget_type": "state"
    },
    {
        "widget_field": "MI",
        "widget_type": "state"
    },
    {
        "widget_field": "UT",
        "widget_type": "state"
    },
    {
        "widget_field": "RI",
        "widget_type": "state"
    },
    {
        "widget_field": "OK",
        "widget_type": "state"
    },
    {
        "widget_field": "NY",
        "widget_type": "state"
    },
    {
        "widget_field": "MO",
        "widget_type": "state"
    },
    {
        "widget_field": "CT",
        "widget_type": "state"
    },
    {
        "widget_field": "AL",
        "widget_type": "state"
    },
    {
        "widget_field": "AE",
        "widget_type": "state"
    },
    {
        "widget_field": "MS",
        "widget_type": "state"
    },
    {
        "widget_field": "IN",
        "widget_type": "state"
    },
    {
        "widget_field": "VT",
        "widget_type": "state"
    },
    {
        "widget_field": "JUNEAU DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PHOENIX (PRESCOTT) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WATERTOWN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LIMA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HATTIESBURG-LAUREL DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "UTICA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LAFAYETTE LA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHICAGO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "KANSAS CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LA CROSSE-EAU CLAIRE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HOUSTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREENSBORO-H.POINT-W.SALEM DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SHREVEPORT DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LOS ANGELES DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLUMBUS-TUPELO-WEST POINT DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "YAKIMA-PASCO-RCHLND-KNNWCK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ALBUQUERQUE-SANTA FE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WHEELING-STEUBENVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BEAUMONT-PORT ARTHUR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MILWAUKEE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DENVER DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LANSING DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BIRMINGHAM (ANN AND TUSC) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GAINESVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MONTEREY-SALINAS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "AMARILLO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SPRINGFIELD MO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CEDAR RAPIDS-WTRLO-IWC&DUB DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PALM SPRINGS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MADISON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MISSOULA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MANKATO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PHILADELPHIA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "INDIANAPOLIS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BILLINGS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SAVANNAH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "EVANSVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BINGHAMTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LAS VEGAS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "NASHVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LAKE CHARLES DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHARLOTTE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SALISBURY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DES MOINES-AMES DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BALTIMORE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MEMPHIS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "Hispanic/Not Likely Spanish Speaker",
        "widget_type": "ethnicity_language_hispanic"
    },
    {
        "widget_field": "Hispanic/Spanish Speaker",
        "widget_type": "ethnicity_language_hispanic"
    },
    {
        "widget_field": "Likely Spanish Speaker",
        "widget_type": "language"
    },
    {
        "widget_field": "Islamic",
        "widget_type": "religion"
    },
    {
        "widget_field": "Unknown",
        "widget_type": "religion"
    },
    {
        "widget_field": "Republican",
        "widget_type": "party"
    },
    {
        "widget_field": "European",
        "widget_type": "ethnicity"
    },
    {
        "widget_field": "East and South Asian",
        "widget_type": "ethnicity"
    },
    {
        "widget_field": "Likely African-American",
        "widget_type": "ethnicity"
    },
    {
        "widget_field": "No College Degree",
        "widget_type": "education_2"
    },
    {
        "widget_field": "Unknown",
        "widget_type": "education_2"
    },
    {
        "widget_field": "HS Diploma - Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Some College - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Vocational Technical Degree - Extremely Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Grad/Prof Degree",
        "widget_type": "education_1"
    },
    {
        "widget_field": "Y",
        "widget_type": "likely_primary_voter"
    },
    {
        "widget_field": "N",
        "widget_type": "likely_primary_voter"
    },
    {
        "widget_field": "OH",
        "widget_type": "state"
    },
    {
        "widget_field": "NV",
        "widget_type": "state"
    },
    {
        "widget_field": "IL",
        "widget_type": "state"
    },
    {
        "widget_field": "KY",
        "widget_type": "state"
    },
    {
        "widget_field": "ID",
        "widget_type": "state"
    },
    {
        "widget_field": "WY",
        "widget_type": "state"
    },
    {
        "widget_field": "PA",
        "widget_type": "state"
    },
    {
        "widget_field": "OR",
        "widget_type": "state"
    },
    {
        "widget_field": "MD",
        "widget_type": "state"
    },
    {
        "widget_field": "MT",
        "widget_type": "state"
    },
    {
        "widget_field": "AZ",
        "widget_type": "state"
    },
    {
        "widget_field": "PR",
        "widget_type": "state"
    },
    {
        "widget_field": "ND",
        "widget_type": "state"
    },
    {
        "widget_field": "ME",
        "widget_type": "state"
    },
    {
        "widget_field": "HI",
        "widget_type": "state"
    },
    {
        "widget_field": "AK",
        "widget_type": "state"
    },
    {
        "widget_field": "TX",
        "widget_type": "state"
    },
    {
        "widget_field": "AUSTIN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ALEXANDRIA LA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ROANOKE-LYNCHBURG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "VICTORIA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "YUMA-EL CENTRO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CASPER-RIVERTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLUMBUS OH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SAN FRANCISCO-OAK-SAN JOSE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PITTSBURGH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHEYENNE-SCOTTSBLUFF DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "QUINCY-HANNIBAL-KEOKUK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LAFAYETTE IN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "NORFOLK-PORTSMTH-NEWPT NWS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MOBILE-PENSACOLA (FT WALT) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LINCOLN & HASTINGS-KRNY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WILMINGTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WILKES BARRE-SCRANTON-HZTN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BURLINGTON-PLATTSBURGH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HELENA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PORTLAND-AUBURN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DAVENPORT-R.ISLAND-MOLINE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ROCHESTER NY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "RALEIGH-DURHAM (FAYETVLLE) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SPRINGFIELD-HOLYOKE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TRAVERSE CITY-CADILLAC DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHICO-REDDING DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BLUEFIELD-BECKLEY-OAK HILL DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "OMAHA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SHERMAN-ADA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREENWOOD-GREENVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HUNTSVILLE-DECATUR (FLOR) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "FAIRBANKS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PROVIDENCE-NEW BEDFORD DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JOPLIN-PITTSBURG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HARLINGEN-WSLCO-BRNSVL-MCA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MONROE-EL DORADO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MARQUETTE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLUMBIA-JEFFERSON CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TUCSON (SIERRA VISTA) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "NEW ORLEANS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SACRAMNTO-STKTON-MODESTO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LEXINGTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "OTTUMWA-KIRKSVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TWIN FALLS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GREENVLL-SPART-ASHEVLL-AND DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DALLAS-FT. WORTH DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TALLAHASSEE-THOMASVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLORADO SPRINGS-PUEBLO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PADUCAH-CAPE GIRARD-HARSBG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHATTANOOGA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ODESSA-MIDLAND DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BOSTON (MANCHESTER) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PANAMA CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "F",
        "widget_type": "gender"
    },
    {
        "widget_field": "M",
        "widget_type": "gender"
    },
    {
        "widget_field": "Not Hispanic",
        "widget_type": "ethnicity_language_hispanic"
    },
    {
        "widget_field": "Likely English Speaker",
        "widget_type": "language"
    },
    {
        "widget_field": "No children",
        "widget_type": "presence_of_children"
    },
    {
        "widget_field": "Hindu",
        "widget_type": "religion"
    },
    {
        "widget_field": "Jewish",
        "widget_type": "religion"
    },
    {
        "widget_field": "Not Married",
        "widget_type": "marital_status"
    },
    {
        "widget_field": "Other",
        "widget_type": "party"
    },
    {
        "widget_field": "Gen X",
        "widget_type": "age_range"
    },
    {
        "widget_field": "Other",
        "widget_type": "ethnicity"
    },
    {
        "widget_field": "Hispanic and Portuguese",
        "widget_type": "ethnicity"
    },
    {
        "widget_field": "Grad Degree - Likely",
        "widget_type": "education"
    },
    {
        "widget_field": "Hispanic",
        "widget_type": "ethnicity_hispanic"
    },
    {
        "widget_field": "Unknown",
        "widget_type": "education_1"
    },
    {
        "widget_field": "Bachelor's Degree",
        "widget_type": "education_1"
    },
    {
        "widget_field": "30 to 39",
        "widget_type": "age_range_standard"
    },
    {
        "widget_field": "NJ",
        "widget_type": "state"
    },
    {
        "widget_field": "SD",
        "widget_type": "state"
    },
    {
        "widget_field": "SC",
        "widget_type": "state"
    },
    {
        "widget_field": "VA",
        "widget_type": "state"
    },
    {
        "widget_field": "DE",
        "widget_type": "state"
    },
    {
        "widget_field": "IA",
        "widget_type": "state"
    },
    {
        "widget_field": "NE",
        "widget_type": "state"
    },
    {
        "widget_field": "GA",
        "widget_type": "state"
    },
    {
        "widget_field": "FL",
        "widget_type": "state"
    },
    {
        "widget_field": "CA",
        "widget_type": "state"
    },
    {
        "widget_field": "WA",
        "widget_type": "state"
    },
    {
        "widget_field": "SAN ANTONIO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "HARRISONBURG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLUMBIA SC DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TERRE HAUTE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DETROIT DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ALBANY-SCHENECTADY-TROY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MINOT-BSMRCK-DCKNSN(WLSTN) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "RENO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GLENDIVE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SEATTLE-TACOMA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TULSA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JACKSONVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CINCINNATI DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SYRACUSE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WICHITA-HUTCHINSON PLUS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "GRAND JUNCTION-MONTROSE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "TOLEDO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ROCKFORD DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DAYTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "RICHMOND-PETERSBURG DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ATLANTA DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHARLESTON-HUNTINGTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WAUSAU-RHINELANDER DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "IDAHO FALS-POCATLLO(JCKSN) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHARLESTON SC DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BOISE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BAKERSFIELD DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SALT LAKE CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "PEORIA-BLOOMINGTON DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ST. LOUIS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "DULUTH-SUPERIOR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JONESBORO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ZANESVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BILOXI-GULFPORT DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "LUBBOCK DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BUFFALO DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "JACKSON MS DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MYRTLE BEACH-FLORENCE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SANTABARBRA-SANMAR-SANLUOB DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "RAPID CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "COLUMBUS GA (OPELIKA AL) DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "ORLANDO-DAYTONA BCH-MELBRN DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MIAMI-FT. LAUDERDALE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "WEST PALM BEACH-FT. PIERCE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "CHARLOTTESVILLE DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "BANGOR DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "SIOUX CITY DMA",
        "widget_type": "dma"
    },
    {
        "widget_field": "MERIDIAN DMA",
        "widget_type": "dma"
    },
  {widget_field: 'lehighvalleylive.com', widget_type: 'source'},
  {widget_field: 'advancelocal.arcpublishing.com', widget_type: 'source'},
  {widget_field: 'annarbor.com', widget_type: 'source'},
  {widget_field: 'theoregonian.com', widget_type: 'source'},
  {widget_field: 'nj.com', widget_type: 'source'},
  {widget_field: 'aol.com', widget_type: 'source'},
  {widget_field: 'Apple', widget_type: 'source'},
  {widget_field: 'Bing', widget_type: 'source'},
  {widget_field: 'cnn.com', widget_type: 'source'},
  {widget_field: 'DuckDuckGo', widget_type: 'source'},
  {widget_field: 'Email Newsletter', widget_type: 'source'},
  {widget_field: 'Facebook', widget_type: 'source'},
  {widget_field: 'Flipboard', widget_type: 'source'},
  {widget_field: 'Google', widget_type: 'source'},
  {widget_field: 'Instagram', widget_type: 'source'},
  {widget_field: 'Legacy', widget_type: 'source'},
  {widget_field: 'LinkedIn', widget_type: 'source'},
  {widget_field: 'MSN', widget_type: 'source'},
  {widget_field: 'NewsBreak', widget_type: 'source'},
  {widget_field: 'Pinterest', widget_type: 'source'},
  {widget_field: 'Politico', widget_type: 'source'},
  {widget_field: 'Reddit', widget_type: 'source'},
  {widget_field: '247sports.com', widget_type: 'source'},
  {widget_field: 'tiderinsider.com', widget_type: 'source'},
  {widget_field: 'detroitlions.com', widget_type: 'source'},
  {widget_field: 'foxsports.com', widget_type: 'source'},
  {widget_field: 'rutgers.forums.rivals.com', widget_type: 'source'},
  {widget_field: 'rollbamaroll.com', widget_type: 'source'},
  {widget_field: 'syracusefan.com', widget_type: 'source'},
  {widget_field: 'tidefans.com', widget_type: 'source'},
  {widget_field: 'fanrecap.com', widget_type: 'source'},
  {widget_field: 'espn.com', widget_type: 'source'},
  {widget_field: 'njschoolsports.com', widget_type: 'source'},
  {widget_field: 'auburn.forums.rivals.com', widget_type: 'source'},
  {widget_field: 'Twitter', widget_type: 'source'},
  {widget_field: 'Wikipedia', widget_type: 'source'},
  {widget_field: 'Yahoo', widget_type: 'source'},
  {widget_field: 'YouTube', widget_type: 'source'},
  {widget_field: 'drudgereport.com', widget_type: 'source'},
  {widget_field: 'nextdoor.com', widget_type: 'source'},
  {widget_field: 'ground.news', widget_type: 'source'},
  {widget_field: 'citizenfreepress.com', widget_type: 'source'},
  {widget_field: 'on3.com', widget_type: 'source'},
  {widget_field: 'smartnews.com', widget_type: 'source'},
  {widget_field: 'other', widget_type: 'source'}
];